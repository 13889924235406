import React from 'react';
import { useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { ROUTER_PATHS, NETZSCH_API, DEFAULT_VALUES, SETTINGS_DIALOG } from '@common/constants';
import { setShowSettingsDialog, selectRules } from '@common/slices/userSlice/userSlice';

import { ReactComponent as Logo } from '../logo/Logo_MYN_AST.svg';

const sxIcon = {
  color: 'primary',
  height: 'auto',
  width: '30px',
  mt: 3,
  '&:hover': {
    backgroundColor: 'transparent'
  }
};

const sxIconButton = {
  mr: 1,
  color: (theme) => theme.palette.primary.main,
  height: '35px',
  width: '35px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
};

const NavBar = ({ handleDrawerClose, showMenu }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const navigate = useNavigate();
  const rules = useSelector(selectRules);

  let pathname = window.location.pathname;

  const onLogoClick = () => {
    navigate({ to: ROUTER_PATHS.analyzer, replace: false });
  };

  const onShowPersonalSettingsDialogClick = () => {
    dispatch(setShowSettingsDialog(SETTINGS_DIALOG.SESSION));
  };

  const onAdminIconClick = () => {
    window.open(`${NETZSCH_API.API_URL}${ROUTER_PATHS.admin}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        height: '54px',
        // boxShadow: 0,
        display: 'inline-block',
        backgroundColor: 'white'
      }}
    >
      <Button
        onClick={onLogoClick}
        sx={{
          float: 'left',
          p: 0,
          backgroundColor:
            pathname === ROUTER_PATHS.analyzer
              ? theme.palette.primary.dark
              : DEFAULT_VALUES.COLOR.NONE
        }}
        color="inherit"
        variant="raised"
        style={{ backgroundColor: 'transparent' }}
        disabled={pathname === ROUTER_PATHS.analyzer}
      >
        <Box sx={{ pl: 2, display: 'flex', pb: 1, pt: 2 }}>
          <Logo className="astLogo" />
        </Box>
      </Button>

      <Box sx={{ float: 'right' }}>
        {pathname === ROUTER_PATHS.analyzer && (
          <IconButton
            sx={sxIconButton}
            onClick={onShowPersonalSettingsDialogClick}
            hidden={pathname === ROUTER_PATHS.admin}
          >
            <AccountCircleIcon sx={{ ...sxIcon, color: 'gray' }} />
          </IconButton>
        )}
        <IconButton sx={sxIconButton} onClick={handleDrawerClose}>
          {showMenu ? <MenuOpenIcon sx={sxIcon} /> : <MenuIcon sx={sxIcon} />}
        </IconButton>
        {rules?.navBar?.adminEnabled && (
          <IconButton sx={sxIconButton} onClick={onAdminIconClick}>
            <SettingsIcon sx={sxIcon} />
          </IconButton>
        )}
      </Box>
    </AppBar>
  );
};

export default NavBar;
