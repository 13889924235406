import { createSlice } from '@reduxjs/toolkit';

const newDutyPoint = {
  name: undefined,
  suctionPressure: undefined,
  dischargePressure: undefined,
  temperature: undefined,
  capacity: undefined,
  speed: undefined,
  viscosity: undefined
};

const initialState = {
  dutyPoints: [newDutyPoint],
  capacityOrSpeed: 'capacity',
  isChanged: true,
  nextAllowed: false,
  expertNeeded: false,
  errors: [
    {
      name: 'requiredField',
      suctionPressure: 'requiredField',
      dischargePressure: 'requiredField',
      temperature: 'requiredField',
      capacity: 'requiredField',
      speed: 'requiredField',
      viscosity: 'requiredField'
    }
  ]
};

export const operationConditionSlice = createSlice({
  name: 'operationCondition',
  initialState,
  reducers: {
    setCapacityOrSpeed: (state, { payload }) => {
      state.capacityOrSpeed = payload;
      state.isChanged = true;
    },
    onAddClick: (state) => {
      state.dutyPoints = [...state.dutyPoints, newDutyPoint];
      state.isChanged = true;
    },
    onCopyClick: (state, { payload }) => {
      state.dutyPoints = [...state.dutyPoints, payload];
      state.isChanged = true;
    },
    setDPIsChange: (state) => {
      state.isChanged = false;
    },
    onDeleteClick: (state, { payload }) => {
      state.dutyPoints = state.dutyPoints.filter((_, stateIndex) => payload !== stateIndex);
      state.isChanged = true;
    },
    onChangeDPValue: (state, { payload }) => {
      const { value, fieldName, dpIndex } = payload;
      if (
        (fieldName === 'speed' && value.length < 7) ||
        (fieldName === 'speed' && value.length < 20) ||
        value.length < 12
      ) {
        state.dutyPoints[dpIndex][fieldName] = value === '' ? undefined : value;
        state.isChanged = true;
      }
    },
    setValidation: (state, { payload }) => {
      state.errors = payload.errors;
      state.nextAllowed = payload.nextAllowed;
    },
    setDPStateFromCPQ: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setExpertNeeded: (state, { payload }) => {
      state.expertNeeded = payload;
    }
  }
});

export const selectDutyPoints = (state) => state.operationCondition.dutyPoints;
export const selectCapacityOrSpeed = (state) => state.operationCondition.capacityOrSpeed;
export const selectOperationConditionNextAllowed = (state) => state.operationCondition.nextAllowed;
export const selectErrors = (state) => state.operationCondition.errors;
export const selectExpertNeeded = (state) => state.operationCondition.expertNeeded;
export const selectExpertModeNextAllowed = (state) =>
  !state.operationCondition?.expertNeeded || state.user.expertModeAllowed;

const { reducer, actions } = operationConditionSlice;

export const {
  setCapacityOrSpeed,
  onAddClick,
  onCopyClick,
  onDeleteClick,
  onChangeDPValue,
  setValidation,
  setDPStateFromCPQ,
  setDPIsChange,
  setExpertNeeded
} = actions;

export default reducer;
