import { useEffect, useState } from 'react';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN, TRANSLATION } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE,
  REPLACE_TOAST_SUCCESS_STATE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';



import { transformTranslationHeaders } from './utils';
import { TRANSLATION_PROPERTIES_LIST } from '../config/uploadProducts';

export default function useTranslationsPanel() {
  const [translationList, setTranslationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );
  const [languages, setLanguages] = useState([]);

  const onUploadCSVOfTranslation = (event, type) => {
    const files = event.target.files;
    if (files) {
      setUploadIsLoading(true);
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: transformTranslationHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(
              results.data,
              TRANSLATION_PROPERTIES_LIST
            );

            if (!isInvalid) {
              const path =
                type === 'upsert'
                  ? ADMIN.UPSERT_TRANSLATION
                  : ADMIN.REPLACE_TRANSLATION;
              await postByPathAndData({
                path,
                data: results.data
              });

              if (type === 'upsert')
                setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
              else setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchTranslationTable();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message || DEFAULT_ERROR_MESSAGE
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const fetchTranslationTable = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_TRANSLATION_PARAMETERS
      });
      setTranslationList(
        data.map(({ translation_id, language_id, value }) => {
          return {
            TranslationId: translation_id,
            LanguageId: language_id,
            Value: value
          };
        })
      );
    } catch {
      console.log('err fetchTranslationTable');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTranslationLanguages = async () => {
    try {
      let { data } = await getByPathAndParams({
        path: TRANSLATION.LANGUAGES
      });
      setLanguages(data);
    } catch {
      console.log('err fetchTranslationLanguages');
    }
  };

  useEffect(() => {
    fetchTranslationTable();
    fetchTranslationLanguages();
  }, []);

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const downloadCSVOfTranslation = () => {
    const csv = Papa.unparse(translationList, {
      delimiter: ';',
      encoding: 'UTF-8'
    });

    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    link.download = 'translation.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    translationList,
    isLoading,
    uploadIsLoading,
    uploadToastState,
    languages,
    onUploadCSVOfTranslation,
    setUploadToastToInitial,
    downloadCSVOfTranslation
  };
}
