import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';

import { SEARCH_METHOD } from '@common/constants';
import {
  APP_LAYOUT_STEPS,
  FIRST_SUB_STEP,
  ONE_STEP,
  STEP_VALUES
} from '@common/slices/layoutSlice/config/layoutConfig';
import {
  selectCurrentStep,
  selectCurrentSubStep,
  setSteps
} from '@common/slices/layoutSlice/layoutSlice';
import { selectSearchType } from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectRules } from '@common/slices/userSlice/userSlice';
import ExpertWarningIcon from '@components/ExpertWarningIcon';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';

const AnalyzerFooter = ({
  nextDisabled,
  backDisabled,
  expertNeeded,
  pdfEnabled,
  setPdfDialogOpen = () => {},
  configureButton
}) => {
  const dispatch = useDispatch();
  const userRules = useSelector(selectRules);

  const activeStep = useSelector(selectCurrentStep);
  const activeSubStep = useSelector(selectCurrentSubStep);
  const selectedSearchType = useSelector(selectSearchType);

  const handleNext = () => {
    if (activeSubStep.value + ONE_STEP < activeStep?.subSteps?.length) {
      dispatch(
        setSteps({
          currentSubStep: activeStep.subSteps[activeSubStep.value + ONE_STEP]
        })
      );
    } else {
      const nextStep = APP_LAYOUT_STEPS.config[activeStep.value + ONE_STEP];
      dispatch(
        setSteps({
          currentSubStep: nextStep.subSteps[FIRST_SUB_STEP],
          currentStep: nextStep
        })
      );
    }
  };

  const handleBack = () => {
    if (activeStep.value === STEP_VALUES.result.value) {
      const previousStep = APP_LAYOUT_STEPS.config[activeStep.value - ONE_STEP];
      switch (selectedSearchType) {
        case SEARCH_METHOD.MULTI_SELECT:
          dispatch(
            setSteps({
              currentSubStep: previousStep.subSteps[STEP_VALUES.productRange.value],
              currentStep: previousStep
            })
          );
          break;
        case SEARCH_METHOD.OTHER_CRITERIA:
          dispatch(
            setSteps({
              currentSubStep: previousStep.subSteps[STEP_VALUES.otherCriteria.value],
              currentStep: previousStep
            })
          );
          break;
        case SEARCH_METHOD.NO_RESTRICTION:
          dispatch(
            setSteps({
              currentSubStep: previousStep.subSteps[FIRST_SUB_STEP],
              currentStep: previousStep
            })
          );
          break;
        case SEARCH_METHOD.MANUAL_CONFIG:
          dispatch(
            setSteps({
              currentSubStep: previousStep.subSteps[STEP_VALUES.productRange.value],
              currentStep: previousStep
            })
          );
          break;
        default:
          break;
      }
    } else if (activeSubStep.value === FIRST_SUB_STEP) {
      const backStep = APP_LAYOUT_STEPS.config[activeStep.value - ONE_STEP];
      dispatch(
        setSteps({
          currentStep: backStep,
          currentSubStep: backStep?.subSteps[backStep?.subSteps?.length - ONE_STEP]
        })
      );
    } else {
      dispatch(
        setSteps({
          currentSubStep: activeStep?.subSteps[activeSubStep.value - ONE_STEP]
        })
      );
    }
  };

  const handleChoose = () => {
    if (
      (activeSubStep?.value === STEP_VALUES.productRange.value &&
        selectedSearchType !== SEARCH_METHOD.OTHER_CRITERIA) ||
      activeSubStep?.value === STEP_VALUES.otherCriteria.value
    ) {
      const nextStep = APP_LAYOUT_STEPS.config[activeStep.value + ONE_STEP];
      dispatch(
        setSteps({
          currentStep: nextStep,
          currentSubStep: nextStep.subSteps[FIRST_SUB_STEP]
        })
      );
    } else {
      dispatch(
        setSteps({
          currentSubStep: activeStep.subSteps[activeSubStep.value + ONE_STEP]
        })
      );
    }
  };

  const showNextButton = () => {
    if (activeStep.value !== STEP_VALUES.searchCriteria.value) return true;
    else return false;
  };

  const showChooseButton = () => {
    if (
      activeStep.value === STEP_VALUES.searchCriteria.value &&
      !(
        activeSubStep.value === FIRST_SUB_STEP ||
        (selectedSearchType === SEARCH_METHOD.MANUAL_CONFIG &&
          activeSubStep.value === STEP_VALUES.productRange.value)
      )
    )
      return true;
    else return false;
  };

  return (
    <SalesCloudContainer
      providedClassNames={{
        classNameCpq: 'footerContainerCpq',
        classNameStandalone: 'footerContainer'
      }}
    >
      <Button
        variant="contained"
        color="primary"
        disabled={
          backDisabled ||
          (activeStep.value === STEP_VALUES.generalAttributes.value &&
            activeSubStep.value === FIRST_SUB_STEP)
        }
        onClick={handleBack}
        className="footerButton"
      >
        {Localize.get('back').defaultMessage('back')}
      </Button>

      {showNextButton() && (
        <Button
          variant="contained"
          color="primary"
          disabled={
            nextDisabled ||
            (activeStep.value === STEP_VALUES.operationConditions.value &&
              expertNeeded &&
              Boolean(!userRules?.stepThree?.nextEnabledForExpertMode))
          }
          onClick={handleNext}
          className="footerButton"
        >
          {Localize.get('next').defaultMessage('next')}
        </Button>
      )}

      {showChooseButton() && (
        <Button
          variant="contained"
          color="primary"
          disabled={nextDisabled}
          onClick={handleChoose}
          className="footerButton"
        >
          {activeSubStep.value === STEP_VALUES.otherCriteria.value
            ? Localize.get('showResult').defaultMessage('showResult')
            : Localize.get('next').defaultMessage('next')}
        </Button>
      )}

      {configureButton}

      {activeStep.value === STEP_VALUES.result.value && (
        <Button
          variant="contained"
          color="primary"
          disabled={!pdfEnabled}
          className="footerButton"
          onClick={() => setPdfDialogOpen(true)}
        >
          {Localize.get('generatePdf').defaultMessage('generatePdf')}
        </Button>
      )}

      {expertNeeded && <ExpertWarningIcon />}
    </SalesCloudContainer>
  );
};

export default AnalyzerFooter;
