import get from 'lodash/get';
import * as yup from 'yup';

const HAS_TO_BE_STRING = 'hasToBeString';
const HAS_TO_BE_NUMBER = 'hasToBeNumber';
const HAS_TO_BE_POSITIVE_NUMBER = 'hasToBePositive';
const REQUIRED_FIELD = 'requiredField';
const NAME_IS_UNIQUE = 'hasToBeUnique';
const MIN_TEMP_ALLOWED = 'minAllowedTemperature';
const MAX_TEMP_ALLOWED = 'maxAllowedTemperature';

yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => get(x, path);
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);

    if (list[idx][path] === '') return true;
    return this.createError({ path: `[${idx}].${path}`, message });
  });
});

const dutyPointsValidator = (capacityOrSpeed) =>
  yup
    .array()
    .of(
      yup.object().shape(
        capacityOrSpeed === 'capacity'
          ? {
              name: yup.string().typeError(HAS_TO_BE_STRING).required(REQUIRED_FIELD),
              suctionPressure: yup.number().typeError(HAS_TO_BE_NUMBER).required(REQUIRED_FIELD),
              dischargePressure: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER),
              temperature: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(-40, MIN_TEMP_ALLOWED)
                .max(300, MAX_TEMP_ALLOWED),
              capacity: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER),
              viscosity: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER)
            }
          : {
              name: yup.string().typeError(HAS_TO_BE_STRING).required(REQUIRED_FIELD),
              suctionPressure: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
              dischargePressure: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER),
              temperature: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(-40, MIN_TEMP_ALLOWED)
                .max(300, MAX_TEMP_ALLOWED),
              speed: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER),
              viscosity: yup
                .number()
                .typeError(HAS_TO_BE_NUMBER)
                .required(REQUIRED_FIELD)
                .min(0, HAS_TO_BE_POSITIVE_NUMBER)
            }
      )
    )
    .min(1)
    .max(6)
    .unique(NAME_IS_UNIQUE, 'name');

export default dutyPointsValidator;
