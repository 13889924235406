import { createAction, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import { setSearchMethodSliceToInit } from '@common/slices/searchMethodSlice/searchMethodSlice';

const unitsMiddlewareListener = createListenerMiddleware();

const action1 = createAction('unitsAndAttributes/setSessionUnitMetrics');
const action2 = createAction('unitsAndAttributes/setUserUnitSystemName');

unitsMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setSearchMethodSliceToInit());
    listenerApi.dispatch(setResultListToInit());
  }
});

export default unitsMiddlewareListener;
