import React from 'react';
import Localize from 'react-intl-universal';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { SEARCH_IMAGES_COLUMNS } from '@common/constants';
import DataGridList from '@components/DataGridList';
import ImagePreview from '@components/ImagePreview/ImagePreview';
import Loader from '@components/Loader';
import StyledButton from '@components/StyledButton/StyledButton';
import Toast from '@components/Toast';
import useGetImage from '@hooks/useGetImage';
import AdminLayout from '@pages/Admin/components/Layout';
import usePicturesPanel from '@pages/Admin/hooks/usePicturesPanel';
import useStatorPicturePanel from '@pages/Admin/hooks/useStatorPicturePanel';

import statorPictureMappingListConfig from '../config/statorPictureMappingList';

const StatorPicturePanel = () => {
  const [
    statorPictureMappingList,
    statorPictureIsLoading,
    uploadToastState,
    setUploadToastToInitial,
    selectedStatorPicture,
    setSelectionModel,
    setLinkMode,
    linkMode,
    onLinkSaveClick
  ] = useStatorPicturePanel();

  const [listOfImages, isLoading, , , , selectedPicItem, onItemClick, , , , , , , , imagePicData] =
    usePicturesPanel();

  const [imageData, imageIsLoading] = useGetImage(selectedStatorPicture?.ImageId);

  return (
    <Box>
      <Loader isLoading={statorPictureIsLoading || imageIsLoading || isLoading} />
      <Toast
        open={uploadToastState.open}
        handleClose={setUploadToastToInitial}
        status={uploadToastState.status}
        message={uploadToastState.message}
      />

      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                density="compact"
                rows={statorPictureMappingList}
                getRowId={(item) => {
                  return `${item.StatorType}-${item.Geometry}-${item.GeometryType}`;
                }}
                columns={statorPictureMappingListConfig}
                pageSize={10}
                selectionModel={[
                  `${selectedStatorPicture?.StatorType}-${selectedStatorPicture?.Geometry}-${selectedStatorPicture?.GeometryType}`
                ]}
                onSelectionModelChange={setSelectionModel}
                columnHeaderHeight={100}
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
              />
            </Box>
          </Box>
        }
        detailsPanel={
          <Box className="adminDetailsContainer">
            <StyledButton
              disabled={!selectedStatorPicture || Boolean(linkMode)}
              onClick={() => setLinkMode('link')}
              sx={{ mb: 1 }}
            >
              {Localize.get('linkStator&Image').defaultMessage('linkStator&Image')}
            </StyledButton>
            <Box>
              <Typography variant="h6">
                {linkMode
                  ? Localize.get('linkStator&ImageTempl', [
                      selectedStatorPicture?.StatorType
                    ]).defaultMessage('linkStator&ImageTempl')
                  : Localize.get('statorPicMapping').defaultMessage('statorPicMapping')}
              </Typography>
            </Box>
            {Boolean(selectedStatorPicture) && (
              <Box>
                {linkMode ? (
                  <Box>
                    <DataGridList
                      density="compact"
                      rows={listOfImages}
                      getRowId={(item) => item.id}
                      columns={SEARCH_IMAGES_COLUMNS}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      selectionModel={selectedPicItem}
                      onSelectionModelChange={onItemClick}
                      sx={{ fontSize: '12px', height: '255px', mb: 1 }}
                      scrollable="scrollable"
                      fixedSCroll="true"
                    />
                    {imagePicData?.image && <ImagePreview data={imagePicData?.image} />}
                    <Box sx={{ mt: 1 }}>
                      <StyledButton
                        sx={{ mr: 1 }}
                        disabled={!imagePicData?.id || !selectedStatorPicture}
                        onClick={() =>
                          onLinkSaveClick(
                            selectedStatorPicture.StatorType,
                            selectedStatorPicture.Geometry,
                            selectedStatorPicture.GeometryType,
                            imagePicData.id
                          )
                        }
                      >
                        {Localize.get('save&Link').defaultMessage('save&Link')}
                      </StyledButton>
                      <StyledButton onClick={() => setLinkMode(undefined)}>
                        {Localize.get('cancel').defaultMessage('cancel')}
                      </StyledButton>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ pb: 2 }}>
                      {Localize.get('selectedStatorType').defaultMessage('selectedStatorType') +
                        ' ' +
                        selectedStatorPicture?.StatorType}
                    </Box>
                    {imageData?.image && <ImagePreview data={imageData?.image} />}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default StatorPicturePanel;
