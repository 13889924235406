import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchMetricsAndPositions } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { fetchLanguages } from '@common/slices/userSlice/userSlice';

const useUnitsAndAttributePositions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMetricsAndPositions());
    /* eslint-disable */
  }, []);

  useEffect(() => {
    dispatch(fetchLanguages());

    /* eslint-disable-next-line */
  }, []);

  return {};
};

export default useUnitsAndAttributePositions;
