import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ROUTER_PATHS, NETZSCH_API, SETTINGS_DIALOG } from '@common/constants';
import { APP_LAYOUT_STEPS } from '@common/slices/layoutSlice/config/layoutConfig';
import { selectOpenAccordions, setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import { setShowSettingsDialog, selectRules } from '@common/slices/userSlice/userSlice';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import useGetValueByKey from '@hooks/useGetValueByKey';

import MenuAccordion from './MenuAccordion';
import { ReactComponent as Logo } from '../../../navigation/logo/Logo_MYN_AST.svg';

const sideBarConfig = APP_LAYOUT_STEPS.config;

const NavigationMenu = ({ activeStep, isLaptopAndUp }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getValueByKey = useGetValueByKey();

  const rules = useSelector(selectRules);
  const openAccordions = useSelector(selectOpenAccordions);

  let pathname = window.location.pathname;

  const onLogoClick = () => {
    navigate({ to: ROUTER_PATHS.analyzer, replace: false });
  };

  const onShowPersonalSettingsDialogClick = () => {
    dispatch(setShowSettingsDialog(SETTINGS_DIALOG.PERSONAL));
  };

  const onAdminIconClick = () => {
    window.open(`${NETZSCH_API.API_URL}${ROUTER_PATHS.admin}`, '_blank', 'noopener,noreferrer');
  };

  const handleAccordionClick = (step) => {
    dispatch(setOpenAccordions(step));
  };

  return (
    <>
      {isLaptopAndUp && (
        <Box className="sideBarHeader">
          <Button
            className="astSidebarButton"
            onClick={onLogoClick}
            color="inherit"
            variant="raised"
            disabled={pathname === ROUTER_PATHS.analyzer}
          >
            <Logo className="astLogo" />
          </Button>
          {pathname !== ROUTER_PATHS.admin && (
            <Box sx={{ float: 'right' }}>
              {rules?.navBar?.adminEnabled && window.self === window.top && (
                <IconButton
                  color="primary"
                  className="astSidebarAdminButton"
                  onClick={onAdminIconClick}
                >
                  <SettingsIcon color="primary" className="astSidebarAdminIcon" />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      )}

      {pathname === ROUTER_PATHS.analyzer && (
        <SalesCloudContainer
          providedClassNames={{
            classNameCpq: 'sidebarAccordionContainerCpq',
            classNameStandalone: 'sidebarAccordionContainer'
          }}
        >
          {sideBarConfig.map((group) => {
            return (
              <MenuAccordion
                key={group.value}
                group={group}
                getValueByKey={getValueByKey}
                activeStep={activeStep}
                isOpen={openAccordions.includes(group.value)}
                handleAccordionClick={() => handleAccordionClick(group.value)}
              />
            );
          })}
        </SalesCloudContainer>
      )}
      {isLaptopAndUp && (
        <SalesCloudContainer
          providedClassNames={{
            classNameCpq: 'astSideBarUserContainerCpq',
            classNameStandalone: 'astSideBarUserContainer'
          }}
        >
          <IconButton
            onClick={onShowPersonalSettingsDialogClick}
            hidden={pathname === ROUTER_PATHS.admin}
            className="astSidebarUserButton"
          >
            <AccountCircleIcon className="astSidebarUserIcon" />
          </IconButton>
          <Typography className="astSidebarUserLabel">
            {Localize.get('userSettings').defaultMessage('userSettings')}
          </Typography>
        </SalesCloudContainer>
      )}
    </>
  );
};

export default NavigationMenu;
