import React from 'react';
import Localize from 'react-intl-universal';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { SEARCH_IMAGES_COLUMNS } from '@common/constants';
import DataGridList from '@components/DataGridList';
import ImagePreview from '@components/ImagePreview/ImagePreview';
import Loader from '@components/Loader';
import StyledButton from '@components/StyledButton/StyledButton';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import useGetImage from '@hooks/useGetImage';
import AdminLayout from '@pages/Admin/components/Layout';
import usePicturesPanel from '@pages/Admin/hooks/usePicturesPanel';
import useProductPanel from '@pages/Admin/hooks/useProductPanel';

import productListConfig from '../config/productList';

const ProductsPanel = () => {
  const [
    productList,
    productIsLoading,
    onCSVUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial,
    selectedProduct,
    setSelectionModel,
    setLinkMode,
    linkMode,
    onLinkSaveClick
  ] = useProductPanel();

  const [listOfImages, isLoading, , , , selectedPicItem, onItemClick, , , , , , , , imagePicData] =
    usePicturesPanel();

  const [imageData, imageIsLoading] = useGetImage(selectedProduct?.ImageId);

  return (
    <Box>
      <Loader isLoading={uploadIsLoading || productIsLoading || imageIsLoading || isLoading} />
      <Toast
        open={uploadToastState.open}
        handleClose={setUploadToastToInitial}
        status={uploadToastState.status}
        message={uploadToastState.message}
      />

      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                density="compact"
                rows={productList}
                getRowId={(item) => item.ProductCode}
                columns={productListConfig}
                pageSize={50}
                selectionModel={selectedProduct?.ProductCode}
                onSelectionModelChange={setSelectionModel}
                columnHeaderHeight={100}
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
                disableColumnFilter={Boolean(linkMode)}
                disableColumnMenu={Boolean(linkMode)}
                disableColumnSelector={Boolean(linkMode)}
                disableDensitySelector={Boolean(linkMode)}
                disableExtendRowFullWidth={Boolean(linkMode)}
                disableSelectionOnClick={Boolean(linkMode)}
              />
            </Box>

            <Box className="adminListFooter">
              <UploadButton
                onChange={(e) => onCSVUpload(e, 'upsert')}
                id="upload-button-file"
                content={Localize.get('upsertData').defaultMessage('upsertData')}
              />
              <UploadButton
                onChange={(e) => onCSVUpload(e, 'replace')}
                id="replace-button-file"
                content={Localize.get('replaceData').defaultMessage('replaceData')}
              />
            </Box>
          </Box>
        }
        detailsPanel={
          <Box className="adminDetailsContainer">
            <StyledButton
              disabled={
                !selectedProduct?.ProductCode || Boolean(linkMode) || selectedProduct?.Level === 3
              }
              onClick={() => setLinkMode('link')}
            >
              {Localize.get('linkProduct&Image').defaultMessage('linkProduct&Image')}
            </StyledButton>
            <Box>
              <Typography variant="h6">
                {linkMode
                  ? Localize.get('linkProduct&ImageTempl', [
                      selectedProduct?.ProductCode
                    ]).defaultMessage('linkProduct&ImageTempl')
                  : Localize.get('productDetails').defaultMessage('productDetails')}
              </Typography>
            </Box>
            {selectedProduct?.ProductCode && (
              <Box>
                {linkMode ? (
                  <Box>
                    <DataGridList
                      density="compact"
                      rows={listOfImages}
                      getRowId={(item) => item.id}
                      columns={SEARCH_IMAGES_COLUMNS}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      selectionModel={selectedPicItem}
                      onSelectionModelChange={onItemClick}
                      sx={{ fontSize: '12px', height: '255px', mb: 1 }}
                      scrollable="scrollable"
                      fixedSCroll="true"
                    />
                    {imagePicData?.image && <ImagePreview data={imagePicData?.image} />}
                    <Box sx={{ mt: 1 }}>
                      <StyledButton
                        sx={{ mr: 1 }}
                        disabled={!imagePicData?.id || !selectedProduct?.ProductCode}
                        onClick={() =>
                          onLinkSaveClick(selectedProduct.ProductCode, imagePicData.id)
                        }
                      >
                        {Localize.get('save&Link').defaultMessage('save&Link')}
                      </StyledButton>
                      <StyledButton onClick={() => setLinkMode(undefined)}>
                        {Localize.get('cancel').defaultMessage('cancel')}
                      </StyledButton>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ pb: 2 }}>
                      {Localize.get('selectedProduct').defaultMessage('selectedProduct') +
                        ' ' +
                        selectedProduct?.ProductCode}
                    </Box>
                    {imageData?.image && <ImagePreview data={imageData?.image} />}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default ProductsPanel;
