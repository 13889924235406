import { createListenerMiddleware, isAnyOf, createAction } from '@reduxjs/toolkit';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { PRODUCT_PATHS } from '@common/network/ApiPaths';
import { setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import {
  checkDutyPointParams,
  checkFluidParams,
  checkIOAValues,
  mapDPForCalcPerformance,
  mapUnitsForCalcPerformance
} from '@pages/Analyzer/hooks/utils';
import { postByPathAndData } from '@services/BaseApi';

import {
  setIsLoading,
  setListOfPumps,
  setListOfTypeCodes,
  clearInputFields,
  setProductCode,
  setProductRange,
  setSelectPumpToInit
} from '../selectPumpSlice';

const selectPumpSliceMiddlewareListener = createListenerMiddleware();

const action1 = createAction('selectPump/setMaterial');
const action2 = createAction('selectPump/setTypeCode');
const action3 = createAction('searchMethod/onSelectProductClick');
const action4 = createAction('searchMethod/onProductClick');
const action5 = createAction('selectPump/setMaterialElastomer');

selectPumpSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2),
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(setResultListToInit());
  }
});

selectPumpSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action5),
  effect: async (_, listenerApi) => {
    const { layout } = listenerApi.getState();
    if (!layout.openAccordions.includes(4)) listenerApi.dispatch(setOpenAccordions(4));
  }
});

selectPumpSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action3, action4),
  effect: async (_, listenerApi) => {
    const { searchMethod } = listenerApi.getState();
    if (searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
      listenerApi.dispatch(setSelectPumpToInit());
      listenerApi.dispatch(setResultListToInit());
    }
  }
});

// get pumps for other criteria
selectPumpSliceMiddlewareListener.startListening({
  type: 'selectPump/fetchPumpsForOtherCriteria',
  effect: async (action, listenerApi) => {
    const {
      generalAttributes,
      fluidAttributes,
      operationCondition,
      unitsAndAttributes,
      searchMethod,
      selectPump
    } = listenerApi.getState();
    listenerApi.dispatch(setIsLoading(true));
    const selectedProductCodes =
      searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
        ? searchMethod.singleSelect.selectedProductCode.map(({ productCode }) => productCode)
        : searchMethod.multiSelect.selectedProductCodes.map(({ productCode }) => productCode);
    const selectedProductRangeCodes =
      searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
        ? searchMethod.singleSelect.selectedProductRangeCode.map(({ productCode }) => productCode)
        : searchMethod.multiSelect.selectedProductRangeCodes.map(({ productCode }) => productCode);

    if (
      selectPump.productCode !== selectedProductCodes[0] ||
      selectPump.productRange !== selectedProductRangeCodes[0]
    ) {
      const dynamicIOAFieldsValues = generalAttributes.dynamicIOAFieldsValues;
      const dynamicIOAFieldsValuesFormatted = checkIOAValues(dynamicIOAFieldsValues);

      const dynamicFluidFieldsValues = fluidAttributes.dynamicFluidFieldsValues;
      const dynamicFluidFields = fluidAttributes.dynamicFluidFields;
      const unitMetrics = unitsAndAttributes.sessionUnitMetrics;
      const fluidParams = checkFluidParams(
        dynamicFluidFieldsValues,
        unitMetrics,
        dynamicFluidFields
      );

      const dutyPoints = operationCondition.dutyPoints;
      const dutyPointParams = checkDutyPointParams(dutyPoints, unitMetrics);

      listenerApi.dispatch(clearInputFields());
      const { data, status } = await postByPathAndData({
        path: PRODUCT_PATHS.GET_PUMPS_BY_RANGE,
        data: {
          mode:
            operationCondition.capacityOrSpeed === DEFAULT_VALUES.DP_INPUT_VARIATION.CAPACITY
              ? 0
              : 1,
          calculationValues: {
            duty_points: mapDPForCalcPerformance(dutyPoints),
            numberOfLinePoints: 6,
            directionAndMaterial: 0,
            abrasionClass: 0,
            fibresClass: 0
          },
          customUnits: mapUnitsForCalcPerformance(unitMetrics),
          productCodes: selectedProductRangeCodes,
          productParameters: {
            ...dynamicIOAFieldsValuesFormatted,
            ...fluidParams,
            ...dutyPointParams
          }
        }
      });
      if (status !== 200) {
        listenerApi.dispatch(setIsLoading(false));
        throw { err: 'Something went wrong' };
      }

      listenerApi.dispatch(setListOfPumps(data));
      listenerApi.dispatch(setListOfTypeCodes(data));
      listenerApi.dispatch(setProductCode(selectedProductCodes[0]));
      listenerApi.dispatch(setProductRange(selectedProductRangeCodes[0]));
    }
    listenerApi.dispatch(setIsLoading(false));
  }
});

export { selectPumpSliceMiddlewareListener };
