import set from 'lodash/set';

import { createAction, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { SEARCH_METHOD } from '@common/constants';
import { setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import { setSearchMethodSliceToInit } from '@common/slices/searchMethodSlice/searchMethodSlice';
import dutyPointsValidator from '@pages/Analyzer/validators/dutyPointsValidator';

import { setValidation } from '../operationConditionSlice';

const action1 = createAction('operationCondition/onChangeDPValue');
const action2 = createAction('operationCondition/onAddClick');
const action3 = createAction('operationCondition/onCopyClick');
const action4 = createAction('operationCondition/onDeleteClick');

export const validateDutyPoints = async (dutyPoints, capacityOrSpeed) => {
  if (dutyPoints?.length) {
    let isValid = await dutyPointsValidator(capacityOrSpeed).isValid(dutyPoints);
    if (!isValid) {
      const dutyPointValidationState = await dutyPointsValidator(capacityOrSpeed)
        .validate(dutyPoints, { abortEarly: false })
        .catch(function ({ inner = [] }) {
          let newErr = inner.reduce((myObj, currentError) => {
            const { path, message } = currentError;
            let newObj = set(myObj, path, message);
            return newObj;
          }, []);

          return newErr;
        });
      return dutyPointValidationState;
    }
    return [];
  }
  return [];
};

const operationConditionMiddlewareListener = createListenerMiddleware();

operationConditionMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2, action3, action4),
  effect: async (action, listenerApi) => {
    const { operationCondition, layout, user } = listenerApi.getState();
    let errors = await validateDutyPoints(
      operationCondition.dutyPoints,
      operationCondition.capacityOrSpeed
    );

    if (errors.length) {
      listenerApi.dispatch(
        setValidation({
          nextAllowed: false,
          errors
        })
      );
    } else {
      if (
        !layout.openAccordions.includes(3) &&
        (!operationCondition?.expertNeeded || user?.expertModeAllowed)
      )
        listenerApi.dispatch(setOpenAccordions(3));
      listenerApi.dispatch(
        setValidation({
          nextAllowed: true,
          errors: []
        })
      );
    }
  }
});

// const action2 = createAction('operationCondition/onAddClick');
// const action3 = createAction('operationCondition/onCopyClick');
// const action4 = createAction('operationCondition/onDeleteClick');

operationConditionMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2, action3, action4),
  effect: async (_, listenerApi) => {
    const searchMethod = listenerApi.getState()?.searchMethod?.selectedSearchCriteria;
    if (searchMethod !== SEARCH_METHOD.OTHER_CRITERIA) {
      listenerApi.dispatch(setSearchMethodSliceToInit());
      listenerApi.dispatch(setResultListToInit());
    }
  }
});

export default operationConditionMiddlewareListener;
