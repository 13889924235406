import DOMPurify from 'dompurify';

const StringToElement = ({ htmlString }) => {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(htmlString)
  });
  return <div dangerouslySetInnerHTML={sanitizedData()} />;
};

export default StringToElement;
