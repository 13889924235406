import { useState, useEffect } from 'react';


import {
  convertFileToBase64,
  compressImage,
  copyObjectWithoutRef
} from '@common/helpers/helpers';
import { IMAGES_PATHS } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

const usePicturesPanel = () => {
  const [listOfImages, setListOfImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [mode, setMode] = useState(undefined);
  const [formState, setFormState] = useState({});
  const [imageData, setImageData] = useState(undefined);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );

  const onEditClick = imageData => {
    setFormState(imageData);
    setMode('edit');
  };

  const onCreateClick = () => {
    setFormState({ image: undefined, description: '' });
    setMode('create');
  };

  const cancelForm = () => {
    setFormState({});
    setMode(undefined);
  };

  const setUploadToastToInitial = () =>
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);

  const onItemClick = images => {
    if (!mode && images?.length) setSelectedItem(images[0]);
  };

  const onFormSave = async currentMode => {
    try {
      const copyFormState = copyObjectWithoutRef(formState);
      delete copyFormState.base64;

      const { status, data } = await postByPathAndData({
        path: IMAGES_PATHS.POST_IMAGE,
        data: { ...copyFormState },
        pathVariables: {
          id: copyFormState?.id
        }
      });

      if (status === 200) {
        setImageData(data);
        setFormState({});
        setMode(undefined);
        setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
        if (currentMode === 'create') {
          setListOfImages(prevState => [
            { id: data.id, description: data.description },
            ...prevState
          ]);
          setSelectedItem(data.id);
        } else {
          setListOfImages(prevState =>
            prevState.map(item => {
              if (item.id === data.id) {
                item = { id: data.id, description: data.description };
              }
              return item;
            })
          );
        }
      }
    } catch (error) {
      setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
    }
  };

  const onDescriptionChange = e => {
    setFormState(prevState => ({ ...prevState, description: e.target.value }));
  };

  const onImageUpload = async e => {
    const file = e.target.files[0];
    try {
      const compressedFile = await compressImage(file);
      const base64 = await convertFileToBase64(compressedFile);
      setFormState(prevState => ({ ...prevState, image: base64 }));
    } catch (error) {
      setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
    }
  };

  useEffect(() => {
    const fetchListOfImages = async () => {
      setIsLoading(true);
      try {
        let { data } = await getByPathAndParams({
          path: IMAGES_PATHS.GET_IMAGE_LIST,
          params: { q: '' }
        });
        if (data?.length) {
          setListOfImages(data);
          setSelectedItem(data[0].id);
        }
      } catch (err) {
        console.log('err: ', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchListOfImages();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      if (selectedItem) {
        setIsLoading(true);
        try {
          let { data } = await getByPathAndParams({
            path: IMAGES_PATHS.GET_IMAGE_BY_ID_MAINTENANCE,
            pathVariables: { id: selectedItem }
          });
          if (data?.base64) {
            setImageData({ ...data, image: data.base64 });
          }
        } catch (err) {
          console.log('err: ', err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setImageData();
      }
    };
    fetchImage();
  }, [selectedItem]);

  return [
    listOfImages,
    isLoading,
    uploadToastState,
    setUploadToastToInitial,
    onImageUpload,
    selectedItem,
    onItemClick,
    mode,
    onEditClick,
    onCreateClick,
    cancelForm,
    formState,
    onFormSave,
    onDescriptionChange,
    imageData
  ];
};

export default usePicturesPanel;
