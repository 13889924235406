import React from 'react';
import Localize from 'react-intl-universal';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InfoIcon from '@mui/icons-material/Info';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PhotoIcon from '@mui/icons-material/Photo';
import StorageIcon from '@mui/icons-material/Storage';
import TranslateIcon from '@mui/icons-material/Translate';
import TabList from '@mui/lab/TabList';

import MainHeaderTab from '@components/MainHeaderTab/MainHeaderTab';

const MainTabHeader = ({ handleTabChange, selectedTab, mainAdminTabOptions, disabledOptions }) => {
  return (
    <TabList className="mainTabHeader" onChange={handleTabChange}>
      <MainHeaderTab
        disabled={disabledOptions?.optionOne}
        icon={
          <AccountBoxIcon
            disabled={disabledOptions?.optionOne}
            className={selectedTab === mainAdminTabOptions.optionOne ? '' : 'activeTab'}
          />
        }
        label={Localize.get('usersTab').defaultMessage('usersTab')}
        value={mainAdminTabOptions.optionOne}
      />
      <MainHeaderTab
        disabled={disabledOptions?.optionTwo}
        icon={
          <StorageIcon
            disabled={disabledOptions?.optionTwo}
            className={selectedTab === mainAdminTabOptions.optionTwo ? '' : 'activeTab'}
          />
        }
        label={Localize.get('data').defaultMessage('data')}
        value={mainAdminTabOptions.optionTwo}
      />
      <MainHeaderTab
        disabled={disabledOptions?.optionThree}
        icon={
          <TranslateIcon
            disabled={disabledOptions?.optionThree}
            className={selectedTab === mainAdminTabOptions.optionThree ? '' : 'activeTab'}
          />
        }
        label={Localize.get('translationsTab').defaultMessage('translationsTab')}
        value={mainAdminTabOptions.optionThree}
      />
      <MainHeaderTab
        disabled={disabledOptions?.optionFour}
        icon={
          <PhotoIcon
            disabled={disabledOptions?.optionFour}
            className={selectedTab === mainAdminTabOptions.optionFour ? '' : 'activeTab'}
          />
        }
        label={Localize.get('picturesTab').defaultMessage('picturesTab')}
        value={mainAdminTabOptions.optionFour}
      />
      <MainHeaderTab
        disabled={disabledOptions?.optionFive}
        icon={
          <IntegrationInstructionsIcon
            disabled={disabledOptions?.optionFive}
            className={selectedTab === mainAdminTabOptions.optionFive ? '' : 'activeTab'}
          />
        }
        label={Localize.get('cpqTab').defaultMessage('cpqTab')}
        value={mainAdminTabOptions.optionFive}
      />

      <MainHeaderTab
        disabled={disabledOptions?.optionSix}
        icon={
          <InfoIcon
            disabled={disabledOptions?.optionSix}
            className={selectedTab === mainAdminTabOptions.optionSix ? '' : 'activeTab'}
          />
        }
        label={Localize.get('info').defaultMessage('info')}
        value={mainAdminTabOptions.optionSix}
      />
    </TabList>
  );
};

export default MainTabHeader;
