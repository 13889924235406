import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import domToImage from 'dom-to-image';

import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import Loader from '@components/Loader';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { getByPathAndParams } from '@services/BaseApi';

import Footer from './components/Footer';
import Header from './components/Header';

const textAlign = 'center';

const styles = StyleSheet.create({
  pdfViewer: { width: 1440, height: 1754 },
  page: {
    padding: 20,
    paddingLeft: 50,
    paddingRight: 50
  },
  section: {
    height: '25%',
    flexGrow: 1,
    alignItems: 'center'
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  },
  image: {
    marginBottom: 10,
    width: 360
  },
  rsvImage: {
    marginTop: 10,
    marginBottom: 10,
    width: 360
  },
  leftColumn: {
    flexDirection: 'column',
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0
    },
    '@media orientation: landscape': {
      width: 200
    }
  },
  footer: {
    fontSize: 10,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    marginTop: 0,
    paddingTop: 5,
    '@media orientation: landscape': {
      marginTop: 10
    }
  },
  table: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  leftTableColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    justifySelf: 'flex-start'
  },
  rightTableColumn: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    width: '30%',
    height: '100px'
  },
  description: {
    fontSize: 10,
    marginTop: 5
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 20
  }
});

const PDFWrapper = ({
  capacityChartRef,
  powerChartRef,
  rsvCartRef,
  animationDone,
  selectedPump,
  torqueTableData,
  unitMetrics,
  pdfData,
  pdfDialogOpen,
  handleClosePdf
}) => {
  const [mySrc, setMySrc] = useState();

  useEffect(() => {
    const convertDomToImages = async () => {
      if (animationDone) {
        let myCapacityEl = capacityChartRef?.current;
        let myPowerEl = powerChartRef?.current;
        let myRsvEl = rsvCartRef?.current;

        let statorMaterialNaming = await getByPathAndParams({
          path: '/naming',
          params: {
            naming: 'StatorMaterial',
            statorType: selectedPump?.statorType,
            model: selectedPump?.model,
            statorMaterial: selectedPump?.statorMaterial
          }
        });

        let modelNaming = await getByPathAndParams({
          path: '/naming',
          params: {
            naming: 'Model',
            statorType: selectedPump?.statorType,
            model: selectedPump?.model,
            statorMaterial: selectedPump?.statorMaterial
          }
        });

        let statorTypeNaming = await getByPathAndParams({
          path: '/naming',
          params: {
            naming: 'StatorType',
            statorType: selectedPump?.statorType,
            model: selectedPump?.model,
            statorMaterial: selectedPump?.statorMaterial
          }
        });

        const capacityImg = await domToImage.toPng(myCapacityEl, {
          height: 450,
          width: 609,
          style: {
            padding: '5px',
            backgroundColor: 'white'
          }
        });
        const powerImg = await domToImage.toPng(myPowerEl, {
          height: 450,
          width: 609,
          style: {
            padding: '5px',
            backgroundColor: 'white'
          }
        });
        const rsvImg = await domToImage.toPng(myRsvEl, {
          height: 60,
          width: 630,
          style: {
            padding: '7px',
            backgroundColor: 'white'
          }
        });

        setMySrc({
          capacityImg,
          powerImg,
          rsvImg,
          statorMaterialNaming: statorMaterialNaming?.data,
          statorTypeNaming: statorTypeNaming?.data,
          modelNaming: modelNaming?.data
        });
      }
    };

    convertDomToImages();
    // !TODO - set next line only to be disabled
    /* eslint-disable */
  }, [animationDone]);

  return (pdfDialogOpen && !animationDone) ||
    !mySrc?.capacityImg ||
    !mySrc?.powerImg ||
    !mySrc?.rsvImg ? (
    <Loader isLoading={pdfDialogOpen && !animationDone} />
  ) : (
    <>
      <AppBar
        sx={{
          position: 'relative',
          '.MuiToolbar-root': {
            minHeight: '44px'
          }
        }}
      >
        <Toolbar>
          <IconButton
            style={styles.closeButton}
            edge="end"
            color="inherit"
            onClick={handleClosePdf}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <PDFViewer style={styles.pdfViewer}>
        <Document fullScreen>
          <Page size="A4" style={styles.page}>
            <Header
              model={mySrc?.modelNaming?.naming || selectedPump?.model}
              materialElastomer={selectedPump?.materialElastomer}
              statorMaterial={mySrc?.statorMaterialNaming?.naming || selectedPump?.statorMaterial}
              statorType={mySrc?.statorTypeNaming?.naming || selectedPump?.statorType}
            />
            <View style={styles.section}>
              {mySrc && (
                <>
                  <Image style={styles.image} src={mySrc.capacityImg} />
                  <Image style={styles.rsvImage} src={mySrc.rsvImg} />
                  <Image style={styles.image} src={mySrc.powerImg} />
                </>
              )}
            </View>

            <View style={styles.table}>
              <View style={styles.leftTableColumn}>
                <Text>
                  {Localize.get('startingTorqueGraph').defaultMessage('startingTorqueGraph') +
                    ' ' +
                    pdfData.startingTorque +
                    ' ' +
                    unitMetrics?.torque?.value?.label}
                </Text>
              </View>
              <View style={styles.rightTableColumn}>
                <Table
                  data={torqueTableData.map((row) => {
                    return {
                      name: row[0],
                      value: row[1]
                    };
                  })}
                >
                  <TableHeader textAlign={textAlign}>
                    <TableCell>
                      {Localize.get('pressurePdf').defaultMessage('pressurePdf')}
                    </TableCell>
                    <TableCell>
                      {Localize.get('torqueGraph').defaultMessage('torqueGraph')}
                    </TableCell>
                  </TableHeader>
                  <TableBody textAlign={textAlign}>
                    <DataTableCell getContent={(r) => r.name} />
                    <DataTableCell
                      getContent={(r) => `${r.value} ${unitMetrics?.torque?.value?.label}`}
                    />
                  </TableBody>
                </Table>
              </View>
            </View>
            <Footer releaseCurveDate={selectedPump?.releaseCurveDate ?? ''} />
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default PDFWrapper;
