import { createListenerMiddleware, isAnyOf, createAction } from '@reduxjs/toolkit';

import { GENERAL_ATTRIBUTES_PATHS } from '@common/network/ApiPaths';
import {
  setAttributeFields,
  setIndustryNextAllowed
} from '@common/slices/industrySlice/industrySlice';
import { setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import { areAllValuesFilled, checkFieldValue } from '@pages/Analyzer/hooks/utils';
import {
  getAttributesForColumn,
  getCPQAttributeValues,
  getIOAValues
} from '@pages/Analyzer/hooks/utils';
import { getByPathAndParams } from '@services/BaseApi';

const industrySliceMiddlewareListener = createListenerMiddleware();

const action1 = createAction('generalAttributes/setSelectAttributeValue');
const action2 = createAction('generalAttributes/setAttributeValue');
const action3 = createAction('generalAttributes/setAttributeFields');
const action4 = createAction('generalAttributes/setQuickSelectionOfAttributes');

industrySliceMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2, action3, action4),
  effect: async (action, listenerApi) => {
    const { generalAttributes, layout } = listenerApi.getState();
    let fistStepAllowNext =
      checkFieldValue(generalAttributes.industry?.id) &&
      areAllValuesFilled(
        generalAttributes.dynamicIOAFieldsValues,
        generalAttributes.dynamicIOAFields.firstColumn
      ) &&
      areAllValuesFilled(
        generalAttributes.dynamicIOAFieldsValues,
        generalAttributes.dynamicIOAFields.secondColumn
      );
    if (fistStepAllowNext && !layout.openAccordions.includes(1)) {
      listenerApi.dispatch(setOpenAccordions(1));
    }
    listenerApi.dispatch(setIndustryNextAllowed({ attributes: Boolean(fistStepAllowNext) }));
  }
});

industrySliceMiddlewareListener.startListening({
  type: 'generalAttributes/setIndustryOfApplication',
  effect: async (action, listenerApi) => {
    const { cpq, user, unitsAndAttributes } = listenerApi.getState();

    const { firstStateData } = cpq;
    const { language } = user;
    const { attributePositions } = unitsAndAttributes;

    if (action?.payload?.id) {
      let { data } = await getByPathAndParams({
        path: GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_ATTRIBUTES,
        pathVariables: {
          id: action.payload.id
        },
        params: { language_id: language }
      });
      if (data) {
        let myData = {
          dynamicIOAFields: {
            firstColumn: getAttributesForColumn(
              data,
              attributePositions,
              2, // column number
              1 // id that presents fist step in AST
            ),
            secondColumn: getAttributesForColumn(
              data,
              attributePositions,
              3, // column number
              1 // id that presents fist step in AST
            )
          },
          cpqIoAAttributeValues: getCPQAttributeValues(data),
          dynamicIOAFieldsValues: getIOAValues(data, firstStateData)
        };
        listenerApi.dispatch(setAttributeFields(myData));
      }
    }
  }
});

export { industrySliceMiddlewareListener };
