import Localize from 'react-intl-universal';

import has from 'lodash/has';
import set from 'lodash/set';
import toPath from 'lodash/toPath';

import { ERROR_MESSAGES } from '@common/constants';
import CPQMappingError from '@common/Exceptions/CPQMappingError';

const findASTParameterByCPQ = (cpqParameter, cpqMappingData = []) => {
  const astParameter = cpqMappingData.find(
    ({ CpqCharacteristic }) => CpqCharacteristic === cpqParameter
  );
  if (astParameter?.AstCharacteristic) return astParameter;

  throw new CPQMappingError({
    status: ERROR_MESSAGES.PARAM_MISSING.STATUS,
    message: Localize.get('cpqParamNotInMappingErr', [cpqParameter]).defaultMessage(
      'cpqParamNotInMappingErr'
    )
  });
};

const getProductDetails = (productCodeData = {}) => {
  const {
    site,
    baseProduct,
    product,
    productRange,
    size,
    stages,
    geometry,
    pressure,
    joint,
    materials,
    appendix1,
    appendix2,
    appendix3
  } = productCodeData;

  return {
    selectedPumpId:
      site +
      baseProduct +
      product +
      productRange +
      size +
      stages +
      geometry +
      pressure +
      joint +
      materials +
      appendix1 +
      appendix2 +
      appendix3,
    productId: site + baseProduct + product,
    productRangeId: site + baseProduct + product + productRange
  };
};

export const mapParametersFromCPQToAST = (params, cpqMappingData) => {
  const firstStateData = {};
  const secondStateData = {};
  const unitsStateData = {};
  const dutyPointsStateData = [];
  const productCodeData = {};
  const notFoundErrors = [];
  const thirdStateData = {};
  let productStateData = {};

  for (const key in params) {
    const value = params[key];
    let isFound = false;
    try {
      const astParameter = findASTParameterByCPQ(key, cpqMappingData);

      if (astParameter?.Section === 'IoA_Properties') {
        set(firstStateData, astParameter.AstCharacteristic, value);
        isFound = true;
      }

      if (astParameter?.Section === 'IoA_Attributes') {
        if (has(astParameter.AstCharacteristic, '].')) {
          const [providedAstIoAIndex, providedAstIoAKey] = toPath(astParameter.AstCharacteristic);
          set(firstStateData, `[${providedAstIoAIndex}].${providedAstIoAKey}`, value);
        } else {
          set(firstStateData, astParameter.AstCharacteristic, value);
        }
        isFound = true;
      }

      if (astParameter?.Section === 'Fluid_Attributes') {
        if (has(astParameter.AstCharacteristic, '].')) {
          const [providedAstFluidIndex, providedAstFluidKey] = toPath(
            astParameter.AstCharacteristic
          );
          set(secondStateData, `[${providedAstFluidIndex}].${providedAstFluidKey}`, value);
        } else {
          set(secondStateData, astParameter.AstCharacteristic, value);
        }
        isFound = true;
      }

      if (astParameter?.Section === 'Fluid_Properties') {
        set(secondStateData, astParameter.AstCharacteristic, value);
        isFound = true;
      }

      if (astParameter?.Section === 'Operation_Condition') {
        set(thirdStateData, astParameter.AstCharacteristic, value);
        isFound = true;
      }

      if (astParameter?.Section === 'Unit') {
        unitsStateData[astParameter.AstCharacteristic] = value;
        isFound = true;
      }

      if (astParameter?.Section === 'Duty_Points') {
        const [providedAstIndex, providedAstKey] = toPath(astParameter.AstCharacteristic);
        set(dutyPointsStateData, `[${providedAstIndex}].${providedAstKey}`, value);
        isFound = true;
      }

      if (astParameter?.Section === 'ProductCode') {
        productCodeData[astParameter?.AstCharacteristic] = value;
        isFound = true;
      }

      if (
        astParameter?.Section === 'Calculated_Values' ||
        astParameter?.Section === 'Product_details' ||
        astParameter?.Section === 'Column_Visibility_Model' ||
        astParameter?.Section === 'ShowAllProducts' ||
        astParameter?.Section === 'Product_limits'
      ) {
        // TODO - Column_Visibility_Model, ShowAllProducts & Product_limits need to be handled
        isFound = true;
      }
    } catch (err) {
      notFoundErrors.push(err);
    }

    if (!isFound) {
      const newErr = new CPQMappingError({
        status: ERROR_MESSAGES.PARAM_MISSING.STATUS,
        message: `${key}`
      });
      notFoundErrors.push(newErr);
    }
  }

  try {
    productStateData = getProductDetails(productCodeData);
  } catch (err) {
    notFoundErrors.push(err);
  }

  if (notFoundErrors?.length) {
    throw new CPQMappingError({
      status: ERROR_MESSAGES.PARAMETERS_NOT_FOUND.STATUS,
      message: Localize.get('parametersNotFoundErr').defaultMessage('parametersNotFoundErr'),
      errors: notFoundErrors
    });
  }

  return {
    firstStateData,
    secondStateData,
    unitsStateData,
    dutyPointsStateData,
    productStateData,
    thirdStateData
  };
};
