import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN } from '@common/network/ApiPaths';
import { fetchLanguages } from '@common/slices/userSlice/userSlice';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE,
  REPLACE_TOAST_SUCCESS_STATE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

import { transformUserHeaders } from './utils';
import { USER_PARAMS_LIST } from '../config/uploadProducts';

export default function useAdminDisplayState() {
  const [allUsers, setAllUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(false);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [previousModel, setPreviousModel] = useState('');
  const [uploadToastState, setUploadToastState] = useState(UPLOAD_TOAST_INITIAL_STATE);
  const dispatch = useDispatch();

  const onSelectionUserChange = (model) => {
    if (selectedUser && allUsers && model) {
      setSelectedUser(allUsers.find((user) => user.id === model[0] || user.id === model));
      setPreviousModel(model[0]);
    }
  };

  const onUploadCSVOfUsers = (event, type) => {
    const files = event.target.files;

    if (files) {
      setUploadIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        encoding: 'ISO-8859-1',
        transformHeader: transformUserHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(results.data, USER_PARAMS_LIST);

            if (!isInvalid) {
              const path = type === 'upsert' ? ADMIN.UPSERT_USERS : ADMIN.REPLACE_USERS;
              await postByPathAndData({
                path,
                data: results.data
              });

              if (type === 'upsert') setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
              else setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchUsers();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message || DEFAULT_ERROR_MESSAGE
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const fetchUsers = async () => {
    try {
      setFetchUsersLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_ALL_USERS
      });
      setAllUsers(data);
    } catch (err) {
      console.log('err fetchUsers: ', err);
    } finally {
      setFetchUsersLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    dispatch(fetchLanguages());
    /* eslint-disable */
  }, []);

  const changeUserSettings = async (userToUpdate) => {
    try {
      await postByPathAndData({
        path: ADMIN.POST_USER_SETTINGS,
        data: {
          user_id: userToUpdate?.userId,
          default_language_id: userToUpdate?.defaultLanguage,
          plant: userToUpdate?.plant,
          unit_system: userToUpdate?.unitSystem,
          is_admin: userToUpdate?.isAdmin,
          expert_mode_allowed: userToUpdate?.expertModeAllowed,
          status: userToUpdate?.status
        }
      });
      fetchUsers();
      onSelectionUserChange(previousModel);
    } catch {
      console.log('err updateUser');
    }
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  return {
    allUsers,
    fetchUsersLoading,
    uploadIsLoading,
    selectedUser,
    uploadToastState,
    onSelectionUserChange,
    changeUserSettings,
    onUploadCSVOfUsers,
    setUploadToastToInitial
  };
}
