import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { SEARCH_METHOD } from '@common/constants';
import {
  APP_LAYOUT_STEPS,
  ONE_STEP,
  STEP_VALUES
} from '@common/slices/layoutSlice/config/layoutConfig';
import {
  selectCurrentStep,
  selectCurrentSubStep,
  setCurrentSubStep,
  setSteps
} from '@common/slices/layoutSlice/layoutSlice';
import {
  deselectSelectedProducts,
  selectListOfProductS,
  selectSearchMethodNextAllowed,
  setSearchType,
  selectSearchType
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectPumpSliceNextAllowed } from '@common/slices/selectPumpSlice/selectPumpSlice';
import { selectSessionUnitMetrics } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectExpertMode } from '@common/slices/userSlice/userSlice';
import SearchMethodSelector from '@components/SearchMethodSelector/SearchMethodSelector';
import ProductRange from '@pages/Analyzer/components/ProductRange/ProductRange';

import OtherCriteriaSearch from '../OtherCriteriaSearch/OtherCriteriaSearch';
import Product from '../Product/Product';
import AnalyzerFooter from '../StepperComponent/AnalyzerFooter';

const isDisabled = (
  nextAllowedForProducts,
  nextAllowedForSelectPump,
  activeSubStep,
  listOfProductCodes
) => {
  return (
    (activeSubStep.value === STEP_VALUES.searchMethod.value && !listOfProductCodes.length === 0) ||
    (activeSubStep.value === STEP_VALUES.productFamily.value && !nextAllowedForProducts.products) ||
    (activeSubStep.value === STEP_VALUES.productRange.value &&
      !nextAllowedForProducts.productRanges) ||
    (activeSubStep.value === STEP_VALUES.otherCriteria.value && !nextAllowedForSelectPump)
  );
};

const SearchMethod = () => {
  const dispatch = useDispatch();
  const unitMetrics = useSelector(selectSessionUnitMetrics);
  const activeStep = useSelector(selectCurrentStep);
  const activeSubStep = useSelector(selectCurrentSubStep);
  const nextAllowedForProducts = useSelector(selectSearchMethodNextAllowed);
  const nextAllowedForSelectPump = useSelector(selectPumpSliceNextAllowed);
  const listOfProductCodes = useSelector(selectListOfProductS);
  const currentSearchType = useSelector(selectSearchType);
  const isUserExpert = useSelector(selectExpertMode);

  const handleSelectedSearchMethod = (selectionType) => {
    if (
      !(
        currentSearchType === SEARCH_METHOD.OTHER_CRITERIA &&
        selectionType === SEARCH_METHOD.OTHER_CRITERIA
      )
    ) {
      dispatch(deselectSelectedProducts());
    }

    dispatch(setSearchType(selectionType));
    switch (selectionType) {
      case SEARCH_METHOD.MULTI_SELECT:
      case SEARCH_METHOD.OTHER_CRITERIA:
      case SEARCH_METHOD.MANUAL_CONFIG:
        dispatch(setCurrentSubStep(STEP_VALUES.productFamily));
        break;
      case SEARCH_METHOD.NO_RESTRICTION: {
        const nextStep = APP_LAYOUT_STEPS.config[activeStep.value + ONE_STEP];
        dispatch(
          setSteps({
            currentStep: nextStep
          })
        );
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      {
        {
          [STEP_VALUES.searchMethod.value]: (
            <SearchMethodSelector
              onClick={handleSelectedSearchMethod}
              title={Localize.get('pleaseChooseSearchMethod').defaultMessage(
                'pleaseChooseSearchMethod'
              )}
              isUserExpert={isUserExpert}
            />
          ),
          [STEP_VALUES.productFamily.value]: <Product unitMetrics={unitMetrics} />,
          [STEP_VALUES.productRange.value]: <ProductRange unitMetrics={unitMetrics} />,
          [STEP_VALUES.otherCriteria.value]: <OtherCriteriaSearch />
        }[activeSubStep.value]
      }

      <AnalyzerFooter
        nextDisabled={isDisabled(
          nextAllowedForProducts,
          nextAllowedForSelectPump,
          activeSubStep,
          listOfProductCodes
        )}
      />
    </>
  );
};

export default SearchMethod;
