import { useState, useEffect } from 'react';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  REPLACE_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';


import { transformCPQHeaders } from './utils'; // CPQ_MAPPING_PROPERTIES_LIST
import { CPQ_MAPPING_PROPERTIES_LIST } from '../config/uploadProducts';


const useCPQMapping = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfParameters, setListOfParameters] = useState([]);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );

  const onCSVUpload = event => {
    const files = event.target.files;

    if (files) {
      setUploadIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: transformCPQHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(
              results.data,
              CPQ_MAPPING_PROPERTIES_LIST
            );
            if (!isInvalid) {
              await postByPathAndData({
                path: ADMIN.POST_CPQ_MAPPING_PARAMETERS,
                data: results.data
              });

              setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchParameters();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message || DEFAULT_ERROR_MESSAGE
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const fetchParameters = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_CPQ_MAPPING_PARAMETERS
      });
      setListOfParameters(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchParameters();
  }, []);

  return [
    listOfParameters,
    isLoading,
    onCSVUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial
  ];
};

export default useCPQMapping;
