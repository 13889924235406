import { useState, useEffect } from 'react';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  REPLACE_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';





import { transformPressureDataHeaders } from './utils';
import { PRESSURE_DATA_LIST } from '../config/uploadProducts';

const usePressureDataPanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfPressureData, setListOfPressureData] = useState([]);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );

  const onUpload = (event, type) => {
    const files = event.target.files;

    if (files) {
      setUploadIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: transformPressureDataHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(
              results.data,
              PRESSURE_DATA_LIST
            );

            if (!isInvalid) {
              const path =
                type === 'upsert'
                  ? ADMIN.UPSERT_PUMP_PRESSURE_DATA
                  : ADMIN.REPLACE_PUMP_PRESSURE_DATA;
              await postByPathAndData({
                path,
                data: results.data
              });

              if (type === 'upsert')
                setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
              else setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchProducts();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_PRESSURE_DATA
      });
      setListOfPressureData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return [
    listOfPressureData,
    isLoading,
    onUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial
  ];
};

export default usePressureDataPanel;
