import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { ROUTER_PATHS, LOGIN_LOGOUT_API } from '@common/constants';
import { getByPathAndParams } from '@services/BaseApi';

const PendingApprovalPage = () => {
  const theme = useTheme();
  const onLogoutClick = async () => {
    let { data } = await getByPathAndParams({
      path: `${LOGIN_LOGOUT_API.API_URL}${ROUTER_PATHS.samlLogout}`
    });
    if (data?.uri) {
      window.location.replace(data.uri);
    }
  };
  return (
    <Box sx={{ p: 2 }}>
      <Box>{Localize.get('notApproved').defaultMessage('notApproved')}</Box>
      <Button
        sx={{
          backgroundColor: `${theme.palette.primary.dark}`
        }}
        color="primary"
        variant="contained"
        onClick={onLogoutClick}
      >
        {Localize.get('logout').defaultMessage('logout')}
      </Button>
    </Box>
  );
};

export default PendingApprovalPage;
