import { createListenerMiddleware, isAnyOf, createAction } from '@reduxjs/toolkit';

import { FLUID_ATTRIBUTES_PATHS } from '@common/network/ApiPaths';
import { setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import {
  getFluidValues,
  getAttributesForColumn,
  getCPQAttributeValues
} from '@pages/Analyzer/hooks/utils';
import { areAllValuesFilled, checkFieldValue } from '@pages/Analyzer/hooks/utils';
import { getByPathAndParams } from '@services/BaseApi';

import { setFluidAttributeFields, setFluidNextAllowed } from '../fluidSlice';

const fluidSliceMiddlewareListener = createListenerMiddleware();

const action1 = createAction('fluidAttributes/setSelectAttributeValue');
const action2 = createAction('fluidAttributes/setFluidAttributeValue');
const action3 = createAction('fluidAttributes/setFluidAttributeFields');
const action4 = createAction('fluidAttributes/setQuickSelectionOfAttributes');

fluidSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2, action3, action4),
  effect: async (_, listenerApi) => {
    const { fluidAttributes, layout } = listenerApi.getState();

    let secondStepAllowNext =
      checkFieldValue(fluidAttributes.medium?.id) &&
      areAllValuesFilled(
        fluidAttributes.dynamicFluidFieldsValues,
        fluidAttributes.dynamicFluidFields.firstColumn
      ) &&
      areAllValuesFilled(
        fluidAttributes.dynamicFluidFieldsValues,
        fluidAttributes.dynamicFluidFields.secondColumn
      ) &&
      areAllValuesFilled(
        fluidAttributes.dynamicFluidFieldsValues,
        fluidAttributes.dynamicFluidFields.thirdColumn
      );

    if (secondStepAllowNext && !layout.openAccordions.includes(2)) {
      listenerApi.dispatch(setOpenAccordions(2));
    }

    listenerApi.dispatch(setFluidNextAllowed({ fluidAttributes: secondStepAllowNext }));
  }
});

fluidSliceMiddlewareListener.startListening({
  type: 'fluidAttributes/setMedium',
  effect: async (action, listenerApi) => {
    const { cpq, user, unitsAndAttributes } = listenerApi.getState();

    const { secondStateData } = cpq;
    const { language } = user;
    const { attributePositions } = unitsAndAttributes;

    if (action?.payload?.id) {
      let { data } = await getByPathAndParams({
        path: FLUID_ATTRIBUTES_PATHS.GET_MEDIUM_ATTRIBUTES,
        pathVariables: {
          id: action?.payload?.id
        },
        params: { language_id: language }
      });
      if (data) {
        let myData = {
          dynamicFluidFields: {
            firstColumn: getAttributesForColumn(
              data,
              attributePositions,
              2, // column number
              2 // id that presents second step in AST
            ),
            secondColumn: getAttributesForColumn(
              data,
              attributePositions,
              3, // column number
              2 // id that presents second step in AST
            ),
            thirdColumn: getAttributesForColumn(data, attributePositions, 4)
          },
          cpqFluidAttributeValues: getCPQAttributeValues(data),
          dynamicFluidFieldsValues: getFluidValues(data, secondStateData)
        };

        listenerApi.dispatch(setFluidAttributeFields(myData));
      }
    }
  }
});

export { fluidSliceMiddlewareListener };
