import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import {
  fetchPumpsForOtherCriteria,
  selectListOfMaterial,
  selectListOfMaterialElastomer,
  selectListOfTypeCodes,
  selectMaterial,
  selectMaterialElastomer,
  selectPumpIsLoading,
  selectTypeCode,
  setMaterial,
  setMaterialElastomer,
  setTypeCode
} from '@common/slices/selectPumpSlice/selectPumpSlice';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import InputSelect from '@components/InputSelect/InputSelect';
import Loader from '@components/Loader';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';

const OtherCriteriaSearch = () => {
  const dispatch = useDispatch();
  const listOfTypeCodes = useSelector(selectListOfTypeCodes);
  const selectedTypeCode = useSelector(selectTypeCode);
  const listOfMaterials = useSelector(selectListOfMaterial);
  const selectedMaterial = useSelector(selectMaterial);

  const listOfMaterialElastomers = useSelector(selectListOfMaterialElastomer);
  const selectedMaterialElastomer = useSelector(selectMaterialElastomer);
  const isLoading = useSelector(selectPumpIsLoading);

  const onChange = (fieldName, event) => {
    switch (fieldName) {
      case 'typeCode':
        dispatch(setTypeCode(event.target.value));
        break;
      case 'material':
        dispatch(setMaterial(event.target.value));
        break;
      case 'materialElastomer':
        dispatch(setMaterialElastomer(event.target.value));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchPumpsForOtherCriteria());
    /* eslint-disable */
  }, []);

  return (
    <SalesCloudContainer>
      <DetailsTitle
        title={Localize.get('refinePumpConfiguration').defaultMessage('refinePumpConfiguration')}
      />
      <Loader isLoading={isLoading} />
      <Box className="attributeList">
        <InputSelect
          label={Localize.get('selectTypeCode').defaultMessage('selectTypeCode')}
          options={listOfTypeCodes}
          value={selectedTypeCode || ''}
          onChange={(event) => onChange('typeCode', event)}
        />

        <InputSelect
          label={Localize.get('selectMaterial').defaultMessage('selectMaterial')}
          options={listOfMaterials}
          value={selectedMaterial || ''}
          disabled={!selectedTypeCode}
          onChange={(event) => onChange('material', event)}
        />

        <InputSelect
          label={Localize.get('selectMaterialElastomer').defaultMessage('selectMaterialElastomer')}
          options={listOfMaterialElastomers}
          value={selectedMaterialElastomer || ''}
          disabled={!selectedMaterial}
          onChange={(event) => onChange('materialElastomer', event)}
        />
      </Box>
    </SalesCloudContainer>
  );
};

export default OtherCriteriaSearch;
