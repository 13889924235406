export const FIRST_SUB_STEP = 0;
export const ONE_STEP = 1;

export const STEP_VALUES = Object.freeze({
  generalAttributes: {
    value: 0,
    key: 'generalAttributes',
    open: true,
    label: 'General attributes'
  },
  industryCluster: {
    value: 0,
    key: 'industryCluster',
    label: 'Industry cluster'
  },
  industryOfApplication: {
    value: 1,
    key: 'industryOfApplication',
    label: 'Industry of Application'
  },
  ioaAttributes: {
    value: 2,
    key: 'attributes',
    label: 'Attributes'
  },
  fluidAttributes: {
    value: 1,
    key: 'fluidAttributes',
    open: false,
    label: 'Fluid attributes'
  },
  fluidFamily: {
    value: 0,
    key: 'fluidFamily',
    label: 'Fluid family'
  },
  fluid: {
    value: 1,
    key: 'fluid',
    label: 'Fluid'
  },
  fluidAttributesSub: {
    value: 2,
    key: 'fluidAttributes',
    label: 'Attributes'
  },
  operationConditions: {
    value: 2,
    key: 'operationConditions',
    open: false,
    label: 'Operation conditions'
  },
  dutyPoints: {
    value: 0,
    key: 'dutyPoints',
    label: 'Duty points'
  },
  searchCriteria: {
    value: 3,
    key: 'searchCriteria',
    open: false,
    label: 'Search criteria'
  },
  searchMethod: {
    value: 0,
    key: 'searchMethod',
    label: 'Search method'
  },
  productFamily: {
    value: 1,
    key: 'productFamily',
    label: 'Product family'
  },
  productRange: {
    value: 2,
    key: 'productRange',
    label: 'Product range'
  },
  otherCriteria: {
    value: 3,
    key: 'otherCriteria',
    label: 'Other criteria'
  },
  result: {
    value: 4,
    key: 'result',
    open: false,
    label: 'Result',
    hideExpandIcon: false
  },
  resultList: {
    value: 0,
    key: 'resultList',
    label: 'Result list'
  }
});

// TODO - change labels to be keys instead of values

export const APP_LAYOUT_STEPS = Object.freeze({
  config: [
    {
      ...STEP_VALUES.generalAttributes,
      subSteps: [
        STEP_VALUES.industryCluster,
        STEP_VALUES.industryOfApplication,
        STEP_VALUES.ioaAttributes
      ]
    },
    {
      ...STEP_VALUES.fluidAttributes,
      subSteps: [STEP_VALUES.fluidFamily, STEP_VALUES.fluid, STEP_VALUES.fluidAttributesSub]
    },
    {
      ...STEP_VALUES.operationConditions,
      subSteps: [STEP_VALUES.dutyPoints]
    },
    {
      ...STEP_VALUES.searchCriteria,
      subSteps: [
        STEP_VALUES.searchMethod,
        STEP_VALUES.productFamily,
        STEP_VALUES.productRange,
        STEP_VALUES.otherCriteria
      ]
    },
    {
      ...STEP_VALUES.result,
      subSteps: [STEP_VALUES.resultList]
    }
  ]
});
