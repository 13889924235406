import { useState, useEffect } from 'react';

import { IMAGES_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';


const useGetImage = imageId => {
  const [imageData, setImageData] = useState(undefined);
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      if (imageId) {
        setIsImageLoading(true);
        try {
          let { data } = await getByPathAndParams({
            path: IMAGES_PATHS.GET_IMAGE_BY_ID,
            pathVariables: { id: imageId }
          });
          if (data?.base64) {
            setImageData({ ...data, image: data.base64 });
          }
        } catch (err) {
          console.log('err: ', err);
        } finally {
          setIsImageLoading(false);
        }
      } else {
        setImageData();
      }
    };
    fetchImage();
  }, [imageId]);

  return [imageData, isImageLoading];
};

export default useGetImage;
