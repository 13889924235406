import Localize from 'react-intl-universal';

import has from 'lodash/has';

import { GRAPH_KIND, GRAPH_TYPE, SEARCH_METHOD } from '@common/constants';
import { copyObjectWithoutRef, isValidNumber } from '@common/helpers/helpers';

const PARTICLE_CONTENT_SOFT_ID = 33;
const PARTICLE_CONTENT_HARD_ID = 34;
const YES_OPTION_VALUE = 1;

const isFilled = (value) => {
  let parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return false;
  return Boolean(parsedValue);
};

const mapUnitsForCalcPerformance = (unitMetrics) => {
  return {
    capacity: unitMetrics?.capacity?.value?.id ?? '',
    concentration: unitMetrics?.concentration?.value?.id ?? '',
    density: unitMetrics?.density?.value?.id ?? '',
    dynamicViscosity: unitMetrics?.dynamicViscosity?.value?.id ?? '',
    force: unitMetrics?.force?.value?.id ?? '',
    frequency: unitMetrics?.frequency?.value?.id ?? '',
    npshr: unitMetrics?.npshr?.value?.id ?? '',
    particleSize: unitMetrics?.particleSize?.value?.id ?? '',
    power: unitMetrics?.power?.value?.id ?? '',
    pressure: unitMetrics?.pressure?.value?.id ?? '',
    rotationalSpeed: unitMetrics?.rotationalSpeed?.value?.id ?? '',
    slidingSpeed: unitMetrics?.slidingSpeed?.value?.id ?? '',
    solidsContent: unitMetrics?.solidsContent?.value?.id ?? '',
    temperature: unitMetrics?.temperature?.value?.id ?? '',
    time: unitMetrics?.time?.value?.id ?? '',
    torque: unitMetrics?.torque?.value?.id ?? ''
  };
};

const mapDPForCalcPerformance = (dutyPoints) => {
  return dutyPoints.map(
    ({ name, suctionPressure, dischargePressure, temperature, capacity, speed, viscosity }) => {
      return {
        temperature,
        capacity,
        speed,
        pressure: Math.abs(dischargePressure - suctionPressure),
        viscosity,
        name
      };
    }
  );
};

const getCalculationValuesForGraph = (providedData) => {
  let data = copyObjectWithoutRef(providedData);
  return data?.resultValues?.calculation_values?.graphs;
};

const getDataForGraphsForSpeedLimitsForCapacity = (providedData, selectedDPIndexes) => {
  let data = copyObjectWithoutRef(providedData);
  let pressureLevelsSCPT =
    data?.resultValues?.calculation_values?.graphs[selectedDPIndexes[0]]?.pressureLevelsSCPT;
  let myDPSpeedLimit = pressureLevelsSCPT.slice(-1)[0];
  return {
    name: myDPSpeedLimit?.name,
    data: myDPSpeedLimit?.speedCapacityList,
    type: myDPSpeedLimit?.type,
    stroke: myDPSpeedLimit?.stroke,
    lineType: myDPSpeedLimit?.lineType
  };
};

const getDataForGraphsForSelectedDutyPoints = (
  providedData,
  graphKind,
  selectedDPIndexes,
  graphType
) => {
  let listOfDutyPoints = [];
  if (graphType === GRAPH_TYPE.DUTY_POINTS_WITH_SAME_VISCOSITY_AND_TEMPERATURE) {
    listOfDutyPoints = getCalculationValuesForGraph(providedData);
  } else if (graphType === GRAPH_TYPE.DUTY_POINTS_WITH_DIFF_VISCOSITY_AND_TEMPERATURE) {
    listOfDutyPoints = getCalculationValuesForGraphForDiffDP(providedData);
  }
  if (graphKind === GRAPH_KIND.NPSHr) {
    return listOfDutyPoints.map(({ viscositySpeedNPSHr }, index) => {
      if (selectedDPIndexes.includes(index)) {
        let myDPLineTemp = viscositySpeedNPSHr.slice(-1)[0];
        return {
          name: myDPLineTemp?.name,
          data: myDPLineTemp?.data,
          type: myDPLineTemp?.type,
          stroke: myDPLineTemp?.stroke,
          lineType: myDPLineTemp?.lineType
        };
      } else return {};
    });
  } else {
    return listOfDutyPoints.map(({ pressureLevelsSCPT }, index) => {
      if (selectedDPIndexes.includes(index)) {
        let myDPLineTemp = pressureLevelsSCPT.slice(-2, -1)[0];
        if (graphKind === GRAPH_KIND.CAPACITY) {
          return {
            name: myDPLineTemp?.name,
            data: myDPLineTemp?.speedCapacityList,
            type: myDPLineTemp?.type,
            stroke: myDPLineTemp?.stroke,
            lineType: myDPLineTemp?.lineType
          };
        } else {
          return {
            name: myDPLineTemp?.name,
            data: myDPLineTemp?.speedPowerTorqueList,
            type: myDPLineTemp?.type,
            stroke: myDPLineTemp?.stroke,
            lineType: myDPLineTemp?.lineType
          };
        }
      } else return {};
    });
  }
};

const getJoinedRubbingSlidingVelocity = (providedData, selectedDPIndexes, graphType) => {
  let listOfDutyPoints = [];
  if (graphType === GRAPH_TYPE.DUTY_POINTS_WITH_SAME_VISCOSITY_AND_TEMPERATURE) {
    listOfDutyPoints = getCalculationValuesForGraph(providedData);
  } else if (graphType === GRAPH_TYPE.DUTY_POINTS_WITH_DIFF_VISCOSITY_AND_TEMPERATURE) {
    listOfDutyPoints = getCalculationValuesForGraphForDiffDP(providedData);
  }
  let rubbingSlidingArr = listOfDutyPoints.map(({ dutyPointAndOther }, index) => {
    if (selectedDPIndexes.includes(index)) return dutyPointAndOther?.rubbingSlidingVelocity;
    else return 0;
  });
  return Math.max(...rubbingSlidingArr);
};

const getDataForGraphsForDataBars = (providedData, graphKind, selectedDPIndexes) => {
  let listOfDutyPoints = getCalculationValuesForGraph(providedData);
  if (graphKind === GRAPH_KIND.NPSHr) {
    let pressureLevelSCPT = listOfDutyPoints[selectedDPIndexes[0]]?.viscositySpeedNPSHr;
    let bars = pressureLevelSCPT.slice(0, -1);
    return bars.map(({ name, data, type, stroke, lineType }) => {
      return {
        name: name,
        data: data,
        type: type,
        stroke: stroke,
        lineType: lineType
      };
    });
  } else {
    let pressureLevelSCPT = listOfDutyPoints[selectedDPIndexes[0]]?.pressureLevelsSCPT;
    let bars = pressureLevelSCPT.slice(0, -2);
    if (graphKind === GRAPH_KIND.CAPACITY) {
      return bars.map(({ name, speedCapacityList, type, stroke, lineType }) => {
        return {
          name: name,
          data: speedCapacityList,
          type: type,
          stroke: stroke,
          lineType: lineType
        };
      });
    } else {
      return bars.map(({ name, speedPowerTorqueList, type, stroke, lineType }) => {
        return {
          name: name,
          data: speedPowerTorqueList,
          type: type,
          stroke: stroke,
          lineType: lineType
        };
      });
    }
  }
};

const getJoinedData = (providedData, graphKind, selectedDPIndexes) => {
  let graphDpsData = getDataForGraphsForSelectedDutyPoints(
    providedData,
    graphKind,
    selectedDPIndexes,
    GRAPH_TYPE.DUTY_POINTS_WITH_SAME_VISCOSITY_AND_TEMPERATURE
  );
  graphDpsData = graphDpsData.filter((element) => {
    if (Object.keys(element).length !== 0) return true;
    else return false;
  });
  let pressureBarsData = getDataForGraphsForDataBars(providedData, graphKind, selectedDPIndexes);
  pressureBarsData.forEach((element) => {
    graphDpsData.push(element);
  });
  if (graphKind === GRAPH_KIND.CAPACITY) {
    let speedLimitsData = getDataForGraphsForSpeedLimitsForCapacity(
      providedData,
      selectedDPIndexes
    );
    graphDpsData.push(speedLimitsData);
  }
  return graphDpsData;
};

const getMergedDataForGraphs = (providedData, selectedDPIndexes) => {
  if (selectedDPIndexes) {
    let capacityData = getJoinedData(providedData, GRAPH_KIND.CAPACITY, selectedDPIndexes);
    let powerTorqueData = getJoinedData(
      providedData,
      GRAPH_KIND.POWER_AND_TORQUE,
      selectedDPIndexes
    );
    let viscosityNPSHr = getJoinedData(providedData, GRAPH_KIND.NPSHr, selectedDPIndexes);
    let rubbingSlidingVelocity = getJoinedRubbingSlidingVelocity(
      providedData,
      selectedDPIndexes,
      GRAPH_TYPE.DUTY_POINTS_WITH_SAME_VISCOSITY_AND_TEMPERATURE
    );
    return {
      speedCapacityList: capacityData,
      speedPowerList: powerTorqueData,
      speedNPSHrList: viscosityNPSHr,
      rubbingSlidingVelocity
    };
  }
};

const convertToDefaultUnit = (value, unitId, units) => {
  const unit = Object.keys(units).reduce((currentObject, unitName) => {
    let { options = [] } = units[unitName];
    let myUnit = options.find((unit) => unit.id === unitId);
    if (myUnit) {
      return myUnit;
    } else {
      return currentObject;
    }
  }, {});

  const { factor, offset } = unit;
  const offsetParsed = parseFloat(offset);
  const factorParsed = parseFloat(factor);
  const valueParsed = parseFloat(value);
  if (isValidNumber(offsetParsed) && isValidNumber(factorParsed) && isValidNumber(valueParsed)) {
    return (valueParsed - offsetParsed) / factorParsed;
  }
  return undefined;
};

const checkIOAValues = (ioaValues) => {
  return Object.keys(ioaValues).reduce((currentParams, currentParameter) => {
    if (typeof ioaValues[currentParameter] === 'boolean') {
      currentParams[currentParameter] = ioaValues[currentParameter];
    }

    let parsedValue = parseFloat(ioaValues[currentParameter]);

    if (typeof ioaValues[currentParameter] === 'string' && ioaValues[currentParameter] === '') {
      currentParams[currentParameter] = '';
      return currentParams;
    }

    if (
      (typeof ioaValues[currentParameter] === 'string' ||
        typeof ioaValues[currentParameter] === 'number') &&
      isValidNumber(parsedValue)
    ) {
      currentParams[currentParameter] = parsedValue;
    }

    return currentParams;
  }, {});
};

const getUnitId = (unitGroupId, units) => {
  return Object.keys(units).reduce((unitId, unitName) => {
    if (parseInt(units[unitName].group_id) === unitGroupId) {
      unitId = units[unitName]?.value?.id;
    }
    return unitId;
  }, '');
};

const checkFluidParams = (fluidAttributeValues, units, dynamicFluidFields) => {
  let { firstColumn = [], secondColumn = [], thirdColumn = [] } = dynamicFluidFields;
  return Object.keys(fluidAttributeValues).reduce((currentObject, fluidName) => {
    const myAttribute = [...firstColumn, ...secondColumn, ...thirdColumn].find(
      ({ key }) => key === fluidName
    );

    if (myAttribute?.unit_group_id) {
      const parsedValue = parseFloat(fluidAttributeValues[fluidName]);
      if (isValidNumber(parsedValue)) {
        currentObject[fluidName] = convertToDefaultUnit(
          parsedValue,
          getUnitId(myAttribute.unit_group_id, units),
          units
        );
      } else {
        currentObject;
      }
    } else {
      currentObject[fluidName] = fluidAttributeValues[fluidName];
    }

    return currentObject;
  }, {});
};

const checkDutyPointParams = (dutyPoints, units) => {
  return dutyPoints.reduce(
    (currentValue, currentDP) => {
      const { capacity, dischargePressure, suctionPressure, speed, temperature, viscosity } =
        currentDP;

      const parsedCapacity = convertToDefaultUnit(
        parseFloat(capacity),
        units?.capacity?.value?.id,
        units
      );
      const parsedDischargePressure = convertToDefaultUnit(
        parseFloat(dischargePressure),
        units?.pressure?.value?.id,
        units
      );
      const parsedSuctionPressure = convertToDefaultUnit(
        parseFloat(suctionPressure),
        units?.pressure?.value?.id,
        units
      );
      const parsedSpeed = convertToDefaultUnit(
        parseFloat(speed),
        units?.rotationalSpeed?.value?.id,
        units
      );
      const parsedTemperature = convertToDefaultUnit(
        parseFloat(temperature),
        units?.temperature?.value?.id,
        units
      );
      const parsedViscosity = convertToDefaultUnit(
        parseFloat(viscosity),
        units?.dynamicViscosity?.value?.id,
        units
      );

      if (isValidNumber(parsedCapacity) && currentValue.FlowrateMax < parsedCapacity) {
        currentValue.FlowrateMax = parsedCapacity;
      }
      if (isValidNumber(parsedCapacity) && currentValue.FlowrateMin > parsedCapacity) {
        currentValue.FlowrateMin = parsedCapacity;
      }

      if (isValidNumber(parsedDischargePressure) && isValidNumber(parsedSuctionPressure)) {
        let pressureDifference = parsedDischargePressure - parsedSuctionPressure;
        if (currentValue.PressureMax < pressureDifference) {
          currentValue.PressureMax = pressureDifference;
        }
        if (currentValue.PressureMin > pressureDifference) {
          currentValue.PressureMin = pressureDifference;
        }
      }

      if (isValidNumber(parsedSpeed) && currentValue.SpeedMax < parsedSpeed) {
        currentValue.SpeedMax = parsedSpeed;
      }
      if (isValidNumber(parsedSpeed) && currentValue.SpeedMin > parsedSpeed) {
        currentValue.SpeedMin = parsedSpeed;
      }

      if (
        isValidNumber(parsedTemperature) &&
        currentValue.FluidTemperatureMax < parsedTemperature
      ) {
        currentValue.FluidTemperatureMax = parsedTemperature;
      }
      if (
        isValidNumber(parsedTemperature) &&
        currentValue.FluidTemperatureMin > parsedTemperature
      ) {
        currentValue.FluidTemperatureMin = parsedTemperature;
      }

      if (isValidNumber(parsedViscosity) && currentValue.ViscosityMax < parsedViscosity) {
        currentValue.ViscosityMax = parsedViscosity;
      }
      if (isValidNumber(parsedViscosity) && currentValue.ViscosityMin > parsedViscosity) {
        currentValue.ViscosityMin = parsedViscosity;
      }

      return currentValue;
    },
    {
      FlowrateMax: -Number.MAX_VALUE,
      FlowrateMin: Number.MAX_VALUE,
      PressureMax: -Number.MAX_VALUE,
      PressureMin: Number.MAX_VALUE,
      SpeedMax: -Number.MAX_VALUE,
      SpeedMin: Number.MAX_VALUE,
      FluidTemperatureMax: -Number.MAX_VALUE,
      FluidTemperatureMin: Number.MAX_VALUE,
      ViscosityMax: -Number.MAX_VALUE,
      ViscosityMin: Number.MAX_VALUE
    }
  );
};

const getUnitParameter = (unitGroupId) => {
  switch (String(unitGroupId)) {
    case '1':
      return 'pressure';
    case '2':
      return 'capacity';
    case '3':
      return 'density';
    case '4':
      return 'dynamicViscosity';
    case '5':
      return 'force';
    case '6':
      return 'frequency';
    case '7':
      return 'npshr';
    case '8':
      return 'particleSize';
    case '9':
      return 'power';
    case '10':
      return 'rotationalSpeed';
    case '11':
      return 'slidingSpeed';
    case '12':
      return 'solidsContent';
    case '13':
      return 'temperature';
    case '14':
      return 'time';
    case '15':
      return 'torque';
    case '16':
      return 'concentration';
    default:
      return undefined;
  }
};

const getUnitsFormatted = (
  unitMetrics = [],
  isCustom,
  metricSystemsValues,
  unitsStateData = {}
) => {
  return unitMetrics.reduce((newObj, currentUnitGroup) => {
    const unitGroupKey = getUnitParameter(currentUnitGroup.group_id);
    newObj[unitGroupKey] = {
      ...currentUnitGroup,
      value: currentUnitGroup.options.find(({ id, key }) => {
        if (has(unitsStateData, unitGroupKey)) {
          return key === unitsStateData[unitGroupKey];
        }
        return isCustom
          ? id ===
              (currentUnitGroup?.user_default_options_id || currentUnitGroup.default_options_id)
          : id === metricSystemsValues[unitGroupKey];
      })
    };
    return newObj;
  }, {});
};

const getUnitsPerCpq = (unitMetrics = {}, isCustom, metricSystemsValues, unitsStateData = {}) => {
  const unitMetricsCopy = copyObjectWithoutRef(unitMetrics);
  const unitMetricsParams = Object.keys(unitMetricsCopy);
  if (unitMetricsParams?.length) {
    return unitMetricsParams.reduce((newObj, currentUnitGroupName) => {
      const unitGroupKey = currentUnitGroupName;
      const currentUnitGroup = unitMetricsCopy[currentUnitGroupName];
      newObj[currentUnitGroupName] = {
        ...currentUnitGroup,
        value: currentUnitGroup?.options?.find(({ id, key }) => {
          if (has(unitsStateData, unitGroupKey)) {
            return key === unitsStateData[unitGroupKey];
          }
          return isCustom
            ? id ===
                (currentUnitGroup?.user_default_options_id || currentUnitGroup.default_options_id)
            : id === metricSystemsValues[unitGroupKey];
        })
      };

      return newObj;
    }, {});
  }
};

const mergeObjects = (obj1, obj2) => {
  let result = {};
  for (let prop in obj1) {
    result[prop] = obj1[prop];
    if (has(obj2, prop)) {
      result[prop] = obj2[prop];
    }
  }
  return result;
};

// 1 ask
// 2 assume
// 3 know

// type_id: 1 - number with units (ph do not have unit)
// type_id: 2 - bool
// type_id: 3 - select
// type_id: 4 - free text

const getIOAValues = (providedArr, providedCpqData = {}) => {
  let cpqFirstStateData = copyObjectWithoutRef(providedCpqData);
  let valuesTemp = {
    EnvironmentalTemperatureMin: '',
    EnvironmentalTemperatureMax: ''
  };
  providedArr.forEach((ioaAttribute) => {
    if (ioaAttribute?.type_id === 3 && Boolean(ioaAttribute?.key)) {
      if (has(cpqFirstStateData, ioaAttribute?.key)) {
        const selectedOptions = ioaAttribute?.options ?? [];
        const selectedOption = selectedOptions.find(
          ({ value }) => value === cpqFirstStateData[ioaAttribute.key]
        );
        if (selectedOption?.id) {
          cpqFirstStateData[ioaAttribute.key] = selectedOption.id;
        }
      }

      if (ioaAttribute?.key_cls === 2 || ioaAttribute?.key_cls === 3) {
        valuesTemp[ioaAttribute.key] = `${ioaAttribute?.default_value_id}`;
      } else {
        valuesTemp[ioaAttribute.key] = '';
      }
    }
    if (ioaAttribute.type_id === 2 && Boolean(ioaAttribute?.key)) {
      if (ioaAttribute?.key_cls === 2 || ioaAttribute?.key_cls === 3) {
        valuesTemp[ioaAttribute.key] = ioaAttribute?.default_value_id;
      } else {
        valuesTemp[ioaAttribute.key] = '';
      }
    }
  });
  return mergeObjects(valuesTemp, cpqFirstStateData);
};

const getCPQAttributeValues = (data) => {
  return data.reduce((values, attribute) => {
    if (attribute?.type_id === 3) {
      if (attribute.key_cls === 3 || attribute.key_cls === 2) {
        values[attribute.key] = attribute.options.find(
          ({ id }) => id === attribute?.default_value_id
        ).value;
      } else {
        values[attribute.key] = 0;
      }
    }
    return values;
  }, {});
};

const areAllValuesFilled = (values = {}, attributeList) => {
  return !attributeList
    .filter((attribute) => {
      return (
        (!attribute?.key_cls || attribute?.key_cls === 1 || attribute?.key_cls === 2) &&
        checkIfAreaIsHazardous(attribute, values) &&
        checkIfPolishedSurface(attribute, values) &&
        checkIfFoodGrade(attribute, values) &&
        checkIfSolidsSize(attribute, values)
      );
    })
    .some(({ key }) => {
      if (values[key]?.constructor === Object) {
        return values[key].value === '';
      }
      return values[key] === '';
    });
};

const checkFieldValue = (value) => {
  return Boolean(value);
};

const findPositionByKey = (positions, providedKey) =>
  positions.find(({ key }) => key === providedKey);

// 1 ask
// 2 assume
// 3 know

// type_id: 1 - number with units (ph do not have unit)
// type_id: 2 - bool
// type_id: 3 - select
// type_id: 4 - free text

const getFluidValues = (providedArr, cpqSecondStateData = {}) => {
  let valuesTemp = {};
  providedArr.forEach((fluidAttribute) => {
    // type_id 1 - inputs with units
    if (fluidAttribute.type_id === 1 && Boolean(fluidAttribute?.key)) {
      valuesTemp[fluidAttribute.key] = fluidAttribute?.default_value ?? '';
    }
    // type_id 2 - booleans
    if (fluidAttribute.type_id === 2 && Boolean(fluidAttribute?.key)) {
      if (fluidAttribute?.key_cls === 3 || fluidAttribute?.key_cls === 2) {
        valuesTemp[fluidAttribute.key] = fluidAttribute?.default_value_id;
      } else {
        valuesTemp[fluidAttribute.key] = '';
      }
    }
    // type_id 3 - dropdown inputs
    if (fluidAttribute.type_id === 3 && Boolean(fluidAttribute?.key)) {
      if (fluidAttribute?.key_cls === 3 || fluidAttribute?.key_cls === 2) {
        valuesTemp[fluidAttribute.key] = fluidAttribute?.default_value_id;
      } else {
        valuesTemp[fluidAttribute.key] = '';
      }
    }
    // type_id 4 - strings
    if (fluidAttribute.type_id === 4 && Boolean(fluidAttribute?.key)) {
      if (fluidAttribute?.key_cls === 3 || fluidAttribute?.key_cls === 2) {
        valuesTemp[fluidAttribute.key] = fluidAttribute?.default_value;
      } else {
        valuesTemp[fluidAttribute.key] = '';
      }
    }
  });

  return mergeObjects(valuesTemp, cpqSecondStateData);
};

const setTwoDecimals = (num) => {
  if (!isNaN(num)) {
    num = parseFloat(num).toFixed(2);
    if (num % 1 === 0) return Math.floor(num);
    else return num;
  }
  return 0;
};

const getTorqueTable = (graphs, selectedDutyPoint) => {
  if (graphs?.length > 0) {
    let pressureLevelsSCPT;
    if (selectedDutyPoint) {
      pressureLevelsSCPT = graphs[selectedDutyPoint[0]]?.pressureLevelsSCPT.slice(0, -2);
    } else {
      pressureLevelsSCPT = graphs[0]?.pressureLevelsSCPT.slice(0, -2);
    }
    return pressureLevelsSCPT.map(({ name, speedPowerTorqueList }) => {
      let row = [];
      row.push(name);
      row.push(
        speedPowerTorqueList?.reduce((maxValue, { torque }) => {
          if (maxValue < torque) maxValue = torque;
          return String(setTwoDecimals(maxValue));
        }, 0)
      );
      return row;
    });
  }
};

const getPDFData = (graphs, selectedDutyPoint) => {
  if (graphs?.length > 0) {
    let index = selectedDutyPoint ? selectedDutyPoint[0] : 0;
    return {
      startingTorque: setTwoDecimals(graphs[index]?.dutyPointAndOther?.startingTorque),
      viscosity: setTwoDecimals(graphs[index]?.dutyPointAndOther?.viscosity),
      temperature: setTwoDecimals(graphs[index]?.dutyPointAndOther?.temperature)
    };
  }
};

const mapStatorType = (statorType) => {
  switch (statorType) {
    case 1:
    case 2:
      return 'iFD-Stator®';
    case 3:
      return 'TORNADO® T1';
    case 4:
      return 'TORNADO® T2';
    case 10:
      return 'iFD-Stator® 2.0';
    default:
      return '';
  }
};

const getCalculationValuesForGraphForDiffDP = (providedData) => {
  let data = copyObjectWithoutRef(providedData);
  return data?.resultValues?.calculation_values?.graphsForDiffDP;
};

const getDataForGraphsForAllSpeedLimits = (providedData) => {
  let data = copyObjectWithoutRef(providedData);
  return data?.resultValues?.calculation_values?.graphs.map(
    ({ pressureLevelsSCPT, dutyPointAndOther }) => {
      let myDPSpeedLimit = pressureLevelsSCPT.slice(-1)[0];
      return {
        name: myDPSpeedLimit?.name + ' ' + dutyPointAndOther?.dutyPoint,
        data: myDPSpeedLimit?.speedCapacityList,
        type: myDPSpeedLimit?.type,
        stroke: myDPSpeedLimit?.stroke,
        lineType: myDPSpeedLimit?.lineType
      };
    }
  );
};

const getDataForGraphsForSpecificBars = (
  providedData,
  graphKind,
  selectedDPIndexes,
  unitMetrics
) => {
  let listOfDutyPoints = getCalculationValuesForGraphForDiffDP(providedData);
  if (graphKind === GRAPH_KIND.NPSHr) {
    let pressureLevelSCPT = listOfDutyPoints[selectedDPIndexes[0]]?.viscositySpeedNPSHr;
    let bars = pressureLevelSCPT.slice(0, -1);
    return bars.map(({ name, data, type, stroke, lineType }) => {
      return {
        name: name,
        data: data,
        type: type,
        stroke: stroke,
        lineType: lineType
      };
    });
  } else {
    let labelNames = [];
    listOfDutyPoints?.forEach(({ dutyPointAndOther }) => {
      let labelName = `/${dutyPointAndOther?.viscosity} ${unitMetrics?.dynamicViscosity?.value?.label}/${dutyPointAndOther?.temperature} ${unitMetrics?.temperature?.value?.label}`;
      labelNames.push(labelName);
    });
    return selectedDPIndexes.map((dutyPointID) => {
      let pressureLevelSCPT = listOfDutyPoints[dutyPointID]?.pressureLevelsSCPT;
      let pressureLevelSCPTBars = pressureLevelSCPT.slice(0, -2)[0];
      if (graphKind === GRAPH_KIND.CAPACITY) {
        let { name, speedCapacityList, type, stroke, lineType } = pressureLevelSCPTBars ?? {};
        return {
          name: name + labelNames[dutyPointID],
          data: speedCapacityList,
          type: type,
          stroke: stroke,
          lineType: lineType
        };
      } else {
        let { name, speedPowerTorqueList, type, stroke, lineType } = pressureLevelSCPTBars ?? {};
        return {
          name: name + labelNames[dutyPointID],
          data: speedPowerTorqueList,
          type: type,
          stroke: stroke,
          lineType: lineType
        };
      }
    });
  }
};

const getAllData = (providedData, graphKind, dpIndexes, unitMetrics) => {
  let graphDpsData = getDataForGraphsForSelectedDutyPoints(
    providedData,
    graphKind,
    dpIndexes,
    GRAPH_TYPE.DUTY_POINTS_WITH_DIFF_VISCOSITY_AND_TEMPERATURE
  );
  graphDpsData = graphDpsData.filter((element) => {
    if (Object.keys(element).length !== 0) return true;
    else return false;
  });

  let pressureBarsData = getDataForGraphsForSpecificBars(
    providedData,
    graphKind,
    dpIndexes,
    unitMetrics
  );
  pressureBarsData.forEach((element) => {
    graphDpsData.push(element);
  });
  if (graphKind === GRAPH_KIND.CAPACITY) {
    let speedLimitsData = getDataForGraphsForAllSpeedLimits(providedData, dpIndexes);
    speedLimitsData.forEach((element) => {
      graphDpsData.push(element);
    });
  }
  return graphDpsData;
};

const getDataForAllGraphs = (providedData, dpIndexes, unitMetrics) => {
  if (dpIndexes) {
    let capacityData = getAllData(providedData, GRAPH_KIND.CAPACITY, dpIndexes, unitMetrics);
    let powerTorqueData = getAllData(
      providedData,
      GRAPH_KIND.POWER_AND_TORQUE,
      dpIndexes,
      unitMetrics
    );
    let viscosityNPSHr = getAllData(providedData, GRAPH_KIND.NPSHr, dpIndexes, unitMetrics);
    let rubbingSlidingVelocity = getJoinedRubbingSlidingVelocity(
      providedData,
      dpIndexes,
      GRAPH_TYPE.DUTY_POINTS_WITH_DIFF_VISCOSITY_AND_TEMPERATURE
    );
    return {
      speedCapacityList: capacityData,
      speedPowerList: powerTorqueData,
      speedNPSHrList: viscosityNPSHr,
      rubbingSlidingVelocity
    };
  }
};

const mapCalcPumps = (pumps) => {
  return pumps.map(
    ({
      ProductCode,
      Model,
      Materials,
      TypeCode,
      MaterialElastomer,
      StatorMaterial,
      StatorType,
      DeliveryTime,
      Price,
      EnergyEfficiency,
      MaintenanceCost,
      TotalCostOfOwnership,
      MeanTimeBetweenFailures,
      SolidsHandling,
      AbrasiveProductsHandling,
      LowShearEffect,
      LowPulsationEffect,
      ReversibleOperation,
      DryRunning,
      Valveless,
      NoDynLoadedElastComp,
      NoComponentContact,
      SmallInstallationEnvelope,
      LowMaintenanceTime,
      LowSlip,
      ShaftSealLowCost,
      AggressiveChemicalsLowCost,
      FailedPumpCalculationRestrictions,
      FailedRestrictions,
      MaxRotationSpeed,
      MaxPower,
      MaxTorque,
      StartingTorque,
      EnvironmentalTemperatureMin,
      EnvironmentalTemperatureMax,
      Geometry,
      GeometryType,
      MinRotationSpeed,
      MaxSlidingVelocity,
      maxNpshr,
      OverallEfficiencyMin,
      OverallEfficiencyMax,
      ReleaseCurveDate
    }) => {
      return {
        id: ProductCode,
        model: Model,
        overallEfficiencyMin:
          OverallEfficiencyMin !== undefined && OverallEfficiencyMin !== null
            ? setTwoDecimals(OverallEfficiencyMin * 100)
            : '',
        overallEfficiencyMax:
          OverallEfficiencyMax !== undefined && OverallEfficiencyMax !== null
            ? setTwoDecimals(OverallEfficiencyMax * 100)
            : '',
        material: Materials,
        typeCode: TypeCode,
        materialElastomer: MaterialElastomer,
        statorMaterial: StatorMaterial,
        statorType: StatorType,
        deliveryTime:
          DeliveryTime !== undefined && DeliveryTime !== null ? parseFloat(DeliveryTime) : '',
        price: Price !== undefined && Price !== null ? parseFloat(Price) : '',
        energyEfficiency:
          EnergyEfficiency !== undefined && EnergyEfficiency !== null
            ? parseFloat(EnergyEfficiency)
            : '',
        maintenanceCost:
          MaintenanceCost !== undefined && MaintenanceCost !== null
            ? parseFloat(MaintenanceCost)
            : '',
        tco:
          TotalCostOfOwnership !== undefined && TotalCostOfOwnership !== null
            ? parseFloat(TotalCostOfOwnership)
            : '',
        meanTimeBetweenFailures:
          MeanTimeBetweenFailures !== undefined && MeanTimeBetweenFailures !== null
            ? parseFloat(MeanTimeBetweenFailures)
            : '',
        solidsHandling:
          SolidsHandling !== undefined && SolidsHandling !== null ? parseFloat(SolidsHandling) : '',
        aph:
          AbrasiveProductsHandling !== undefined && AbrasiveProductsHandling !== null
            ? parseFloat(AbrasiveProductsHandling)
            : '',
        shearEffect:
          LowShearEffect !== undefined && LowShearEffect !== null ? parseFloat(LowShearEffect) : '',
        pulsationEffect:
          LowPulsationEffect !== undefined && LowPulsationEffect !== null
            ? parseFloat(LowPulsationEffect)
            : '',
        reversibleOperation:
          ReversibleOperation !== undefined && ReversibleOperation !== null
            ? parseFloat(ReversibleOperation)
            : '',
        drc: DryRunning !== undefined && DryRunning !== null ? parseFloat(DryRunning) : '',
        valveless: Valveless !== undefined && Valveless !== null ? parseFloat(Valveless) : '',
        ndlec:
          NoDynLoadedElastComp !== undefined && NoDynLoadedElastComp !== null
            ? parseFloat(NoDynLoadedElastComp)
            : '',
        ncc:
          NoComponentContact !== undefined && NoComponentContact !== null
            ? parseFloat(NoComponentContact)
            : '',
        installationEnvelope:
          SmallInstallationEnvelope !== undefined && SmallInstallationEnvelope !== null
            ? parseFloat(SmallInstallationEnvelope)
            : '',
        lowMaintenanceTime:
          LowMaintenanceTime !== undefined && LowMaintenanceTime !== null
            ? parseFloat(LowMaintenanceTime)
            : '',
        lowSlip: LowSlip !== undefined && LowSlip !== null ? parseFloat(LowSlip) : '',
        sss:
          ShaftSealLowCost !== undefined && ShaftSealLowCost !== null
            ? parseFloat(ShaftSealLowCost)
            : '',
        aclcm:
          AggressiveChemicalsLowCost !== undefined && AggressiveChemicalsLowCost !== null
            ? parseFloat(AggressiveChemicalsLowCost)
            : '',
        failedPumpCalculationRestrictions: FailedPumpCalculationRestrictions,
        failedRestrictions: FailedRestrictions,
        rotationSpeedMax:
          MaxRotationSpeed !== undefined && MaxRotationSpeed !== null
            ? Math.round(parseFloat(MaxRotationSpeed))
            : '',
        powerRequiredMax:
          MaxPower !== undefined && MaxPower !== null ? setTwoDecimals(parseFloat(MaxPower)) : '',
        torqueMax:
          MaxTorque !== undefined && MaxTorque !== null
            ? setTwoDecimals(parseFloat(MaxTorque))
            : '',
        startingTorque:
          StartingTorque !== undefined && StartingTorque !== null ? parseFloat(StartingTorque) : '',
        environmentalTemperatureMin:
          EnvironmentalTemperatureMin !== undefined && EnvironmentalTemperatureMin !== null
            ? EnvironmentalTemperatureMin
            : '',
        environmentalTemperatureMax:
          EnvironmentalTemperatureMax !== undefined && EnvironmentalTemperatureMax !== null
            ? EnvironmentalTemperatureMax
            : '',
        geometry: Geometry !== undefined && Geometry !== null ? Geometry : '',
        geometryType:
          GeometryType !== undefined && GeometryType !== null ? parseFloat(GeometryType) : '',
        minRotationSpeed:
          MinRotationSpeed !== undefined && MinRotationSpeed !== null
            ? Math.round(parseFloat(MinRotationSpeed))
            : '',
        maxSlidingVelocity:
          MaxSlidingVelocity !== undefined && MaxSlidingVelocity !== null
            ? setTwoDecimals(parseFloat(MaxSlidingVelocity))
            : '',
        maxNpshr:
          maxNpshr === undefined || maxNpshr === null
            ? ''
            : maxNpshr === Infinity || maxNpshr === -Infinity
            ? Infinity
            : setTwoDecimals(parseFloat(maxNpshr)),
        releaseCurveDate: ReleaseCurveDate
      };
    }
  );
};

const getTitleLabel = (graphDutyPoint) => {
  return {
    viscosity: graphDutyPoint?.viscosity,
    temperature: graphDutyPoint?.temperature
  };
};

const getAttributesForColumn = (data, attributePositions, columnNumber, componentGroupId) => {
  const columnAttributes = attributePositions.filter(
    ({ group_id, column }) => group_id === componentGroupId && column === columnNumber
  );

  return data
    .filter((attribute) => {
      return (
        attribute?.key_cls &&
        attribute.key_cls !== 3 &&
        columnAttributes.some((position) => position.key === attribute.key)
      );
    })
    .sort((a, b) => {
      const positionA = findPositionByKey(columnAttributes, a.key);
      const positionB = findPositionByKey(columnAttributes, b.key);

      if ((positionA?.row || positionA?.row === 0) && (positionB?.row || positionB?.row === 0)) {
        if (positionA.row < positionB.row) {
          return -1;
        }
        if (positionA.row > positionB.row) {
          return 1;
        }
      }

      return 0;
    });
};

const sortByPublicTitle = (products) => {
  return products.sort((product1, product2) => {
    if (product1?.PublicTitle !== null && product2?.PublicTitle !== null) {
      return product1.PublicTitle.localeCompare(product2.PublicTitle);
    } else return 0;
  });
};

const sortArrayLexicographic = (arr = []) => {
  return arr.sort((elem1, elem2) => elem1.localeCompare(elem2));
};

const getValueForCPQ = (attributesList = [], fieldName, selectedId) => {
  return attributesList
    .find(({ key }) => key === fieldName)
    .options.find(({ id }) => id === selectedId).value;
};

const arrContainByValue = (arr = [], providedValue) => {
  return arr.some((value) => value === providedValue);
};

const getTypeCodeOptions = (providedListOfPumps = []) => {
  return providedListOfPumps.reduce((newArr, pump) => {
    let typeCode = pump?.TypeCode;
    if (typeCode) {
      if (!arrContainByValue(newArr, typeCode)) newArr.push(typeCode);
    }
    return newArr;
  }, []);
};

const getMaterialOptions = (providedListOfPumps = [], typeCode) => {
  return providedListOfPumps.reduce((newArr, pump) => {
    let foundTypeCode = pump?.TypeCode;
    if (foundTypeCode === typeCode) {
      let foundMaterial = pump?.Materials;
      if (foundMaterial && !arrContainByValue(newArr, foundMaterial)) {
        newArr.push(foundMaterial);
      }
    }
    return newArr;
  }, []);
};

const getMaterialElastomerOptions = (providedListOfPumps = [], materialCode, typeCode) => {
  return providedListOfPumps.reduce((newArr, pump) => {
    let foundTypeCode = pump?.TypeCode;
    let foundMaterial = pump?.Materials;
    if (foundTypeCode === typeCode && foundMaterial === materialCode) {
      let foundMaterialElastomer = pump?.MaterialElastomer;
      if (foundMaterialElastomer && !arrContainByValue(newArr, foundMaterialElastomer))
        newArr.push(foundMaterialElastomer);
    }

    return newArr;
  }, []);
};

const mapSearchCriteria = (searchType) => {
  switch (searchType) {
    case SEARCH_METHOD.MULTI_SELECT:
      return Localize.get('productMultiSelect').defaultMessage('productMultiSelect');
    case SEARCH_METHOD.OTHER_CRITERIA:
      return Localize.get('productSingleSelect').defaultMessage('productSingleSelect');
    case SEARCH_METHOD.NO_RESTRICTION:
      return Localize.get('noSearchRestriction').defaultMessage('noSearchRestriction');
    case SEARCH_METHOD.MANUAL_CONFIG:
      return Localize.get('manualConfig').defaultMessage('manualConfig');
    default:
      return '';
  }
};

const getPumpByParameters = (typeCode, material, materialElastomer, listOfPumps) => {
  return listOfPumps.find((pump) => {
    let foundTypeCode = pump.TypeCode;
    let foundMaterial = pump.Materials;
    let foundMaterialElastomer = pump.MaterialElastomer;
    return (
      foundTypeCode === typeCode &&
      foundMaterial === material &&
      foundMaterialElastomer === materialElastomer
    );
  });
};

const checkIfSolidsSize = (dynamicField, values) => {
  if (dynamicField === 'ParticleContent') {
    return values['SolidsSize'] === 33 || values['SolidsSize'] === 34;
  } else {
    return true;
  }
};

const checkIfAreaIsHazardous = (dynamicField, values) => {
  if (
    (dynamicField.key !== 'HazardousArea' && dynamicField.key.includes('HazardousArea')) ||
    dynamicField.key === 'ExZoneCertification'
  ) {
    return values['HazardousArea'] === YES_OPTION_VALUE;
  } else {
    return true;
  }
};

const checkIfPolishedSurface = (dynamicField, values) => {
  if (dynamicField.key === 'ThreeA' || dynamicField.key === 'EHEDG') {
    return values['SmoothSurface'] === YES_OPTION_VALUE;
  } else {
    return true;
  }
};

const checkIfFoodGrade = (dynamicField, values) => {
  if (
    dynamicField.key === 'FDA' ||
    dynamicField.key === 'EC19352004' ||
    dynamicField.key === 'GB4806' ||
    dynamicField.key === 'Anvisa'
  ) {
    return values['FoodGrade'] === YES_OPTION_VALUE;
  } else {
    return true;
  }
};

const checkIfParticleContent = (dynamicField, values) => {
  if (dynamicField.key === 'SolidsSize') {
    return (
      values['ParticleContent'] === PARTICLE_CONTENT_SOFT_ID ||
      values['ParticleContent'] === PARTICLE_CONTENT_HARD_ID
    );
  } else {
    return true;
  }
};

export {
  isFilled,
  mapUnitsForCalcPerformance,
  mapDPForCalcPerformance,
  checkFluidParams,
  checkDutyPointParams,
  checkIOAValues,
  getUnitsFormatted,
  getIOAValues,
  areAllValuesFilled,
  checkFieldValue,
  getFluidValues,
  getMergedDataForGraphs,
  getUnitParameter,
  getTorqueTable,
  getPDFData,
  setTwoDecimals,
  mapStatorType,
  getDataForAllGraphs,
  mapCalcPumps,
  getTitleLabel,
  findPositionByKey,
  getAttributesForColumn,
  sortByPublicTitle,
  sortArrayLexicographic,
  getValueForCPQ,
  getCPQAttributeValues,
  getUnitsPerCpq,
  getTypeCodeOptions,
  getMaterialOptions,
  getMaterialElastomerOptions,
  mapSearchCriteria,
  getPumpByParameters,
  checkIfAreaIsHazardous,
  checkIfPolishedSurface,
  checkIfFoodGrade,
  checkIfParticleContent
};
