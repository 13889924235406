import { createListenerMiddleware, isAnyOf, createAction } from '@reduxjs/toolkit';

import { DEFAULT_VALUES } from '@common/constants';
import { GRAPH_PATHS } from '@common/network/ApiPaths';
import { setFluidIsChange } from '@common/slices/fluidSlice/fluidSlice';
import { setIndustryIsChange } from '@common/slices/industrySlice/industrySlice';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { setDPIsChange } from '@common/slices/operationCondition/operationConditionSlice';
import {
  mapUnitsForCalcPerformance,
  mapDPForCalcPerformance,
  getMergedDataForGraphs,
  getDataForAllGraphs,
  getTitleLabel
} from '@pages/Analyzer/hooks/utils';
import { postByPathAndData } from '@services/BaseApi';

import { setResultList, setGraphTitleLabel, setGraphData } from '../resultListSlice';

const resultListSliceMiddlewareListener = createListenerMiddleware();

const action1 = createAction('resultList/setResultList');
const action2 = createAction('resultList/setSelectedDutyPoint');

// get curve-performance for selected pump
resultListSliceMiddlewareListener.startListening({
  predicate: (_, currentState, previousState) => {
    if (
      currentState.resultList.selectedPump?.id !== previousState.resultList.selectedPump?.id ||
      (currentState.resultList.selectedPump?.id === previousState.resultList.selectedPump?.id &&
        (currentState.fluidAttributes.isChanged ||
          currentState.generalAttributes.isChanged ||
          currentState.operationCondition.isChanged) &&
        currentState.layout.currentStep.value === STEP_VALUES.result.value)
    ) {
      return true;
    }
    return false;
  },
  effect: async (_, listenerApi) => {
    const { operationCondition, unitsAndAttributes, resultList } = listenerApi.getState();

    let myObj = {};
    let groupedDutyPointIds = [];

    try {
      const selectedPump = resultList.selectedPump;

      let { data } = await postByPathAndData({
        path: GRAPH_PATHS.CURVE_PERFORMANCE,
        data: {
          mode:
            operationCondition.capacityOrSpeed === DEFAULT_VALUES.DP_INPUT_VARIATION.CAPACITY
              ? DEFAULT_VALUES.ZERO_VALUE
              : DEFAULT_VALUES.ONE_VALUE,
          model:
            selectedPump.model === null || selectedPump.model === undefined
              ? ''
              : selectedPump.model,
          stator_material:
            selectedPump.statorMaterial === null || selectedPump.statorMaterial === undefined
              ? ''
              : selectedPump.statorMaterial,
          stator_type:
            selectedPump.statorType === null || selectedPump.statorType === undefined
              ? ''
              : selectedPump.statorType,
          calculation_values: {
            duty_points: mapDPForCalcPerformance(operationCondition.dutyPoints),
            numberOfLinePoints: 6,
            directionAndMaterial: 0,
            abrasionClass: 0,
            fibresClass: 0
          },
          custom_units: mapUnitsForCalcPerformance(unitsAndAttributes.sessionUnitMetrics)
        }
      });

      myObj.graphDataOriginal = data;

      if (data?.resultValues?.calculation_values?.graphs?.length) {
        data?.resultValues?.calculation_values?.graphs?.forEach(
          (pressureLevelSCPT, index1, self) => {
            let currDutyPoint = pressureLevelSCPT?.dutyPointAndOther;
            let tempDutyPointsIds = [];
            if (groupedDutyPointIds?.length > 0) {
              if (!groupedDutyPointIds?.find((dpArray) => dpArray?.includes(index1))) {
                tempDutyPointsIds.push(index1);
                self.forEach(({ dutyPointAndOther }, index2) => {
                  if (
                    index1 < index2 &&
                    currDutyPoint?.temperature === dutyPointAndOther?.temperature &&
                    currDutyPoint?.viscosity === dutyPointAndOther?.viscosity
                  ) {
                    tempDutyPointsIds.push(index2);
                  }
                });
                groupedDutyPointIds?.push(tempDutyPointsIds);
              }
            } else {
              tempDutyPointsIds.push(index1);
              self.forEach(({ dutyPointAndOther }, index2) => {
                if (
                  index1 < index2 &&
                  currDutyPoint?.temperature === dutyPointAndOther?.temperature &&
                  currDutyPoint?.viscosity === dutyPointAndOther?.viscosity
                ) {
                  tempDutyPointsIds.push(index2);
                }
              });
              groupedDutyPointIds?.push(tempDutyPointsIds);
            }
          }
        );

        myObj.groupedDutyPoints = groupedDutyPointIds;

        if (groupedDutyPointIds?.length > 1) {
          myObj.selectedDutyPoint = undefined;
        } else {
          myObj.selectedDutyPoint = groupedDutyPointIds[0];
        }
      }

      listenerApi.dispatch(setIndustryIsChange());
      listenerApi.dispatch(setFluidIsChange());
      listenerApi.dispatch(setDPIsChange());
      listenerApi.dispatch(setResultList(myObj));
    } catch (err) {
      // console.log('err: ', err);
    }
  }
});

// set graph data for selected duty points
resultListSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2),
  effect: (_, listenerApi) => {
    const { unitsAndAttributes, resultList } = listenerApi.getState();

    let graphDataOriginal = resultList.graphDataOriginal;
    let selectedDutyPoint = resultList.selectedDutyPoint;
    let unitMetrics = unitsAndAttributes.sessionUnitMetrics;

    let graphData;
    if (selectedDutyPoint !== undefined) {
      if (selectedDutyPoint?.length > 0 && graphDataOriginal !== undefined) {
        graphData = getMergedDataForGraphs(graphDataOriginal, selectedDutyPoint);

        listenerApi.dispatch(
          setGraphTitleLabel(
            getTitleLabel(
              graphDataOriginal?.resultValues?.calculation_values?.graphs[selectedDutyPoint[0]]
                ?.dutyPointAndOther
            )
          )
        );
      }
    } else if (graphDataOriginal !== undefined) {
      let dutyPoints = [];
      for (
        let i = 0;
        i < graphDataOriginal?.resultValues?.calculation_values?.graphs?.length;
        i++
      ) {
        dutyPoints.push(i);
      }
      graphData = getDataForAllGraphs(graphDataOriginal, dutyPoints, unitMetrics);

      listenerApi.dispatch(setGraphTitleLabel());
    }
    if (graphData !== undefined) listenerApi.dispatch(setGraphData(graphData));
  }
});

export default resultListSliceMiddlewareListener;
