import { createTheme } from '@mui/material';

import lightPalette from './lightPalette';

const scrollBarBkgColor = '#ededed';
const scrollBkgColor = '#dadada';
const scrollBkgFocColor = '#c6c6c6';
const scrollBkgHovColor = '#c6c6c6';

const createNetzschTheme = () =>
  createTheme({
    palette: lightPalette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 900,
        lg: 1024,
        xl: 1536
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: `${scrollBkgColor} ${scrollBarBkgColor}`,
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: scrollBarBkgColor,
              width: 10,
              height: 10
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: scrollBkgColor,
              minHeight: 24,
              border: `1px solid ${scrollBarBkgColor}`
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
              {
                backgroundColor: scrollBkgFocColor
              },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
              {
                backgroundColor: scrollBkgFocColor
              },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
              {
                backgroundColor: scrollBkgHovColor
              },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: scrollBarBkgColor
            }
          }
        }
      }
    }
  });

export default createNetzschTheme;
