import React from 'react';
import Localize from 'react-intl-universal';

import { TabPanel } from '@mui/lab';

import BaseDataPanel from './components/BaseDatePanel';
import PressureDataPanel from './components/PressureDataPanel';
import ProductDetails from './components/ProductDetails';
import ProductLimitsPanel from './components/ProductLimitsPanel';
import ProductsPanel from './components/ProductsPanel';
import StatorPicturePanel from './components/StatorPicturePanel';

const ProductTabPanels = ({ productPanelTabOptions }) => {
  return (
    <>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionOne}>
        <ProductsPanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionTwo}>
        <ProductLimitsPanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionThree}>
        {Localize.get('limitsMapping').defaultMessage('limitsMapping')}
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionFour}>
        {Localize.get('limitsFluid').defaultMessage('limitsFluid')}
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionFive}>
        <ProductDetails />
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionSix}>
        <BaseDataPanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionSeven}>
        <PressureDataPanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={productPanelTabOptions.optionEight}>
        <StatorPicturePanel />
      </TabPanel>
    </>
  );
};

export default ProductTabPanels;
