import { createSlice } from '@reduxjs/toolkit';

import {
  getMaterialElastomerOptions,
  getMaterialOptions,
  getTypeCodeOptions,
  sortArrayLexicographic,
  getPumpByParameters
} from '@pages/Analyzer/hooks/utils';

export const initialState = {
  productCode: '',
  productRange: '',
  typeCode: '',
  listOfTypeCodes: [],
  material: '',
  listOfMaterial: [],
  materialElastomer: '',
  listOfMaterialElastomer: [],
  listOfPumps: [],
  isLoading: false,
  otherCriteriaSelectedPump: undefined,
  cpqSelectedPump: undefined
};

export const selectPumpSlice = createSlice({
  name: 'selectPump',
  initialState,
  reducers: {
    setSelectPumpToInit: () => {
      return initialState;
    },
    setListOfPumps: (state, { payload }) => {
      state.isLoading = false;
      state.listOfPumps = payload;
    },
    setListOfTypeCodes: (state, { payload }) => {
      state.listOfTypeCodes = sortArrayLexicographic(getTypeCodeOptions(payload));
      state.typeCode = '';
      state.material = '';
      state.listOfMaterial = [];
      state.materialElastomer = '';
      state.listOfMaterialElastomer = [];
      state.isLoading = false;
    },
    setTypeCode: (state, { payload }) => {
      state.typeCode = payload;
      state.listOfMaterial = sortArrayLexicographic(
        getMaterialOptions(state.listOfPumps, state.typeCode)
      );
      state.material = '';
      state.materialElastomer = '';
      state.otherCriteriaSelectedPump = undefined;
    },
    setMaterial: (state, { payload }) => {
      state.material = payload;
      state.listOfMaterialElastomer = sortArrayLexicographic(
        getMaterialElastomerOptions(state.listOfPumps, state.material, state.typeCode)
      );
      state.materialElastomer = '';
      state.otherCriteriaSelectedPump = undefined;
    },
    setMaterialElastomer: (state, { payload }) => {
      state.materialElastomer = payload;
      state.otherCriteriaSelectedPump = getPumpByParameters(
        state.typeCode,
        state.material,
        payload,
        state.listOfPumps
      );
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    clearInputFields: (state) => {
      state.productCode = '';
      state.productRange = '';
      state.typeCode = '';
      state.listOfTypeCodes = [];
      state.material = '';
      state.listOfMaterial = [];
      state.materialElastomer = '';
      state.listOfMaterialElastomer = [];
      state.otherCriteriaSelectedPump = undefined;
    },
    fetchPumpsForOtherCriteria: () => {},
    setSelectPumpFromCPQ: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setProductCode: (state, { payload }) => {
      state.productCode = payload;
    },
    setProductRange: (state, { payload }) => {
      state.productRange = payload;
    }
  }
});

export const selectTypeCode = (state) => state.selectPump.typeCode;
export const selectListOfTypeCodes = (state) => state.selectPump.listOfTypeCodes;
export const selectMaterial = (state) => state.selectPump.material;
export const selectListOfMaterial = (state) => state.selectPump.listOfMaterial;
export const selectMaterialElastomer = (state) => state.selectPump.materialElastomer;
export const selectListOfMaterialElastomer = (state) => state.selectPump.listOfMaterialElastomer;
export const selectListOfPumps = (state) => state.selectPump.listOfPumps;
export const selectPumpIsLoading = (state) => state.selectPump.isLoading;
export const selectPumpSliceNextAllowed = (state) =>
  Boolean(state.selectPump.typeCode) &&
  Boolean(state.selectPump.material) &&
  Boolean(state.selectPump.materialElastomer);

const { reducer, actions } = selectPumpSlice;

export const {
  setSelectPumpToInit,
  setTypeCode,
  setMaterial,
  setMaterialElastomer,
  clearInputFields,
  setIsLoading,
  setListOfPumps,
  setListOfTypeCodes,
  fetchPumpsForOtherCriteria,
  setSelectPumpFromCPQ,
  setProductCode,
  setProductRange
} = actions;

export default reducer;
