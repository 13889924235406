import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { DEFAULT_VALUES, LOGIN_LOGOUT_API, ROUTER_PATHS } from '@common/constants';
import { setCpqUrlParams } from '@common/slices/cpqSlice/cpqSlice';
import { setLoggedIn } from '@common/slices/userSlice/userSlice';
import { postByPathAndData } from '@services/BaseApi';

const Secret = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const login = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const QuoteNumber = params.get('QuoteNumber');
      const configurationFlag = params.get('configuration_flag');
      const CpqTableEntryId = params.get('CpqTableEntryId');
      const PartNumber = params.get('PartNumber');
      const ItemID = params.get('ItemID');
      const ItemNumber = params.get('ItemNumber');
      const Host_Url = params.get('Host_Url');
      const ItemGUID = params.get('ItemGUID');
      const CPQ_41PERMISSION02 = params.get('CPQ_41PERMISSION02');
      const optionalProps = {
        ItemID,
        ItemGUID,
        PartNumber,
        CpqTableEntryId,
        ItemNumber,
        CPQ_41PERMISSION02,
        Host_Url
      };

      try {
        const { data } = await postByPathAndData({
          headers: {
            'Content-Type': 'application/json',
            authorization: `${DEFAULT_VALUES.AUTH.BEARER} ${token}`
          },
          baseURL: `${LOGIN_LOGOUT_API.API_URL}${ROUTER_PATHS.samlTokenCheck}`
        });

        if (token && data?.email) {
          dispatch(
            setLoggedIn({
              isLoggedIn: true,
              firstName: data.first_name,
              lastName: data.last_name,
              email: data.email,
              token,
              isAdmin: data.is_admin,
              status: data.status,
              userId: data.id,
              plant: data.plant,
              unitSystem: data?.unit_system ? data.unit_system.toUpperCase() : null,
              languageUser: data.default_language_id,
              expertModeAllowed: Boolean(data?.expert_mode_allowed)
            })
          );
          if (data?.status === 'inactive') {
            navigate({ to: '/pending-approval', replace: false });
          } else {
            if (QuoteNumber && configurationFlag) {
              dispatch(
                setCpqUrlParams({
                  QuoteNumber,
                  configurationFlag,
                  ...optionalProps
                })
              );
              params.delete('token');
              navigate({
                to: `${ROUTER_PATHS.analyzer}?${params}`,
                replace: false
              });
            } else {
              navigate({ to: ROUTER_PATHS.analyzer, replace: false });
            }
          }
        }
      } catch (e) {
        console.log('error', e);
      }
    };

    login();
    /* eslint-disable-next-line */
  }, []);
  return <div>{Localize.get('loading').defaultMessage('loading')}</div>;
};

export default Secret;
