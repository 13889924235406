const CAPACITY_TAB = '1';
const POWER_TAB = '2';
const TORQUE_TAB = '3';
const NPSHR_TAB = '4';
const RUBBING_SLIDING_TAB = '5';

const GRAPHS_ACCESSORS = {
  X_AXIS_DATA_KEY: 'speed',
  CAPACITY_LINE_DATA_KEY: 'capacity',
  POWER_LINE_DATA_KEY: 'power',
  TORQUE_LINE_DATA_KEY: 'torque',
  NPSHR_LINE_DATA_KEY: 'NPSHr'
};

export {
  CAPACITY_TAB,
  POWER_TAB,
  TORQUE_TAB,
  NPSHR_TAB,
  RUBBING_SLIDING_TAB,
  GRAPHS_ACCESSORS
};
