import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { DEFAULT_VALUES } from '@common/constants';
import { setSteps } from '@common/slices/layoutSlice/layoutSlice';

const BORDER_LINE = '1px solid lightgray';
const FONT_SIZE_12 = '12px';
const FONT_SIZE_14 = '14px';
const FONT_WIGHT_600 = 600;
const SIDEBAR_BCK_COLOR = '#F7F7F7';

const getLabel = (groupValue, localizedText) => {
  return `${groupValue + 1}${DEFAULT_VALUES.CHARS.DOT} ${localizedText}`;
};

const MenuAccordion = ({
  group,
  getValueByKey,
  activeStep,
  isOpen,
  handleAccordionClick = () => {}
}) => {
  const dispatch = useDispatch();

  const goToStep = (newStep, newSubStep) => {
    dispatch(
      setSteps({
        currentStep: newStep,
        currentSubStep: newSubStep
      })
    );
  };

  let isActiveStep = activeStep.value === group?.value;

  return (
    <Accordion
      sx={{
        backgroundColor: SIDEBAR_BCK_COLOR,
        borderTop: BORDER_LINE,
        boxShadow: 'none'
      }}
      expanded={isOpen}
      onChange={handleAccordionClick}
    >
      <AccordionSummary
        expandIcon={group?.hideExpandIcon ? undefined : <ExpandMoreIcon color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          margin: '0px !important',
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0px'
          },
          backgroundColor: isActiveStep ? '#EFF5F4' : 'none'
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontSize: FONT_SIZE_14,
            fontWeight: FONT_WIGHT_600,
            pl: 2,
            color: (theme) => (isActiveStep ? theme.palette.primary.main : undefined)
          }}
        >
          {getLabel(group.value, Localize.get(group.key).defaultMessage(group.key))}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {group?.subSteps?.length && (
          <List
            sx={{
              borderTop: BORDER_LINE,
              p: 0,
              pt: 1,
              pb: 1
            }}
          >
            {group.subSteps.map((subStep) => {
              const subStepState = getValueByKey(subStep?.key);
              return (
                <ListItem key={subStep.key} sx={{ pt: 0, pb: 0, height: '40px' }}>
                  {subStepState?.navigationAllowed ? (
                    <CheckCircleIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        color: (theme) => theme.palette.primary.main
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ width: '16px', height: '16px' }} />
                  )}
                  <Box
                    sx={{
                      pl: 2,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    <Button
                      variant="p"
                      sx={{
                        textTransform: 'none',
                        height: '21',
                        p: 0,
                        minWidth: 0,
                        '&:hover': {
                          backgroundColor: SIDEBAR_BCK_COLOR
                        }
                      }}
                      disabled={!subStepState?.navigationAllowed}
                      onClick={() => goToStep(group, subStep)}
                    >
                      <Typography
                        sx={{
                          fontSize: FONT_SIZE_14,
                          fontWeight: FONT_WIGHT_600,
                          textDecoration: 'underline'
                        }}
                      >
                        {Localize.get(`${subStep.key}`).defaultMessage(`${subStep.key}`)}
                      </Typography>
                    </Button>

                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: FONT_SIZE_12,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                    >
                      {subStepState?.value ?? '-'}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuAccordion;
