import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';

import { ROUTER_PATHS, LOGIN_LOGOUT_API } from '@common/constants';

const HomePage = () => {
  window.location.replace(
    `${LOGIN_LOGOUT_API.API_URL}${ROUTER_PATHS.samlSSO}${window.location.search}`
  );

  return (
    <Box sx={{ p: 2 }}>
      <Box>{Localize.get('redirecting').defaultMessage('redirecting')}</Box>
    </Box>
  );
};

export default HomePage;
