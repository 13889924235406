// https://experience.sap.com/fiori-design-web/colors/

const LIGHTGREY = '#979EA5';
const NETZSCH_GREEN = '#007167';
const NETZSCH_GREEN_LIGHT = '#008b7e';
const NETZSCH_GREEN_DARK = '#005850';
const BLUE_FOG = '#EFF4F9';
const ORANGE_ACTION = '#E1A100';
const ORANGE_ACTION_LIGHT = '#fbb300';
const ORANGE_ACTION_DARK = '#c88f00';
const ERROR_RED = '#BB0000';
const ERROR_RED_LIGHT = '#FF8888';
const ERROR_RED_DARK = '#880000';
const BLUE_MUD = '#5E696E';

export default {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF'
  },
  primary: {
    main: NETZSCH_GREEN,
    light: NETZSCH_GREEN_LIGHT,
    dark: NETZSCH_GREEN_DARK,
    contrastText: BLUE_FOG
  },
  secondary: {
    main: ORANGE_ACTION,
    light: ORANGE_ACTION_LIGHT,
    dark: ORANGE_ACTION_DARK,
    contrastText: '#FFFFFF'
  },
  error: {
    main: ERROR_RED,
    light: ERROR_RED_LIGHT,
    dark: ERROR_RED_DARK,
    contrastText: BLUE_MUD
  },
  warning: {
    main: '#E78C07',
    light: '#FABD64',
    dark: '#E78C07'
  },
  info: {
    main: '#3277D5',
    light: '#91c8f6',
    dark: '#427CAC'
  },
  success: {
    main: '#2B7D2B',
    light: '#ABE2AB',
    dark: '#2B7C2B'
  },
  accent: {
    main: '#E6600D',
    light: '#E09D00',
    dark: '#C14646'
  },
  chart: {
    label: '#808080'
  },
  grey: {
    50: '#333333',
    100: '#666666',
    200: '#BFBFBF',
    300: '#CCCCCC',
    400: '#E5E5E5',
    500: '#FFFFFF'
  },
  action: {
    active: '#0099DB',
    hover: 'rgba(0, 0, 0, 0.1)',
    hoverOpacity: '0.4'
  },
  text: {
    primary: '#264247',
    secondary: LIGHTGREY
  }
};
