import React from 'react';

import TextField from '@mui/material/TextField';

import InputComponentWrapper from './InputComponentWrapper';

const InputComponent = ({
  label,
  hint = undefined,
  wrapperClassName = 'textFieldInputContainer',
  sxLabelWrapper,
  onHintClick,
  ...rest
}) => {
  return (
    <InputComponentWrapper
      wrapperClassName={wrapperClassName}
      sxLabelWrapper={sxLabelWrapper}
      onHintClick={onHintClick}
      label={label}
      hint={hint}
    >
      <TextField
        className='textFieldInput'
        variant='outlined'
        autoComplete='off'
        {...rest}
      />
    </InputComponentWrapper>
  );
};

export default InputComponent;
