import React from 'react';

import { Box } from '@mui/system';


import DataGridUsers from '@components/DataGridUsers';
import Loader from '@components/Loader';
import UserDetails from '@components/UserDetails';
import AdminLayout from '@pages/Admin/components/Layout';
import { userList } from '@pages/Admin/config/userList';
import useAdminDisplayState from '@pages/Admin/hooks/useAdminDisplayState';

const TabPanelUsers = () => {
  const {
    allUsers,
    fetchUsersLoading,
    uploadIsLoading,
    selectedUser,
    onSelectionUserChange,
    changeUserSettings
  } = useAdminDisplayState();

  return (
    <>
      <Loader isLoading={uploadIsLoading || fetchUsersLoading} />
      <AdminLayout
        listPanel={
          <Box className='adminListContainer'>
            <Box className='adminList'>
              <DataGridUsers
                columns={[...userList]}
                rows={allUsers}
                onSelectionUserChange={onSelectionUserChange}
                columnHeaderHeight={100}
                pageSize={50}
                density='compact'
                rowHeight={60}
                scrollable='scrollable'
                fixedSCroll='true'
                pagination
                columnBuffer={100}
              />
            </Box>
          </Box>
        }
        detailsPanel={
          <UserDetails
            selectedUser={selectedUser}
            changeUserSettings={changeUserSettings}
          />
        }
      />
    </>
  );
};

export default TabPanelUsers;
