export const PRODUCT_PROPERTIES_LIST = [
  'product_code',
  'internal_description',
  'public_description',
  'public_image',
  'site',
  'level',
  'parent_product_code',
  'public_title'
];

export const CPQ_MAPPING_PROPERTIES_LIST = [
  'Section',
  'AstCharacteristic',
  'InternalDescription',
  'CpqCharacteristic',
  'InternalDataType',
  'CpqDigits',
  'CpqDecimalPlaces'
];

export const PRODUCT_LIMITS_PROPERTIES_LIST = [
  'product_code',
  'FlowrateMin',
  'FlowrateMax',
  'PressureMax',
  'ViscosityMin',
  'ViscosityMax',
  'FluidTemperatureMin',
  'FluidTemperatureMax',
  'DryOrganicSolidsContentMin',
  'DryOrganicSolidsContentMax',
  'SpecificGravityMin',
  'SpecificGravityMax',
  'Abrasiveness1',
  'Abrasiveness2',
  'Abrasiveness3',
  'Abrasiveness4',
  'Abrasiveness5',
  'SandContent1',
  'SandContent2',
  'SandContent3',
  'ParticleContent1',
  'ParticleContent2',
  'CriticalSolidsSizeMin',
  'CriticalSolidsSizeMax',
  'SolidsSizeMax',
  'BuildsBridgesOrAgglomeratesNo',
  'BuildsBridgesOrAgglomeratesYes',
  'Adhesion1',
  'Adhesion2',
  'Adhesion3',
  'FibreLengthMax',
  'ShearSensitivity1',
  'ShearSensitivity2',
  'ShearSensitivity3',
  'PHValueMin',
  'PHValueMax',
  'ChlorideContentMax',
  'OilFatWaxContentNo',
  'OilFatWaxContentYes',
  'LubricatingNo',
  'LubricatingYes',
  'EnvironmentalTemperatureMin',
  'EnvironmentalTemperatureMax',
  'ImmersedNo',
  'ImmersedYes',
  'FDANo',
  'FDAYes',
  '3ANo',
  '3AYes',
  'EHEDGNo',
  'EHEDGYes',
  'EC19352004No',
  'EC19352004Yes',
  'GB4806No',
  'GB4806Yes',
  'AnvisaNo',
  'AnvisaYes',
  'FoodGradeNo',
  'FoodGradeYes',
  'SmoothSurfaceNo',
  'SmoothSurfaceYes',
  'CleaningInPlace',
  'SterilizationInPlace',
  'HazardousAreaNo',
  'HazardousAreaYes',
  'HazardousAreaZone0',
  'HazardousAreaZone1',
  'HazardousAreaZone2',
  'HazardousAreaZone20',
  'HazardousAreaZone21',
  'HazardousAreaZone22',
  'HazardousAreaTemperatureT1',
  'HazardousAreaTemperatureT2',
  'HazardousAreaTemperatureT3',
  'HazardousAreaTemperatureT4',
  'HazardousAreaTemperatureT5',
  'HazardousAreaTemperatureT6',
  'ExZoneCertification',
  'LeakageProtection',
  'MachineRegulations1',
  'MachineRegulations2',
  'MachineRegulations3',
  'MachineRegulations4'
];

export const PRESSURE_DATA_LIST = ['model', 'stator_material', 'stator_type', 'pressure'];

export const BASE_DATA_LIST = [
  'Model',
  'Size',
  'MaterialType',
  'StatorMaterial',
  'StatorType',
  'Stages',
  'Geometry',
  'GeometryType',
  'MaxParticleSize',
  'Release',
  'MachineType',
  'UnitGroup',
  'Displacement',
  'RotorPitchDiameter',
  'Eccentricity',
  'MaxSpeedLowPressure',
  'MaxSpeedHighPressure',
  'MaxPressureLowSpeed',
  'MaxPressureHighSpeed',
  'MaxPressureLowSpeedRHDCI',
  'MaxPressureHighSpeedRHDCI',
  'MaxPressureLowSpeedRHDSS',
  'MaxPressureHighSpeedRHDSS',
  'MinSpeedFactor',
  'PressureFactorAbrasion1',
  'PressureFactorAbrasion2',
  'PressureFactorAbrasion3',
  'PressureFactorAbrasion4',
  'PressureFactorAbrasion5',
  'SpeedFactorAbrasion1',
  'SpeedFactorAbrasion2',
  'SpeedFactorAbrasion3',
  'SpeedFactorAbrasion4',
  'SpeedFactorAbrasion5',
  'ViscosityLimitFactor1',
  'ViscosityLimitFactor2',
  'Temperature1',
  'Temperature2',
  'Temperature3',
  'Temperature2Factor',
  'Temperature3Factor',
  'RotorTemperature1',
  'RotorTemperature2',
  'ClearanceAvailable1',
  'ClearanceAvailable2',
  'ClearanceLowPressure',
  'ClearanceHighPressure',
  'ViscosityPositiveIncClearance',
  'ViscosityPositiveStdClearance',
  'ViscosityFactorIncClearance',
  'ViscosityFactorClearance1',
  'ViscosityFactorClearance2',
  'Margin1',
  'Slip5LowPressure',
  'Slip4LowPressure',
  'Slip3LowPressure',
  'Slip2LowPressure',
  'Slip1LowPressure',
  'PressureSplit',
  'Margin2',
  'Slip5HighPressure',
  'Slip3HighPressure',
  'Slip4HighPressure',
  'Slip2HighPressure',
  'Slip1HighPressure',
  'SlipSpan',
  'SlipEfficiency',
  'SlipFan',
  'CentrePoint',
  'SafetyFactorFibres1',
  'SafetyFactorFibres2',
  'SafetyFactorFibres3',
  'SafetyFactorFibres4',
  'Torque',
  'TorquePerBar',
  'RiseMaxSpeed',
  'RiseViscosityFactor',
  'MaxTorque',
  'StartingTorque',
  'PortDiameter',
  'NPSH1',
  'NPSH2',
  'NPSH3',
  'NPSHSpan1',
  'NPSHSpan2',
  'NPSHSpan3',
  'NPSHFan1',
  'NPSHFan2',
  'NPSHFan3',
  'NPSHFan4'
];

export const PRODUCT_DETAILS_LIST = [
  'product_code',
  'TypeCode',
  'Materials',
  'MaterialElastomer',
  'Model',
  'StatorMaterial',
  'StatorType',
  'Price',
  'MeanTimeBetweenFailures',
  'MaintenanceCost',
  'EnergyEfficiency',
  'TotalCostOfOwnership',
  'DeliveryTime',
  'SolidsHandling',
  'AbrasiveProductsHandling',
  'LowShearEffect',
  'LowPulsationEffect',
  'ReversibleOperation',
  'DryRunning',
  'Valveless',
  'NoDynLoadedElastComp',
  'NoComponentContact',
  'SmallInstallationEnvelope',
  'LowMaintenanceTime',
  'LowSlip',
  'ShaftSealLowCost',
  'AggressiveChemicalsLowCost'
];

export const USER_PARAMS_LIST = [
  'first_name',
  'last_name',
  'phone',
  'email',
  'status',
  'default_language',
  'plant',
  'unit_system',
  'admin',
  'expert_mode_allowed'
];

export const TRANSLATION_PROPERTIES_LIST = ['translation_id', 'language_id', 'value'];
