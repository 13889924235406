import React, { useState } from 'react';

import TabContext from '@mui/lab/TabContext';

import DataTabHeader from './components/DataTabHeader/DataTabHeader';
import DataTabPanels from './components/DataTabPanels/DataTabPanels';

const dataPanelTabOptions = {
  optionOne: 'generalAttributes',
  optionTwo: 'fluidAttributes',
  optionThree: 'products',
  optionFour: 'users'
};

const TabPanelData = () => {
  const [selectedTab, setSelectedTab] = useState(
    dataPanelTabOptions.optionThree
  );
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <TabContext value={selectedTab}>
      <DataTabHeader
        handleTabChange={handleTabChange}
        dataPanelTabOptions={dataPanelTabOptions}
        disabledOptions={{ optionOne: true, optionTwo: true }}
      />
      <DataTabPanels dataPanelTabOptions={dataPanelTabOptions} />
    </TabContext>
  );
};

export default TabPanelData;
