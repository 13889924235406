import { createSlice } from '@reduxjs/toolkit';

import { APP_LAYOUT_STEPS } from './config/layoutConfig';

export const initialState = {
  currentStep: APP_LAYOUT_STEPS.config[0],
  currentSubStep: APP_LAYOUT_STEPS.config[0].subSteps[0],
  nextEnabled: false,
  screenSize: window.innerWidth,
  openAccordions: [0]
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setCurrentSubStep: (state, { payload }) => {
      state.currentSubStep = payload;
    },
    setSteps: (state, { payload }) => {
      if (
        payload?.currentSubStep?.value ||
        payload?.currentSubStep?.value === 0
      )
        state.currentSubStep = payload.currentSubStep;
      if (payload?.currentStep?.value || payload?.currentStep?.value === 0) {
        state.currentStep = payload.currentStep;
        let allowedValues = [0, 1, 2, 3, 4];
        if (allowedValues.includes(payload.currentStep?.value)) {
          if (!state.openAccordions.includes(payload.currentStep?.value)) {
            state.openAccordions = [
              ...state.openAccordions,
              payload.currentStep.value
            ];
          }
        }
      }
    },
    setOpenAccordions: (state, { payload }) => {
      if (state.openAccordions.includes(payload)) {
        state.openAccordions = state.openAccordions.filter(
          accordion => accordion !== payload
        );
      } else {
        state.openAccordions = [...state.openAccordions, payload];
      }
    }
  }
});

export const selectCurrentStep = state => state.layout.currentStep;
export const selectCurrentSubStep = state => state.layout.currentSubStep;
export const selectOpenAccordions = state => state.layout.openAccordions;

const { reducer, actions } = layoutSlice;

export const { setSteps, setCurrentSubStep, setOpenAccordions } = actions;

export default reducer;
