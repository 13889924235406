export default [
  {
    field: 'TranslationId',
    headerName: 'TranslationId',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LanguageId',
    headerName: 'LanguageId',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Value',
    headerName: 'Value',
    width: 500,
    align: 'left',
    editable: false
  }
];
