import { createListenerMiddleware, isAnyOf, createAction } from '@reduxjs/toolkit';

import { SEARCH_METHOD } from '@common/constants';
import { PRODUCT_PATHS } from '@common/network/ApiPaths';
import { setOpenAccordions } from '@common/slices/layoutSlice/layoutSlice';
import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import {
  checkDutyPointParams,
  checkFluidParams,
  checkIOAValues,
  sortByPublicTitle,
  mapUnitsForCalcPerformance
} from '@pages/Analyzer/hooks/utils';
import { postByPathAndData } from '@services/BaseApi';

import { setIsLoading, setListOfProductRanges } from '../searchMethodSlice';

const searchMethodSliceMiddlewareListener = createListenerMiddleware();

const action1 = createAction('searchMethod/onProductClick');
const action2 = createAction('searchMethod/onProductRangesClick');
const action3 = createAction('searchMethod/onSelectProductRangeClick');
const action4 = createAction('searchMethod/onSelectProductClick');

// check next allowed and reset result list to init
searchMethodSliceMiddlewareListener.startListening({
  matcher: isAnyOf(action1, action2, action3, action4),
  effect: async ({ type }, listenerApi) => {
    const { searchMethod, layout } = listenerApi.getState();

    if (
      type === 'searchMethod/onProductRangesClick' &&
      searchMethod.selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA &&
      !layout.openAccordions.includes(4) &&
      searchMethod.multiSelect.selectedProductRangeCodes?.length
    ) {
      listenerApi.dispatch(setOpenAccordions(4));
    }
    listenerApi.dispatch(setResultListToInit());
  }
});

// get product families
searchMethodSliceMiddlewareListener.startListening({
  type: 'searchMethod/setSearchType',
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setResultListToInit());
  }
});

// get product ranges
searchMethodSliceMiddlewareListener.startListening({
  type: 'searchMethod/fetchProductRanges',
  effect: async (action, listenerApi) => {
    const {
      generalAttributes,
      fluidAttributes,
      operationCondition,
      unitsAndAttributes,
      user,
      searchMethod
    } = listenerApi.getState();

    const dynamicIOAFieldsValues = generalAttributes.dynamicIOAFieldsValues;
    const dynamicIOAFieldsValuesFormatted = checkIOAValues(dynamicIOAFieldsValues);

    const dynamicFluidFieldsValues = fluidAttributes.dynamicFluidFieldsValues;
    const dynamicFluidFields = fluidAttributes.dynamicFluidFields;
    const unitMetrics = unitsAndAttributes.sessionUnitMetrics;
    const fluidParams = checkFluidParams(dynamicFluidFieldsValues, unitMetrics, dynamicFluidFields);

    const dutyPoints = operationCondition.dutyPoints;
    const dutyPointParams = checkDutyPointParams(dutyPoints, unitMetrics);

    listenerApi.dispatch(setIsLoading(true));

    const { data, status } = await postByPathAndData({
      path: PRODUCT_PATHS.GET_PRODUCT_RANGES,
      params: { language_id: user.language },
      data: {
        customUnits: mapUnitsForCalcPerformance(unitMetrics),
        productParameters: {
          ...dynamicIOAFieldsValuesFormatted,
          ...fluidParams,
          ...dutyPointParams
        },
        productCodes:
          searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
            ? searchMethod.singleSelect.selectedProductCode.map(({ productCode }) => productCode)
            : searchMethod.multiSelect.selectedProductCodes.map(({ productCode }) => productCode)
      }
    });

    if (status !== 200) {
      listenerApi.dispatch(setIsLoading(false));
      throw { err: 'Something went wrong' };
    }

    listenerApi.dispatch(setListOfProductRanges(sortByPublicTitle(data)));
  }
});

export { searchMethodSliceMiddlewareListener };
