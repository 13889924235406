import Localize from 'react-intl-universal';

import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ExpertWarningIcon = () => {
  return (
    <Box className="footerWarningContainer">
      <WarningIcon color="warning" className="footerWarningIcon" />
      <Typography className="footerWarningText">
        <b>{Localize.get('expertMode').defaultMessage('expertMode')}</b>
      </Typography>
    </Box>
  );
};

export default ExpertWarningIcon;
