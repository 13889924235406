import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { Button, FormControlLabel, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { DEFAULT_VALUES, SETTINGS_DIALOG } from '@common/constants';
import { selectFluidAttributesNextAllowed } from '@common/slices/fluidSlice/fluidSlice';
import { selectGeneralAttributesNextAllowed } from '@common/slices/industrySlice/industrySlice';
import {
  onChangeDPValue,
  selectDutyPoints,
  selectCapacityOrSpeed,
  setCapacityOrSpeed,
  onAddClick,
  onCopyClick,
  onDeleteClick,
  selectErrors,
  selectOperationConditionNextAllowed,
  selectExpertNeeded
} from '@common/slices/operationCondition/operationConditionSlice';
import { selectSessionUnitMetrics } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectRules, setShowSettingsDialog } from '@common/slices/userSlice/userSlice';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import styleConfig from '@config/style.config.json';

import ListOfDutyPoints from './components';
import AnalyzerFooter from '../StepperComponent/AnalyzerFooter';

const isDisabled = (
  nextAllowedForOperationCondition,
  nextAllowedForFluid,
  nextAllowedForGeneralAttributes
) => {
  return (
    !nextAllowedForOperationCondition ||
    !nextAllowedForFluid.mediumType ||
    !nextAllowedForFluid.medium ||
    !nextAllowedForFluid.fluidAttributes ||
    !nextAllowedForGeneralAttributes.industryCluster ||
    !nextAllowedForGeneralAttributes.industryOfApplication ||
    !nextAllowedForGeneralAttributes.attributes
  );
};

const OperationConditions = () => {
  const customConfig = styleConfig.ast;
  const dispatch = useDispatch();

  const unitMetrics = useSelector(selectSessionUnitMetrics);
  const userRules = useSelector(selectRules);
  const dutyPoints = useSelector(selectDutyPoints);
  const capacityOrSpeed = useSelector(selectCapacityOrSpeed);
  const dutyPointErrors = useSelector(selectErrors);
  const nextAllowedForOperationConditions = useSelector(selectOperationConditionNextAllowed);
  const nextAllowedForFluid = useSelector(selectFluidAttributesNextAllowed);
  const nextAllowedForGeneralAttributes = useSelector(selectGeneralAttributesNextAllowed);

  const expertNeeded = useSelector(selectExpertNeeded);

  const handleOnChangeDP = (event, fieldName, index) => {
    let { value } = event.target;
    dispatch(onChangeDPValue({ value, fieldName, dpIndex: index }));
  };

  const onChangeUnitsClick = () => {
    dispatch(setShowSettingsDialog(SETTINGS_DIALOG.SESSION));
  };

  return (
    <>
      <SalesCloudContainer>
        <DetailsTitle
          title={Localize.get('pleaseAddDPConfiguration').defaultMessage(
            'pleaseAddDPConfiguration'
          )}
          description={Localize.get('operationConditionDescription').defaultMessage(
            'operationConditionDescription'
          )}
          onChangeUnitsClick={onChangeUnitsClick}
          showChangeUnitsButton={true}
        />

        {Boolean(userRules?.stepThree?.capacitySwitchEnabled) &&
          customConfig?.operationConditions?.showCapacitySwitch && (
            <FormControlLabel
              className="dutyPointSwitchContainer"
              checked={capacityOrSpeed === 'capacity'}
              control={<Switch color="primary" />}
              label={
                <Typography className="dutyPointSwitch">
                  {capacityOrSpeed === 'capacity'
                    ? Localize.get('capacity').defaultMessage('capacity')
                    : Localize.get('speed').defaultMessage('speed')}
                </Typography>
              }
              labelPlacement="start"
              onClick={(e) =>
                dispatch(
                  setCapacityOrSpeed(
                    e.target.checked
                      ? DEFAULT_VALUES.DP_INPUT_VARIATION.CAPACITY
                      : DEFAULT_VALUES.DP_INPUT_VARIATION.SPEED
                  )
                )
              }
            />
          )}

        <Box className="cardList dutyPointList">
          <ListOfDutyPoints
            dutyPoints={dutyPoints}
            onCopyClick={(newDutyPoint) => dispatch(onCopyClick(newDutyPoint))}
            addDisabled={dutyPoints?.length === 6}
            deleteDisabled={dutyPoints?.length === 1}
            onDeleteClick={(index) => dispatch(onDeleteClick(index))}
            capacityOrSpeed={capacityOrSpeed}
            dutyPointErrors={dutyPointErrors}
            unitMetrics={unitMetrics}
            handleOnChangeDP={handleOnChangeDP}
          />
          {dutyPoints?.length < 6 && (
            <Box className="addDutyPointContainer">
              <Box>
                <Typography className="dutyPointAddText">
                  {Localize.get('addDPConfiguration').defaultMessage('addDPConfiguration')}
                </Typography>
                <Button
                  variant="contained"
                  className="dutyPointButton dutyPointAddButton"
                  disabled={dutyPoints?.length === 6}
                  onClick={() => dispatch(onAddClick())}
                >
                  {DEFAULT_VALUES.CHARS.PLUS +
                    Localize.get('addNewDPC').defaultMessage('addNewDPC')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </SalesCloudContainer>
      <AnalyzerFooter
        nextDisabled={
          isDisabled(
            nextAllowedForOperationConditions,
            nextAllowedForFluid,
            nextAllowedForGeneralAttributes
          ) ||
          (expertNeeded && !userRules?.stepThree?.nextEnabledForExpertMode)
        }
        expertNeeded={expertNeeded}
      />
    </>
  );
};

export default OperationConditions;
