import React from 'react';
import Localize from 'react-intl-universal';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { SEARCH_METHOD } from '@common/constants';
import StringToElement from '@components/StringToElement';
import StyledButton from '@components/StyledButton/StyledButton';
import useGetImage from '@hooks/useGetImage';

const DefaultImg = () => (
  <Box className="productCardMissingImage">
    {Localize.get('imageMissing').defaultMessage('imageMissing')}
  </Box>
);

const ProductItemCard = ({
  id,
  product,
  handleClick = () => {},
  selectedItems,
  selectClicked = () => {},
  selectedSearchCriteria
}) => {
  const [imageData] = useGetImage(product?.ImageId);
  const isSelected = selectedItems.includes(product.ProductCode);

  return (
    <Card
      className={`productItemCard ${
        selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA || !isSelected ? '' : 'selectedCard'
      }`}
      key={id}
    >
      {Boolean(product?.FailedRestrictions?.length) && (
        <ErrIcon
          className="productErrorIcon"
          onClick={(event) => handleClick(event, product.FailedRestrictions)}
        />
      )}
      {selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA && isSelected && (
        <CheckCircleRoundedIcon className="productCheckedIcon" />
      )}
      <CardMedia
        className="productCardImage"
        component={imageData?.image ? 'img' : DefaultImg}
        image={imageData?.image}
        alt="No image provided"
      />
      <CardContent className="productCardContent">
        <Box>
          <Typography className="productCardValue">
            {product?.PublicTitle || product.ProductCode}
          </Typography>
        </Box>

        <Box className="productDescription">
          {product?.publicDescription ? (
            <StringToElement htmlString={product.publicDescription} />
          ) : (
            product?.InternalDescription
          )}
        </Box>
      </CardContent>

      <CardActions className="productCardActions">
        <StyledButton
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            selectClicked(
              product.ProductCode,
              product?.PublicTitle || product?.InternalDescription || '',
              isSelected
            );
          }}
        >
          {selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA || !isSelected
            ? Localize.get('select').defaultMessage('select')
            : Localize.get('unselect').defaultMessage('unselect')}
        </StyledButton>
      </CardActions>
    </Card>
  );
};

export default ProductItemCard;
