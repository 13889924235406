import React from 'react';
import Localize from 'react-intl-universal';

import { Box } from '@mui/system';

import DataGridList from '@components/DataGridList';
import Loader from '@components/Loader';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import AdminLayout from '@pages/Admin/components/Layout';
import usePressureDataPanel from '@pages/Admin/hooks/usePressureDataPanel';

import pressureDataListConfig from '../config/pressureDataList';

const PressureDataPanel = () => {
  const [
    pressureDataList,
    pressureDataIsLoading,
    onUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial
  ] = usePressureDataPanel();
  let index = 0;
  return (
    <Box>
      <Loader isLoading={uploadIsLoading || pressureDataIsLoading} />
      <Toast
        open={uploadToastState.open}
        handleClose={setUploadToastToInitial}
        status={uploadToastState.status}
        message={uploadToastState.message}
      />
      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                density="compact"
                rows={pressureDataList}
                getRowId={(item) => {
                  return `${item.Model}-${item.MaterialType}-${item.StatorMaterial}-${
                    item.Pressure
                  }-${index++}`;
                }}
                columns={pressureDataListConfig}
                columnHeaderHeight={100}
                pageSize={50}
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
              />
            </Box>
            <Box className="adminListFooter">
              <UploadButton
                onChange={(e) => onUpload(e, 'upsert')}
                id="upload-button-file"
                content={Localize.get('upsertData').defaultMessage('upsertData')}
              />
              <UploadButton
                onChange={(e) => onUpload(e, 'replace')}
                id="replace-button-file"
                content={Localize.get('replaceData').defaultMessage('replaceData')}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default PressureDataPanel;
