import { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { findById } from '@common/helpers/helpers';
import {
  fetchFluidAttributes,
  fetchFluidTypes,
  fetchFluids,
  fetchIndustries,
  fetchIndustryAttributes,
  fetchIndustryClusters,
  fetchTranslation
} from '@common/services';
import {
  selectMedium,
  selectMediumType,
  setMediumTypeLangChanged,
  setMediumLangChanged,
  setFluidStateWithoutListener
} from '@common/slices/fluidSlice/fluidSlice';
import {
  selectIndustryCluster,
  selectIndustryOfApplication,
  setIndustryClusterLangChanged,
  setIndustryOfApplicationLangChanged,
  setGeneralAttributesWIthoutListener
} from '@common/slices/industrySlice/industrySlice';
import { selectAttributePositions } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectLanguageSession } from '@common/slices/userSlice/userSlice';

import { getAttributesForColumn } from './utils';

const useLanguageChange = () => {
  const dispatch = useDispatch();
  const sessionLanguage = useSelector(selectLanguageSession);
  const industryCluster = useSelector(selectIndustryCluster);
  const industryOfApplication = useSelector(selectIndustryOfApplication);
  const mediumType = useSelector(selectMediumType);
  const medium = useSelector(selectMedium);
  const attributePositions = useSelector(selectAttributePositions);
  const [rerenderOnChange, setRerenderOnChange] = useState(false);

  const fallbackLanguageId = 1;

  useEffect(() => {
    const initLocales = async () => {
      const fallbackLocales = await fetchTranslation(fallbackLanguageId);
      const userLocales = await fetchTranslation(sessionLanguage);

      const languages = { fallbackLocales, userLocales };

      if (Object.keys(languages)?.length) {
        const locales = {
          [fallbackLanguageId]: languages.fallbackLocales,
          [sessionLanguage]: languages.userLocales
        };

        Localize.init({
          currentLocale: sessionLanguage,
          locales,
          fallbackLocale: fallbackLanguageId
        }).then(async () => {
          /* call clusters if it's selected */
          const idustryClusterData = await fetchIndustryClusters(sessionLanguage);
          dispatch(
            setIndustryClusterLangChanged(findById(idustryClusterData, industryCluster?.id) ?? null)
          );

          /* call industries if it's selected */
          if (industryOfApplication?.id) {
            const industryData = await fetchIndustries(industryCluster?.id, sessionLanguage);
            dispatch(
              setIndustryOfApplicationLangChanged(findById(industryData, industryOfApplication?.id))
            );

            if (industryOfApplication?.id) {
              const industryAttributesData = await fetchIndustryAttributes(
                industryOfApplication?.id,
                sessionLanguage
              );

              if (industryAttributesData) {
                dispatch(
                  setGeneralAttributesWIthoutListener({
                    dynamicIOAFields: {
                      firstColumn: getAttributesForColumn(
                        industryAttributesData,
                        attributePositions,
                        2, // column number
                        1 // id that presents fist step in AST
                      ),
                      secondColumn: getAttributesForColumn(
                        industryAttributesData,
                        attributePositions,
                        3, // column number
                        1 // id that presents fist step in AST
                      )
                    }
                  })
                );
              }
            }
          }

          /* call fluid types if it's selected */
          if (mediumType?.id) {
            const dataFluidTypes = await fetchFluidTypes(
              industryOfApplication?.id,
              sessionLanguage
            );
            dispatch(setMediumTypeLangChanged(findById(dataFluidTypes, mediumType?.id)));
          }

          /* call fluids if it's selected */
          if (medium?.id) {
            const dataFluids = await fetchFluids(
              industryOfApplication?.id,
              mediumType?.id,
              sessionLanguage
            );
            dispatch(setMediumLangChanged(findById(dataFluids, medium?.id)));

            if (medium?.id) {
              const fluidAttributesData = await fetchFluidAttributes(medium?.id, sessionLanguage);

              if (fluidAttributesData) {
                dispatch(
                  setFluidStateWithoutListener({
                    dynamicFluidFields: {
                      firstColumn: getAttributesForColumn(
                        fluidAttributesData,
                        attributePositions,
                        2, // column number
                        2 // id that presents second step in AST
                      ),
                      secondColumn: getAttributesForColumn(
                        fluidAttributesData,
                        attributePositions,
                        3, // column number
                        2 // id that presents second step in AST
                      ),
                      thirdColumn: getAttributesForColumn(
                        fluidAttributesData,
                        attributePositions,
                        4
                      )
                    }
                  })
                );
              }
            }
          }

          document.title = Localize.get('appTitle').defaultMessage(
            'NETZSCH Analyzer/Selector Tool'
          );
          setRerenderOnChange(!rerenderOnChange);
        });
      }
    };

    initLocales();
    //eslint-disable-next-line
  }, [sessionLanguage]);

  return {};
};

export default useLanguageChange;
