import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { isEqual } from 'lodash';

import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';

import { ROUTER_PATHS, LOGIN_LOGOUT_API } from '@common/constants';
import { UNIT_SYSTEM_NAMES, UNIT_SYSTEMS } from '@common/constants';
import { copyObjectWithoutRef, getMetricSystemValues, findById } from '@common/helpers/helpers';
import {
  postUserCustomUnitsData,
  postUserLanguage,
  postUserStandardUnitsData
} from '@common/services';
import {
  selectUserUnitMetrics,
  selectUserUnitSystemName,
  setUserUnitSystemName,
  setUserUnitMetrics
} from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import {
  selectLanguages,
  selectLanguageSession,
  setShowSettingsDialog,
  selectUserId,
  setUserAndStorage,
  selectLanguageUser,
  setSessionLanguage
} from '@common/slices/userSlice/userSlice';
import SessionDialog from '@components/SessionDialog';
import UserlDialog from '@components/UserlDialog';
import { getByPathAndParams } from '@services/BaseApi';

const TABS = {
  SESSION: '1',
  PERSONAL: '2'
};

const PersonalSettingsDialog = () => {
  const dispatch = useDispatch();

  const languageOptions = useSelector(selectLanguages);
  const userLanguage = useSelector(selectLanguageUser);
  const sessionLanguage = useSelector(selectLanguageSession);
  const userUnitMetrics = useSelector(selectUserUnitMetrics);
  const userUnitSystemName = useSelector(selectUserUnitSystemName);
  const userId = useSelector(selectUserId);

  const [dialogUnitsMetricsUser, setDialogUnitsMetricsUser] = useState(userUnitMetrics);
  const [dialogUnitSystemNameUser, setDialogUnitSystemNameUser] = useState(userUnitSystemName);
  const [dialogLanguageUser, setDialogLanguageUser] = useState(userLanguage);
  const [dialogLanguageSession, setDialogLanguageSession] = useState(sessionLanguage);

  const onSaveUserClick = async () => {
    const isUserLangUpdated = userLanguage !== dialogLanguageUser;

    const isSessionLangUpdated = dialogLanguageSession !== sessionLanguage;
    if (isSessionLangUpdated) dispatch(setSessionLanguage(dialogLanguageSession));

    const resultState = {};

    if (isUserLangUpdated) {
      const resLang = await postUserLanguage(userId, dialogLanguageUser);
      if (resLang) resultState.languageUser = dialogLanguageUser;
    }

    const isUnitMetricsUpdated = !isEqual(userUnitMetrics, dialogUnitsMetricsUser);
    if (isUnitMetricsUpdated) {
      // check if custom units object should be added to payload
      if (dialogUnitSystemNameUser === UNIT_SYSTEM_NAMES.CUSTOM) {
        const responseCustomUnits = await postUserCustomUnitsData(
          userId,
          dialogUnitSystemNameUser,
          dialogUnitsMetricsUser
        );
        if (responseCustomUnits) resultState.unitSystem = dialogUnitSystemNameUser;
      } else {
        const responseStandardUnits = await postUserStandardUnitsData(
          userId,
          dialogUnitSystemNameUser
        );

        if (responseStandardUnits) resultState.unitSystem = dialogUnitSystemNameUser;
      }

      dispatch(setUserUnitSystemName(dialogUnitSystemNameUser));
      dispatch(setUserUnitMetrics(dialogUnitsMetricsUser));
    }

    dispatch(setUserAndStorage(resultState));
    dispatch(setShowSettingsDialog(undefined));
  };

  const onChangeUserLanguage = (event) => {
    setDialogLanguageSession(event.target.value);
    setDialogLanguageUser(event.target.value);
  };

  const handleOnChangeMetricValueSession = (fieldName, event) => {
    const myState = copyObjectWithoutRef(dialogUnitsMetricsUser);
    myState[fieldName] = {
      ...myState[fieldName],
      value: findById(myState[fieldName].options, event.target.value)
    };

    let currentMetricValues = Object.keys(myState).reduce((currentObj, currentUnitGroup) => {
      currentObj[currentUnitGroup] = myState[currentUnitGroup]?.value?.id;
      return currentObj;
    }, {});

    if (isEqual(currentMetricValues, UNIT_SYSTEMS.SI)) {
      setDialogUnitSystemNameUser(UNIT_SYSTEM_NAMES.SI);
    } else if (isEqual(currentMetricValues, UNIT_SYSTEMS.US)) {
      setDialogUnitSystemNameUser(UNIT_SYSTEM_NAMES.US);
    } else {
      setDialogUnitSystemNameUser(UNIT_SYSTEM_NAMES.CUSTOM);
    }
    setDialogUnitsMetricsUser(myState);
  };

  const onChangeMetricSystemName = (event) => {
    const currentMetricSystemValues = getMetricSystemValues(event.target.value);
    const myState = copyObjectWithoutRef(dialogUnitsMetricsUser);
    const newState = Object.keys(myState).reduce((currentObj, currentUnit) => {
      currentObj[currentUnit] = {
        ...myState[currentUnit],
        value: findById(myState[currentUnit]?.options, currentMetricSystemValues[currentUnit])
      };
      return currentObj;
    }, {});

    setDialogUnitSystemNameUser(event.target.value);
    setDialogUnitsMetricsUser(newState);
  };

  const [selectedTab, setSelectedTab] = useState(TABS.SESSION);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const onLogoutClick = async () => {
    let { data } = await getByPathAndParams({
      path: `${LOGIN_LOGOUT_API.API_URL}${ROUTER_PATHS.samlLogout}`
    });
    if (data?.uri) {
      window.location.replace(data.uri);
    }
  };

  const onSessionLangChange = (event) => {
    setDialogLanguageSession(event.target.value);
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <DialogTitle>
          <TabList onChange={handleTabChange}>
            <Tab
              label={Localize.get('sessionSettings').defaultMessage('sessionSettings')}
              value={TABS.SESSION}
            />
            <Tab
              label={Localize.get('personalSettings').defaultMessage('personalSettings')}
              value={TABS.PERSONAL}
            />
          </TabList>
        </DialogTitle>
        <DialogContent>
          <TabPanel value={TABS.SESSION}>
            <SessionDialog
              languageOptions={languageOptions}
              languageValue={dialogLanguageSession}
              onSessionLangChange={onSessionLangChange}
            />
          </TabPanel>
          <TabPanel value={TABS.PERSONAL}>
            <UserlDialog
              dialogName="userDialog"
              userLanguage={dialogLanguageUser}
              languageOptions={languageOptions}
              onChangeLanguage={onChangeUserLanguage}
              dialogUnitSystemNameUser={dialogUnitSystemNameUser}
              onChangeMetricSystemName={onChangeMetricSystemName}
              dialogUnitsMetricsUser={dialogUnitsMetricsUser}
              handleOnChangeMetricValueSession={handleOnChangeMetricValueSession}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          {selectedTab === TABS.PERSONAL ? (
            <Button color="secondary" variant="contained" onClick={onSaveUserClick}>
              {Localize.get('ok').defaultMessage('ok')}
            </Button>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={6} align="left">
                <Button color="primary" variant="contained" onClick={onLogoutClick}>
                  {Localize.get('logout').defaultMessage('logout')}
                </Button>
              </Grid>
              <Grid item xs={6} align="right">
                <Button color="secondary" variant="contained" onClick={onSaveUserClick}>
                  {Localize.get('ok').defaultMessage('ok')}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </TabContext>
    </>
  );
};

export default PersonalSettingsDialog;
