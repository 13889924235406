import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import { selectCpqError, setClearErrors } from '@common/slices/cpqSlice/cpqSlice';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { selectCurrentStep } from '@common/slices/layoutSlice/layoutSlice';
import SettingsDialog from '@components/SettingsDialog';
import Toast from '@components/Toast';
import useExpertState from '@pages/Admin/hooks/useExpertState';
import FluidAttributes from '@pages/Analyzer/components/FluidAttributes/FluidAttributes';
import GeneralAttributes from '@pages/Analyzer/components/GeneralAttributes/GeneralAttributes';
import OperationConditions from '@pages/Analyzer/components/OperationConditions/OperationConditions';
import ResultList from '@pages/Analyzer/components/ResultList/ResultList';

import SearchMethod from './components/SearchMethod/SearchMethod';
import useLanguageChange from './hooks/useLanguageChange';
import useUnitsAndAttributePositions from './hooks/useUnitsAndAttributePositions';

const Analyzer = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(selectCurrentStep);
  const cpqCommunicationError = useSelector(selectCpqError);

  useExpertState();

  useLanguageChange();

  const onCPQErrorClear = () => {
    dispatch(setClearErrors({}));
  };

  useUnitsAndAttributePositions();

  return (
    <Box className="analyzerContainer">
      <SettingsDialog />
      <Toast
        open={cpqCommunicationError?.open}
        handleClose={onCPQErrorClear}
        status={cpqCommunicationError?.status}
        message={cpqCommunicationError?.message && Localize.get(cpqCommunicationError?.message)}
      />

      {
        {
          [STEP_VALUES.generalAttributes.value]: <GeneralAttributes />,
          [STEP_VALUES.fluidAttributes.value]: <FluidAttributes />,
          [STEP_VALUES.operationConditions.value]: <OperationConditions />,
          [STEP_VALUES.searchCriteria.value]: <SearchMethod />,
          [STEP_VALUES.result.value]: <ResultList />
        }[activeStep.value]
      }
    </Box>
  );
};

export default Analyzer;
