import React from 'react';
import Localize from 'react-intl-universal';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { setTwoDecimals } from '@pages/Analyzer/hooks/utils';

const checkIsSelected = (selectedDutyPoints, index, columnsLength) => {
  return selectedDutyPoints?.length > 0
    ? selectedDutyPoints.includes(index - 2)
      ? true && Boolean(columnsLength - 1)
      : false
    : selectedDutyPoints + 2 === index
    ? true && Boolean(columnsLength - 1)
    : false;
};

const DataTable = ({ graphs = [], unitMetrics, selectedDutyPoints, handleMouseClick }) => {
  const rows = [
    [
      Localize.get('capacity').defaultMessage('capacity'),
      unitMetrics?.capacity?.value?.label ?? ''
    ],
    [
      Localize.get('viscosity').defaultMessage('viscosity'),
      unitMetrics?.dynamicViscosity?.value?.label ?? ''
    ],
    [
      Localize.get('temperature').defaultMessage('temperature'),
      unitMetrics?.temperature?.value?.label ?? ''
    ],
    [
      Localize.get('rotationSpeed').defaultMessage('rotationSpeed'),
      unitMetrics?.rotationalSpeed?.value?.label ?? ''
    ],
    [
      Localize.get('slidingVelocity').defaultMessage('slidingVelocity'),
      unitMetrics?.slidingSpeed?.value?.label ?? ''
    ],
    [
      Localize.get('differentialPressure').defaultMessage('differentialPressure'),
      unitMetrics?.pressure?.value?.label ?? ''
    ],
    [Localize.get('npshr').defaultMessage('npshr'), unitMetrics?.npshr?.value?.label ?? ''],
    [
      Localize.get('powerRequirement').defaultMessage('powerRequirement'),
      unitMetrics?.power?.value?.label ?? ''
    ],
    [
      Localize.get('operatingTorque').defaultMessage('operatingTorque'),
      unitMetrics?.torque?.value?.label ?? ''
    ],
    [
      Localize.get('startingTorque').defaultMessage('startingTorque'),
      unitMetrics?.torque?.value?.label ?? ''
    ],
    [Localize.get('volumetricEfficiency').defaultMessage('volumetricEfficiency'), '%'],
    [Localize.get('hydraulicEfficiency').defaultMessage('hydraulicEfficiency'), '%'],
    [Localize.get('overallEfficiency').defaultMessage('overallEfficiency'), '%'],
    [
      Localize.get('maxAxialForce').defaultMessage('maxAxialForce'),
      unitMetrics?.force?.value?.label ?? ''
    ]
  ];

  if (graphs?.length > 1) {
    rows.push(['', '']);
  }

  const header = [' ', Localize.get('units').defaultMessage('units')];
  graphs.map(({ dutyPointAndOther }) => {
    header.push(dutyPointAndOther.dutyPoint);
    rows[0].push(
      dutyPointAndOther?.capacity === undefined ? '' : setTwoDecimals(dutyPointAndOther.capacity)
    );
    rows[1].push(
      dutyPointAndOther?.viscosity === undefined ? '' : setTwoDecimals(dutyPointAndOther.viscosity)
    );
    rows[2].push(
      dutyPointAndOther?.temperature === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.temperature)
    );
    rows[3].push(dutyPointAndOther?.speed === undefined ? '' : Math.round(dutyPointAndOther.speed));
    rows[4].push(
      dutyPointAndOther?.slidingVelocity === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.slidingVelocity)
    );
    rows[5].push(
      dutyPointAndOther?.differentialPressure === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.differentialPressure)
    );
    rows[6].push(dutyPointAndOther?.npshr === undefined ? '' : dutyPointAndOther.npshr);
    rows[7].push(
      dutyPointAndOther?.power === undefined ? '' : setTwoDecimals(dutyPointAndOther.power)
    );
    rows[8].push(
      dutyPointAndOther?.torque === undefined ? '' : setTwoDecimals(dutyPointAndOther.torque)
    );
    rows[9].push(
      dutyPointAndOther?.startingTorque === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.startingTorque)
    );
    rows[10].push(
      dutyPointAndOther?.volumetricEfficiency === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.volumetricEfficiency * 100)
    );
    rows[11].push(
      dutyPointAndOther?.hydraulicEfficiency === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.hydraulicEfficiency * 100)
    );
    rows[12].push(
      dutyPointAndOther?.overallEfficiency === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.overallEfficiency * 100)
    );
    rows[13].push(
      dutyPointAndOther?.maxAxialForce === undefined
        ? ''
        : setTwoDecimals(dutyPointAndOther.maxAxialForce)
    );
    if (graphs?.length > 1) {
      rows[14].push('');
    }
  });

  return (
    <TableContainer component={Paper}>
      {header.at(2) !== undefined ? (
        <Table className="resultDataTable" size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((row, index) => {
                const isSelected = checkIsSelected(selectedDutyPoints, index, graphs?.length);
                return (
                  <TableCell
                    className={isSelected ? 'dpHeaderSelected' : 'dpHeader'}
                    key={index}
                    align="center"
                  >
                    <b>{row}</b>
                    {isSelected ? <CheckCircleIcon className="dpSelectedIcon" /> : <div />}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  {row.map((col, colIndex) => {
                    const isSelected = checkIsSelected(
                      selectedDutyPoints,
                      colIndex,
                      graphs?.length
                    );
                    return (
                      <TableCell
                        className={`${
                          isSelected
                            ? rowIndex === 14
                              ? 'selectedDpBottom'
                              : 'selectedDp'
                            : 'dpUnselectedCell'
                        }`}
                        key={colIndex}
                        align="center"
                      >
                        {rowIndex === 14 && colIndex > 1 ? (
                          <Button
                            className="resultDataTableButton"
                            onClick={() => {
                              handleMouseClick(colIndex);
                            }}
                            variant="contained"
                          >
                            {isSelected
                              ? Localize.get('unselect').defaultMessage('unselect')
                              : Localize.get('select').defaultMessage('select')}
                          </Button>
                        ) : colIndex === 0 || colIndex === 1 ? (
                          col
                        ) : col === '' ? (
                          ''
                        ) : (
                          setTwoDecimals(col)
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div />
      )}
    </TableContainer>
  );
};
export default DataTable;
