import * as React from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';

const DataGridList = ({
  rows = [],
  columns = [],
  selectionModel = undefined,
  onSelectionModelChange,
  ...rest
}) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      {...rest}
    />
  );
};
export default DataGridList;
