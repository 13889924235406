import React from 'react';

import { Box } from '@mui/system';

import DataGridList from '@components/DataGridList';
import useGeneralAttributesPanel from '@pages/Admin/hooks/useGeneralAttributesPanel';

const columns = [
  {
    field: 'key',
    headerName: 'key',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'name',
    headerName: 'name',
    width: 170,
    align: 'left',
    editable: false
  }
];

const GeneralAttributePanel = () => {
  const [attributesList] = useGeneralAttributesPanel();
  return (
    <Box sx={{ height: '455px', width: '1130px' }}>
      <DataGridList
        density='compact'
        rows={attributesList}
        // getRowId={item => item.product_code}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        sx={{ fontSize: '12px' }}
        scrollable='scrollable'
        fixedSCroll='true'
      />
    </Box>
  );
};

export default GeneralAttributePanel;
