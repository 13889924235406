import React from 'react';

import moment from 'moment';

import VersionInfo from '@components/VersionInfo';
import VersionList from '@components/VersionList';
import versionList from '@pages/Admin/config/versionList.json';

import AdminLayout from '../Layout';

const TabPanelInfo = () => {
  return (
    <AdminLayout
      listPanel={<VersionList moment={moment} versionList={versionList} />}
      detailsPanel={
        <VersionInfo moment={moment} lastVersion={versionList[0]} />
      }
    />
  );
};

export default TabPanelInfo;
