import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';

import InputSelect from '@components/InputSelect';

const SessionDialog = ({ languageValue, languageOptions = [], onSessionLangChange }) => {
  return (
    <Box>
      <InputSelect
        label={Localize.get('language').defaultMessage('language')}
        value={languageValue}
        onChange={onSessionLangChange}
        accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
        options={languageOptions}
      />
    </Box>
  );
};

export default SessionDialog;
