import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { CPQ_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  cpqUrlParams: {},
  cpqProductData: {},
  parsedData: {},
  cpqMappingData: [],
  isLoading: false,
  cpqError: {}
};

export const fetchParamsForMapping = createAsyncThunk('cpq/fetchParamsForMapping', async () => {
  const response = await getByPathAndParams({
    path: CPQ_PATHS.GET_CPQ_MAPPING_PARAMETERS
  });
  if (response?.status !== 200) {
    throw { ...response.response.data };
  }
  return response.data;
});

export const fetchProductFromCpq = createAsyncThunk(
  'cpq/fetchProductFromCpq',
  async (productId) => {
    const response = await getByPathAndParams({
      path: CPQ_PATHS.GET_PRODUCT_CONFIGURATION,
      pathVariables: productId
    });
    if (response?.status !== 200) {
      throw { ...response.response.data };
    }
    return response.data;
  }
);

export const cpqSlice = createSlice({
  name: 'cpq',
  initialState,
  reducers: {
    setCpqUrlParams: (state, { payload }) => {
      state.cpqUrlParams = payload;
      state.isLoading = true;
    },
    setParsedValues: (state, { payload }) => {
      state.parsedData = payload;
    },
    setCpqData: (state, { payload }) => {
      state.cpqProductData = payload.cpqProductData;
      state.cpqMappingData = payload.cpqMappingData;
      state.isLoading = false;
    },
    setCpqIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setClearErrors: (state) => {
      state.error = {};
    },
    setCpqErrors: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    }
  }
});

export const selectCurrentStep = (state) => state.cpq.currentStep;
export const selectCpqProductData = (state) => state.cpq.cpqProductData;
export const selectCpqData = (state) => state.cpq.cpqUrlParams;
export const selectCpqMappingData = (state) => state.cpq.cpqMappingData;
export const selectCpqIsLoading = (state) => state.cpq.isLoading;
export const selectParsedData = (state) => state.cpq.parsedData;
export const selectCpqError = (state) => state.cpq.error;

const { reducer, actions } = cpqSlice;

export const {
  setCpqUrlParams,
  setCpqData,
  setCpqIsLoading,
  setCpqErrors,
  setClearErrors,
  setParsedValues
} = actions;

export default reducer;
