import React from 'react';

import Box from '@mui/material/Box';

const AdminLayout = ({ listPanel, detailsPanel }) => {
  return (
    <Box className='adminPanelLayout'>
      {listPanel}
      {detailsPanel}
    </Box>
  );
};

export default AdminLayout;
