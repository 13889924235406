import React from 'react';

import Button from '@mui/material/Button';

const DownloadButton = ({
  children,
  onClick = () => {},
  content = '',
  id = 'download-button-file',
  sx = { mr: 1 },
  ...rest
}) => {
  return (
    <label htmlFor={id}>
      <Button
        variant='contained'
        color='secondary'
        component='span'
        onClick={onClick}
        sx={sx}
        {...rest}
      >
        {children || content}
      </Button>
    </label>
  );
};

export default DownloadButton;
