import { useState, useEffect } from 'react';

import { ADMIN, IMAGES_PATHS } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  LINK_IMAGE_TOAST_SUCCESS_STATE,
  LINK_IMAGE_ERROR_STATE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

const useStatorPicturePanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfStatorPictureMapping, setListOfStatorPictureMapping] = useState(
    []
  );
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [linkMode, setLinkMode] = useState(undefined);

  const setSelectionModel = model => {
    setSelectedItem(
      listOfStatorPictureMapping.find(
        ({ StatorType, Geometry, GeometryType }) =>
          `${StatorType}-${Geometry}-${GeometryType}` === model[0]
      )
    );
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const onLinkSaveClick = async (
    StatorType,
    Geometry,
    GeometryType,
    ImageId
  ) => {
    try {
      const { data } = await postByPathAndData({
        path: IMAGES_PATHS.LINK_STATOR_IMAGE,
        data: {
          StatorType,
          Geometry,
          GeometryType,
          ImageId
        }
      });
      if (data) {
        setSelectedItem(data);
        setListOfStatorPictureMapping(prevState =>
          prevState.map(item => {
            if (
              item.StatorType === data.StatorType &&
              item.Geometry === data.Geometry &&
              item.GeometryType === data.GeometryType
            ) {
              item.ImageId = data.ImageId;
            }
            return item;
          })
        );
        setUploadToastState(LINK_IMAGE_TOAST_SUCCESS_STATE);
      } else {
        setUploadToastState(LINK_IMAGE_ERROR_STATE);
      }

      setLinkMode(undefined);
    } catch (err) {
      setUploadToastState(LINK_IMAGE_ERROR_STATE);
    }
  };

  const fetchStatorPictureMapping = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_STATOR_PICTURE_MAPPING
      });
      setListOfStatorPictureMapping(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatorPictureMapping();
  }, []);

  return [
    listOfStatorPictureMapping,
    isLoading,
    uploadToastState,
    setUploadToastToInitial,
    selectedItem,
    setSelectionModel,
    setLinkMode,
    linkMode,
    onLinkSaveClick
  ];
};

export default useStatorPicturePanel;
