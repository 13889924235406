import { useState } from 'react';

import TabContext from '@mui/lab/TabContext';

import MainTabHeader from './components/MainTabHeader/MainTabHeader';
import MainTabPanel from './components/MainTabPanel/MainTabPanel';

const mainAdminTabOptions = {
  optionOne: '1',
  optionTwo: '2',
  optionThree: '3',
  optionFour: '4',
  optionFive: '5',
  optionSix: '6'
};

const Admin = () => {
  const [selectedTab, setSelectedTab] = useState(mainAdminTabOptions.optionOne);
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <MainTabHeader
        mainAdminTabOptions={mainAdminTabOptions}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      <MainTabPanel mainAdminTabOptions={mainAdminTabOptions} />
    </TabContext>
  );
};

export default Admin;
