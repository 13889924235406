import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { isEqual } from 'lodash';

import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { UNIT_SYSTEM_NAMES, UNIT_SYSTEMS } from '@common/constants';
import { copyObjectWithoutRef, getMetricSystemValues, findById } from '@common/helpers/helpers';
import {
  setSessionUnitMetrics,
  selectSessionUnitMetrics,
  selectSessionUnitSystemName,
  setSessionUnitSystemName
} from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { setShowSettingsDialog } from '@common/slices/userSlice/userSlice';
import InputSelect from '@components/InputSelect';

const UnitsSettingsDialog = () => {
  const dispatch = useDispatch();
  const sessionUnitMetrics = useSelector(selectSessionUnitMetrics);
  const sessionUnitSystemName = useSelector(selectSessionUnitSystemName);

  const [dialogUnitsMetrics, setDialogUnitsMetrics] = useState(sessionUnitMetrics);
  const [dialogUnitSystemName, setDialogUnitSystemName] = useState(sessionUnitSystemName);

  const closePersonalSettingsDialog = () => {
    dispatch(setShowSettingsDialog(undefined));
  };

  const onDialogOkClick = () => {
    dispatch(setSessionUnitMetrics(dialogUnitsMetrics));
    dispatch(setSessionUnitSystemName(dialogUnitSystemName));
    dispatch(setShowSettingsDialog(undefined));
  };

  const handleChangeUnit = (fieldName, event) => {
    const myState = copyObjectWithoutRef(dialogUnitsMetrics);
    myState[fieldName] = {
      ...myState[fieldName],
      value: findById(myState[fieldName].options, event.target.value)
    };

    let currentMetricValues = Object.keys(myState).reduce((currentObj, currentUnitGroup) => {
      currentObj[currentUnitGroup] = myState[currentUnitGroup]?.value?.id;
      return currentObj;
    }, {});

    if (isEqual(currentMetricValues, UNIT_SYSTEMS.SI)) {
      setDialogUnitSystemName(UNIT_SYSTEM_NAMES.SI);
    } else if (isEqual(currentMetricValues, UNIT_SYSTEMS.US)) {
      setDialogUnitSystemName(UNIT_SYSTEM_NAMES.US);
    } else {
      setDialogUnitSystemName(UNIT_SYSTEM_NAMES.CUSTOM);
    }

    setDialogUnitsMetrics(myState);
  };

  const onChangeMetricSystemName = (event) => {
    const currentMetricSystemValues = getMetricSystemValues(event.target.value);
    const myState = copyObjectWithoutRef(dialogUnitsMetrics);
    const newState = Object.keys(myState).reduce((currentObj, currentUnit) => {
      currentObj[currentUnit] = {
        ...myState[currentUnit],
        value: findById(myState[currentUnit]?.options, currentMetricSystemValues[currentUnit])
      };
      return currentObj;
    }, {});

    setDialogUnitSystemName(event.target.value);
    setDialogUnitsMetrics(newState);
  };

  return (
    <>
      <DialogTitle>
        <Typography>{Localize.get('unitsDialog').defaultMessage('unitsDialog')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <InputSelect
            selectContainerProps={{
              className: 'unitInputContainer'
            }}
            label={Localize.get('unitSystem').defaultMessage('unitSystem')}
            value={dialogUnitSystemName}
            onChange={onChangeMetricSystemName}
            accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
            options={[
              { label: 'SI', value: UNIT_SYSTEM_NAMES.SI },
              { label: 'US', value: UNIT_SYSTEM_NAMES.US },
              {
                label: Localize.get('custom').defaultMessage('custom'),
                value: UNIT_SYSTEM_NAMES.CUSTOM,
                disabled: true
              }
            ]}
          />

          <Box className="unitDialogInputsContainer">
            {Object.keys(dialogUnitsMetrics).map((keyName, index) => {
              return (
                <InputSelect
                  selectContainerProps={{
                    className: 'unitInputContainer'
                  }}
                  key={index}
                  options={dialogUnitsMetrics[keyName]?.options ?? []}
                  label={dialogUnitsMetrics[keyName]?.name ?? ''}
                  value={dialogUnitsMetrics[keyName]?.value?.id ?? ''}
                  accessors={{ valueAccessor: 'id', textAccessor: 'label' }}
                  onChange={(event) => handleChangeUnit(keyName, event)}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6} align="left">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                closePersonalSettingsDialog();
              }}
            >
              {Localize.get('cancel').defaultMessage('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                onDialogOkClick(dialogUnitsMetrics);
              }}
            >
              {Localize.get('ok').defaultMessage('ok')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default UnitsSettingsDialog;
