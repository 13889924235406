import { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import { TextField, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { selectLanguages } from '@common/slices/userSlice/userSlice';
import InputSelect from '@components/InputSelect';

const CustomizedBox = styled(Box)(() => ({
  margin: '0 auto',
  display: 'grid',
  gridGap: '5px',
  overflowY: 'hidden',
  overflowX: 'hidden',
  alignItems: 'start',

  gridTemplateColumns: 'repeat(2, auto)',
  gridTemplateRows: 'repeat(2, 75px)',
  '@media screen and (min-width:700px)': {
    gridTemplateColumns: 'repeat(2, auto)',
    gridTemplateRows: 'repeat(2, 75px)'
  }
}));

const CustomizedTextField = styled(TextField)(() => ({
  width: '100%',
  '.MuiOutlinedInput-root': {
    borderRadius: '10px'
  },
  '.MuiOutlinedInput-input': {
    padding: '10px 15px',
    fontSize: '15px'
  }
}));

const UserDetails = ({ selectedUser, changeUserSettings }) => {
  const [editMode, setEditMode] = useState(false);
  const [newUserSettings, setNewUserSettings] = useState({});
  const allLanguages = useSelector(selectLanguages);

  useEffect(() => {
    if (selectedUser) {
      setNewUserSettings(() => {
        return {
          userId: selectedUser.id,
          defaultLanguage: selectedUser.defaultLanguageId,
          plant: selectedUser.plant,
          unitSystem: selectedUser.unitSystem,
          isAdmin: selectedUser.isAdmin,
          expertModeAllowed: selectedUser.expertModeAllowed,
          status: selectedUser.status
        };
      });
    }
  }, [selectedUser]);

  const handleCheckBox = (fieldName, event) => {
    if (fieldName === 'status') {
      if (event.target.checked) {
        setNewUserSettings((prevState) => {
          return {
            ...prevState,
            [fieldName]: 'inactive'
          };
        });
      } else {
        setNewUserSettings((prevState) => {
          return {
            ...prevState,
            [fieldName]: 'active'
          };
        });
      }
    } else {
      setNewUserSettings((prevState) => {
        return {
          ...prevState,
          [fieldName]: event.target.checked
        };
      });
    }
  };

  const handleInputSelect = (fieldName, event) => {
    setNewUserSettings((prevState) => {
      return {
        ...prevState,
        [fieldName]: event.target.value
      };
    });
  };

  const handleCancel = () => {
    setEditMode(false);
    setNewUserSettings((prevState) => {
      return {
        ...prevState,
        defaultLanguage: selectedUser.defaultLanguageId,
        plant: selectedUser.plant,
        unitSystem: selectedUser.unitSystem,
        isAdmin: selectedUser.isAdmin,
        expertModeAllowed: selectedUser.expertModeAllowed,
        status: selectedUser.status
      };
    });
  };

  const handleSave = () => {
    changeUserSettings(newUserSettings);
    setEditMode(false);
  };

  return (
    <Box className="adminDetailsContainer">
      <Typography className="adminUserDetailsTitle">
        <b>{Localize.get('userDetails').defaultMessage('userDetails')}</b>
      </Typography>
      <CustomizedBox>
        <Box>
          <Typography
            sx={{
              pl: 1,
              fontWeight: '600',
              fontSize: '0.75em',
              textAlign: 'left',
              color: (theme) => theme.palette.text.disabled
            }}
          >
            {Localize.get('firstName').defaultMessage('firstName')}
          </Typography>
          <CustomizedTextField
            value={selectedUser?.firstName || ''}
            InputProps={{ readOnly: true }}
            disabled={true}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              pl: 1,
              fontWeight: '600',
              fontSize: '0.75em',
              textAlign: 'left',
              color: (theme) => theme.palette.text.disabled
            }}
          >
            {Localize.get('lastName').defaultMessage('lastName')}
          </Typography>
          <CustomizedTextField
            value={selectedUser?.lastName || ''}
            InputProps={{ readOnly: true }}
            disabled={true}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              pl: 1,
              fontWeight: '600',
              fontSize: '0.75em',
              textAlign: 'left',
              color: (theme) => theme.palette.text.disabled
            }}
          >
            {Localize.get('userName').defaultMessage('userName')}
          </Typography>
          <CustomizedTextField
            value={selectedUser?.email || ''}
            InputProps={{ readOnly: true }}
            disabled={true}
          />
        </Box>
      </CustomizedBox>
      <Typography className="adminUserDetailsTitle">
        <b>{Localize.get('status').defaultMessage('status')}</b>
      </Typography>
      <FormGroup
        sx={{
          textAlign: 'left'
        }}
      >
        <FormControlLabel
          label={Localize.get('locked').defaultMessage('locked')}
          value="locked"
          control={
            <Checkbox
              checked={newUserSettings?.status === 'active' ? false : true}
              disabled={!editMode}
              onChange={(event) => handleCheckBox('status', event)}
            />
          }
          sx={{ ml: 0, width: 10 }}
        />
      </FormGroup>
      <Typography className="adminUserDetailsTitle">
        <b>{Localize.get('astSettings').defaultMessage('astSettings')}</b>
      </Typography>

      <InputSelect
        label={Localize.get('defaultLanguage').defaultMessage('defaultLanguage')}
        value={newUserSettings?.defaultLanguage ? newUserSettings?.defaultLanguage : ''}
        onChange={(event) => handleInputSelect('defaultLanguage', event)}
        accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
        options={allLanguages}
        disabled={!editMode}
        selectContainerProps={{
          className: 'unitInputContainer'
        }}
      />
      <InputSelect
        label={Localize.get('plant').defaultMessage('plant')}
        value={newUserSettings?.plant ? newUserSettings?.plant : ''}
        onChange={(event) => handleInputSelect('plant', event)}
        accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
        options={[
          { label: 'NdB', value: 'NdB' },
          { label: 'NPS', value: 'NPS' },
          { label: 'NLP', value: 'NLP' },
          { label: 'NTI', value: 'NTI' },
          { label: 'NPA', value: 'NPA' }
        ]}
        disabled={!editMode}
        selectContainerProps={{
          className: 'unitInputContainer'
        }}
      />
      <InputSelect
        label={Localize.get('unitSystem').defaultMessage('unitSystem')}
        value={newUserSettings?.unitSystem ? newUserSettings?.unitSystem : ''}
        onChange={(event) => handleInputSelect('unitSystem', event)}
        accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
        options={[
          { label: 'SI', value: 'si' },
          { label: 'US', value: 'us' },
          {
            label: Localize.get('custom').defaultMessage('custom'),
            value: 'CU',
            disabled: true
          }
        ]}
        disabled={!editMode}
        selectContainerProps={{
          className: 'unitInputContainer'
        }}
      />

      <Typography className="adminUserDetailsTitle">
        <b>{Localize.get('permission').defaultMessage('permission')}</b>
      </Typography>
      <CustomizedBox>
        <FormGroup
          sx={{
            textAlign: 'left',
            marginBottom: 1
          }}
        >
          <FormControlLabel
            label={Localize.get('admin').defaultMessage('admin')}
            value="Admin"
            control={
              <Checkbox
                checked={Boolean(newUserSettings?.isAdmin)}
                disabled={!editMode}
                onChange={(event) => handleCheckBox('isAdmin', event)}
              />
            }
            sx={{ ml: 0, mt: 1, width: 10 }}
          />
        </FormGroup>
        <FormGroup
          sx={{
            textAlign: 'left',
            marginBottom: 1
          }}
        >
          <FormControlLabel
            label={Localize.get('expertModeAllowed').defaultMessage('expertModeAllowed')}
            value="ExpertModeAllowed"
            control={
              <Checkbox
                checked={Boolean(newUserSettings?.expertModeAllowed)}
                disabled={!editMode}
                onChange={(event) => handleCheckBox('expertModeAllowed', event)}
              />
            }
            sx={{ ml: 20, mt: 1, width: 200 }}
          />
        </FormGroup>
      </CustomizedBox>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          height: '48px',
          marginTop: '-55px'
        }}
      >
        <Box sx={{ flex: '1 1 auto' }} />
        {!editMode && (
          <Button
            color="secondary"
            variant="contained"
            disabled={Object.keys(selectedUser).length === 0}
            onClick={() => setEditMode(true)}
          >
            {Localize.get('edit').defaultMessage('edit')}
          </Button>
        )}
        {editMode && (
          <Button color="secondary" variant="contained" onClick={() => handleSave()} sx={{ mr: 1 }}>
            {Localize.get('save').defaultMessage('save')}
          </Button>
        )}
        {editMode && (
          <Button color="secondary" variant="contained" onClick={() => handleCancel()}>
            {Localize.get('cancel').defaultMessage('cancel')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default UserDetails;
