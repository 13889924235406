export default [
  {
    field: 'Model',
    headerName: 'Model',
    width: 120,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorMaterial',
    headerName: 'StatorMaterial',
    width: 150,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorType',
    headerName: 'StatorType',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Pressure',
    headerName: 'Pressure',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
