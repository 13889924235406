const API = {
  API_URL: process.env.REACT_APP_API_URL
};

const MY_NETZSCH_API = {
  API_URL: process.env.REACT_APP_MY_NETZSCH_API_URL
};

const NETZSCH_API = {
  API_URL: process.env.REACT_APP_NETZSCH_API_URL
};

const LOGIN_LOGOUT_API = {
  API_URL: process.env.REACT_APP_LOGIN_LOGOUT_REDIRECT_API
};

const CPQ_API = {
  BASE_URL: process.env.REACT_APP_CPQ_BASE_URL,
  REDIRECTION_URL: '/integration/external-configurator/external/landing',
  REDIRECTION_URL_NEW: `//:Host_Url/integration/external-configurator/external/landing?quoteNumber=:quoteNumber&configuration_flag=:configurationFlag&CpqTableEntryId=:CpqTableEntryId&CPQ_41PERMISSION02=:cpqPermission`
};

const NETWORK_STATUSES = {
  BAD_REQUEST: 400,
  PAGE_NOT_FOUND: 404,
  NOT_AUTHORIZED: 401
};

const ERROR_MESSAGES = {
  PARAMETERS_NOT_FOUND: {
    STATUS: 500,
    MESSAGE: 'Product parameters received from CPQ not found in mapping table:'
  },
  PARAM_MISSING: {
    STATUS: 501,
    MESSAGE: '{0} parameter received from CPQ not found in mapping table'
  },
  MAPPING_AST_TO_CPQ: {
    STATUS: 503,
    MESSAGE: 'Error occur during mapping parameters from AST to CPQ.'
  },
  STATE_MISSING: {
    STATUS: 504,
    MESSAGE: 'State not found for parameter - {0}'
  },
  CHAR_IS_LONGER: {
    STATUS: 505,
    MESSAGE: 'Reduce number of characters, CHAR, AstCharacteristic: {0} - {1}, value: {2}.'
  },
  REDUCE_VALUE_ISNAN: {
    STATUS: 506,
    MESSAGE: 'Reduce decimal places - isNaN, NUM, AstCharacteristic: {0} - {1}.'
  },
  DIGIT_IS_LONGER: {
    STATUS: 507,
    MESSAGE: 'Reduce number of CpqDigits, NUM, AstCharacteristic: {0} - {1}, value: {2}.'
  },
  REDUCE_VALUE_DEFAULT: {
    STATUS: 508,
    MESSAGE: 'Reduce decimal places, DEFAULT, AstCharacteristic:'
  }
};

const ROUTER_PATHS = Object.freeze({
  home: '/',
  other: '*',
  analyzer: '/ast',
  admin: '/admin',
  saml: '/saml/token',
  samlTokenCheck: '/saml/token/check',
  samlSSO: '/saml/sso',
  samlLogout: '/saml/logout',
  secret: '/secret',
  logoutRedirect: '/logout-redirection',
  approval: '/pending-approval'
});

const GRAPH_KIND = {
  CAPACITY: 1,
  POWER_AND_TORQUE: 2,
  NPSHr: 3
};

const SEARCH_IMAGES_COLUMNS = [
  {
    field: 'id',
    headerName: 'Id',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 470,
    align: 'left',
    editable: false
  }
];

const BIG_SCREEN_BREAK_POINT = '(min-width:1024px)';

const SEARCH_METHOD = {
  MULTI_SELECT: 1,
  OTHER_CRITERIA: 2,
  NO_RESTRICTION: 3,
  MANUAL_CONFIG: 4
};

const GRAPH_TYPE = {
  DUTY_POINTS_WITH_SAME_VISCOSITY_AND_TEMPERATURE: 1,
  DUTY_POINTS_WITH_DIFF_VISCOSITY_AND_TEMPERATURE: 2
};

const DEFAULT_VALUES = {
  ZERO_VALUE: 0,
  ONE_VALUE: 1,
  DP_INPUT_VARIATION: {
    SPEED: 'speed',
    CAPACITY: 'capacity'
  },
  THEME: {
    LIGHT: 'light',
    DARK: 'dark'
  },
  NUMBERS: {
    THIRTY: 30,
    FIVE: 5,
    FIFTEEN: 15,
    FIVE_HUNDRED: 500,
    ZERO: 0
  },
  DISPLAY: {
    BLOCK: 'block',
    NONE: 'none'
  },
  CHARS: {
    SLASH: '/',
    PLUS: '+ ',
    MINUS: '-',
    DOT: '.'
  },
  POP_OVER_ID: 'simple-popover',
  AUTH: {
    BEARER: 'Bearer'
  },
  COLOR: {
    NONE: 'none'
  }
};

const UNIT_SYSTEM_NAMES = Object.freeze({
  SI: 'SI',
  US: 'US',
  CUSTOM: 'CU'
});

const SETTINGS_DIALOG = {
  PERSONAL: 'userSettings',
  SESSION: 'sessionSettings'
};

const UNIT_SYSTEMS = Object.freeze({
  SI: {
    pressure: 2, // 'bar'
    capacity: 10, // 'm3_h'
    density: 18, // 'kg_dm3'
    dynamicViscosity: 13, // 'mPa_s'
    force: 36, // 'N'
    frequency: 37, // 'Hz'
    npshr: 38, // 'm'
    particleSize: 20, // 'mm'
    power: 40, // 'kW'
    rotationalSpeed: 44, // '1_min'
    slidingSpeed: 45, // 'm_s'
    solidsContent: 17, // 'ProzentTS'
    temperature: 14, // 'GradCelsius'
    time: 23, // 'h'
    torque: 48, // 'Nm'
    concentration: 22 // 'ppm'
  },
  US: {
    pressure: 4, // 'PSI'
    capacity: 11, // 'gal_min_US'
    density: 32, // 'oz_gal_US'
    dynamicViscosity: 13, // 'mPa_s'
    force: 35, // 'lbf'
    frequency: 37, // 'Hz'
    npshr: 39, // 'ft'
    particleSize: 19, // 'in'
    power: 41, // 'hp'
    rotationalSpeed: 44, // '1_min'
    slidingSpeed: 46, // 'ft_s'
    solidsContent: 17, // 'ProzentTS'
    temperature: 15, // 'GradFahrenheit'
    time: 23, // 'h'
    torque: 47, // 'lbft'
    concentration: 22 // 'ppm'
  }
});

const APP_LANGUAGES = Object.freeze({
  EN: 1,
  DE: 2
});

export {
  API,
  MY_NETZSCH_API,
  NETZSCH_API,
  CPQ_API,
  ROUTER_PATHS,
  LOGIN_LOGOUT_API,
  NETWORK_STATUSES,
  GRAPH_KIND,
  ERROR_MESSAGES,
  SEARCH_IMAGES_COLUMNS,
  BIG_SCREEN_BREAK_POINT,
  SEARCH_METHOD,
  DEFAULT_VALUES,
  UNIT_SYSTEMS,
  UNIT_SYSTEM_NAMES,
  APP_LANGUAGES,
  SETTINGS_DIALOG,
  GRAPH_TYPE
};
