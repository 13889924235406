import React from 'react';

import { TabPanel } from '@mui/lab';

import CPQConfig from '../CPQConfig/CPQConfig';
import PicturesPanel from '../PicturesPanel/PicturesPanel';
import TabPanelData from '../TabPanelData/TabPanelData';
import TabPanelInfo from '../TabPanelInfo/TabPanelInfo';
import TabPanelTranslations from '../TabPanelTranslations/TabPanelTranslations';
import TabPanelUsers from '../TabPanelUsers/TabPanelUsers';

const sxProps = {
  p: 0,
  py: 1
};

const MainTabPanel = ({ mainAdminTabOptions }) => {
  return (
    <>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionOne}>
        <TabPanelUsers />
      </TabPanel>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionTwo}>
        <TabPanelData />
      </TabPanel>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionThree}>
        <TabPanelTranslations />
      </TabPanel>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionFour}>
        <PicturesPanel />
      </TabPanel>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionFive}>
        <CPQConfig />
      </TabPanel>
      <TabPanel sx={sxProps} value={mainAdminTabOptions.optionSix}>
        <TabPanelInfo />
      </TabPanel>
    </>
  );
};

export default MainTabPanel;
