import * as React from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { isString, isObject } from '@common/helpers/helpers';
import HintIcon from '@components/HintIcon';

const InputSelect = ({
  label,
  options = [],
  hint = undefined,
  onHintClick = () => {},
  accessors: { valueAccessor = 'id', textAccessor = 'value' } = {},
  selectContainerProps,
  sxLabelWrapper,
  helperText,
  ...rest
}) => {
  const matches = useMediaQuery('(min-width:1150px)');

  const getValue = (option, propName) => {
    if (isString(option)) {
      return option;
    }
    if (isObject(option)) {
      if (propName === textAccessor) return option[textAccessor] ?? '';
      if (propName === valueAccessor) return option[valueAccessor] ?? '';
    }
    return '';
  };

  return (
    <Box className="selectContainer" {...selectContainerProps}>
      <Box className="labelContainer" sx={sxLabelWrapper}>
        <Typography className="selectLabel">{label}</Typography>
        {Boolean(hint) && !matches && (
          <HintIcon color="primary" onClick={(event) => onHintClick(event, hint)} />
        )}
      </Box>

      <Box className="selectWrapper">
        <Select size="medium" className="selectInput" {...rest}>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              className="selectInputItem"
              disabled={option?.disabled}
              value={getValue(option, valueAccessor)}
            >
              {getValue(option, textAccessor)}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText className="helperTextSelect">{helperText}</FormHelperText>}
      </Box>

      {Boolean(hint) && matches && (
        <Box className="hintContainer">
          <HintIcon color="primary" className="hintIcon" />
          <Typography className="hintValueSide">{hint}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default InputSelect;
