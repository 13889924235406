import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const HintIcon = styled(InfoIcon)({
  float: 'left',
  marginLeft: '10px',
  width: '17px',
  height: '17px',
  minHeight: '17px',
  '&:hover': {
    cursor: 'pointer'
  }
});

export default HintIcon;
