export default [
  {
    field: 'ProductCode',
    headerName: 'ProductCode',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'InternalDescription',
    headerName: 'InternalDescription',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'Site',
    headerName: 'Site',
    width: 70,
    align: 'left',
    editable: false
  },
  {
    field: 'Level',
    headerName: 'Level',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ParentProductCode',
    headerName: 'ParentProductCode',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'ImageId',
    headerName: 'ImageId',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'TitleTranslationId',
    headerName: 'TitleTranslationId',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'DescriptionTranslationId',
    headerName: 'DescriptionTranslationId',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
