export default [
  {
    field: 'ProductCode',
    headerName: 'ProductCode',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'FlowrateMin',
    headerName: 'FlowrateMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FlowrateMax',
    headerName: 'FlowrateMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureMax',
    headerName: 'PressureMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityMin',
    headerName: 'ViscosityMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityMax',
    headerName: 'ViscosityMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FluidTemperatureMin',
    headerName: 'FluidTemperatureMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FluidTemperatureMax',
    headerName: 'FluidTemperatureMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'DryOrganicSolidsContentMin',
    headerName: 'DryOrganicSolidsContentMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'DryOrganicSolidsContentMax',
    headerName: 'DryOrganicSolidsContentMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpecificGravityMin',
    headerName: 'SpecificGravityMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpecificGravityMax',
    headerName: 'SpecificGravityMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Abrasiveness1',
    headerName: 'Abrasiveness1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Abrasiveness2',
    headerName: 'Abrasiveness2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Abrasiveness3',
    headerName: 'Abrasiveness3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Abrasiveness4',
    headerName: 'Abrasiveness4',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Abrasiveness5',
    headerName: 'Abrasiveness5',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SandContent1',
    headerName: 'SandContent1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SandContent2',
    headerName: 'SandContent2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SandContent3',
    headerName: 'SandContent3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ParticleContent1',
    headerName: 'ParticleContent1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ParticleContent2',
    headerName: 'ParticleContent2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SolidsSize1Max',
    headerName: 'SolidsSize1Max',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SolidsSize2Max',
    headerName: 'SolidsSize2Max',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureMin',
    headerName: 'PressureMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'BuildsBridgesOrAgglomeratesNo',
    headerName: 'BuildsBridgesOrAgglomeratesNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'BuildsBridgesOrAgglomeratesYes',
    headerName: 'BuildsBridgesOrAgglomeratesYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Adhesion1',
    headerName: 'Adhesion1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Adhesion2',
    headerName: 'Adhesion2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Adhesion3',
    headerName: 'Adhesion3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FibreLengthMax',
    headerName: 'FibreLengthMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ShearSensitivity1',
    headerName: 'ShearSensitivity1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ShearSensitivity2',
    headerName: 'ShearSensitivity2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ShearSensitivity3',
    headerName: 'ShearSensitivity3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PHValueMin',
    headerName: 'PHValueMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PHValueMax',
    headerName: 'PHValueMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ChlorideContentMax',
    headerName: 'ChlorideContentMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'OilFatWaxContentNo',
    headerName: 'OilFatWaxContentNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'OilFatWaxContentYes',
    headerName: 'OilFatWaxContentYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LubricatingNo',
    headerName: 'LubricatingNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LubricatingYes',
    headerName: 'LubricatingYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EnvironmentalTemperatureMin',
    headerName: 'EnvironmentalTemperatureMin',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EnvironmentalTemperatureMax',
    headerName: 'EnvironmentalTemperatureMax',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ImmersedNo',
    headerName: 'ImmersedNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ImmersedYes',
    headerName: 'ImmersedYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FDANo',
    headerName: 'FDANo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FDAYes',
    headerName: 'FDAYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ThreeANo',
    headerName: 'ThreeANo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ThreeAYes',
    headerName: 'ThreeAYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EHEDGNo',
    headerName: 'EHEDGNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EHEDGYes',
    headerName: 'EHEDGYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EC19352004No',
    headerName: 'EC19352004No',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EC19352004Yes',
    headerName: 'EC19352004Yes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'GB4806No',
    headerName: 'GB4806No',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'GB4806Yes',
    headerName: 'GB4806Yes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'AnvisaNo',
    headerName: 'AnvisaNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'AnvisaYes',
    headerName: 'AnvisaYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FoodGradeNo',
    headerName: 'FoodGradeNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'FoodGradeYes',
    headerName: 'FoodGradeYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SmoothSurfaceNo',
    headerName: 'SmoothSurfaceNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SmoothSurfaceYes',
    headerName: 'SmoothSurfaceYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NitrosamineFree',
    headerName: 'NitrosamineFree',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'CleaningInPlace',
    headerName: 'CleaningInPlace',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SterilizationInPlace',
    headerName: 'SterilizationInPlace',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaNo',
    headerName: 'HazardousAreaNo',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaYes',
    headerName: 'HazardousAreaYes',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone0',
    headerName: 'HazardousAreaZone0',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone1',
    headerName: 'HazardousAreaZone1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone2',
    headerName: 'HazardousAreaZone2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone20',
    headerName: 'HazardousAreaZone20',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone21',
    headerName: 'HazardousAreaZone21',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaZone22',
    headerName: 'HazardousAreaZone22',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT1',
    headerName: 'HazardousAreaTemperatureT1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT2',
    headerName: 'HazardousAreaTemperatureT2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT3',
    headerName: 'HazardousAreaTemperatureT3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT4',
    headerName: 'HazardousAreaTemperatureT4',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT5',
    headerName: 'HazardousAreaTemperatureT5',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'HazardousAreaTemperatureT6',
    headerName: 'HazardousAreaTemperatureT6',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ExZoneCertification',
    headerName: 'ExZoneCertification',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LeakageProtection',
    headerName: 'LeakageProtection',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MachineRegulations1',
    headerName: 'MachineRegulations1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MachineRegulations2',
    headerName: 'MachineRegulations2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MachineRegulations3',
    headerName: 'MachineRegulations3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MachineRegulations4',
    headerName: 'MachineRegulations4',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
