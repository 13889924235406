import React from 'react';
import { useSelector, useDispatch } from 'react-redux';



import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography, Button, Box } from '@mui/material';

import { SEARCH_METHOD } from '@common/constants';
import {
  APP_LAYOUT_STEPS,
  STEP_VALUES,
  FIRST_SUB_STEP,
  ONE_STEP
} from '@common/slices/layoutSlice/config/layoutConfig';
import {
  selectCurrentStep,
  selectCurrentSubStep,
  setSteps
} from '@common/slices/layoutSlice/layoutSlice';
import { selectSearchType } from '@common/slices/searchMethodSlice/searchMethodSlice';
import useGetValueByKey from '@hooks/useGetValueByKey';

const getBackStep = (activeStep, activeSubStep, selectedSearchMethod) => {
  if (
    activeStep.value === STEP_VALUES.generalAttributes.value &&
    activeSubStep.value === FIRST_SUB_STEP
  )
    return undefined;

  if (activeSubStep.value === FIRST_SUB_STEP) {
    const backStep = APP_LAYOUT_STEPS.config[activeStep.value - ONE_STEP];
    if (
      activeStep.value === STEP_VALUES.result.value &&
      selectedSearchMethod === SEARCH_METHOD.NO_RESTRICTION
    ) {
      return {
        step: backStep,
        subStep: backStep.subSteps[FIRST_SUB_STEP]
      };
    } else if (
      activeStep.value === STEP_VALUES.result.value &&
      selectedSearchMethod !== SEARCH_METHOD.OTHER_CRITERIA
    ) {
      return {
        step: backStep,
        subStep: backStep.subSteps[backStep.subSteps.length - 2]
      };
    }
    return {
      step: backStep,
      subStep: backStep.subSteps[backStep.subSteps.length - ONE_STEP]
    };
  } else {
    return {
      step: activeStep,
      subStep: activeStep.subSteps[activeSubStep.value - ONE_STEP]
    };
  }
};

const getNextStep = (activeStep, activeSubStep, selectedSearchMethod) => {
  if (activeStep.value === STEP_VALUES.result.value) return undefined;

  if (
    activeStep.subSteps.length - ONE_STEP === activeSubStep.value ||
    (activeStep.subSteps.length - 2 === activeSubStep.value &&
      activeStep.value === STEP_VALUES.searchCriteria.value &&
      selectedSearchMethod !== SEARCH_METHOD.OTHER_CRITERIA)
  ) {
    const nextStep = APP_LAYOUT_STEPS.config[activeStep.value + ONE_STEP];
    return {
      step: nextStep,
      subStep: nextStep.subSteps[FIRST_SUB_STEP]
    };
  } else {
    return {
      step: activeStep,
      subStep: activeStep.subSteps[activeSubStep.value + ONE_STEP]
    };
  }
};

const BreadcrumbsNavigation = () => {
  const dispatch = useDispatch();
  const getValueByKey = useGetValueByKey();

  const activeStep = useSelector(selectCurrentStep);
  const activeSubStep = useSelector(selectCurrentSubStep);
  const selectedSearchMethod = useSelector(selectSearchType);

  const handleNext = () => {
    if (
      (activeSubStep.value + ONE_STEP < activeStep?.subSteps?.length &&
        activeStep.value !== STEP_VALUES.searchCriteria.value) ||
      (activeStep.value === STEP_VALUES.searchCriteria.value &&
        ((activeSubStep.value + ONE_STEP < activeStep?.subSteps?.length &&
          selectedSearchMethod === SEARCH_METHOD.OTHER_CRITERIA) ||
          (activeSubStep.value + 2 < activeStep?.subSteps?.length &&
            selectedSearchMethod !== SEARCH_METHOD.OTHER_CRITERIA)))
    ) {
      dispatch(
        setSteps({
          currentSubStep: activeStep.subSteps[activeSubStep.value + ONE_STEP]
        })
      );
    } else {
      const nextStep = APP_LAYOUT_STEPS.config[activeStep.value + ONE_STEP];
      dispatch(
        setSteps({
          currentSubStep: nextStep.subSteps[FIRST_SUB_STEP],
          currentStep: nextStep
        })
      );
    }
  };

  const handleBack = () => {
    if (activeSubStep.value === FIRST_SUB_STEP) {
      const backStep = APP_LAYOUT_STEPS.config[activeStep.value - ONE_STEP];
      if (
        activeStep.value === STEP_VALUES.result.value &&
        selectedSearchMethod === SEARCH_METHOD.NO_RESTRICTION
      ) {
        dispatch(
          setSteps({
            currentStep: backStep,
            currentSubStep: backStep?.subSteps[FIRST_SUB_STEP]
          })
        );
      } else if (
        activeStep.value === STEP_VALUES.result.value &&
        selectedSearchMethod !== SEARCH_METHOD.OTHER_CRITERIA
      ) {
        dispatch(
          setSteps({
            currentStep: backStep,
            currentSubStep: backStep?.subSteps[backStep?.subSteps?.length - 2]
          })
        );
      } else {
        dispatch(
          setSteps({
            currentStep: backStep,
            currentSubStep:
              backStep?.subSteps[backStep?.subSteps?.length - ONE_STEP]
          })
        );
      }
    } else {
      dispatch(
        setSteps({
          currentSubStep: activeStep?.subSteps[activeSubStep.value - ONE_STEP]
        })
      );
    }
  };

  const currentStepState = getValueByKey(activeSubStep?.key);

  const backStep = getBackStep(activeStep, activeSubStep, selectedSearchMethod);

  const nextStep = getNextStep(activeStep, activeSubStep, selectedSearchMethod);

  return (
    <div className='breadcrumbNavContainer'>
      {backStep && (
        <Button className='breadcrumbButton' onClick={handleBack}>
          <KeyboardArrowLeftIcon className='breadcrumbArrow' />
          <Box className='breadcrumbRow'>
            <Typography className='breadcrumbText'>
              {backStep.subStep.label}
            </Typography>
          </Box>
        </Button>
      )}
      {nextStep && (
        <Button
          className='breadcrumbButton breadcrumbButtonRight'
          disabled={!currentStepState?.nextAllowed}
          onClick={handleNext}
        >
          <Box
            className={`breadcrumbRow${
              !currentStepState?.nextAllowed && 'disabledText'
            }`}
          >
            <Box>
              <Typography
                className={`breadcrumbText ${
                  !currentStepState?.nextAllowed && 'disabledText'
                }`}
              >
                {nextStep.subStep.label}
              </Typography>
            </Box>
          </Box>
          <KeyboardArrowRightIcon
            className={`breadcrumbArrow ${
              !currentStepState?.nextAllowed && 'disabledText'
            }`}
          />
        </Button>
      )}
    </div>
  );
};

export default BreadcrumbsNavigation;
