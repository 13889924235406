import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import { SEARCH_METHOD } from '@common/constants';
import {
  selectMedium,
  selectMediumType,
  selectFluidAttributesNextAllowed,
  selectDynamicFluidFields
} from '@common/slices/fluidSlice/fluidSlice';
import {
  selectIndustryCluster,
  selectIndustryOfApplication,
  selectDynamicIOAFields,
  selectGeneralAttributesNextAllowed
} from '@common/slices/industrySlice/industrySlice';
import {
  selectOperationConditionNextAllowed,
  selectDutyPoints,
  selectExpertModeNextAllowed
} from '@common/slices/operationCondition/operationConditionSlice';
import { selectLengthOfPumpList } from '@common/slices/resultListSlice/resultListSlice';
import {
  selectProductCodes,
  selectProductRangeCodes,
  selectProductRangeLabels,
  selectProductLabels,
  selectSearchMethodNextAllowed,
  selectSearchType
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import {
  selectMaterial,
  selectMaterialElastomer,
  selectPumpSliceNextAllowed,
  selectTypeCode
} from '@common/slices/selectPumpSlice/selectPumpSlice';
import { mapSearchCriteria } from '@pages/Analyzer/hooks/utils';

const useGetValueByKey = () => {
  const expertModeNextAllowed = useSelector(selectExpertModeNextAllowed);
  const industryCluster = useSelector(selectIndustryCluster);
  const industry = useSelector(selectIndustryOfApplication);
  const ioaFIelds = useSelector(selectDynamicIOAFields);

  const mediumType = useSelector(selectMediumType);
  const medium = useSelector(selectMedium);
  const fluidFields = useSelector(selectDynamicFluidFields);

  const dutyPoints = useSelector(selectDutyPoints);

  const generalAttributesNextAllowed = useSelector(selectGeneralAttributesNextAllowed);
  const fluidAttributesNextAllowed = useSelector(selectFluidAttributesNextAllowed);
  const operationConditionNextAllowed = useSelector(selectOperationConditionNextAllowed);
  const selectedSearchCriteria = useSelector(selectSearchType);
  const selectedProductCodes = useSelector(selectProductCodes);
  const selectedProductLabels = useSelector(selectProductLabels);
  const selectedProductRangeCodes = useSelector(selectProductRangeCodes);
  const selectedProductRangeLabels = useSelector(selectProductRangeLabels);
  const searchMethodNextAllowed = useSelector(selectSearchMethodNextAllowed);

  const selectPumpNextAllowed = useSelector(selectPumpSliceNextAllowed);
  const selectedTypeCode = useSelector(selectTypeCode);
  const selectedMaterial = useSelector(selectMaterial);
  const selectedMaterialElastomer = useSelector(selectMaterialElastomer);

  const listOfPumps = useSelector(selectLengthOfPumpList);

  const getValueByKey = (subStepKey) => {
    switch (subStepKey) {
      case 'industryCluster':
        return {
          value: industryCluster?.value,
          nextAllowed: generalAttributesNextAllowed?.industryCluster,
          navigationAllowed: true
        };
      case 'industryOfApplication':
        return {
          value: industry?.value,
          nextAllowed: generalAttributesNextAllowed?.industryOfApplication,
          navigationAllowed: generalAttributesNextAllowed?.industryCluster
        };
      case 'attributes':
        return {
          value: (ioaFIelds?.firstColumn?.length ?? 0) + (ioaFIelds?.secondColumn?.length ?? 0),
          nextAllowed: generalAttributesNextAllowed?.attributes,
          navigationAllowed:
            generalAttributesNextAllowed?.industryCluster &&
            generalAttributesNextAllowed?.industryOfApplication
        };
      case 'fluidFamily':
        return {
          value: mediumType?.value,
          nextAllowed: fluidAttributesNextAllowed?.mediumType,
          navigationAllowed: generalAttributesNextAllowed?.attributes
        };
      case 'fluid':
        return {
          value: medium?.value,
          nextAllowed: fluidAttributesNextAllowed?.medium,
          navigationAllowed: fluidAttributesNextAllowed?.mediumType
        };
      case 'fluidAttributes':
        return {
          value:
            (fluidFields?.firstColumn?.length ?? 0) +
            (fluidFields?.secondColumn?.length ?? 0) +
            (fluidFields?.thirdColumn?.length ?? 0),
          nextAllowed: fluidAttributesNextAllowed?.fluidAttributes,
          navigationAllowed: fluidAttributesNextAllowed?.medium
        };
      case 'dutyPoints':
        return {
          value: Localize.get('namMenuNumOfDps', [dutyPoints?.length]).defaultMessage(
            'namMenuNumOfDps'
          ),
          nextAllowed:
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            expertModeNextAllowed,
          navigationAllowed: fluidAttributesNextAllowed?.fluidAttributes
        };
      case 'searchMethod':
        return {
          value: mapSearchCriteria(selectedSearchCriteria),
          nextAllowed:
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes,
          navigationAllowed:
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            expertModeNextAllowed
        };
      case 'productFamily':
        return {
          value: `${selectedProductLabels}`,
          nextAllowed:
            searchMethodNextAllowed?.products &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes,
          navigationAllowed:
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            expertModeNextAllowed &&
            Boolean(selectedSearchCriteria) &&
            selectedSearchCriteria !== SEARCH_METHOD.NO_RESTRICTION
        };
      case 'productRange':
        return {
          value: `${selectedProductRangeLabels}`,
          nextAllowed:
            searchMethodNextAllowed?.productRanges &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes,
          navigationAllowed:
            searchMethodNextAllowed?.products &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            expertModeNextAllowed &&
            Boolean(selectedProductCodes?.length)
        };
      case 'otherCriteria':
        return {
          value: Localize.get('navMenuNumOfCriteria', [
            (selectedTypeCode ? 1 : 0) +
              (selectedMaterial ? 1 : 0) +
              (selectedMaterialElastomer ? 1 : 0)
          ]).defaultMessage('navMenuNumOfCriteria'),

          nextAllowed:
            selectPumpNextAllowed &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA,
          navigationAllowed:
            searchMethodNextAllowed?.productRanges &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA &&
            expertModeNextAllowed &&
            Boolean(selectedProductRangeCodes?.length)
        };
      case 'resultList':
        return {
          value: Localize.get('navMenuNumOfPumps', [listOfPumps]).defaultMessage(
            'navMenuNumOfPumps'
          ),
          nextAllowed:
            Boolean(listOfPumps) &&
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes,
          navigationAllowed:
            operationConditionNextAllowed &&
            generalAttributesNextAllowed?.attributes &&
            fluidAttributesNextAllowed?.fluidAttributes &&
            expertModeNextAllowed &&
            (selectedSearchCriteria === SEARCH_METHOD.NO_RESTRICTION ||
              (selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
                ? selectPumpNextAllowed
                : searchMethodNextAllowed?.productRanges))
        };
      default:
        return '-';
    }
  };

  return getValueByKey;
};

export default useGetValueByKey;
