import React from 'react';
import { useDispatch } from 'react-redux';

import ErrIcons from '@mui/icons-material/Error';

import { setListErrorAnchor } from '@common/slices/resultListSlice/resultListSlice';

export default function ListErrorIcon({ params }) {
  const dispatch = useDispatch();
  return (
    <ErrIcons
      color="error"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(
          setListErrorAnchor({
            anchorRef: event.currentTarget,
            failedPumpCalculationRestrictions: [
              ...(params.row?.failedPumpCalculationRestrictions || []),
              ...(params.row?.failedRestrictions || [])
            ]
          })
        );
      }}
      className="resultListErrorIcon"
    />
  );
}
