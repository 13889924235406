import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import useDebounce from '@hooks/useDebounce';
import { getByPathAndParams } from '@services/BaseApi';

const AstSelector = ({
  selectionType,
  onClick,
  path,
  title,
  description,
  language,
  searchTitle
}) => {
  const [options, setOptions] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOnChange = (e) => setSearchTerm(e.target.value);

  useDebounce(
    () => {
      setListToDisplay(
        options.filter((d) => d?.value?.toLowerCase()?.includes(searchTerm.toLowerCase()))
      );
    },
    [options, searchTerm],
    searchTerm?.length ? 500 : 0
  );

  useEffect(() => {
    const getOptions = async () => {
      try {
        const { data } = await getByPathAndParams({
          path,
          params: { language_id: language }
        });

        setOptions(data);
      } catch (err) {
        console.log('err fetch options: ', err);
      }
    };

    getOptions();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Box>
      <DetailsTitle title={title} description={description} />
      <TextField
        id="input-with-icon-textfield"
        label={searchTitle}
        className="searchAttributeField"
        onChange={handleOnChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className="searchAttributeIcon" />
            </InputAdornment>
          )
        }}
        variant="outlined"
      />
      <Box className="cardList">
        {listToDisplay?.map((item) => (
          <Card className="astSelectorCard" key={item.id}>
            <CardContent className="cardContent">
              <Typography className="cardValue">{item.value}</Typography>
            </CardContent>

            <CardActions className="cardActions">
              <Button
                variant="contained"
                onClick={() => onClick(selectionType, item)}
                className="cardSelectButton"
              >
                {Localize.get('select').defaultMessage('select')}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AstSelector;
