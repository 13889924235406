import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const ResultListAccordion = ({ title, details, accordionStyle = '' }) => {
  return (
    <Accordion className={accordionStyle} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export default ResultListAccordion;
