import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const SwitchStyled = styled(Switch)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const InputSwitch = ({ label, ...props }) => {
  return (
    <Box className='switchButton'>
      <Typography className='switchButtonLabel'>{label}</Typography>
      <SwitchStyled size='medium' {...props} />
    </Box>
  );
};

export default InputSwitch;
