import { toUpper, get } from 'lodash';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { UNIT_SYSTEM_NAMES } from '@common/constants';
import { getMetricSystemValues } from '@common/helpers/helpers';
import { GENERAL_ATTRIBUTES_PATHS, ATTRIBUTE_POSITIONS } from '@common/network/ApiPaths';
import { getUnitsFormatted } from '@pages/Analyzer/hooks/utils';
import { getAllByPathList } from '@services/BaseApi';

export const initialState = {
  attributePositions: [],
  userUnitMetrics: {},
  sessionUnitMetrics: {},
  userUnitSystemName: UNIT_SYSTEM_NAMES.SI,
  sessionUnitSystemName: UNIT_SYSTEM_NAMES.SI,
  userMetricSystemName: UNIT_SYSTEM_NAMES.SI,
  isLoading: false
};

export const fetchMetricsAndPositions = createAsyncThunk(
  'unitsAndAttributes/fetchPositions',
  async (_, thunkAPI) => {
    const { unitSystem, language } = thunkAPI.getState().user;
    const response = await getAllByPathList(
      [GENERAL_ATTRIBUTES_PATHS.GET_UNITS, ATTRIBUTE_POSITIONS.GET_POSITIONS],
      language
    );
    if (response?.length !== 2 && response?.some(({ status }) => status !== 200)) {
      throw { err: 'Something went wrong' };
    }
    return { data: response, unitSystem };
  }
);

export const cpqSlice = createSlice({
  name: 'unitsAndAttributes',
  initialState,
  reducers: {
    setUserUnitMetrics: (state, { payload }) => {
      state.userUnitMetrics = payload;
      state.sessionUnitMetrics = payload;
    },
    setSessionUnitMetrics: (state, { payload }) => {
      state.sessionUnitMetrics = payload;
    },
    setUserUnitSystemName: (state, { payload }) => {
      state.userUnitSystemName = payload;
      state.sessionUnitSystemName = payload;
    },
    setSessionUnitSystemName: (state, { payload }) => {
      state.sessionUnitSystemName = payload;
    },
    setMetricSystemName: (state, { payload }) => {
      state.userMetricSystemName = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetricsAndPositions.fulfilled, (state, { payload }) => {
        const unitsFormatted = getUnitsFormatted(
          get(payload?.data, '[0].data', []),
          toUpper(payload?.unitSystem) === UNIT_SYSTEM_NAMES.CUSTOM,
          getMetricSystemValues(toUpper(payload?.unitSystem))
        );
        state.userUnitSystemName = toUpper(payload?.unitSystem);
        state.sessionUnitSystemName = toUpper(payload?.unitSystem);
        state.userUnitMetrics = unitsFormatted;
        state.sessionUnitMetrics = unitsFormatted;
        state.attributePositions = get(payload?.data, '[1].data', []);
        state.isLoading = false;
      })
      .addCase(fetchMetricsAndPositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMetricsAndPositions.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const selectAttributePositions = (state) => state.unitsAndAttributes.attributePositions;
export const selectUserMetricSystemName = (state) => state.unitsAndAttributes.userMetricSystemName;
export const selectIsLoading = (state) => state.unitsAndAttributes.isLoading;
// nove

export const selectUserUnitMetrics = (state) => state.unitsAndAttributes.userUnitMetrics; // - userUnitMetrics
export const selectSessionUnitMetrics = (state) => state.unitsAndAttributes.sessionUnitMetrics; // - sessionUnitMetrics
export const selectUserUnitSystemName = (state) => state.unitsAndAttributes.userUnitSystemName; // - userUnitSystemName
export const selectSessionUnitSystemName = (state) =>
  state.unitsAndAttributes.sessionUnitSystemName; // - sessionUnitSystemName

const { reducer, actions } = cpqSlice;

export const {
  setMetricSystemName,
  setUserUnitMetrics, // novo
  setSessionUnitMetrics,
  setUserUnitSystemName,
  setSessionUnitSystemName
} = actions;

export default reducer;
