import React from 'react';

import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

const MainHeaderTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    '&.MuiButtonBase-root': {
      minHeight: '70px',
      minWidth: '80px',
      fontSize: '0.7rem',
      backgroundColor: 'white',
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main
      }
    }
  })
);

export default MainHeaderTab;
