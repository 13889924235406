import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { PRODUCT_PATHS } from '@common/network/ApiPaths';
import { status as ERROR_STATUS } from '@common/toastConstants';
import { columnVisibilityInitState } from '@pages/Analyzer/components/ResultList/config/resultList';
import {
  checkDutyPointParams,
  checkFluidParams,
  checkIOAValues,
  mapUnitsForCalcPerformance,
  mapDPForCalcPerformance,
  mapCalcPumps
} from '@pages/Analyzer/hooks/utils';
import { postByPathAndData } from '@services/BaseApi';

import { setCpqErrors } from '../cpqSlice/cpqSlice';

const initialGraphDataState = {
  speedCapacityList: [],
  speedPowerList: [],
  speedNPSHrList: [],
  units: {
    capacity: 'm3_h',
    density: 'kg_dm3',
    dynamicViscosity: 'mPa_s',
    force: 'N',
    npshr: 'm',
    particleSize: 'mm',
    power: 'kW',
    pressure: 'bar',
    rotationalSpeed: '1_min',
    slidingSpeed: 'm_s',
    temperature: 'GradCelsius',
    torque: 'Nm'
  },
  rubbingSlidingVelocity: 0,
  capacityMax: 0,
  powerMax: 0,
  torqueMax: 0,
  npshrMax: 0,
  graphs: []
};

export const initialState = {
  listOfPumps: [],
  listOfValidPumps: [],
  selectedPump: undefined,
  isLoading: false,
  graphDataOriginal: undefined,
  graphData: initialGraphDataState,
  selectedDutyPoint: undefined,
  groupedDutyPoints: [],
  graphTitleLabel: undefined,
  showAllPumps: false,
  resultListModel: {
    viewModel: columnVisibilityInitState,
    sortModel: []
  },
  listErrorAnchor: null
};

export const fetchPumps = createAsyncThunk('resultList/fetchPumps', async (_, thunkApi) => {
  try {
    const {
      generalAttributes,
      operationCondition,
      unitsAndAttributes,
      fluidAttributes,
      searchMethod,
      selectPump,
      user,
      cpq
    } = thunkApi.getState();

    let capacityOrSpeed = operationCondition.capacityOrSpeed;
    let dynamicIOAFieldsValues = generalAttributes.dynamicIOAFieldsValues;
    let providedFluidValues = fluidAttributes.dynamicFluidFieldsValues;
    let unitMetrics = unitsAndAttributes.sessionUnitMetrics;
    let providedFluids = fluidAttributes.dynamicFluidFields;
    let dutyPoints = operationCondition.dutyPoints;

    const dynamicIOAFieldsValuesFormatted = checkIOAValues(dynamicIOAFieldsValues);
    const fluidParams = checkFluidParams(providedFluidValues, unitMetrics, providedFluids);
    const dutyPointParams = checkDutyPointParams(dutyPoints, unitMetrics);

    const cpqSelectedPumpId = cpq?.cpqProductData?.productStateData?.selectedPumpId;

    let optionalParams = {};
    if (searchMethod.selectedSearchCriteria !== SEARCH_METHOD.NO_RESTRICTION) {
      optionalParams.productCodes =
        searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
          ? searchMethod.singleSelect.selectedProductRangeCode.map(({ productCode }) => productCode)
          : searchMethod.multiSelect.selectedProductRangeCodes.map(
              ({ productCode }) => productCode
            );
    }

    if (searchMethod.selectedSearchCriteria === null && !optionalParams.productCodes?.length) {
      throw { message: 'params not yet provided' };
    }

    const response = await postByPathAndData({
      signal: thunkApi.signal,
      path:
        selectPump?.otherCriteriaSelectedPump?.ProductCode &&
        searchMethod?.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
          ? PRODUCT_PATHS.GET_PUMP_BY_PRODUCT_CODE
          : searchMethod.selectedSearchCriteria === SEARCH_METHOD.NO_RESTRICTION
          ? PRODUCT_PATHS.GET_ALL_PUMPS
          : PRODUCT_PATHS.GET_PUMPS_BY_RANGE,
      pathVariables: {
        ProductCode:
          searchMethod?.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
            ? selectPump?.otherCriteriaSelectedPump?.ProductCode
            : undefined
      },
      params: { language_id: user.language },
      data: {
        productParameters: {
          ...dynamicIOAFieldsValuesFormatted,
          ...fluidParams,
          ...dutyPointParams
        },
        mode: capacityOrSpeed === DEFAULT_VALUES.DP_INPUT_VARIATION.CAPACITY ? 0 : 1,
        calculationValues: {
          duty_points: mapDPForCalcPerformance(dutyPoints),
          numberOfLinePoints: 6,
          directionAndMaterial: 0,
          abrasionClass: 0,
          fibresClass: 0
        },
        customUnits: mapUnitsForCalcPerformance(unitMetrics),
        ...optionalParams
      }
    });

    if (response.status !== 200) {
      throw response;
    }
    return {
      listOfPumps: response.data,
      cpqSelectedPumpId,
      selectedSearchCriteria: searchMethod?.selectedSearchCriteria
    };
  } catch (err) {
    // console.log('err: ', err);
    if (err?.response?.data?.type === 'Input Params Missing') {
      thunkApi.dispatch(
        setCpqErrors({
          open: true,
          status: ERROR_STATUS.error,
          message: 'pumpParametersMissing'
        })
      );
    }
    throw { err };
  }
});

export const resultListSlice = createSlice({
  name: 'resultList',
  initialState,
  reducers: {
    setResultListToInit: () => {
      return initialState;
    },
    setSelectedPump: (state, { payload }) => {
      state.selectedPump = payload;
    },
    setGraphTitleLabel: (state, { payload }) => {
      state.graphTitleLabel = payload;
    },
    setGraphData: (state, { payload }) => {
      state.graphData = payload;
    },
    setSelectedDutyPoint: (state, { payload }) => {
      state.selectedDutyPoint = payload;
    },
    setShowAllPumps: (state, { payload }) => {
      state.showAllPumps = payload;
    },
    setResultList: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setResultListFromCPQ: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setViewModel: (state, { payload }) => {
      state.resultListModel.viewModel = payload;
    },
    setSortModel: (state, { payload }) => {
      state.resultListModel.sortModel = payload;
    },
    setListErrorAnchor: (state, { payload }) => {
      state.listErrorAnchor = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPumps.fulfilled, (state, { payload }) => {
        const data = mapCalcPumps(payload?.listOfPumps);
        const filteredList =
          data?.filter(
            ({ failedPumpCalculationRestrictions, failedRestrictions }) =>
              failedPumpCalculationRestrictions?.length === 0 && failedRestrictions?.length === 0
          ) ?? [];

        state.listOfValidPumps = filteredList;
        state.listOfPumps = data;
        state.isLoading = false;

        if (
          payload?.cpqSelectedPumpId ||
          payload?.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
        ) {
          state.selectedPump = data[0];
        } else {
          state.selectedPump = filteredList[0];
        }
      })
      .addCase(fetchPumps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPumps.rejected, (state, { payload }) => {
        console.log('err: ', payload);
        state.isLoading = false;
      });
  }
});

export const selectListOfPumps = (state) => state.resultList.listOfPumps;
export const selectListOfValidPumps = (state) => state.resultList.listOfValidPumps;
export const selectSelectedPump = (state) => state.resultList.selectedPump;
export const selectGroupedDutyPoints = (state) => state.resultList.groupedDutyPoints;
export const selectGraphDataOriginal = (state) => state.resultList.graphDataOriginal;
export const selectGraphData = (state) => state.resultList.graphData;
export const selectSelectedDutyPoint = (state) => state.resultList.selectedDutyPoint;
export const selectGraphTitleLabel = (state) => state.resultList.graphTitleLabel;
export const selectResultListIsLoading = (state) => state.resultList.isLoading;
export const selectResultListModel = (state) => state.resultList.resultListModel;
export const selectShowAllPumps = (state) => state.resultList.showAllPumps;
export const selectLengthOfPumpList = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return Boolean(state.selectPump.typeCode) &&
      Boolean(state.selectPump.material) &&
      Boolean(state.selectPump.materialElastomer)
      ? 1
      : 0;
  } else {
    return state.resultList.showAllPumps ||
      state.searchMethod.showAllProductFamilies ||
      state.searchMethod.showAllProductRanges
      ? Number(state.resultList.listOfPumps?.length ?? 0)
      : Number(state.resultList.listOfValidPumps?.length ?? 0);
  }
};
export const selectListErrorAnchor = (state) => state.resultList.listErrorAnchor;

const { reducer, actions } = resultListSlice;

export const {
  setResultListToInit,
  setSelectedPump,
  setResultListFromCPQ,
  setResultList,
  setGraphTitleLabel,
  setGraphData,
  setSelectedDutyPoint,
  setViewModel,
  setSortModel,
  setShowAllPumps,
  setListErrorAnchor
} = actions;

export default reducer;
