import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';

import InputComponent from '@components/InputComponent';
import InputSelect from '@components/InputSelect';
import UnitAdornment from '@components/UnitAdornment/UnitAdornment';
import {
  getUnitParameter,
  checkIfAreaIsHazardous,
  checkIfPolishedSurface,
  checkIfFoodGrade,
  checkIfParticleContent
} from '@pages/Analyzer/hooks/utils';

const AttributeInput = ({ dynamicField, onHintClick, onChange, values, unitMetrics, column }) => {
  const arrOptions = [
    {
      value: 1,
      label: Localize.get('yes').defaultMessage('yes')
    },
    { value: 0, label: Localize.get('no').defaultMessage('no') }
  ];
  let isError =
    values[dynamicField.key] === '' ||
    values[dynamicField.key] === undefined ||
    values[dynamicField.key] === null;

  if (dynamicField?.key_cls !== 3) {
    if (
      dynamicField.type_id === 2 &&
      checkIfPolishedSurface(dynamicField, values) &&
      checkIfFoodGrade(dynamicField, values)
    ) {
      return (
        <InputSelect
          label={dynamicField.name}
          options={arrOptions}
          value={values[dynamicField.key]}
          error={isError}
          helperText={isError && Localize.get('requiredField').defaultMessage('requiredField')}
          hint={dynamicField.hint}
          onHintClick={onHintClick}
          accessors={{
            valueAccessor: 'value',
            textAccessor: 'label'
          }}
          onChange={(event) => onChange('select', dynamicField.key, event)}
        />
      );
    } else if (dynamicField.type_id === 3 && checkIfAreaIsHazardous(dynamicField, values)) {
      return (
        <InputSelect
          label={dynamicField.name}
          options={dynamicField.options}
          value={values[dynamicField.key]}
          error={isError}
          helperText={isError && Localize.get('requiredField').defaultMessage('requiredField')}
          hint={dynamicField.hint}
          onHintClick={onHintClick}
          accessors={{
            valueAccessor: 'id',
            textAccessor: 'label'
          }}
          onChange={(event) => onChange('select', dynamicField.key, event, 3, column)}
        />
      );
    } else if (dynamicField.type_id === 1 && checkIfParticleContent(dynamicField, values)) {
      return (
        <Box>
          <InputComponent
            label={dynamicField.name}
            type="number"
            value={values[dynamicField.key] ?? ''}
            error={isError}
            helperText={isError && Localize.get('requiredField').defaultMessage('requiredField')}
            hint={dynamicField.hint}
            onHintClick={onHintClick}
            onChange={(event) => onChange('input-unit/input', dynamicField.key, event)}
            InputProps={{
              endAdornment: (
                <UnitAdornment
                  label={
                    unitMetrics[`${getUnitParameter(`${dynamicField.unit_group_id}`)}`]?.value
                      ?.label
                  }
                />
              )
            }}
          />
        </Box>
      );
    } else if (dynamicField.type_id === 4) {
      return (
        <InputComponent
          hint={dynamicField.hint}
          onHintClick={onHintClick}
          label={dynamicField.name}
          value={values[dynamicField.key]}
          error={isError}
          helperText={isError && Localize.get('requiredField').defaultMessage('requiredField')}
          onChange={(event) => onChange('input', dynamicField.key, event)}
        />
      );
    } else {
      return null;
    }
  }
  return null;
};

export default AttributeInput;
