import React, { useState } from 'react';

import TabContext from '@mui/lab/TabContext';

import ProductTabHeader from './components/ProductTabHeader/ProductTabHeader';
import ProductTabPanels from './components/ProductTabPanels/ProductTabPanels';

const productPanelTabOptions = {
  optionOne: 'product',
  optionTwo: 'limits',
  optionThree: 'limitsMapping',
  optionFour: 'limitsFluid',
  optionFive: 'details',
  optionSix: 'baseData',
  optionSeven: 'pressureData',
  optionEight: 'statorPictureMapping'
};

const ProductPanel = () => {
  const [selectedTab, setSelectedTab] = useState(
    productPanelTabOptions.optionOne
  );
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <TabContext value={selectedTab}>
      <ProductTabHeader
        handleTabChange={handleTabChange}
        productPanelTabOptions={productPanelTabOptions}
        disabledOptions={{ optionThree: true, optionFour: true }}
      />
      <ProductTabPanels productPanelTabOptions={productPanelTabOptions} />
    </TabContext>
  );
};

export default ProductPanel;
