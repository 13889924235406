import { useSelector } from 'react-redux';

import { CPQ_API } from '@common/constants';
import { CPQ_PATHS } from '@common/network/ApiPaths';
import {
  selectCpqData,
  selectCpqMappingData,
  selectParsedData,
  setCpqErrors,
  setCpqIsLoading
} from '@common/slices/cpqSlice/cpqSlice';
import {
  selectCpqFluidAttributeValues,
  selectDynamicFluidFieldsValues,
  selectDynamicFluidFields,
  selectMedium,
  selectMediumType
} from '@common/slices/fluidSlice/fluidSlice';
import {
  selectIndustryCluster,
  selectIndustryOfApplication,
  selectCpqIoAAttributeValues,
  selectDynamicIOAFieldsValues
} from '@common/slices/industrySlice/industrySlice';
import {
  selectCapacityOrSpeed,
  selectDutyPoints
} from '@common/slices/operationCondition/operationConditionSlice';
import {
  selectSelectedPump,
  selectGraphDataOriginal
} from '@common/slices/resultListSlice/resultListSlice';
import { selectSessionUnitMetrics } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectUserLevel } from '@common/slices/userSlice/userSlice';
import { status as ERROR_STATUS } from '@common/toastConstants';
import { insertPathVariables, postByPathAndData } from '@services/BaseApi';

import { convertToCPQBodyParams, checkOptionalParams } from '../utils/fromAstToCpqUtils';

const getObjectsDifferences = (o1, o2) =>
  Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key]
    };
  }, {});

export default function useCommunicationWithHost(showAllProducts, resultListModel, dispatch) {
  const industryCluster = useSelector(selectIndustryCluster);
  const industry = useSelector(selectIndustryOfApplication);
  const dynamicIOAFieldsValues = useSelector(selectDynamicIOAFieldsValues);
  const cpqIoAAttributeValues = useSelector(selectCpqIoAAttributeValues);
  const mediumType = useSelector(selectMediumType);
  const medium = useSelector(selectMedium);
  const dynamicFluidFields = useSelector(selectDynamicFluidFields);
  const dynamicFluidFieldsValues = useSelector(selectDynamicFluidFieldsValues);
  const cpqFluidAttributeValues = useSelector(selectCpqFluidAttributeValues);
  const dutyPoints = useSelector(selectDutyPoints);
  const capacityOrSpeed = useSelector(selectCapacityOrSpeed);

  const parsedData = useSelector(selectParsedData);

  const graphDataOriginal = useSelector(selectGraphDataOriginal);

  const selectedPump = useSelector(selectSelectedPump);

  const cpqData = useSelector(selectCpqData);
  const cpqMappingData = useSelector(selectCpqMappingData);

  const unitMetrics = useSelector(selectSessionUnitMetrics);

  const userLevel = useSelector(selectUserLevel);

  const handleSentToCPQNew = async () => {
    dispatch(setCpqIsLoading(true));
    const {
      QuoteNumber,
      configurationFlag,
      ItemGUID,
      ItemID,
      PartNumber,
      ItemNumber,
      CpqTableEntryId,
      CPQ_41PERMISSION02,
      Host_Url
    } = cpqData;

    try {
      const payload = convertToCPQBodyParams(
        cpqMappingData,
        { graphDataOriginal },
        selectedPump,
        resultListModel,
        unitMetrics,
        {
          industryCluster,
          industry,
          dynamicIOAFieldsValues,
          cpqIoAAttributeValues
        },
        {
          mediumType,
          medium,
          dynamicFluidFields,
          dynamicFluidFieldsValues,
          cpqFluidAttributeValues
        },
        selectedPump.id,
        showAllProducts,
        { userLevel },
        { dutyPoints, capacityOrSpeed }
      );

      const optParams = checkOptionalParams({
        CpqTableEntryId
      });

      const optionalUpdatedValues = {};

      if (configurationFlag === '1' || configurationFlag === '6') {
        optionalUpdatedValues.UpdatedValues = getObjectsDifferences(parsedData, payload);
      }

      const cpqResponseEdit = await postByPathAndData({
        path: CPQ_PATHS.POST_CONFIGURE,
        data: {
          ...optParams,
          cpqData: {
            QuoteNumber,
            ...optParams,
            Selections: {
              configuration_flag: configurationFlag,
              ItemGUID,
              ItemID,
              PartNumber,
              ItemNumber,
              ...payload
            },
            ...optionalUpdatedValues,
            Processed: 'False',
            AddedToQuote: 'False'
          }
        },
        params: { Host_Url }
      });

      if (cpqResponseEdit?.data?.CpqTableEntryId) {
        const redirectionPath = insertPathVariables(CPQ_API.REDIRECTION_URL_NEW, {
          Host_Url,
          quoteNumber: QuoteNumber,
          configurationFlag,
          CpqTableEntryId: cpqResponseEdit.data.CpqTableEntryId,
          cpqPermission: CPQ_41PERMISSION02
        });
        window.location.href = `https:${redirectionPath}`;
      }
    } catch (err) {
      console.log(err);
      dispatch(
        setCpqErrors({
          open: true,
          status: ERROR_STATUS.error,
          message: 'toastErrorAstToCpq'
        })
      );
    } finally {
      dispatch(setCpqIsLoading(false));
    }
  };

  return [handleSentToCPQNew];
}
