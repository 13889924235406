import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { selectSearchType } from '@common/slices/searchMethodSlice/searchMethodSlice';

import ProductItemCard from './ProductItemCard';


export default function ProductItemList({
  list,
  selectedItems,
  showAllProducts,
  selectClicked
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedSearchCriteria = useSelector(selectSearchType);

  const handleClick = (event, FailedRestrictions) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl({ anchorRef: event.currentTarget, FailedRestrictions });
  };

  const handleClose = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl?.anchorRef);
  const id = open ? DEFAULT_VALUES.POP_OVER_ID : undefined;

  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl?.anchorRef}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box className='productCardContainer'>
          {anchorEl?.FailedRestrictions.map((errMessage, index) => (
            <Box key={index}>
              <Typography>{errMessage}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
      <Box className='cardList'>
        {list
          .filter(({ FailedRestrictions }) =>
            showAllProducts ||
            selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
              ? true
              : !FailedRestrictions?.length
          )
          .map((product, index) => (
            <ProductItemCard
              key={index}
              id={index}
              product={product}
              handleClick={handleClick}
              selectedItems={selectedItems}
              selectClicked={selectClicked}
              selectedSearchCriteria={selectedSearchCriteria}
            />
          ))}
      </Box>
    </Box>
  );
}
