import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';

import get from 'lodash/get';

import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import { getLocalStorage } from '@common/helpers/helpers';
import { TRANSLATION } from '@common/network/ApiPaths';
import AppRouter from '@navigation/AppRouter';
import { getByPathAndParams } from '@services/BaseApi';

import './App.css';

const App = () => {
  const [localizeInitDone, setLocalizeInitDone] = useState(false);
  const [initialLocale, setInitialLocale] = useState({});

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY);

  const enLanguage = 1;

  const loadLocales = (providedValues) => {
    if (providedValues?.locales && Object.keys(providedValues.locales)?.length) {
      Localize.init(providedValues).then(() => {
        setLocalizeInitDone(true);
      });
    }
  };

  useEffect(() => {
    const auth = getLocalStorage('auth');
    const initialState = {
      languageSession: auth?.languageSession
    };

    const fetchTranslation = async () => {
      try {
        if (initialState?.languageSession && initialState?.languageSession !== 1) {
          let englishResponse = await getByPathAndParams({
            path: TRANSLATION.STATIC_VALUES,
            params: { language_id: 1 }
          });
          let anotherLangResponse = await getByPathAndParams({
            path: TRANSLATION.STATIC_VALUES,
            params: { language_id: initialState?.languageSession }
          });

          const currentTitle = get(anotherLangResponse, 'data.appTitle');
          document.title = currentTitle
            ? currentTitle
            : get(englishResponse, 'data.appTitle', 'NETZSCH Analyzer/Selector Tool');

          setInitialLocale({
            locales: {
              [enLanguage]: get(englishResponse, 'data', {}),
              [initialState?.languageSession]: get(anotherLangResponse, 'data', {})
            },
            currentLocale: initialState?.languageSession,
            fallbackLocale: enLanguage
          });
        } else {
          let englishResponse = await getByPathAndParams({
            path: TRANSLATION.STATIC_VALUES,
            params: { language_id: 1 }
          });

          document.title = get(englishResponse, 'data.appTitle', 'NETZSCH Analyzer/Selector Tool');
          setInitialLocale({
            locales: { [enLanguage]: get(englishResponse, 'data', {}) },
            currentLocale: enLanguage
          });
        }
      } catch {
        console.log('err fetchTranslation');
      }
    };
    fetchTranslation();
  }, []);

  /* eslint-disable */
  useEffect(() => loadLocales(initialLocale), [initialLocale]);

  return localizeInitDone ? (
    <>
      <CssBaseline />
      <AppRouter />
    </>
  ) : null;
};

export default App;
