import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import { SEARCH_METHOD } from '@common/constants';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { setSteps } from '@common/slices/layoutSlice/layoutSlice';
import {
  selectListOfProductS,
  selectProductCodes,
  selectSearchType,
  selectShowAllProductFamilies,
  setShowAllProductFamilies,
  setShowAllProductRanges,
  setShowAllPumps,
  selectIsLoading,
  onProductClick,
  onSelectProductClick,
  fetchProduct
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectRules } from '@common/slices/userSlice/userSlice';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import InputSwitch from '@components/InputSwitch';
import Loader from '@components/Loader';
import ProductItemList from '@components/ProductItemList';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';

const Product = ({ unitMetrics }) => {
  const dispatch = useDispatch();
  const userRules = useSelector(selectRules);
  const selectedProductCodes = useSelector(selectProductCodes);
  const listOfProducts = useSelector(selectListOfProductS);
  const showAllProductFamilies = useSelector(selectShowAllProductFamilies);
  const isLoading = useSelector(selectIsLoading);
  const selectedSearchCriteria = useSelector(selectSearchType);

  const selectClicked = (productCode, productLabel, isSelected) => {
    if (isSelected) {
      if (selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        dispatch(
          setSteps({
            currentSubStep: STEP_VALUES.productRange
          })
        );
      } else {
        dispatch(onProductClick({ productCode, productLabel }));
      }
    } else {
      if (selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        dispatch(onSelectProductClick({ productCode, productLabel }));
        dispatch(
          setSteps({
            currentSubStep: STEP_VALUES.productRange
          })
        );
      } else {
        dispatch(onProductClick({ productCode, productLabel }));
      }
    }
  };

  useEffect(() => {
    dispatch(fetchProduct());
    /* eslint-disable */
  }, []);

  return (
    <SalesCloudContainer>
      <DetailsTitle
        title={Localize.get('pleaseChooseProductFamilies').defaultMessage(
          'pleaseChooseProductFamilies'
        )}
        description={Localize.get('productSelectionDescription').defaultMessage(
          'productSelectionDescription'
        )}
        showAllSwitch={
          Boolean(userRules?.stepFour?.showAllEnabled) &&
          selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA && (
            <InputSwitch
              label={Localize.get('showAll').defaultMessage('showAll')}
              disabled={!userRules?.stepFour?.showAllEnabled}
              onClick={(event) => {
                dispatch(
                  setShowAllProductFamilies({
                    showAllProductFamilies: event.target.checked
                  })
                );
                dispatch(
                  setShowAllProductRanges({
                    showAllProductRanges: event.target.checked
                  })
                );
                dispatch(
                  setShowAllPumps({
                    showAllPumps: event.target.checked
                  })
                );
              }}
              checked={showAllProductFamilies}
            />
          )
        }
      />

      <Loader isLoading={isLoading} />
      <ProductItemList
        list={listOfProducts}
        selectedItems={selectedProductCodes} // ovde se koristi
        showAllProducts={showAllProductFamilies}
        unitMetrics={unitMetrics}
        selectClicked={selectClicked}
      />
    </SalesCloudContainer>
  );
};

export default Product;
