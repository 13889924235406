import { useState, useEffect } from 'react';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  REPLACE_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';





import { transformProductDetailsHeaders } from './utils';
import { PRODUCT_DETAILS_LIST } from '../config/uploadProducts';

const useProductDetailsPanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfProductDetails, setListOfProductDetails] = useState([]);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );

  const onCSVUpload = (event, type) => {
    const files = event.target.files;

    if (files) {
      setUploadIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: transformProductDetailsHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(
              results.data,
              PRODUCT_DETAILS_LIST
            );

            if (!isInvalid) {
              const path =
                type === 'upsert'
                  ? ADMIN.UPSERT_PRODUCT_DETAILS
                  : ADMIN.REPLACE_PRODUCT_DETAILS;
              await postByPathAndData({
                path,
                data: results.data
              });

              if (type === 'upsert')
                setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
              else setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchProductDetails();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const fetchProductDetails = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_PRODUCT_DETAILS
      });
      setListOfProductDetails(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  return [
    listOfProductDetails,
    isLoading,
    onCSVUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial
  ];
};

export default useProductDetailsPanel;
