import { createSlice } from '@reduxjs/toolkit';

import { getValueForCPQ } from '@pages/Analyzer/hooks/utils';

export const initialState = {
  mediumType: null,
  medium: null,
  cpqFluidAttributeValues: {},
  dynamicFluidFields: { firstColumn: [], secondColumn: [], thirdColumn: [] },
  dynamicFluidFieldsValues: {},
  fluidName: '',
  isChanged: true,
  nextAllowed: {
    fluidAttributes: false
  }
};

export const fluidAttributesSlice = createSlice({
  name: 'fluidAttributes',
  initialState,
  reducers: {
    setFluidSliceToInit: () => {
      return initialState;
    },
    setMediumType: (_, { payload }) => {
      return {
        ...initialState,
        mediumType: payload,
        isChanged: true,
        nextAllowed: {
          fluidAttributes: false
        }
      };
    },
    setMediumTypeLangChanged: (state, { payload }) => {
      state.mediumType = payload;
    },
    setMedium: (state, { payload }) => {
      return {
        ...initialState,
        mediumType: state.mediumType,
        medium: payload,
        isChanged: true,
        nextAllowed: {
          fluidAttributes: false
        }
      };
    },
    setMediumLangChanged: (state, { payload }) => {
      state.medium = payload;
    },
    setFluidIsChange: (state) => {
      state.isChanged = false;
    },
    setSelectAttributeValue: (state, { payload }) => {
      state.isChanged = true;
      state.dynamicFluidFieldsValues[payload.fieldName] = payload.value;
      state.cpqFluidAttributeValues[payload.fieldName] = getValueForCPQ(
        state.dynamicFluidFields[payload.column],
        payload.fieldName,
        payload.value
      );
    },
    setFluidAttributeValue: (state, { payload }) => {
      state.isChanged = true;
      state.dynamicFluidFieldsValues[payload.fieldName] = payload.value;
    },
    setFluidAttributeFields: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setFluidNextAllowed: (state, { payload }) => {
      state.nextAllowed = { ...payload };
    },
    setFluidName: (state, { payload }) => {
      state.fluidName = payload;
    },
    setFluidStateWithoutListener: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setQuickSelectionOfAttributes: (state, { payload }) => {
      return {
        ...state,
        dynamicFluidFieldsValues: {
          ...state.dynamicFluidFieldsValues,
          ...payload
        }
      };
    }
  }
});

export const selectMediumType = (state) => state.fluidAttributes.mediumType;
export const selectMedium = (state) => state.fluidAttributes.medium;
export const selectCpqFluidAttributeValues = (state) =>
  state.fluidAttributes.cpqFluidAttributeValues;
export const selectDynamicFluidFields = (state) => state.fluidAttributes.dynamicFluidFields;
export const selectDynamicFluidFieldsValues = (state) =>
  state.fluidAttributes.dynamicFluidFieldsValues;
export const selectFluidAttributesNextAllowed = (state) => {
  return {
    mediumType: Boolean(state.fluidAttributes.mediumType),
    medium: Boolean(state.fluidAttributes.medium),
    fluidAttributes: Boolean(state.fluidAttributes.nextAllowed.fluidAttributes)
  };
};

export const selectFluidName = (state) => state.fluidAttributes.fluidName;

const { reducer, actions } = fluidAttributesSlice;

export const {
  setMediumType,
  setMediumTypeLangChanged,
  setMedium,
  setMediumLangChanged,
  setSelectAttributeValue,
  setFluidAttributeValue,
  setFluidAttributeFields,
  setFluidNextAllowed,
  setFluidName,
  setFluidSliceToInit,
  setFluidStateWithoutListener,
  setFluidIsChange,
  setQuickSelectionOfAttributes
} = actions;

export default reducer;
