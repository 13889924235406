const transformHeaders = (headerName) => {
  switch (headerName) {
    case 'ProductCode':
      return 'product_code';
    case 'InternalDescription':
      return 'internal_description';
    case 'DescriptionTranslationId':
      return 'public_description';
    case 'ImageId':
      return 'public_image';
    case 'Site':
      return 'site';
    case 'Level':
      return 'level';
    case 'ParentProductCode':
      return 'parent_product_code';
    case 'TitleTranslationId':
      return 'public_title';
    default:
      return 'error_param';
  }
};

const transformCPQHeaders = (headerName) => {
  switch (headerName) {
    case 'Section':
      return 'Section';
    case 'AstCharacteristic':
      return 'AstCharacteristic';
    case 'InternalDescription':
      return 'InternalDescription';
    case 'CpqCharacteristic':
      return 'CpqCharacteristic';
    case 'InternalDataType':
      return 'InternalDataType';
    case 'CpqDigits':
      return 'CpqDigits';
    case 'CpqDecimalPlaces':
      return 'CpqDecimalPlaces';
    default:
      return 'error_param';
  }
};

const transformUserHeaders = (headerName) => {
  switch (headerName) {
    case 'FirstName':
      return 'first_name';
    case 'LastName':
      return 'last_name';
    case 'Phone':
      return 'phone';
    case 'Email':
      return 'email';
    case 'Status':
      return 'status';
    case 'Id':
      return 'id';
    case 'DefaultLanguage':
      return 'default_language';
    case 'Plant':
      return 'plant';
    case 'UnitSystem':
      return 'unit_system';
    case 'IsAdmin':
      return 'admin';
    case 'ExpertModeAllowed':
      return 'expert_mode_allowed';
    default:
      return 'error_param';
  }
};

const transformProductLimitsHeaders = (headerName) => {
  return headerName === 'ProductCode' ? 'product_code' : headerName;
};

const transformProductDetailsHeaders = (headerName) => {
  return headerName === 'ProductCode' ? 'product_code' : headerName;
};

// TODO: remove this redundant method
const transformPumpBaseDataHeaders = (headerName) => {
  switch (headerName) {
    case 'Model':
      return 'Model';
    case 'Size':
      return 'Size';
    case 'MaterialType':
      return 'MaterialType';
    case 'StatorMaterial':
      return 'StatorMaterial';
    case 'StatorType':
      return 'StatorType';
    case 'Stages':
      return 'Stages';
    case 'Geometry':
      return 'Geometry';
    case 'GeometryType':
      return 'GeometryType';
    case 'MaxParticleSize':
      return 'MaxParticleSize';
    case 'Release':
      return 'Release';
    case 'MachineType':
      return 'MachineType';
    case 'UnitGroup':
      return 'UnitGroup';
    case 'Displacement':
      return 'Displacement';
    case 'RotorPitchDiameter':
      return 'RotorPitchDiameter';
    case 'Eccentricity':
      return 'Eccentricity';
    case 'MaxSpeedLowPressure':
      return 'MaxSpeedLowPressure';
    case 'MaxSpeedHighPressure':
      return 'MaxSpeedHighPressure';
    case 'MaxPressureLowSpeed':
      return 'MaxPressureLowSpeed';
    case 'MaxPressureHighSpeed':
      return 'MaxPressureHighSpeed';
    case 'MaxPressureLowSpeedRHDCI':
      return 'MaxPressureLowSpeedRHDCI';
    case 'MaxPressureHighSpeedRHDCI':
      return 'MaxPressureHighSpeedRHDCI';
    case 'MaxPressureLowSpeedRHDSS':
      return 'MaxPressureLowSpeedRHDSS';
    case 'MaxPressureHighSpeedRHDSS':
      return 'MaxPressureHighSpeedRHDSS';
    case 'MinSpeedFactor':
      return 'MinSpeedFactor';
    case 'PressureFactorAbrasion1':
      return 'PressureFactorAbrasion1';
    case 'PressureFactorAbrasion2':
      return 'PressureFactorAbrasion2';
    case 'PressureFactorAbrasion3':
      return 'PressureFactorAbrasion3';
    case 'PressureFactorAbrasion4':
      return 'PressureFactorAbrasion4';
    case 'PressureFactorAbrasion5':
      return 'PressureFactorAbrasion5';
    case 'SpeedFactorAbrasion1':
      return 'SpeedFactorAbrasion1';
    case 'SpeedFactorAbrasion2':
      return 'SpeedFactorAbrasion2';
    case 'SpeedFactorAbrasion3':
      return 'SpeedFactorAbrasion3';
    case 'SpeedFactorAbrasion4':
      return 'SpeedFactorAbrasion4';
    case 'SpeedFactorAbrasion5':
      return 'SpeedFactorAbrasion5';
    case 'ViscosityLimitFactor1':
      return 'ViscosityLimitFactor1';
    case 'ViscosityLimitFactor2':
      return 'ViscosityLimitFactor2';
    case 'Temperature1':
      return 'Temperature1';
    case 'Temperature2':
      return 'Temperature2';
    case 'Temperature3':
      return 'Temperature3';
    case 'Temperature2Factor':
      return 'Temperature2Factor';
    case 'Temperature3Factor':
      return 'Temperature3Factor';
    case 'RotorTemperature1':
      return 'RotorTemperature1';
    case 'RotorTemperature2':
      return 'RotorTemperature2';
    case 'ClearanceAvailable1':
      return 'ClearanceAvailable1';
    case 'ClearanceAvailable2':
      return 'ClearanceAvailable2';
    case 'ClearanceLowPressure':
      return 'ClearanceLowPressure';
    case 'ClearanceHighPressure':
      return 'ClearanceHighPressure';
    case 'ViscosityPositiveIncClearance':
      return 'ViscosityPositiveIncClearance';
    case 'ViscosityPositiveStdClearance':
      return 'ViscosityPositiveStdClearance';
    case 'ViscosityFactorIncClearance':
      return 'ViscosityFactorIncClearance';
    case 'ViscosityFactorClearance1':
      return 'ViscosityFactorClearance1';
    case 'ViscosityFactorClearance2':
      return 'ViscosityFactorClearance2';
    case 'Margin1':
      return 'Margin1';
    case 'Slip5LowPressure':
      return 'Slip5LowPressure';
    case 'Slip4LowPressure':
      return 'Slip4LowPressure';
    case 'Slip3LowPressure':
      return 'Slip3LowPressure';
    case 'Slip2LowPressure':
      return 'Slip2LowPressure';
    case 'Slip1LowPressure':
      return 'Slip1LowPressure';
    case 'PressureSplit':
      return 'PressureSplit';
    case 'Margin2':
      return 'Margin2';
    case 'Slip5HighPressure':
      return 'Slip5HighPressure';
    case 'Slip4HighPressure':
      return 'Slip4HighPressure';
    case 'Slip3HighPressure':
      return 'Slip3HighPressure';
    case 'Slip2HighPressure':
      return 'Slip2HighPressure';
    case 'Slip1HighPressure':
      return 'Slip1HighPressure';
    case 'SlipSpan':
      return 'SlipSpan';
    case 'SlipEfficiency':
      return 'SlipEfficiency';
    case 'SlipFan':
      return 'SlipFan';
    case 'CentrePoint':
      return 'CentrePoint';
    case 'SafetyFactorFibres1':
      return 'SafetyFactorFibres1';
    case 'SafetyFactorFibres2':
      return 'SafetyFactorFibres2';
    case 'SafetyFactorFibres3':
      return 'SafetyFactorFibres3';
    case 'SafetyFactorFibres4':
      return 'SafetyFactorFibres4';
    case 'Torque':
      return 'Torque';
    case 'TorquePerBar':
      return 'TorquePerBar';
    case 'RiseMaxSpeed':
      return 'RiseMaxSpeed';
    case 'RiseViscosityFactor':
      return 'RiseViscosityFactor';
    case 'MaxTorque':
      return 'MaxTorque';
    case 'StartingTorque':
      return 'StartingTorque';
    case 'PortDiameter':
      return 'PortDiameter';
    case 'NPSH1':
      return 'NPSH1';
    case 'NPSH2':
      return 'NPSH2';
    case 'NPSH3':
      return 'NPSH3';
    case 'NPSHSpan1':
      return 'NPSHSpan1';
    case 'NPSHSpan2':
      return 'NPSHSpan2';
    case 'NPSHSpan3':
      return 'NPSHSpan3';
    case 'NPSHFan1':
      return 'NPSHFan1';
    case 'NPSHFan2':
      return 'NPSHFan2';
    case 'NPSHFan3':
      return 'NPSHFan3';
    case 'NPSHFan4':
      return 'NPSHFan4';
    default:
      return 'ErrorParam';
  }
};

const transformPressureDataHeaders = (headerName) => {
  switch (headerName) {
    case 'Model':
      return 'model';
    case 'StatorMaterial':
      return 'stator_material';
    case 'StatorType':
      return 'stator_type';
    case 'Pressure':
      return 'pressure';
    default:
      return 'error_param';
  }
};

const transformTranslationHeaders = (headerName) => {
  switch (headerName) {
    case 'Id':
      return 'translation_id';
    case 'LanguageId':
      return 'language_id';
    case 'Value':
      return 'value';
    default:
      return 'error_param';
  }
};

export {
  transformHeaders,
  transformProductLimitsHeaders,
  transformProductDetailsHeaders,
  transformPumpBaseDataHeaders,
  transformPressureDataHeaders,
  transformUserHeaders,
  transformCPQHeaders,
  transformTranslationHeaders
};
