import { useState } from 'react';
import { useSelector } from 'react-redux';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BIG_SCREEN_BREAK_POINT, ROUTER_PATHS } from '@common/constants';
import { selectCpqIsLoading } from '@common/slices/cpqSlice/cpqSlice';
import { selectCurrentStep } from '@common/slices/layoutSlice/layoutSlice';
import NavBar from '@navigation/components/NavBar';

import BreadcrumbsNavigation from './components/BreadcrumbsNavigation';
import NavigationMenu from './components/NavigationMenu';

const AnalyzerLayout = ({ mainDetails }) => {
  const activeStep = useSelector(selectCurrentStep);
  const cpqIsLoading = useSelector(selectCpqIsLoading);

  const isLaptopAndUp = useMediaQuery(BIG_SCREEN_BREAK_POINT);
  const [showMenu, setShowMenu] = useState(false);

  const handleDrawerClose = () => {
    setShowMenu(!showMenu);
  };

  let pathname = window.location.pathname;

  let pageClassName =
    pathname === ROUTER_PATHS.admin ? 'adminPage' : 'analyzerPage';
  let stackContainerClassName =
    pathname === ROUTER_PATHS.admin
      ? 'stackContainerAdminUi'
      : 'stackContainer';
  let detailsContainerClassName =
    pathname === ROUTER_PATHS.admin
      ? 'mainContainerAdminUi'
      : 'detailsContainer';

  return (
    <Box className='analyzerLayout'>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={cpqIsLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {!isLaptopAndUp && pathname !== ROUTER_PATHS.admin && (
        <NavBar handleDrawerClose={handleDrawerClose} showMenu={showMenu} />
      )}

      <div className={pageClassName}>
        {!isLaptopAndUp && pathname !== ROUTER_PATHS.admin && (
          <BreadcrumbsNavigation />
        )}

        <Stack direction='row' spacing={0} className={stackContainerClassName}>
          {isLaptopAndUp && pathname !== ROUTER_PATHS.admin && (
            <Paper className='navigationMenu'>
              <NavigationMenu
                activeStep={activeStep}
                isLaptopAndUp={isLaptopAndUp}
              />
            </Paper>
          )}

          {!isLaptopAndUp && pathname !== ROUTER_PATHS.admin && (
            <Drawer
              className='drawerMenu'
              variant='persistent'
              anchor='top'
              open={showMenu}
              onClose={handleDrawerClose}
            >
              <Paper className='navigationMenu'>
                <NavigationMenu
                  activeStep={activeStep}
                  isLaptopAndUp={isLaptopAndUp}
                />
              </Paper>
            </Drawer>
          )}

          <Paper className={detailsContainerClassName}>{mainDetails}</Paper>
        </Stack>
      </div>
    </Box>
  );
};

export default AnalyzerLayout;
