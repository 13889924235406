import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { setSteps } from '@common/slices/layoutSlice/layoutSlice';
import {
  selectListOfProductRanges,
  selectProductRangeCodes,
  selectSearchType,
  selectShowAllProductFamilies,
  selectShowAllProductRanges,
  setShowAllProductRanges,
  setShowAllPumps,
  onProductRangesClick,
  onSelectProductRangeClick,
  fetchProductRanges
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectRules } from '@common/slices/userSlice/userSlice';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import InputSwitch from '@components/InputSwitch';
import ProductItemList from '@components/ProductItemList';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import useDebounce from '@hooks/useDebounce';

const ProductRange = ({ unitMetrics }) => {
  const dispatch = useDispatch();
  const userRules = useSelector(selectRules);
  const showAllProductFamilies = useSelector(selectShowAllProductFamilies);
  const showAllProductRanges = useSelector(selectShowAllProductRanges);
  const selectedProductRangeCodes = useSelector(selectProductRangeCodes);
  const listOfProductRanges = useSelector(selectListOfProductRanges);
  const selectedSearchCriteria = useSelector(selectSearchType);

  const [listToDisplay, setListToDisplay] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const selectClicked = (productCode, productLabel, isSelected) => {
    if (isSelected) {
      if (selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        dispatch(
          setSteps({
            currentSubStep: STEP_VALUES.otherCriteria
          })
        );
      } else {
        dispatch(onProductRangesClick({ productCode, productLabel }));
      }
    } else {
      if (selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        dispatch(onSelectProductRangeClick({ productCode, productLabel }));
        dispatch(
          setSteps({
            currentSubStep: STEP_VALUES.otherCriteria
          })
        );
      } else {
        dispatch(onProductRangesClick({ productCode, productLabel }));
      }
    }
  };

  const handleOnChange = (e) => setSearchTerm(e.target.value);

  useDebounce(
    () => {
      setListToDisplay(
        listOfProductRanges.filter(
          (product) =>
            product?.PublicTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product?.ProductCode?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    },
    [listOfProductRanges, searchTerm],
    searchTerm?.length ? DEFAULT_VALUES.NUMBERS.FIVE_HUNDRED : DEFAULT_VALUES.NUMBERS.ZERO
  );

  useEffect(() => {
    dispatch(fetchProductRanges());
    /* eslint-disable-next-line */
  }, []);

  return (
    <SalesCloudContainer>
      <DetailsTitle
        title={Localize.get('pleaseChooseProductRanges').defaultMessage(
          'pleaseChooseProductRanges'
        )}
        description={Localize.get('productRangeSelectionDescription').defaultMessage(
          'productRangeSelectionDescription'
        )}
        showAllSwitch={
          Boolean(userRules?.stepFive?.showAllEnabled) &&
          selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA && (
            <InputSwitch
              label={Localize.get('showAll').defaultMessage('showAll')}
              onClick={(event) => {
                dispatch(
                  setShowAllProductRanges({
                    showAllProductRanges: event.target.checked
                  })
                );
                dispatch(
                  setShowAllPumps({
                    showAllPumps: event.target.checked
                  })
                );
              }}
              disabled={showAllProductFamilies || !userRules?.stepFive?.showAllEnabled}
              checked={showAllProductRanges}
            />
          )
        }
      />

      <TextField
        id="input-with-icon-textfield"
        label={Localize.get('searchProductRanges').defaultMessage('searchProductRanges')}
        className="searchAttributeField"
        onChange={handleOnChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className="searchAttributeIcon" />
            </InputAdornment>
          )
        }}
        variant="outlined"
      />
      <ProductItemList
        list={listToDisplay}
        selectedItems={selectedProductRangeCodes}
        showAllProducts={showAllProductRanges}
        unitMetrics={unitMetrics}
        selectClicked={selectClicked}
      />
    </SalesCloudContainer>
  );
};

export default ProductRange;
