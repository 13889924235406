import React from 'react';
import Localize from 'react-intl-universal';

import { Box } from '@mui/system';

import DataGridList from '@components/DataGridList';
import Loader from '@components/Loader';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import AdminLayout from '@pages/Admin/components/Layout';
import useCPQMapping from '@pages/Admin/hooks/useCPQMapping';

import dataGridColumns from './config/dataGridColumns';

const CPQConfig = () => {
  const [
    listOfParameters,
    isLoading,
    onCSVUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial
  ] = useCPQMapping();

  return (
    <Box>
      <Loader isLoading={uploadIsLoading || isLoading} />
      <Toast
        open={uploadToastState.open}
        handleClose={setUploadToastToInitial}
        status={uploadToastState.status}
        message={uploadToastState.message}
      />
      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                density="compact"
                rows={listOfParameters}
                getRowId={(item) => item.CpqCharacteristic}
                columns={dataGridColumns}
                pageSize={50}
                columnHeaderHeight={100}
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
              />
            </Box>

            <Box className="adminListFooter">
              <UploadButton
                onChange={onCSVUpload}
                content={Localize.get('replaceData').defaultMessage('replaceData')}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default CPQConfig;
