import React from 'react';
import Localize from 'react-intl-universal';

import { TabPanel } from '@mui/lab';

import GeneralAttributePanel from './components/GeneralAttributesPanel/GeneralAttributePanel';
import ProductPanel from './components/ProductsPanel/ProductPanel';
import UsersPanel from './components/UserPanel/UserPanel';

const DataTabPanels = ({ dataPanelTabOptions }) => {
  return (
    <>
      <TabPanel className="tabPanel" value={dataPanelTabOptions.optionOne}>
        <GeneralAttributePanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={dataPanelTabOptions.optionTwo}>
        {Localize.get('fluid').defaultMessage('fluid')}
      </TabPanel>
      <TabPanel className="tabPanel" value={dataPanelTabOptions.optionThree}>
        <ProductPanel />
      </TabPanel>
      <TabPanel className="tabPanel" value={dataPanelTabOptions.optionFour}>
        <UsersPanel />
      </TabPanel>
    </>
  );
};

export default DataTabPanels;
