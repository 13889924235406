import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { DEFAULT_VALUES, NETWORK_STATUSES } from '@common/constants';
import { USER } from '@common/network/ApiPaths';
import { selectToken, selectUserId } from '@common/slices/userSlice/userSlice';
import { getByPathAndParams } from '@services/BaseApi';

const useInterceptors = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await getByPathAndParams({
          path: USER.GET_USER_BY_ID,
          pathVariables: {
            id: userId
          },
          headers: {
            'Content-Type': 'application/json',
            authorization: `${DEFAULT_VALUES.AUTH.BEARER} ${token}`
          }
        });

        if (data?.email) {
          dispatch({
            type: 'setUserData',
            payload: {
              email: data.email,
              isAdmin: data.is_admin,
              status: data.status,
              plant: data.plant,
              unitSystem: data?.unit_system ? data.unit_system.toUpperCase() : null,
              language: data.default_language_id,
              expertModeAllowed: Boolean(data?.expert_mode_allowed)
            }
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    };

    if (token) fetchUserData();
    /* eslint-disable */
  }, []);

  useEffect(() => {
    const handleErrors = (err) => {
      switch (err?.response?.status) {
        case NETWORK_STATUSES.PAGE_NOT_FOUND:
          console.log('page not found');
          break;
        case NETWORK_STATUSES.BAD_REQUEST:
          console.log('bad request');
          break;
        case NETWORK_STATUSES.NOT_AUTHORIZED:
          console.log('not authorized');
          // dispatch({ type: 'setLogOut', payload: {} });
          // navigate({ to: ROUTER_PATHS.home, replace: false });
          break;
        default:
          console.log('unhandled error');
          break;
      }
    };
    if (token) axios.defaults.headers.authorization = `${DEFAULT_VALUES.AUTH.BEARER} ${token}`;
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        handleErrors(err);
        return err;
      }
    );
  }, [token]);
};

export default useInterceptors;
