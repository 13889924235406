export default [
  {
    field: 'ProductCode',
    headerName: 'ProductCode',
    width: 170,
    align: 'left',
    editable: false
  },
  {
    field: 'TypeCode',
    headerName: 'TypeCode',
    width: 130,
    align: 'left',
    editable: false
  },
  {
    field: 'Materials',
    headerName: 'Materials',
    width: 120,
    align: 'left',
    editable: false
  },
  {
    field: 'MaterialElastomer',
    headerName: 'MaterialElastomer',
    width: 140,
    align: 'left',
    editable: false
  },
  {
    field: 'Model',
    headerName: 'Model',
    width: 120,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorMaterial',
    headerName: 'StatorMaterial',
    width: 140,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorType',
    headerName: 'StatorType',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Price',
    headerName: 'Price',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MeanTimeBetweenFailures',
    headerName: 'MeanTimeBetweenFailures',
    width: 170,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaintenanceCost',
    headerName: 'MaintenanceCost',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'EnergyEfficiency',
    headerName: 'EnergyEfficiency',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'TotalCostOfOwnership',
    headerName: 'TotalCostOfOwnership',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'DeliveryTime',
    headerName: 'DeliveryTime',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SolidsHandling',
    headerName: 'SolidsHandling',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'AbrasiveProductsHandling',
    headerName: 'AbrasiveProductsHandling',
    width: 170,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LowShearEffect',
    headerName: 'LowShearEffect',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LowPulsationEffect',
    headerName: 'LowPulsationEffect',
    width: 125,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ReversibleOperation',
    headerName: 'ReversibleOperation',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'DryRunning',
    headerName: 'DryRunning',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Valveless',
    headerName: 'Valveless',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NoDynLoadedElastComp',
    headerName: 'NoDynLoadedElastComp',
    width: 170,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NoComponentContact',
    headerName: 'NoComponentContact',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SmallInstallationEnvelope',
    headerName: 'SmallInstallationEnvelope',
    width: 170,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LowMaintenanceTime',
    headerName: 'LowMaintenanceTime',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'LowSlip',
    headerName: 'LowSlip',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ShaftSealLowCost',
    headerName: 'ShaftSealLowCost',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'AggressiveChemicalsLowCost',
    headerName: 'AggressiveChemicalsLowCost',
    width: 185,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
