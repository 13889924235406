import { get, toLower } from 'lodash';

import {
  TRANSLATION,
  GENERAL_ATTRIBUTES_PATHS,
  FLUID_ATTRIBUTES_PATHS,
  USER
} from '@common/network/ApiPaths';
import { mapUnitsForCalcPerformance } from '@pages/Analyzer/hooks/utils';
import { getByPathAndParams, insertPathVariables, postByPathAndData } from '@services/BaseApi';

const fetchTranslation = async (languageId) => {
  try {
    const languageData = await getByPathAndParams({
      path: TRANSLATION.STATIC_VALUES,
      params: { language_id: languageId }
    });
    return get(languageData, 'data', {});
  } catch {
    console.log('err fetchTranslation');
    return {};
  }
};

const fetchIndustryClusters = async (sessionLanguage) => {
  try {
    const { data } = await getByPathAndParams({
      path: GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_CLUSTERS,
      params: { language_id: sessionLanguage }
    });
    return data;
  } catch (err) {
    console.log('err fetching clusters: ', err);
    return undefined;
  }
};

const fetchIndustries = async (industryClusterId, sessionLanguage) => {
  try {
    const { data } = await getByPathAndParams({
      path: insertPathVariables(GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_OF_APPLICATIONS, {
        industryClusterId
      }),
      params: {
        language_id: sessionLanguage
      }
    });
    return data;
  } catch (err) {
    console.log('err fetching industries: ', err);
    return undefined;
  }
};

const fetchIndustryAttributes = async (industryId, sessionLanguage) => {
  try {
    const { data } = await getByPathAndParams({
      path: GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_ATTRIBUTES,
      pathVariables: {
        id: industryId
      },
      params: { language_id: sessionLanguage }
    });
    return data;
  } catch (err) {
    console.log('err fetching industry attributes: ', err);
    return undefined;
  }
};

const fetchFluidTypes = async (industryId, sessionLanguage) => {
  try {
    const { data } = await getByPathAndParams({
      path: insertPathVariables(FLUID_ATTRIBUTES_PATHS.GET_MEDIUM_TYPES, {
        industryId: industryId
      }),
      params: {
        language_id: sessionLanguage
      }
    });
    return data;
  } catch (err) {
    console.log('err fetching fluid types: ', err);
    return undefined;
  }
};

const fetchFluids = async (industryId, fluidTypeId, sessionLanguage) => {
  try {
    const { data } = await getByPathAndParams({
      path: insertPathVariables(FLUID_ATTRIBUTES_PATHS.GET_MEDIUMS, {
        industryId: industryId,
        mediumTypeId: fluidTypeId
      }),
      params: {
        language_id: sessionLanguage
      }
    });
    return data;
  } catch (err) {
    console.log('err fetching fluids: ', err);
    return undefined;
  }
};

const fetchFluidAttributes = async (fluidId, sessionLanguage) => {
  try {
    let { data } = await getByPathAndParams({
      path: FLUID_ATTRIBUTES_PATHS.GET_MEDIUM_ATTRIBUTES,
      pathVariables: {
        id: fluidId
      },
      params: { language_id: sessionLanguage }
    });
    return data;
  } catch (err) {
    console.log('err fetching fluid attributes: ', err);
    return undefined;
  }
};

const postUserCustomUnitsData = async (
  userId,
  dialogUnitSystemNameUser,
  dialogUnitsMetricsUser
) => {
  try {
    const responseCustomUnits = await postByPathAndData({
      path: USER.PUT_USER_CUSTOM_UNIT,
      data: {
        user_id: userId,
        unit_system: toLower(dialogUnitSystemNameUser),
        custom_units: mapUnitsForCalcPerformance(dialogUnitsMetricsUser)
      }
    });

    if (responseCustomUnits.status === 200) {
      return responseCustomUnits;
    }
    return undefined;
  } catch (err) {
    console.log('err post user custom units: ', err);
    return undefined;
  }
};

const postUserStandardUnitsData = async (userId, dialogUnitSystemNameUser) => {
  try {
    const responseStandardUnits = await postByPathAndData({
      path: USER.PUT_USER_UNIT_SYSTEM,
      data: {
        user_id: userId,
        unit_system: toLower(dialogUnitSystemNameUser)
      }
    });

    if (responseStandardUnits.status === 200) {
      return responseStandardUnits;
    }
    return undefined;
  } catch (err) {
    console.log('err post user standard units: ', err);
    return undefined;
  }
};

const postUserLanguage = async (userId, dialogLanguageUser) => {
  try {
    const resLang = await postByPathAndData({
      path: USER.PUT_USER_LANGUAGE,
      data: {
        user_id: userId,
        default_language_id: dialogLanguageUser
      }
    });
    if (resLang.status === 200) return resLang;
    return undefined;
  } catch (err) {
    console.log('err post user language: ', err);
    return undefined;
  }
};

export {
  fetchTranslation,
  fetchIndustryClusters,
  fetchIndustries,
  fetchIndustryAttributes,
  fetchFluidTypes,
  fetchFluids,
  fetchFluidAttributes,
  postUserCustomUnitsData,
  postUserStandardUnitsData,
  postUserLanguage
};
