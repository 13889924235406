import React from 'react';
import Localize from 'react-intl-universal';

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import NetzschLogo from '../../../../../../../navigation/logo/NETZSCH_Logo.jpg';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch'
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    textTransform: 'uppercase',
    justifySelf: 'flex-start'
  },
  descriptionColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    alignSelf: 'center',
    justifySelf: 'flex-start'
  },
  titleColumn: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    textTransform: 'uppercase'
  },
  name: {
    fontSize: 20
  },
  description: {
    fontSize: 10
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end'
  },
  link: {
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end'
  }
});

const MyHeader = ({ model, statorMaterial, statorType }) => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
      <Text style={styles.name}>{model}</Text>
      <Text style={styles.subtitle}>{statorType}</Text>
    </View>
    <View style={styles.descriptionColumn}>
      <Text style={styles.description}>
        {Localize.get('performanceCurve').defaultMessage('performanceCurve')}
      </Text>
      <Text style={styles.description}>
        {Localize.get('elastomerQuality').defaultMessage('elastomerQuality') + ' ' + statorMaterial}
      </Text>
    </View>
    <View style={styles.titleColumn}>
      <Image src={NetzschLogo} style={{ width: '100px', height: 'auto' }} />
    </View>
  </View>
);

export default MyHeader;
