import React from 'react';

import DutyPoint from '../DutyPoint/DutyPoint';

const ListOfDutyPoints = ({
  dutyPoints,
  onCopyClick,
  onDeleteClick,
  capacityOrSpeed,
  dutyPointErrors,
  unitMetrics,
  addDisabled,
  deleteDisabled,
  handleOnChangeDP
}) => {
  return dutyPoints.map((dutyPointState, index) => {
    return (
      <DutyPoint
        key={index}
        index={index}
        dutyPointState={dutyPointState}
        onCopyClick={onCopyClick}
        onDeleteClick={onDeleteClick}
        addDisabled={addDisabled}
        deleteDisabled={deleteDisabled}
        capacityOrSpeed={capacityOrSpeed}
        dutyPointErrors={dutyPointErrors[index]}
        unitMetrics={unitMetrics}
        handleOnChangeDP={handleOnChangeDP}
      />
    );
  });
};

export default ListOfDutyPoints;
