import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';

import InputSelect from '@components/InputSelect';

const UserlDialog = ({
  userLanguage,
  onChangeLanguage = () => {},
  dialogUnitSystemNameUser,
  onChangeMetricSystemName = () => {},
  dialogUnitsMetricsUser,
  handleOnChangeMetricValueSession,
  languageOptions = [],
  dialogName
}) => {
  return (
    <Box>
      <Box className="unitDialogInputsContainer">
        <InputSelect
          label={Localize.get('language').defaultMessage('language')}
          value={userLanguage}
          onChange={(e) => onChangeLanguage(e, dialogName)}
          accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
          options={languageOptions}
          selectContainerProps={{
            className: 'unitInputContainer'
          }}
        />
        <InputSelect
          label={Localize.get('unitSystem').defaultMessage('unitSystem')}
          value={dialogUnitSystemNameUser}
          onChange={onChangeMetricSystemName}
          accessors={{ valueAccessor: 'value', textAccessor: 'label' }}
          options={[
            { label: 'SI', value: 'SI' },
            { label: 'US', value: 'US' },
            { label: 'Custom', value: 'CU', disabled: true }
          ]}
          selectContainerProps={{
            className: 'unitInputContainer'
          }}
        />
      </Box>
      <Box className="unitDialogInputsContainer">
        {Object.keys(dialogUnitsMetricsUser).map((keyName, index) => {
          return (
            <InputSelect
              key={index}
              options={dialogUnitsMetricsUser[keyName]?.options ?? []}
              label={dialogUnitsMetricsUser[keyName]?.name ?? ''}
              value={dialogUnitsMetricsUser[keyName]?.value?.id ?? ''}
              accessors={{ valueAccessor: 'id', textAccessor: 'label' }}
              onChange={(event) => handleOnChangeMetricValueSession(keyName, event)}
              selectContainerProps={{
                className: 'unitInputContainer'
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default UserlDialog;
