import Localize from 'react-intl-universal';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import InputComponent from '@components/InputComponent';

const VersionInfo = ({ moment, lastVersion }) => {
  return (
    <Box sx={{ px: 5, minWidth: '400px', width: '40vw' }}>
      <Typography
        sx={{
          textAlign: 'left',
          marginBottom: 1,
          marginLeft: 3,
          marginTop: 3
        }}
      >
        <b>{Localize.get('astInfo').defaultMessage('astInfo')}</b>
      </Typography>
      <InputComponent
        label={Localize.get('version').defaultMessage('version')}
        type="string"
        value={lastVersion?.version}
        InputProps={{ readOnly: true }}
      />
      <InputComponent
        label={Localize.get('deployedSprintNo').defaultMessage('deployedSprintNo')}
        type="string"
        value={lastVersion?.sprint}
        InputProps={{ readOnly: true }}
      />
      <InputComponent
        label={Localize.get('deployedDate').defaultMessage('deployedDate')}
        type="string"
        value={moment(lastVersion?.date).format('LL')}
        InputProps={{ readOnly: true }}
      />
    </Box>
  );
};
export default VersionInfo;
