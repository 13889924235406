import React from 'react';
import { useSelector } from 'react-redux';

import { Dialog } from '@mui/material';

import { SETTINGS_DIALOG } from '@common/constants';
import { selectShowSettingsDialog } from '@common/slices/userSlice/userSlice';
import PersonalSettingsDialog from '@components/PersonalSettingsDialog';
import UnitsSettingsDialog from '@components/UnitsSettingsDialog/UnitsSettingsDialog';

const SettingsDialog = () => {
  const showPersonalSettings = useSelector(selectShowSettingsDialog);
  return (
    <Dialog open={showPersonalSettings ? true : false} maxWidth="md" fullWidth={true}>
      {
        {
          [SETTINGS_DIALOG.SESSION]: <UnitsSettingsDialog />,
          [SETTINGS_DIALOG.PERSONAL]: <PersonalSettingsDialog />
        }[showPersonalSettings]
      }
    </Dialog>
  );
};

export default SettingsDialog;
