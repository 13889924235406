import React from 'react';
import Localize from 'react-intl-universal';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { SEARCH_IMAGES_COLUMNS } from '@common/constants';
import DataGridList from '@components/DataGridList';
import ImagePreview from '@components/ImagePreview/ImagePreview';
import InputComponent from '@components/InputComponent';
import Loader from '@components/Loader';
import StyledButton from '@components/StyledButton/StyledButton';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import AdminLayout from '@pages/Admin/components/Layout';
import usePicturesPanel from '@pages/Admin/hooks/usePicturesPanel';

const PicturesPanel = () => {
  const [
    listOfImages,
    isLoading,
    uploadToastState,
    setUploadToastToInitial,
    onImageUpload,
    selectedItem,
    onItemClick,
    mode,
    onEditClick,
    onCreateClick,
    cancelForm,
    formState,
    onFormSave,
    onDescriptionChange,
    imageDetails
  ] = usePicturesPanel();

  return (
    <Box>
      <Loader isLoading={isLoading} />
      <Toast
        open={uploadToastState.open}
        handleClose={setUploadToastToInitial}
        status={uploadToastState.status}
        message={uploadToastState.message}
      />
      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                density="compact"
                rows={listOfImages}
                getRowId={(item) => item.id}
                columns={SEARCH_IMAGES_COLUMNS}
                pageSize={50}
                selectionModel={selectedItem}
                onSelectionModelChange={onItemClick}
                columnHeaderHeight={100}
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
              />
            </Box>
          </Box>
        }
        detailsPanel={
          <Box className="adminDetailsContainer">
            <StyledButton onClick={onCreateClick} disabled={Boolean(mode)}>
              <AddIcon sx={{ mr: 2 }} />
              {Localize.get('createNew').defaultMessage('createNew')}
            </StyledButton>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">
                {mode === 'create'
                  ? Localize.get('createImage').defaultMessage('createImage')
                  : mode === 'edit'
                  ? Localize.get('editImage').defaultMessage('editImage')
                  : Localize.get('imageDetails').defaultMessage('imageDetails')}
              </Typography>

              {mode && (
                <>
                  <InputComponent
                    disabled={!mode}
                    value={mode ? formState?.description : imageDetails?.description}
                    label={Localize.get('description').defaultMessage('description')}
                    onChange={onDescriptionChange}
                    sx={{ width: '250px', pb: 1 }}
                  />
                  <Box
                    sx={{
                      position: 'relative',
                      justifyContent: 'end',
                      mb: 2
                    }}
                  >
                    <UploadButton
                      onChange={onImageUpload}
                      color="primary"
                      sx={{ ml: 1 }}
                      content={
                        formState?.image
                          ? Localize.get('changeImage').defaultMessage('changeImage')
                          : Localize.get('uploadImage').defaultMessage('uploadImage')
                      }
                    />
                  </Box>
                </>
              )}

              {(formState?.image || imageDetails?.image) && (
                <Paper
                  sx={{
                    p: 1,
                    pb: '50px',
                    minHeight: '400px',
                    position: 'relative'
                  }}
                >
                  <ImagePreview data={mode ? formState?.image : imageDetails?.image} />
                </Paper>
              )}

              {mode ? (
                <Box sx={{ ml: 1, mt: 2 }}>
                  <StyledButton
                    disabled={Boolean(!formState?.description?.length || !formState.image)}
                    onClick={() => onFormSave(mode)}
                    sx={{ mr: 1 }}
                  >
                    {Localize.get('save').defaultMessage('save')}
                  </StyledButton>
                  <StyledButton onClick={cancelForm}>
                    {Localize.get('cancel').defaultMessage('cancel')}
                  </StyledButton>
                </Box>
              ) : (
                imageDetails?.image && (
                  <StyledButton sx={{ mt: 2 }} onClick={() => onEditClick(imageDetails)}>
                    <EditIcon sx={{ mr: 2 }} />
                    {Localize.get('edit').defaultMessage('edit')}
                  </StyledButton>
                )
              )}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default PicturesPanel;
