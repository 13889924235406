import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { ROUTER_PATHS } from '@common/constants';
import { setLogOut } from '@common/slices/userSlice/userSlice';

const LogoutRedirection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const logout = async () => {
      dispatch(setLogOut());
      navigate({ to: ROUTER_PATHS.home, replace: false });
    };
    logout();
    /* eslint-disable */
  }, []);
  return <div>{Localize.get('redirecting').defaultMessage('redirecting')}</div>;
};

export default LogoutRedirection;
