import imageCompression from 'browser-image-compression';

import { DEFAULT_VALUES, UNIT_SYSTEM_NAMES, UNIT_SYSTEMS } from '@common/constants';

const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 600,
  useWebWorker: true
};

const filterEmptyStrings = (filterObject) => {
  if (!filterObject) return;
  let checkedObj = { ...filterObject };
  // eslint-disable-next-line array-callback-return
  Object.keys(checkedObj).map((column) => {
    if (
      checkedObj[column] === undefined ||
      checkedObj[column] === null ||
      checkedObj[column] === ''
    )
      delete checkedObj[column];
  });
  return checkedObj;
};

const copyObjectWithoutRef = (providedObj) => JSON.parse(JSON.stringify(providedObj));

const isObject = (a) => a && a.constructor === Object;
const isArray = (a) => Array.isArray(a);
const isNumber = (a) => !isNaN(a);
const isString = (a) => typeof a === 'string';
const isBool = (a) => typeof a === 'boolean';
const isInteger = (n) => Number(n) === n && n % 1 === 0;
const isFloat = (n) => Number(n) === n && n % 1 !== 0;
const isDate = (date) => {
  if (date === null) return false;
  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

const parseValues = (num1, num2) => {
  let a = parseFloat(num1);
  let b = parseFloat(num2);
  return { a, b };
};

const isValidNumber = (num) => {
  return num !== null && !isNaN(num);
};

const numOr0 = (n) => {
  let parsedNum = parseFloat(n);
  return n === null || isNaN(parsedNum) ? DEFAULT_VALUES.ZERO_VALUE : parsedNum;
};

const sum = (...arrOfNumbers) => {
  return [...arrOfNumbers].reduce((a, b) => numOr0(a) + numOr0(b));
};

const multiple = (num1, num2) => {
  let { a, b } = parseValues(num1, num2);
  if (isNaN(a) || isNaN(b)) return 0;
  return a * b;
};

const division = (num1, num2) => {
  let { a, b } = parseValues(num1, num2);
  if (isValidNumber(a) && isValidNumber(b) && b !== 0) return a / b;
  return 0;
};

const subtraction = (num1, num2) => {
  let { a, b } = parseValues(num1, num2);
  if (isNaN(a) || isNaN(b)) return 0;
  return a - b;
};

const convertToNumber = (value, altValue) => {
  let myNum = parseFloat(value);
  if (!isNaN(myNum)) {
    return myNum;
  }
  if (altValue) {
    return altValue;
  } else {
    return 0;
  }
};

const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const compressImage = async (file, options = imageCompressionOptions) =>
  await imageCompression(file, options);

const checkJSONParams = (listOfObjs = [], listOfProperties = []) => {
  for (let i = 0; i < listOfObjs.length; i++) {
    let { isInvalid, errorMessage } = validateObjectParams(listOfObjs[i], listOfProperties, i);
    if (isInvalid) {
      return { isInvalid, errorMessage };
    }
  }
  return {};
};

const getUniqueParams = (arr1, arr2) => {
  let unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
  let unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);

  return unique1.concat(unique2);
};

const validateObjectParams = (obj = {}, listOfProperties = [], indexOfRow) => {
  let listOfObjProperties = Object.keys(obj);
  let uniqueParams = getUniqueParams(listOfObjProperties, listOfProperties);
  if (uniqueParams?.length > 0) {
    return {
      errorMessage: `${uniqueParams} error at line ${indexOfRow}.`,
      isInvalid: true
    };
  }

  return {};
};

const insertValueInStringTemplate = (format, restArgs) => {
  return format.replace(/{(\d+)}/g, function (match, number) {
    return typeof restArgs[number] !== 'undefined' ? restArgs[number] : match;
  });
};

const getLocalStorage = (key) => {
  let data = window.localStorage.getItem('store') || null;
  data = JSON.parse(data);
  if (!data) return null;
  return key in data ? data[key] : null;
};

const setLocalStorage = (key, value) => {
  let data = window.localStorage.getItem('store') || null;
  data = JSON.parse(data) || {};
  data[key] = value;
  window.localStorage.setItem('store', JSON.stringify(data));
};

const getMetricSystemValues = (metricSystem) => {
  switch (metricSystem) {
    case UNIT_SYSTEM_NAMES.SI:
      return UNIT_SYSTEMS.SI;
    case UNIT_SYSTEM_NAMES.US:
      return UNIT_SYSTEMS.US;
    default:
      return UNIT_SYSTEMS.SI;
  }
};

const findById = (options = [], providedId) => {
  return options.find(({ id }) => id === providedId);
};

export {
  filterEmptyStrings,
  isObject,
  isArray,
  isNumber,
  isString,
  isBool,
  isInteger,
  isFloat,
  isDate,
  copyObjectWithoutRef,
  parseValues,
  isValidNumber,
  numOr0,
  sum,
  multiple,
  division,
  subtraction,
  convertToNumber,
  convertFileToBase64,
  compressImage,
  checkJSONParams,
  insertValueInStringTemplate,
  getLocalStorage,
  setLocalStorage,
  getMetricSystemValues,
  findById
};
