export default [
  {
    field: 'Section',
    headerName: 'Section',
    width: 250,
    align: 'left',
    editable: false
  },
  {
    field: 'AstCharacteristic',
    headerName: 'AstCharacteristic',
    width: 250,
    align: 'left',
    editable: false
  },
  {
    field: 'InternalDescription',
    headerName: 'InternalDescription',
    width: 250,
    align: 'left',
    editable: false
  },
  {
    field: 'CpqCharacteristic',
    headerName: 'CpqCharacteristic',
    width: 250,
    align: 'left',
    editable: false
  },
  {
    field: 'InternalDataType',
    headerName: 'InternalDataType',
    width: 150,
    align: 'left',
    editable: false
  },
  {
    field: 'CpqDigits',
    headerName: 'CpqDigits',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'CpqDecimalPlaces',
    headerName: 'CpqDecimalPlaces',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
