export const JSON_PLACEHOLDER_PATHS = Object.freeze({
  GET_TODO_LIST: '/todos'
});

export const GRAPH_PATHS = Object.freeze({
  POST: '/poc',
  CURVE_PERFORMANCE: '/curve-performance',
  GET_STATOR_IMAGE: '/stator-image-mapping/image'
});

export const ANALYZER_PATHS = Object.freeze({
  POST: '/poc/filter'
});

export const ATTRIBUTE_POSITIONS = Object.freeze({
  GET_POSITIONS: '/attribute/positions'
});

export const GENERAL_ATTRIBUTES_PATHS = Object.freeze({
  GET_UNITS: '/units',

  GET_INDUSTRY_CLUSTERS: '/industry-clusters',
  GET_INDUSTRY_OF_APPLICATIONS:
    '/industry-clusters/:industryClusterId/industry-of-applications',
  GET_INDUSTRY_ATTRIBUTES: '/groups/ioa/values/:id/attributes'
});

export const FLUID_ATTRIBUTES_PATHS = Object.freeze({
  GET_MEDIUM_TYPES: '/industry-of-applications/:industryId/medium-types',
  GET_MEDIUMS:
    'industry-of-application/:industryId/medium-types/:mediumTypeId/mediums',
  GET_MEDIUM_ATTRIBUTES: '/groups/fluid/values/:id/attributes'
});

export const PRODUCT_PATHS = Object.freeze({
  GET_PRODUCTS: '/products',
  GET_PRODUCT_RANGES: '/product-ranges/products',
  GET_PUMPS_BY_RANGE: '/pumps/product-ranges/products',
  GET_IMAGE_BY_PRODUCT_CODE: '/product/:productCode/image',
  GET_ALL_PUMPS: '/all-pumps',
  GET_PUMP_BY_PRODUCT_CODE: '/pumps/product-by-code/:ProductCode'
});

export const IMAGES_PATHS = Object.freeze({
  GET_IMAGE_LIST: '/maintenance/images',
  GET_IMAGE_BY_ID_MAINTENANCE: '/maintenance/images/:id',
  GET_IMAGE_BY_ID: '/images/:id',
  POST_IMAGE: '/maintenance/image',
  LINK_IMAGE: '/maintenance/image/link',
  LINK_STATOR_IMAGE: '/maintenance/stator-image/link'
});

export const ADMIN = Object.freeze({
  GET_ALL_USERS: '/users',
  GET_USER: '/user/:id',
  POST_USER_SETTINGS: '/user/settings',
  GET_PRODUCTS: '/maintenance/table/products',
  UPSERT_PRODUCTS: '/maintenance/products/upsert',
  UPSERT_PRODUCT: '/maintenance/product/upsert',
  UPLOAD_PRODUCT_IMAGE: '/maintenance/product/image/add',
  REPLACE_PRODUCTS: '/maintenance/products/replace',
  GET_PRODUCT_LIMITS: '/maintenance/table/product-limits',
  UPSERT_PRODUCT_LIMITS: '/maintenance/product-limits/upsert',
  REPLACE_PRODUCT_LIMITS: '/maintenance/product-limits/replace',
  GET_PRODUCT_DETAILS: '/maintenance/table/product-details',
  UPSERT_PRODUCT_DETAILS: '/maintenance/product-details/upsert',
  REPLACE_PRODUCT_DETAILS: '/maintenance/product-details/replace',
  GET_BASE_DATA: '/maintenance/table/pump-model',
  UPSERT_PUMP_BASE_DATA: '/maintenance/pump-base-data/upsert',
  REPLACE_PUMP_BASE_DATA: '/maintenance/pump-base-data/replace',
  GET_PRESSURE_DATA: '/maintenance/table/pressure-levels',
  UPSERT_PUMP_PRESSURE_DATA: '/maintenance/pump-pressure-levels/upsert',
  REPLACE_PUMP_PRESSURE_DATA: '/maintenance/pump-pressure-levels/replace',
  UPSERT_USERS: '/maintenance/users/upsert',
  REPLACE_USERS: '/maintenance/users/replace',
  GET_CPQ_MAPPING_PARAMETERS: '/cpq-mappings',
  POST_CPQ_MAPPING_PARAMETERS: '/maintenance/cpq/replace',
  GET_TRANSLATION_PARAMETERS: '/translations',
  UPSERT_TRANSLATION: '/maintenance/translations/upsert',
  REPLACE_TRANSLATION: '/maintenance/translations/replace',
  GET_STATOR_PICTURE_MAPPING: '/maintenance/table/stator-image-mapping'
});

export const USER = Object.freeze({
  PUT_USER_LANGUAGE: '/user/language-id',
  PUT_USER_UNIT_SYSTEM: '/user/unit-system',
  PUT_USER_CUSTOM_UNIT: '/user/custom-units',
  GET_USER_BY_ID: '/user/:id'
});

export const TRANSLATION = Object.freeze({
  STATIC_VALUES: '/translations/ui',
  LANGUAGES: '/languages'
});

export const CPQ_PATHS = Object.freeze({
  POST_CONFIGURE: '/send-to-cpq',
  GET_PRODUCT_CONFIGURATION: '/get-product-reconfigure/:id',
  GET_CPQ_MAPPING_PARAMETERS: '/cpq-mappings'
});
