import { createSlice } from '@reduxjs/toolkit';

import { getValueForCPQ } from '@pages/Analyzer/hooks/utils';

export const initialState = {
  industryCluster: null,
  industry: null,
  cpqIoAAttributeValues: {},
  dynamicIOAFields: { firstColumn: [], secondColumn: [] },
  dynamicIOAFieldsValues: {
    EnvironmentalTemperatureMin: '',
    EnvironmentalTemperatureMax: ''
  },
  isChanged: true,
  nextAllowed: {
    attributes: false
  }
};

export const generalAttributesSlice = createSlice({
  name: 'generalAttributes',
  initialState,
  reducers: {
    setIndustryCluster: (_, { payload }) => {
      return {
        ...initialState,
        isChanged: true,
        nextAllowed: {
          attributes: false
        },
        industryCluster: payload
      };
    },
    setIndustryClusterLangChanged: (state, { payload }) => {
      state.industryCluster = payload;
    },
    setIndustryOfApplication: (state, { payload }) => {
      return {
        ...initialState,
        isChanged: true,
        industryCluster: state.industryCluster,
        industry: payload,
        nextAllowed: {
          attributes: false
        }
      };
    },
    setIndustryOfApplicationLangChanged: (state, { payload }) => {
      state.industry = payload;
    },
    setIndustryIsChange: (state) => {
      state.isChanged = false;
    },
    setSelectAttributeValue: (state, { payload }) => {
      state.isChanged = true;
      state.dynamicIOAFieldsValues[payload.fieldName] = payload.value;
      state.cpqIoAAttributeValues[payload.fieldName] = getValueForCPQ(
        state.dynamicIOAFields[payload.column],
        payload.fieldName,
        payload.value
      );
    },
    setAttributeValue: (state, { payload }) => {
      state.isChanged = true;
      state.dynamicIOAFieldsValues[payload.fieldName] = payload.value;
    },
    setGeneralAttributesWIthoutListener: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setAttributeFields: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    setIndustryNextAllowed: (state, { payload }) => {
      state.nextAllowed = { ...payload };
    },
    setQuickSelectionOfAttributes: (state, { payload }) => {
      return {
        ...state,
        dynamicIOAFieldsValues: {
          ...state.dynamicIOAFieldsValues,
          ...payload
        }
      };
    }
  }
});

export const selectIndustryCluster = (state) => state.generalAttributes.industryCluster;
export const selectIndustryOfApplication = (state) => state.generalAttributes.industry;
export const selectCpqIoAAttributeValues = (state) => state.generalAttributes.cpqIoAAttributeValues;
export const selectDynamicIOAFields = (state) => state.generalAttributes.dynamicIOAFields;
export const selectDynamicIOAFieldsValues = (state) =>
  state.generalAttributes.dynamicIOAFieldsValues;
export const selectGeneralAttributesNextAllowed = (state) => {
  return {
    industryCluster: Boolean(state.generalAttributes.industryCluster),
    industryOfApplication: Boolean(state.generalAttributes.industry),
    attributes: Boolean(state.generalAttributes.nextAllowed?.attributes)
  };
};

const { reducer, actions } = generalAttributesSlice;

export const {
  setIndustryCluster,
  setIndustryClusterLangChanged,
  setIndustryOfApplication,
  setIndustryOfApplicationLangChanged,
  setSelectAttributeValue,
  setAttributeValue,
  setAttributeFields,
  setGeneralAttributesWIthoutListener,
  setIndustryNextAllowed,
  setQuickSelectionOfAttributes,
  setIndustryIsChange
} = actions;

export default reducer;
