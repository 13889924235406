const userList = [
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 100,
    align: 'left'
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 100,
    editable: false,
    align: 'left'
  },
  {
    field: 'email',
    headerName: 'Username',
    width: 240,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      return (
        <div
          style={{
            width: '50',
            display: 'inline-block',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            textAlign: 'left'
          }}
        >
          {params.value}
        </div>
      );
    }
  },
  {
    field: 'isAdmin',
    headerName: 'Admin',
    width: 70,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      switch (params.value) {
        case true:
          params.value = 'Yes';
          break;
        case false:
          params.value = 'No';
          break;
        default:
          break;
      }
      return params.value;
    }
  },
  {
    field: 'expertModeAllowed',
    headerName: 'Expert',
    width: 70,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      switch (params.value) {
        case true:
          params.value = 'Yes';
          break;
        case false:
          params.value = 'No';
          break;
        default:
          break;
      }
      return params.value;
    }
  },
  {
    field: 'status',
    headerName: 'Locked',
    width: 70,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      switch (params.value) {
        case 'active':
          params.value = 'No';
          break;
        case 'inactive':
          params.value = 'Yes';
          break;
        default:
          break;
      }
      return params.value;
    }
  },
  {
    field: 'defaultLanguage',
    headerName: 'Language',
    width: 100,
    editable: false,
    align: 'left'
  },
  {
    field: 'plant',
    headerName: 'Plant',
    width: 70,
    editable: false,
    align: 'left'
  },
  {
    field: 'unitSystem',
    headerName: 'Unit System',
    width: 100,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      switch (params.value) {
        case 'si':
          params.value = 'SI';
          break;
        case 'us':
          params.value = 'US';
          break;
        case 'cu':
          params.value = 'Custom';
          break;
        default:
          break;
      }
      return params.value;
    }
  }
];

export { userList };
