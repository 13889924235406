import React from 'react';
import Localize from 'react-intl-universal';

import { Box } from '@mui/system';

import { DEFAULT_VALUES } from '@common/constants';
import DataGridList from '@components/DataGridList';
import DownloadButton from '@components/DownloadButton/DownloadButton';
import Loader from '@components/Loader';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import AdminLayout from '@pages/Admin/components/Layout';
import useTranslationsPanel from '@pages/Admin/hooks/useTranslationsPanel';

import dataGridColumns from './config/dataGridColumns';

const getLabel = (languageId, languageCode) => {
  return `${languageId} ${DEFAULT_VALUES.CHARS.MINUS} ${languageCode}`;
};

const TabPanelTranslations = () => {
  const {
    translationList,
    isLoading,
    uploadIsLoading,
    uploadToastState,
    languages,
    onUploadCSVOfTranslation,
    setUploadToastToInitial,
    downloadCSVOfTranslation
  } = useTranslationsPanel();

  return (
    <Box>
      <Loader isLoading={uploadIsLoading || isLoading} />
      <Toast
        open={uploadToastState?.open ?? ''}
        handleClose={setUploadToastToInitial}
        status={uploadToastState?.status ?? ''}
        message={uploadToastState?.message ?? ''}
      />
      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridList
                columns={dataGridColumns}
                rows={translationList}
                getRowId={(item) => {
                  return `${item.TranslationId} ${item.LanguageId}`;
                }}
                pageSize={50}
                columnHeaderHeight={100}
                rowHeight={60}
                density="compact"
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={100}
              />
            </Box>

            <Box className="adminListFooter">
              <UploadButton
                id="upload-button-file"
                onChange={(e) => onUploadCSVOfTranslation(e, 'upsert')}
                content={Localize.get('upsertData').defaultMessage('upsertData')}
              />
              <UploadButton
                id="replace-button-file"
                onChange={(e) => onUploadCSVOfTranslation(e, 'replace')}
                content={Localize.get('replaceData').defaultMessage('replaceData')}
              />
              <DownloadButton
                onClick={downloadCSVOfTranslation}
                content={Localize.get('download').defaultMessage('download')}
              />
            </Box>
          </Box>
        }
        detailsPanel={
          <Box className="adminDetailsContainer">
            <b>{Localize.get('languageId').defaultMessage('languageId')}</b>
            <br />
            {languages.map((language, index) => (
              <p key={index}>{getLabel(language.Id, language.Code)}</p>
            ))}
          </Box>
        }
      />
    </Box>
  );
};

export default TabPanelTranslations;
