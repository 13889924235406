import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SEARCH_METHOD } from '@common/constants';
import { PRODUCT_PATHS } from '@common/network/ApiPaths';
import {
  checkDutyPointParams,
  checkFluidParams,
  checkIOAValues,
  sortByPublicTitle,
  mapUnitsForCalcPerformance
} from '@pages/Analyzer/hooks/utils';
import { postByPathAndData } from '@services/BaseApi';

export const initialState = {
  selectedSearchCriteria: null,
  selectedProductCodes: [],
  listOfProducts: [],
  selectedProductRangeCodes: [],
  listOfProductRanges: [],
  singleSelect: {
    selectedProductCode: [],
    selectedProductRangeCode: [],
    nextAllowed: {
      listOfProducts: false,
      listOfProductRanges: false
    }
  },
  multiSelect: {
    selectedProductCodes: [],
    selectedProductRangeCodes: [],
    nextAllowed: {
      listOfProducts: false,
      listOfProductRanges: false
    }
  },
  isLoading: false,
  showAllProductFamilies: false,
  showAllProductRanges: false,
  showAllPumps: false,
  nextAllowed: {
    listOfProducts: false,
    listOfProductRanges: false
  }
};

export const fetchProduct = createAsyncThunk('searchMethod/fetchProduct', async (_, thunkApi) => {
  try {
    const { generalAttributes, fluidAttributes, operationCondition, unitsAndAttributes, user } =
      thunkApi.getState();

    const dynamicIOAFieldsValuesFormatted = checkIOAValues(
      generalAttributes.dynamicIOAFieldsValues
    );

    const unitMetrics = unitsAndAttributes.sessionUnitMetrics;
    const fluidParams = checkFluidParams(
      fluidAttributes.dynamicFluidFieldsValues,
      unitMetrics,
      fluidAttributes.dynamicFluidFields
    );

    const dutyPoints = operationCondition.dutyPoints;
    const dutyPointParams = checkDutyPointParams(dutyPoints, unitMetrics);

    const { data, status } = await postByPathAndData({
      path: PRODUCT_PATHS.GET_PRODUCTS,
      params: { language_id: user.language },
      data: {
        custom_units: mapUnitsForCalcPerformance(unitMetrics),
        productParameters: {
          ...dynamicIOAFieldsValuesFormatted,
          ...fluidParams,
          ...dutyPointParams
        }
      }
    });

    if (status !== 200) {
      throw { err: 'Something went wrong' };
    }

    return data;
  } catch (err) {
    console.log('err: ', err);
    throw { err };
  }
});

export const searchMethodSlice = createSlice({
  name: 'searchMethod',
  initialState,
  reducers: {
    setSearchMethodSliceToInit: (state) => {
      return { ...initialState, singleSelect: state.singleSelect };
    },
    setSearchType: (state, { payload }) => {
      state.selectedSearchCriteria = payload;
    },
    setListOfProductRanges: (state, { payload }) => {
      state.listOfProductRanges = payload;
      state.isLoading = false;
    },
    onProductClick: (state, { payload }) => {
      if (state.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        if (
          state.singleSelect.selectedProductCode.some(
            ({ productCode }) => productCode === payload.productCode
          )
        ) {
          state.singleSelect.selectedProductCode = [];
          state.singleSelect.selectedProductRangeCode = [];
        } else {
          state.singleSelect.selectedProductCode = [
            { productCode: payload.productCode, productLabel: payload.productLabel }
          ];
          state.singleSelect.selectedProductRangeCode = [];
        }
      } else {
        if (
          state.multiSelect.selectedProductCodes.some(
            ({ productCode }) => productCode === payload.productCode
          )
        ) {
          state.multiSelect.selectedProductCodes = state.multiSelect.selectedProductCodes.filter(
            ({ productCode }) => productCode !== payload.productCode
          );
          state.multiSelect.selectedProductRangeCodes =
            state.multiSelect.selectedProductRangeCodes.filter(
              ({ productCode }) => !productCode.includes(payload.productCode)
            );
        } else {
          state.multiSelect.selectedProductCodes.push({
            productCode: payload.productCode,
            productLabel: payload.productLabel
          });
        }
      }
    },
    onSelectProductClick: (state, { payload }) => {
      if (state.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        state.singleSelect.selectedProductCode = [
          { productCode: payload.productCode, productLabel: payload.productLabel }
        ];
        state.singleSelect.selectedProductRangeCode = [];
      } else {
        state.multiSelect.selectedProductRangeCodes =
          state.multiSelect.selectedProductRangeCodes.filter((productCode) =>
            productCode.includes(payload.productCode)
          );
        state.multiSelect.selectedProductCodes = [
          { productCode: payload.productCode, productLabel: payload.productLabel }
        ];
      }
    },
    onSelectProductRangeClick: (state, { payload }) => {
      if (state.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        state.singleSelect.selectedProductRangeCode = [
          { productCode: payload.productCode, productLabel: payload.productLabel }
        ];
      } else {
        state.multiSelect.selectedProductRangeCodes = [
          { productCode: payload.productCode, productLabel: payload.productLabel }
        ];
      }
    },
    onProductRangesClick: (state, { payload }) => {
      if (state.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
        if (
          state.singleSelect.selectedProductRangeCode.some(
            ({ productCode }) => productCode === payload.productCode
          )
        ) {
          state.singleSelect.selectedProductRangeCode = [];
        } else {
          state.singleSelect.selectedProductRangeCode = [
            { productCode: payload.productCode, productLabel: payload.productLabel }
          ];
        }
      } else {
        if (
          state.multiSelect.selectedProductRangeCodes.some(
            ({ productCode }) => productCode === payload.productCode
          )
        ) {
          state.multiSelect.selectedProductRangeCodes =
            state.multiSelect.selectedProductRangeCodes.filter(
              ({ productCode }) => productCode !== payload.productCode
            );
        } else {
          state.multiSelect.selectedProductRangeCodes.push({
            productCode: payload.productCode,
            productLabel: payload.productLabel
          });
        }
      }
    },
    setShowAllProductFamilies: (state, { payload }) => {
      state.showAllProductFamilies = payload.showAllProductFamilies;
    },
    setShowAllProductRanges: (state, { payload }) => {
      state.showAllProductRanges = payload.showAllProductRanges;
    },
    setShowAllPumps: (state, { payload }) => {
      state.showAllPumps = payload.showAllPumps;
    },
    deselectSelectedProducts: (state) => {
      if (state.selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
        state.multiSelect.selectedProductCodes = [];
        state.multiSelect.selectedProductRangeCodes = [];
      }
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setMethodStateFromCPQ: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    fetchProductRanges: () => {}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.fulfilled, (state, { payload }) => {
        state.listOfProducts = sortByPublicTitle(payload);
        if (state.selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
          state.listOfProductRanges = [];
        }

        state.isLoading = false;
      })
      .addCase(fetchProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProduct.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const selectSearchType = (state) => state.searchMethod.selectedSearchCriteria;
export const selectProductCodes = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return state.searchMethod.singleSelect.selectedProductCode.map(
      ({ productCode }) => productCode
    );
  } else {
    return state.searchMethod.multiSelect.selectedProductCodes.map(
      ({ productCode }) => productCode
    );
  }
};
export const selectProductLabels = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return state.searchMethod.singleSelect.selectedProductCode.map(
      ({ productLabel }) => productLabel
    );
  } else {
    return state.searchMethod.multiSelect.selectedProductCodes.map(
      ({ productLabel }) => productLabel
    );
  }
};

export const selectListOfProductS = (state) => state.searchMethod.listOfProducts;
export const selectProductRangeCodes = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return state.searchMethod.singleSelect.selectedProductRangeCode.map(
      ({ productCode }) => productCode
    );
  } else {
    return state.searchMethod.multiSelect.selectedProductRangeCodes.map(
      ({ productCode }) => productCode
    );
  }
};
export const selectProductRangeLabels = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return state.searchMethod.singleSelect.selectedProductRangeCode.map(
      ({ productLabel }) => productLabel
    );
  } else {
    return state.searchMethod.multiSelect.selectedProductRangeCodes.map(
      ({ productLabel }) => productLabel
    );
  }
};

export const selectListOfProductRanges = (state) => state.searchMethod.listOfProductRanges;
export const selectShowAllProductFamilies = (state) => state.searchMethod.showAllProductFamilies;
export const selectShowAllProductRanges = (state) => state.searchMethod.showAllProductRanges;
export const selectShowAllPumps = (state) => state.searchMethod.showAllPumps;
export const selectIsLoading = (state) => state.searchMethod.isLoading;
export const selectSearchMethodNextAllowed = (state) => {
  if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA) {
    return {
      products: Boolean(state.searchMethod.singleSelect.selectedProductCode?.length),
      productRanges: Boolean(state.searchMethod.singleSelect.selectedProductRangeCode?.length)
    };
  } else if (state.searchMethod.selectedSearchCriteria === SEARCH_METHOD.NO_RESTRICTION) {
    return {
      products: false,
      productRanges: false
    };
  } else {
    return {
      products: Boolean(state.searchMethod.multiSelect.selectedProductCodes?.length),
      productRanges: Boolean(state.searchMethod.multiSelect.selectedProductRangeCodes?.length)
    };
  }
};

const { reducer, actions } = searchMethodSlice;

export const {
  setSearchMethodSliceToInit,
  setSearchType,
  setListOfProductRanges,
  onProductClick,
  onSelectProductClick,
  onSelectProductRangeClick,
  onProductRangesClick,
  setShowAllProductFamilies,
  setShowAllProductRanges,
  setShowAllPumps,
  deselectSelectedProducts,
  setIsLoading,
  fetchProductRanges,
  setMethodStateFromCPQ
} = actions;

export default reducer;
