import React, { useEffect } from 'react';
import { Outlet, ReactLocation, Router, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import startsWith from 'lodash/startsWith';

import { ROUTER_PATHS } from '@common/constants';
import { setCpqUrlParams } from '@common/slices/cpqSlice/cpqSlice';
import { FIRST_SUB_STEP, STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { setSteps } from '@common/slices/layoutSlice/layoutSlice';
import { selectIsLoggedIn, selectRules } from '@common/slices/userSlice/userSlice';
import AnalyzerLayout from '@components/AnalyzerLayout/AnalyzerLayout';
import useInterceptors from '@hooks/useInterceptors';
import routes from '@navigation/routes/routes';

const location = new ReactLocation();

const PublicRoute = () => {
  const navigate = useNavigate();
  let pathname = window.location.pathname;
  if (pathname !== ROUTER_PATHS.home && !startsWith(pathname, ROUTER_PATHS.secret)) {
    navigate({ to: ROUTER_PATHS.home, replace: false });
  }
  return <Outlet />;
};

const PrivateRoute = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const rules = useSelector(selectRules);

  let pathname = window.location.pathname;

  if (isLoggedIn && pathname === ROUTER_PATHS.admin && !rules?.navBar?.adminEnabled) {
    navigate({ to: ROUTER_PATHS.analyzer, replace: false });
  }

  if (isLoggedIn && pathname === ROUTER_PATHS.home) {
    navigate({ to: ROUTER_PATHS.analyzer, replace: false });
  }

  return (
    <>
      <AnalyzerLayout mainDetails={<Outlet />} />
    </>
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useInterceptors();

  useEffect(() => {
    if (isLoggedIn) {
      const params = new URLSearchParams(window.location.search);

      const QuoteNumber = params.get('QuoteNumber');
      const configurationFlag = params.get('configuration_flag');
      const CpqTableEntryId = params.get('CpqTableEntryId');
      const PartNumber = params.get('PartNumber');
      const ItemID = params.get('ItemID');
      const ItemNumber = params.get('ItemNumber');
      const ItemGUID = params.get('ItemGUID');
      const Host_Url = params.get('Host_Url');
      const CPQ_41PERMISSION02 = params.get('CPQ_41PERMISSION02');

      const optionalProps = {
        ItemID,
        ItemGUID,
        PartNumber,
        CpqTableEntryId,
        ItemNumber,
        CPQ_41PERMISSION02
      };

      if (QuoteNumber) {
        dispatch(
          setCpqUrlParams({
            QuoteNumber,
            configurationFlag,
            Host_Url,
            ...optionalProps
          })
        );

        if (Number(configurationFlag) === 6) {
          dispatch(
            setSteps({
              currentSubStep: { value: FIRST_SUB_STEP },
              currentStep: { value: STEP_VALUES.result.value }
            })
          );
        }
      }
    }
    /* eslint-disable */
  }, []);

  return (
    <Router location={location} routes={routes(Boolean(isLoggedIn))}>
      {isLoggedIn ? (
        <PrivateRoute isLoggedIn={isLoggedIn} />
      ) : (
        <PublicRoute isLoggedIn={isLoggedIn} />
      )}
    </Router>
  );
};

export default AppRouter;
