import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { selectRules } from '@common/slices/userSlice/userSlice';
import InputComponent from '@components/InputComponent';
import UnitAdornment from '@components/UnitAdornment/UnitAdornment';
import styleConfig from '@config/style.config.json';

const DutyPoint = ({
  onCopyClick,
  onDeleteClick,
  addDisabled,
  deleteDisabled,
  dutyPointState = {},
  index,
  capacityOrSpeed,
  dutyPointErrors,
  unitMetrics,
  handleOnChangeDP
}) => {
  const customConfig = styleConfig.ast;
  const userRules = useSelector(selectRules);

  const { name, suctionPressure, dischargePressure, temperature, capacity, speed, viscosity } =
    dutyPointState;

  return (
    <Box className="dutyPointCard">
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('name').defaultMessage('name')}
        type="string"
        value={name ?? ''}
        onChange={(e) => handleOnChangeDP(e, 'name', index)}
        error={Boolean(dutyPointErrors?.name)}
        helperText={
          dutyPointErrors?.name
            ? Localize.get(dutyPointErrors.name).defaultMessage(dutyPointErrors.name) ?? ''
            : ''
        }
      />
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('capacity').defaultMessage('capacity')}
        value={capacityOrSpeed === 'capacity' ? capacity ?? '' : ''}
        disabled={capacityOrSpeed !== 'capacity'}
        onChange={(e) => handleOnChangeDP(e, 'capacity', index)}
        InputProps={{
          endAdornment: <UnitAdornment label={unitMetrics?.capacity?.value?.label ?? ''} />
        }}
        error={Boolean(dutyPointErrors?.capacity)}
        helperText={
          dutyPointErrors?.capacity
            ? Localize.get(dutyPointErrors.capacity).defaultMessage(dutyPointErrors.capacity) ?? ''
            : ''
        }
      />
      {Boolean(userRules?.stepThree?.capacitySwitchEnabled) &&
        customConfig?.operationConditions?.showSpeedField && (
          <InputComponent
            wrapperClassName="textFieldInputContainerBlock"
            label={Localize.get('speed').defaultMessage('speed')}
            value={capacityOrSpeed === 'speed' ? speed ?? '' : ''}
            disabled={capacityOrSpeed !== 'speed'}
            onChange={(e) => handleOnChangeDP(e, 'speed', index)}
            InputProps={{
              endAdornment: (
                <UnitAdornment label={unitMetrics?.rotationalSpeed?.value?.label ?? ''} />
              )
            }}
            error={Boolean(dutyPointErrors?.speed)}
            helperText={
              dutyPointErrors?.speed
                ? Localize.get(dutyPointErrors.speed).defaultMessage(dutyPointErrors.speed) ?? ''
                : ''
            }
          />
        )}
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('inletPressure').defaultMessage('inletPressure')}
        value={suctionPressure ?? ''}
        onChange={(e) => handleOnChangeDP(e, 'suctionPressure', index)}
        InputProps={{
          endAdornment: <UnitAdornment label={unitMetrics?.pressure?.value?.label ?? ''} />
        }}
        error={Boolean(dutyPointErrors?.suctionPressure)}
        helperText={
          dutyPointErrors?.suctionPressure
            ? Localize.get(dutyPointErrors.suctionPressure).defaultMessage(
                dutyPointErrors.suctionPressure
              ) ?? ''
            : ''
        }
      />
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('dischargePressure').defaultMessage('dischargePressure')}
        value={dischargePressure ?? ''}
        onChange={(e) => handleOnChangeDP(e, 'dischargePressure', index)}
        InputProps={{
          endAdornment: <UnitAdornment label={unitMetrics?.pressure?.value?.label ?? ''} />
        }}
        error={Boolean(dutyPointErrors?.dischargePressure)}
        helperText={
          dutyPointErrors?.dischargePressure
            ? Localize.get(dutyPointErrors.dischargePressure).defaultMessage(
                dutyPointErrors.dischargePressure
              ) ?? ''
            : ''
        }
      />
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('temperature').defaultMessage('temperature')}
        value={temperature ?? ''}
        onChange={(e) => handleOnChangeDP(e, 'temperature', index)}
        InputProps={{
          endAdornment: <UnitAdornment label={unitMetrics?.temperature?.value?.label ?? ''} />
        }}
        error={Boolean(dutyPointErrors?.temperature)}
        helperText={
          dutyPointErrors?.temperature
            ? Localize.get(dutyPointErrors.temperature).defaultMessage(
                dutyPointErrors.temperature
              ) ?? ''
            : ''
        }
      />
      <InputComponent
        wrapperClassName="textFieldInputContainerBlockDP"
        label={Localize.get('viscosity').defaultMessage('viscosity')}
        value={viscosity ?? ''}
        onChange={(e) => handleOnChangeDP(e, 'viscosity', index)}
        InputProps={{
          endAdornment: <UnitAdornment label={unitMetrics?.dynamicViscosity?.value?.label ?? ''} />
        }}
        error={Boolean(dutyPointErrors?.viscosity)}
        helperText={
          dutyPointErrors?.viscosity
            ? Localize.get(dutyPointErrors.viscosity).defaultMessage(dutyPointErrors.viscosity) ??
              ''
            : ''
        }
      />
      <Box className="dutyPointButtonsContainer">
        {!deleteDisabled && (
          <Button
            className="dutyPointButton"
            size="small"
            disabled={deleteDisabled}
            color="secondary"
            onClick={() => onDeleteClick(index)}
            startIcon={<DeleteIcon />}
          >
            {Localize.get('remove').defaultMessage('remove')}
          </Button>
        )}
        <Button
          className="dutyPointButton dutyPointCopyButton"
          size="small"
          disabled={addDisabled}
          variant="contained"
          onClick={() =>
            onCopyClick({
              name: '',
              suctionPressure,
              dischargePressure,
              temperature,
              capacity,
              speed,
              viscosity
            })
          }
          startIcon={<ContentCopyRoundedIcon />}
        >
          {Localize.get('duplicate').defaultMessage('duplicate')}
        </Button>
      </Box>
    </Box>
  );
};

export default DutyPoint;
