import { useState, useEffect } from 'react';

import Papa from 'papaparse';

import { checkJSONParams } from '@common/helpers/helpers';
import { ADMIN, IMAGES_PATHS } from '@common/network/ApiPaths';
import {
  UPLOAD_TOAST_INITIAL_STATE,
  UPLOAD_TOAST_SUCCESS_STATE,
  REPLACE_TOAST_SUCCESS_STATE,
  UPLOAD_TOAST_ERROR_STATE,
  DEFAULT_ERROR_MESSAGE,
  LINK_IMAGE_TOAST_SUCCESS_STATE,
  LINK_IMAGE_ERROR_STATE
} from '@common/toastConstants';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';



import { transformHeaders } from './utils';
import { PRODUCT_PROPERTIES_LIST } from '../config/uploadProducts';

const useProductPanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [uploadToastState, setUploadToastState] = useState(
    UPLOAD_TOAST_INITIAL_STATE
  );
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [linkMode, setLinkMode] = useState(undefined);

  const setSelectionModel = model => {
    setSelectedItem(
      listOfProducts.find(({ ProductCode }) => ProductCode === model[0])
    );
  };

  const onCSVUpload = (event, type) => {
    const files = event.target.files;

    if (files) {
      setUploadIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: transformHeaders,
        error: function () {
          setUploadIsLoading(false);
          setUploadToastState(UPLOAD_TOAST_ERROR_STATE);
        },
        complete: async function (results) {
          try {
            let { isInvalid, errorMessage } = checkJSONParams(
              results.data,
              PRODUCT_PROPERTIES_LIST
            );
            if (!isInvalid) {
              const path =
                type === 'upsert'
                  ? ADMIN.UPSERT_PRODUCTS
                  : ADMIN.REPLACE_PRODUCTS;
              await postByPathAndData({
                path,
                data: results.data
              });

              if (type === 'upsert')
                setUploadToastState(UPLOAD_TOAST_SUCCESS_STATE);
              else setUploadToastState(REPLACE_TOAST_SUCCESS_STATE);
              await fetchProducts();
            } else {
              setUploadToastState({
                ...UPLOAD_TOAST_ERROR_STATE,
                message: errorMessage || DEFAULT_ERROR_MESSAGE
              });
            }
          } catch ({ response }) {
            setUploadToastState({
              ...UPLOAD_TOAST_ERROR_STATE,
              message: response?.data?.message || DEFAULT_ERROR_MESSAGE
            });
          } finally {
            event.target.value = '';
            setUploadIsLoading(false);
          }
        }
      });
    }
  };

  const setUploadToastToInitial = () => {
    setUploadToastState(UPLOAD_TOAST_INITIAL_STATE);
  };

  const onLinkSaveClick = async (productId, ImageId) => {
    try {
      const { data } = await postByPathAndData({
        path: IMAGES_PATHS.LINK_IMAGE,
        data: {
          table_name: 'Product',
          table_key: 'ProductCode',
          table_value: productId,
          ImageId
        }
      });
      if (data) {
        setSelectedItem(data);
        setListOfProducts(prevState =>
          prevState.map(item => {
            if (item.ProductCode === data.ProductCode) {
              item.ImageId = data.ImageId;
            }
            return item;
          })
        );
        setUploadToastState(LINK_IMAGE_TOAST_SUCCESS_STATE);
      } else {
        setUploadToastState(LINK_IMAGE_ERROR_STATE);
      }

      setLinkMode(undefined);
    } catch (err) {
      setUploadToastState(LINK_IMAGE_ERROR_STATE);
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      let { data } = await getByPathAndParams({
        path: ADMIN.GET_PRODUCTS
      });
      setListOfProducts(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return [
    listOfProducts,
    isLoading,
    onCSVUpload,
    uploadIsLoading,
    uploadToastState,
    setUploadToastToInitial,
    selectedItem,
    setSelectionModel,
    setLinkMode,
    linkMode,
    onLinkSaveClick
  ];
};

export default useProductPanel;
