import get from 'lodash/get';

import { createListenerMiddleware } from '@reduxjs/toolkit';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { getMetricSystemValues } from '@common/helpers/helpers';
import { GENERAL_ATTRIBUTES_PATHS, ATTRIBUTE_POSITIONS } from '@common/network/ApiPaths';
import { setCpqData, setCpqErrors, setParsedValues } from '@common/slices/cpqSlice/cpqSlice';
import { setFluidStateWithoutListener } from '@common/slices/fluidSlice/fluidSlice';
import { setGeneralAttributesWIthoutListener } from '@common/slices/industrySlice/industrySlice';
import { setDPStateFromCPQ } from '@common/slices/operationCondition/operationConditionSlice';
import { fetchPumps } from '@common/slices/resultListSlice/resultListSlice';
import { setMethodStateFromCPQ } from '@common/slices/searchMethodSlice/searchMethodSlice';
import { setSelectPumpFromCPQ } from '@common/slices/selectPumpSlice/selectPumpSlice';
import { status as ERROR_STATUS } from '@common/toastConstants';
import {
  getUnitsFormatted,
  checkFluidParams,
  checkDutyPointParams,
  checkIOAValues,
  mapCalcPumps
} from '@pages/Analyzer/hooks/utils';
import { mapParametersFromCPQToAST } from '@pages/Analyzer/utils/fromCpqToAstUtils';
import { getAllByPathList } from '@services/BaseApi';

import {
  getMappingParams,
  getProductConfiguration,
  getPumpByCode,
  getPumpsByRange,
  getIndustryState,
  getFluidState,
  getSelectedPumpState,
  getOperationConditionState,
  getSearchMethodState
} from './utils';

const cpqSliceMiddlewareListener = createListenerMiddleware();

cpqSliceMiddlewareListener.startListening({
  type: 'cpq/setCpqUrlParams',
  effect: async (action, listenerApi) => {
    const user = listenerApi.getState().user;
    const language = user.language;
    const token = user.token;

    const { configurationFlag, CpqTableEntryId, QuoteNumber, Host_Url } = action.payload;

    try {
      const cpqMappingData = token && (await getMappingParams());

      if (cpqMappingData) {
        if (Boolean(configurationFlag) && Boolean(QuoteNumber)) {
          let cpqProductData = undefined;

          // calling units and attribute position data
          let unitsAndPositions = await getAllByPathList(
            [GENERAL_ATTRIBUTES_PATHS.GET_UNITS, ATTRIBUTE_POSITIONS.GET_POSITIONS],
            language
          );

          // ******* reconfigure start
          if (Boolean(CpqTableEntryId) && Boolean(Host_Url)) {
            // calling reconfigure context from CPQ
            const cpqResponseWithData = await getProductConfiguration(Host_Url, CpqTableEntryId);

            if (cpqResponseWithData?.data?.Selections) {
              const parsedData = JSON.parse(cpqResponseWithData?.data?.Selections);
              delete parsedData.ItemGUID;
              delete parsedData.ItemID;
              delete parsedData.ItemNumber;
              if (parsedData?.PartNumber) delete parsedData.PartNumber;
              if (parsedData?.configuration_flag) delete parsedData.configuration_flag;

              listenerApi.dispatch(setParsedValues(parsedData));

              // mapping reconfigure data from CPQ to AST state
              const {
                firstStateData,
                secondStateData,
                unitsStateData,
                dutyPointsStateData,
                productStateData,
                thirdStateData
              } = mapParametersFromCPQToAST(parsedData, cpqMappingData);

              const unitMetrics = getUnitsFormatted(
                get(unitsAndPositions, '[0].data', {}),
                true,
                getMetricSystemValues('CUSTOM'),
                unitsStateData
              );

              const attributePositions = get(unitsAndPositions, '[1].data', []);

              const providedFirstState = await getIndustryState(
                firstStateData,
                language,
                attributePositions
              );

              const providedSecondState = await getFluidState(
                firstStateData,
                secondStateData,
                language,
                attributePositions
              );

              // set industry state
              listenerApi.dispatch(setGeneralAttributesWIthoutListener(providedFirstState));
              // set industry state

              // set fluid state
              listenerApi.dispatch(setFluidStateWithoutListener(providedSecondState));
              // set fluid state

              // set operationCondition state
              const operationConditionState = getOperationConditionState(
                dutyPointsStateData,
                thirdStateData,
                DEFAULT_VALUES
              );

              listenerApi.dispatch(setDPStateFromCPQ(operationConditionState));
              // set operationCondition state

              // set methodSlice state
              let { productRangeId, productId } = productStateData;

              const searchMethodState = await getSearchMethodState(
                productId,
                productRangeId,
                SEARCH_METHOD
              );

              listenerApi.dispatch(setMethodStateFromCPQ(searchMethodState));
              // set methodSlice state

              // get product state
              const dynamicIOAFieldsValues = checkIOAValues(
                providedFirstState?.dynamicIOAFieldsValues
              );
              const fluidParams = checkFluidParams(
                providedSecondState.dynamicFluidFieldsValues,
                unitMetrics,
                providedSecondState.dynamicFluidFields
              );
              const dutyPointParams = checkDutyPointParams(dutyPointsStateData, unitMetrics);

              let responseProductCalculated = await getPumpByCode(
                productStateData.selectedPumpId,
                dynamicIOAFieldsValues,
                fluidParams,
                dutyPointParams,
                thirdStateData,
                dutyPointsStateData,
                unitMetrics
              );

              let productCalculated = mapCalcPumps(responseProductCalculated.data);
              // get product state

              // get other citeria list
              const otherCiteriaPumps = await getPumpsByRange(
                thirdStateData,
                dutyPointsStateData,
                unitMetrics,
                dynamicIOAFieldsValues,
                fluidParams,
                dutyPointParams,
                productRangeId
              );

              // set select pump
              const selectedPethodState = getSelectedPumpState(
                productCalculated,
                otherCiteriaPumps,
                productId,
                productRangeId
              );

              listenerApi.dispatch(setSelectPumpFromCPQ(selectedPethodState));
              // set select pump

              // set result list list
              listenerApi.dispatch(fetchPumps());
              // set result list list

              cpqProductData = {
                firstStateData,
                secondStateData,
                unitsStateData,
                dutyPointsStateData,
                productStateData,
                thirdStateData
              };
            }
          }
          // ******* reconfigure end

          listenerApi.dispatch(
            setCpqData({
              cpqProductData,
              cpqMappingData
            })
          );
        }
      }
    } catch (err) {
      console.log('err: ', err);
      listenerApi.dispatch(
        setCpqErrors({
          open: true,
          status: ERROR_STATUS.error,
          errors: err?.errors,
          message: 'toastErrorCpqToAst'
        })
      );
    }
  }
});

export { cpqSliceMiddlewareListener };
