import React from 'react';
import Localize from 'react-intl-universal';

import TabList from '@mui/lab/TabList';

import AdminSubTab from '@components/AdminSubTab/AdminSubTab';

const DataTabHeader = ({ handleTabChange, dataPanelTabOptions, disabledOptions }) => {
  return (
    <TabList
      className="mainTabHeader productTabContainer productDataTabHeader"
      onChange={handleTabChange}
    >
      <AdminSubTab
        value={dataPanelTabOptions.optionOne}
        label={Localize.get('generalAttributes').defaultMessage('generalAttributes')}
        disabled={disabledOptions?.optionOne}
      />
      <AdminSubTab
        value={dataPanelTabOptions.optionTwo}
        label={Localize.get('fluidAttributes').defaultMessage('fluidAttributes')}
        disabled={disabledOptions?.optionTwo}
      />
      <AdminSubTab
        value={dataPanelTabOptions.optionThree}
        label={Localize.get('productsTab').defaultMessage('productsTab')}
        disabled={disabledOptions?.optionThree}
      />
      <AdminSubTab
        value={dataPanelTabOptions.optionFour}
        label={Localize.get('usersTab').defaultMessage('usersTab')}
        disabled={disabledOptions?.optionFour}
      />
    </TabList>
  );
};

export default DataTabHeader;
