import React from 'react';

import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

const AdminSubTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    height: '30px',
    '&.MuiButtonBase-root': {
      minHeight: '0',
      minWidth: '0',
      fontSize: '0.8rem',
      textTransform: 'none',
      backgroundColor: 'white',
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: theme.palette.primary.main
      }
    }
  })
);

export default AdminSubTab;
