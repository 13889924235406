export default [
  {
    field: 'StatorType',
    headerName: 'StatorType',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Geometry',
    headerName: 'Geometry',
    width: 130,
    align: 'left',
    editable: false
  },
  {
    field: 'GeometryType',
    headerName: 'GeometryType',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ImageId',
    headerName: 'ImageId',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
