import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import { DEFAULT_VALUES, SEARCH_METHOD, SETTINGS_DIALOG } from '@common/constants';
import { FLUID_ATTRIBUTES_PATHS } from '@common/network/ApiPaths';
import {
  selectDynamicFluidFieldsValues,
  selectDynamicFluidFields,
  selectFluidName,
  selectMediumType,
  selectMedium,
  setMediumType,
  setMedium,
  setFluidName,
  setFluidAttributeValue,
  setSelectAttributeValue,
  selectFluidAttributesNextAllowed,
  setQuickSelectionOfAttributes
} from '@common/slices/fluidSlice/fluidSlice';
import { selectIndustryOfApplication } from '@common/slices/industrySlice/industrySlice';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { selectCurrentSubStep, setCurrentSubStep } from '@common/slices/layoutSlice/layoutSlice';
import { selectExpertNeeded } from '@common/slices/operationCondition/operationConditionSlice';
import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import {
  selectSearchType,
  setSearchMethodSliceToInit
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectSessionUnitMetrics } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectLanguageSession, setShowSettingsDialog } from '@common/slices/userSlice/userSlice';
import AstSelector from '@components/AstSelector/AstSelector';
import AttributeInput from '@components/AttributeInput';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import InputComponent from '@components/InputComponent';
import InputComponentWrapper from '@components/InputComponent/components/InputComponentWrapper';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import StringToElement from '@components/StringToElement';
import { insertPathVariables } from '@services/BaseApi';

import AnalyzerFooter from '../StepperComponent/AnalyzerFooter';

const PARTICLE_CONTENT_NONE_ID = 32;

const isDisabled = (nextAllowed, activeSubStep, mediumType, medium) => {
  return (
    (activeSubStep.value === STEP_VALUES.fluidAttributesSub.value && !nextAllowed) ||
    (activeSubStep.value === STEP_VALUES.fluidFamily.value && !mediumType?.id) ||
    (activeSubStep.value === STEP_VALUES.fluid.value && !medium?.id)
  );
};

const FluidAttributes = () => {
  const dispatch = useDispatch();
  const activeSubStep = useSelector(selectCurrentSubStep);

  const language = useSelector(selectLanguageSession);
  const industry = useSelector(selectIndustryOfApplication);
  const unitMetrics = useSelector(selectSessionUnitMetrics);

  const mediumType = useSelector(selectMediumType);
  const medium = useSelector(selectMedium);
  const dynamicFluidFields = useSelector(selectDynamicFluidFields);
  const dynamicFluidFieldsValues = useSelector(selectDynamicFluidFieldsValues);
  const fluidName = useSelector(selectFluidName);
  const nextAllowed = useSelector(selectFluidAttributesNextAllowed);
  const selectedSearchCriteria = useSelector(selectSearchType);

  const expertNeeded = useSelector(selectExpertNeeded);

  const [anchorEl, setAnchorEl] = useState(null);

  const onChangeUnitsClick = () => {
    dispatch(setShowSettingsDialog(SETTINGS_DIALOG.SESSION));
  };

  const hocFluidMandatoryFields = (selectionType, id) => {
    switch (selectionType) {
      case 'mediumType':
        dispatch(setMediumType(id));
        dispatch(setCurrentSubStep(STEP_VALUES.fluid));
        break;
      case 'medium':
        dispatch(setMedium(id));
        dispatch(setCurrentSubStep(STEP_VALUES.fluidAttributesSub));
        break;
      case 'fluidName':
        dispatch(setFluidName(id));
        break;
      default:
        break;
    }

    if (selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
      dispatch(setSearchMethodSliceToInit());
      dispatch(setResultListToInit());
    }
  };

  const hocFluidDynamicValues = (fieldType, fieldName, event, type_id, column) => {
    if (fieldType === 'checkbox') {
      dispatch(
        setFluidAttributeValue({
          fieldName,
          value: event.target.checked
        })
      );
    } else {
      if (type_id === 3) {
        dispatch(
          setSelectAttributeValue({
            fieldName,
            value: event.target.value,
            column
          })
        );

        if (fieldName === 'ParticleContent' && event.target.value === PARTICLE_CONTENT_NONE_ID) {
          dispatch(
            setQuickSelectionOfAttributes({
              SolidsSize: '0'
            })
          );
        }
      } else {
        dispatch(
          setFluidAttributeValue({
            fieldName,
            value: event.target.value
          })
        );
      }
    }

    if (selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
      dispatch(setSearchMethodSliceToInit());
      dispatch(setResultListToInit());
    }
  };

  const onHintClick = (event, hint) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl({ anchorRef: event.currentTarget, hint });
  };

  const onHintClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl?.anchorRef);
  const id = open ? DEFAULT_VALUES.POP_OVER_ID : undefined;

  const phInputs = dynamicFluidFields.firstColumn.filter(
    ({ key }) => key === 'PHValueMax' || key === 'PHValueMin'
  );

  let isErrorPHValueMin =
    dynamicFluidFieldsValues?.PHValueMin === '' ||
    dynamicFluidFieldsValues?.PHValueMin === undefined ||
    dynamicFluidFieldsValues?.PHValueMin === null;

  let isErrorPHValueMax =
    dynamicFluidFieldsValues?.PHValueMax === '' ||
    dynamicFluidFieldsValues?.PHValueMax === undefined ||
    dynamicFluidFieldsValues?.PHValueMax === null;

  return (
    <>
      <SalesCloudContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl?.anchorRef}
          onClose={onHintClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box className="hintValue">
            <StringToElement htmlString={anchorEl?.hint ?? ''} />
          </Box>
        </Popover>
        {
          {
            [STEP_VALUES.fluidFamily.value]: (
              <AstSelector
                key={STEP_VALUES.fluidFamily.value}
                selectionType="mediumType"
                title={Localize.get('pleaseSelectFluidType').defaultMessage(
                  'pleaseSelectFluidType'
                )}
                description={Localize.get('fluidTypeDescription').defaultMessage(
                  'fluidTypeDescription'
                )}
                searchTitle={Localize.get('searchFluidFamilies').defaultMessage(
                  'searchFluidFamilies'
                )}
                onClick={hocFluidMandatoryFields}
                path={insertPathVariables(FLUID_ATTRIBUTES_PATHS.GET_MEDIUM_TYPES, {
                  industryId: industry?.id
                })}
                language={language}
              />
            ),
            [STEP_VALUES.fluid.value]: (
              <AstSelector
                key={STEP_VALUES.fluid.value}
                selectionType="medium"
                title={Localize.get('pleaseSelectFluid').defaultMessage('pleaseSelectFluid')}
                description={Localize.get('fluidDescription').defaultMessage('fluidDescription')}
                searchTitle={Localize.get('searchFluids').defaultMessage('searchFluids')}
                onClick={hocFluidMandatoryFields}
                path={insertPathVariables(FLUID_ATTRIBUTES_PATHS.GET_MEDIUMS, {
                  industryId: industry?.id,
                  mediumTypeId: mediumType?.id
                })}
                language={language}
              />
            ),
            [STEP_VALUES.fluidAttributesSub.value]: (
              <Box>
                <DetailsTitle
                  onChangeUnitsClick={onChangeUnitsClick}
                  title={Localize.get('pleaseProvideInfoAboutFluidAttributes').defaultMessage(
                    'pleaseProvideInfoAboutFluidAttributes'
                  )}
                  description={Localize.get('fluidAttributesDescription').defaultMessage(
                    'fluidAttributesDescription'
                  )}
                  showChangeUnitsButton={true}
                />

                <Box className="attributeList">
                  <InputComponent
                    label={Localize.get('fluidName').defaultMessage('fluidName')}
                    type="string"
                    value={fluidName}
                    onChange={(e) => hocFluidMandatoryFields('fluidName', e.target.value)}
                  />
                  {phInputs?.length === 2 && (
                    <InputComponentWrapper
                      wrapperClassName="selectContainer"
                      label={Localize.get('phValue').defaultMessage('phValue')}
                    >
                      <Box className="commonInputContainer">
                        <InputComponent
                          wrapperClassName="textFieldInputContainerBlock"
                          key="PHValueMin"
                          type="number"
                          onChange={(e) => hocFluidDynamicValues('input', 'PHValueMin', e, 1, 2)}
                          placeholder={Localize.get('min').defaultMessage('min')}
                          value={dynamicFluidFieldsValues?.PHValueMin}
                          error={isErrorPHValueMin}
                          helperText={
                            isErrorPHValueMin &&
                            Localize.get('requiredField').defaultMessage('requiredField')
                          }
                        />
                        <InputComponent
                          wrapperClassName="textFieldInputContainerBlock"
                          key="PHValueMax"
                          type="number"
                          onChange={(e) => hocFluidDynamicValues('input', 'PHValueMax', e, 1, 2)}
                          value={dynamicFluidFieldsValues?.PHValueMax}
                          placeholder={Localize.get('max').defaultMessage('max')}
                          error={isErrorPHValueMax}
                          helperText={
                            isErrorPHValueMax &&
                            Localize.get('requiredField').defaultMessage('requiredField')
                          }
                        />
                      </Box>
                    </InputComponentWrapper>
                  )}
                  {dynamicFluidFields.firstColumn
                    .filter(({ key }) => key !== 'PHValueMax' && key !== 'PHValueMin')
                    .map((dynamicField, index) => (
                      <AttributeInput
                        key={index}
                        dynamicField={dynamicField}
                        onHintClick={onHintClick}
                        onChange={hocFluidDynamicValues}
                        values={dynamicFluidFieldsValues}
                        unitMetrics={unitMetrics}
                        column="firstColumn"
                      />
                    ))}
                  {dynamicFluidFields.secondColumn.map((dynamicField, index) => (
                    <AttributeInput
                      key={index}
                      dynamicField={dynamicField}
                      onHintClick={onHintClick}
                      onChange={hocFluidDynamicValues}
                      values={dynamicFluidFieldsValues}
                      unitMetrics={unitMetrics}
                      column="secondColumn"
                    />
                  ))}
                  {dynamicFluidFields.thirdColumn.map((dynamicField, index) => (
                    <AttributeInput
                      key={index}
                      dynamicField={dynamicField}
                      onHintClick={onHintClick}
                      onChange={hocFluidDynamicValues}
                      values={dynamicFluidFieldsValues}
                      unitMetrics={unitMetrics}
                      column="thirdColumn"
                    />
                  ))}
                </Box>
              </Box>
            )
          }[activeSubStep.value]
        }
      </SalesCloudContainer>

      <AnalyzerFooter
        nextDisabled={isDisabled(nextAllowed?.fluidAttributes, activeSubStep, mediumType, medium)}
        expertNeeded={expertNeeded}
      />
    </>
  );
};

export default FluidAttributes;
