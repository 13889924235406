import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import ceil from 'lodash/ceil';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { DEFAULT_VALUES } from '@common/constants';
import StringToElement from '@components/StringToElement';
import { postByPathAndData } from '@services/BaseApi';

import { GRAPHS_ACCESSORS } from '../../config/graphConstants';

const Graphs = ({
  graphData,
  capacityChartRef,
  powerChartRef,
  rsvCartRef,
  pdfDialogOpen,
  unitMetrics,
  titleForGraphs,
  selectedPump
}) => {
  const [graph, setGraph] = useState();
  const [graphIsLoading, setGraphIsLoading] = useState();

  const {
    speedCapacityList = [],
    speedPowerList = [],
    speedNPSHrList = [],
    rubbingSlidingVelocity = 0
  } = graphData;

  useEffect(() => {
    const graphSize = {
      width: 600,
      height: 430
    };

    const fetchGraphData = async () => {
      try {
        setGraphIsLoading(true);
        setGraph(undefined);
        let { data } = await postByPathAndData({
          path: '/get-performance-graph',
          data: {
            capacityGraph: {
              xAxisDataKey: GRAPHS_ACCESSORS.X_AXIS_DATA_KEY,
              data: speedCapacityList,
              xLabel: Localize.get('speedRpm').defaultMessage('speedRpm'),
              yLabel: `${Localize.get('capacity').defaultMessage('capacity')} [${
                unitMetrics?.capacity?.value?.label ?? ''
              }]`,
              xDomain: [0, (dataMax) => ceil(dataMax)],
              yDomain: [0, (dataMax) => ceil(dataMax / 10) * 10],
              lineDataKey: GRAPHS_ACCESSORS.CAPACITY_LINE_DATA_KEY,
              titleForGraphs,
              lineChartWidth: graphSize.width,
              lineChartHeight: graphSize.height
            },
            powerGraph: {
              xAxisDataKey: GRAPHS_ACCESSORS.X_AXIS_DATA_KEY,
              data: speedPowerList,
              xLabel: Localize.get('speedRpm').defaultMessage('speedRpm'),
              yLabel: `${Localize.get('power').defaultMessage('power')} [${
                unitMetrics?.power?.value?.label ?? ''
              }]`,
              xDomain: [0, (dataMax) => ceil(dataMax)],
              yDomain: [0, (dataMax) => ceil(dataMax)],
              lineDataKey: GRAPHS_ACCESSORS.POWER_LINE_DATA_KEY,
              titleForGraphs,
              lineChartWidth: graphSize.width,
              lineChartHeight: graphSize.height
            },
            torqueGraph: {
              xAxisDataKey: GRAPHS_ACCESSORS.X_AXIS_DATA_KEY,
              data: speedPowerList,
              xLabel: Localize.get('speedRpm').defaultMessage('speedRpm'),
              yLabel: `${Localize.get('torque').defaultMessage('torque')} [${
                unitMetrics?.torque?.value?.label ?? ''
              }]`,
              xDomain: [0, (dataMax) => ceil(dataMax)],
              yDomain: [0, (dataMax) => ceil(dataMax / 10) * 10],
              lineDataKey: GRAPHS_ACCESSORS.TORQUE_LINE_DATA_KEY,
              titleForGraphs,
              lineChartWidth: graphSize.width,
              lineChartHeight: graphSize.height
            },
            npshrGraph: {
              xAxisDataKey: GRAPHS_ACCESSORS.X_AXIS_DATA_KEY,
              data: speedNPSHrList,
              xLabel: Localize.get('speedRpm').defaultMessage('speedRpm'),
              yLabel: `NPSHr [${unitMetrics?.npshr?.value?.label ?? ''}]`,
              xDomain: [0, (dataMax) => ceil(dataMax)],
              yDomain: [0, (dataMax) => ceil(dataMax)],
              lineDataKey: GRAPHS_ACCESSORS.NPSHR_LINE_DATA_KEY,
              titleForGraphs: 'pdf',
              lineChartWidth: graphSize.width,
              lineChartHeight: graphSize.height
            },
            rsvGraph: {
              data: [
                { y: 0, x: 0 },
                { y: 0, x: rubbingSlidingVelocity }
              ],
              xLabel: `vgm [${unitMetrics?.slidingSpeed?.value?.label ?? ''}]`,
              graphForPdf: 60,
              titleForGraphs: 'pdf'
            },
            statorImageData: {
              statorType: selectedPump.statorType,
              geometry: selectedPump.geometry,
              geometryType: selectedPump.geometryType
            }
          }
        });
        setGraph({ data, graphLoaded: true });
      } catch (err) {
        console.log('err: ', err);
      } finally {
        setGraphIsLoading(false);
      }
    };

    fetchGraphData();
    /* eslint-disable */
  }, [graphData]);

  return (
    <Box className="cardList">
      {graphIsLoading && (
        <div className="graphsLoaderContainer">
          <CircularProgress className="graphsLoader" title="Graphs are loading" />
        </div>
      )}

      {graph?.graphLoaded && (
        <>
          <Box className="chartBox">
            <Box ref={capacityChartRef}>
              <StringToElement htmlString={graph?.data?.capacityGraphPdf} />
            </Box>
          </Box>
          <Box className="chartBox">
            <Box ref={powerChartRef}>
              <StringToElement htmlString={graph?.data?.powerGraph} />
            </Box>
          </Box>
          <Box className="chartBox">
            <StringToElement htmlString={graph?.data?.torqueGraph} />
          </Box>
          <Box className="chartBox">
            <StringToElement htmlString={graph?.data?.npshrGraph} />
          </Box>
        </>
      )}

      <Box
        sx={{
          display: pdfDialogOpen ? DEFAULT_VALUES.DISPLAY.BLOCK : DEFAULT_VALUES.DISPLAY.NONE
        }}
      >
        <Box className="chartBoxPdf" ref={rsvCartRef}>
          <StringToElement htmlString={graph?.data?.rsvGraph} />
        </Box>
      </Box>
    </Box>
  );
};
export default Graphs;
