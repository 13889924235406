import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';

import { API } from '@common/constants';
import { filterEmptyStrings } from '@common/helpers/helpers';
import { baseApiInstance } from '@common/libs/myAxios';

const getDefaultOptions = ({
  baseURL = API.API_URL,
  headers = {
    'Content-Type': 'application/json'
  },
  method = 'get',
  params = undefined,
  data = undefined,
  url = undefined,
  responseType = undefined,
  ...rest
}) => {
  return {
    headers,
    baseURL,
    method,
    responseType,
    params,
    data,
    url,
    ...rest
  };
};
// pathVariables - is object that contains key value pair that will replace :key name in path (as variable)
export const insertPathVariables = (path, pathVariables) => {
  if (!pathVariables) {
    return path;
  }
  templateSettings.interpolate = /:([A-z]*)/g;
  // replacing the ':param' with param data
  return template(path)(pathVariables);
};

// params - is object with key value pairs that will be serialized to query string
export const getByPathAndParams = ({ path = '/', params, pathVariables, headers } = {}) => {
  return baseApiInstance(
    getDefaultOptions({
      params: filterEmptyStrings(params),
      url: insertPathVariables(path, pathVariables),
      headers
    })
  );
};
// data - is value that will be sent as payload
export const putByPathAndData = ({ path = '/', data, pathVariables } = {}) => {
  return baseApiInstance(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      data,
      method: 'put'
    })
  );
};

export const postByPathAndData = ({
  path = '/',
  data,
  pathVariables,
  params,
  headers,
  baseURL,
  ...rest
} = {}) => {
  return baseApiInstance(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      params: filterEmptyStrings(params),
      data,
      method: 'post',
      headers,
      baseURL,
      ...rest
    })
  );
};

export const deleteByPath = ({ path = '/', pathVariables } = {}) => {
  return baseApiInstance(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      method: 'delete'
    })
  );
};

// configList - is array of path strings
export const getAllByPathList = async (configList = [], language) => {
  const response = [];
  configList.map((path) => {
    response.push(getByPathAndParams({ path, params: { language_id: language } }));
  });
  return await Promise.all(response);
};
