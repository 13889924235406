import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import HintIcon from '@components/HintIcon';

const InputComponentWrapper = ({
  label,
  hint = undefined,
  wrapperClassName = 'textFieldInputContainer',
  sxLabelWrapper,
  onHintClick = () => {},
  children
}) => {
  const matches = useMediaQuery('(min-width:1150px)');
  return (
    <Box className={wrapperClassName}>
      {label && (
        <Box className='labelContainer' sx={sxLabelWrapper}>
          <Typography className='selectLabel'>{label}</Typography>
          {Boolean(hint) && !matches && (
            <HintIcon
              color='primary'
              onClick={event => onHintClick(event, hint)}
            />
          )}
        </Box>
      )}
      {children}
      {Boolean(hint) && matches && (
        <Box className='hintContainer'>
          <HintIcon color='primary' className='hintIcon' />
          <Typography className='hintValueSide'>{hint}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default InputComponentWrapper;
