import Localize from 'react-intl-universal';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

const VersionList = ({ moment, versionList }) => {
  return (
    <Box sx={{ px: 1, minWidth: '650px', width: '53vw', height: '550px' }}>
      <Typography
        sx={{
          textAlign: 'left',
          marginBottom: 1,
          marginLeft: 3,
          marginTop: 3
        }}
      >
        <b>{Localize.get('history').defaultMessage('history')}</b>
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              borderRight: '1px solid rgba(224, 224, 224, 1)'
            }
          }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: '20%' }}>
                <b>{Localize.get('date').defaultMessage('date')}</b>
              </TableCell>
              <TableCell align="center" style={{ width: '5%' }}>
                <b>{Localize.get('sprint').defaultMessage('sprint')}</b>
              </TableCell>
              <TableCell align="center" style={{ width: '5%' }}>
                <b>{Localize.get('version').defaultMessage('version')}</b>
              </TableCell>
              <TableCell
                align="center"
                // style={{ width: '50%' }}
              >
                <b>{Localize.get('info').defaultMessage('info')}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versionList.map(({ date, version, sprint, info }, index) => (
              <TableRow key={index}>
                <TableCell align="center">{moment(date).format('LL')}</TableCell>
                <TableCell align="center">{sprint}</TableCell>
                <TableCell align="center">{version}</TableCell>
                <TableCell align="left">{info}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default VersionList;
