import React from 'react';

import { Input } from '@mui/material';

import StyledButton from '@components/StyledButton/StyledButton';

const UploadButton = ({
  children,
  onChange = () => {},
  content = '',
  id = 'upload-button-file',
  sx = { mr: 1, width: 'fit-content', whiteSpace: 'nowrap' },
  ...rest
}) => {
  return (
    <label htmlFor={id}>
      <Input
        accept='.csv'
        id={id}
        multiple
        type='file'
        sx={{ display: 'none' }}
        onChange={onChange}
      />
      <StyledButton sx={sx} {...rest}>
        {children || content}
      </StyledButton>
    </label>
  );
};

export default UploadButton;
