import Localize from 'react-intl-universal';

import Rating from '@mui/material/Rating';

import ListErrorIcon from '../components/ListErrorIcon/ListErrorIcon';

const getValueWithUnit = (value, unit = '') => {
  return value !== 'Infinity' &&
    value !== '-Infinity' &&
    typeof parseFloat(value) === 'number' &&
    !isNaN(parseFloat(value))
    ? `${value} ${unit}`
    : null;
};

const getValueOrNull = (value) => {
  return value !== 'Infinity' &&
    value !== '-Infinity' &&
    typeof parseFloat(value) === 'number' &&
    !isNaN(parseFloat(value))
    ? value
    : null;
};

const MyRating = ({ name, value }) => (
  <Rating
    name={name}
    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
    value={value}
    precision={0.5}
    readOnly
  />
);

const resultListConfig = (unitMetrics) => [
  {
    field: 'failedPumpCalculationRestrictions',
    headerName: '',
    width: 50,
    sortable: false,
    editable: false,
    filterable: true,
    align: 'left',
    renderCell: (params) => {
      return (
        <div className="resultListErrorIconContainer">
          {params.row.failedPumpCalculationRestrictions?.length ||
          params.row.failedRestrictions?.length ? (
            <ListErrorIcon params={params} />
          ) : (
            <div />
          )}
        </div>
      );
    }
  },
  {
    field: 'id',
    headerName: Localize.get('typeMaterialElastomerHeader').defaultMessage(
      'typeMaterialElastomerHeader'
    ),
    width: 260,
    editable: false,
    align: 'left',
    valueGetter: (params) =>
      `${params?.row?.typeCode ?? ''} ${params?.row?.material ?? ''} ${
        params?.row?.materialElastomer ?? ''
      }`
  },
  {
    field: 'statorMaterial',
    headerName: Localize.get('statorQualityHeader').defaultMessage('statorQualityHeader'),
    width: 175,
    editable: false,
    align: 'left',
    valueGetter: (params) => params?.value?.toUpperCase()
  },
  {
    field: 'statorType',
    headerName: Localize.get('statorTypeHeader').defaultMessage('statorTypeHeader'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => params?.value
  },
  {
    field: 'deliveryTime',
    headerName: Localize.get('deliveryTimeHeader').defaultMessage('deliveryTimeHeader'),
    width: 110,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.deliveryTime),
    valueFormatter: (params) =>
      getValueWithUnit(params?.value, Localize.get('workingDays').defaultMessage('workingDays'))
  },
  {
    field: 'price',
    headerName: Localize.get('priceIndexHeader').defaultMessage('priceIndexHeader'),
    width: 110,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => params?.value
  },
  {
    field: 'minRotationSpeed',
    headerName: Localize.get('minRotationSpeed').defaultMessage('minRotationSpeed'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.minRotationSpeed),
    valueFormatter: (params) =>
      getValueWithUnit(params?.value, unitMetrics?.rotationalSpeed?.value?.label)
  },
  {
    field: 'rotationSpeedMax',
    headerName: Localize.get('rotationSpeedHeader').defaultMessage('rotationSpeedHeader'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.rotationSpeedMax),
    valueFormatter: (params) =>
      getValueWithUnit(params?.value, unitMetrics?.rotationalSpeed?.value?.label)
  },
  {
    field: 'torqueMax',
    headerName: Localize.get('torqueMaxHeader').defaultMessage('torqueMaxHeader'),
    width: 110,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.torqueMax),
    valueFormatter: (params) => getValueWithUnit(params?.value, unitMetrics?.torque?.value?.label)
  },
  {
    field: 'powerRequiredMax',
    headerName: Localize.get('powerRequiredHeader').defaultMessage('powerRequiredHeader'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.powerRequiredMax),
    valueFormatter: (params) => getValueWithUnit(params?.value, unitMetrics?.power?.value?.label)
  },
  {
    field: 'startingTorque',
    headerName: Localize.get('startingTorque').defaultMessage('startingTorque'),
    width: 112,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.startingTorque),
    valueFormatter: (params) => getValueWithUnit(params?.value, unitMetrics?.torque?.value?.label)
  },
  {
    field: 'overallEfficiencyMin',
    headerName: Localize.get('energyEfficiencyMinHeader').defaultMessage(
      'energyEfficiencyMinHeader'
    ),
    width: 160,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.overallEfficiencyMin),
    valueFormatter: (params) => getValueWithUnit(params?.value, '%')
  },
  {
    field: 'overallEfficiencyMax',
    headerName: Localize.get('energyEfficiencyMaxHeader').defaultMessage(
      'energyEfficiencyMaxHeader'
    ),
    width: 160,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.overallEfficiencyMax),
    valueFormatter: (params) => getValueWithUnit(params?.value, '%')
  },
  {
    field: 'meanTimeBetweenFailures',
    headerName: Localize.get('reliabilityHeader').defaultMessage('reliabilityHeader'),
    width: 112,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => params?.value
  },
  {
    field: 'maxSlidingVelocity',
    headerName: Localize.get('maxSlidingVelocity').defaultMessage('maxSlidingVelocity'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.maxSlidingVelocity),
    valueFormatter: (params) =>
      getValueWithUnit(params?.value, unitMetrics?.slidingSpeed?.value?.label)
  },
  {
    field: 'maxNpshr',
    headerName: Localize.get('maxNpshr').defaultMessage('maxNpshr'),
    width: 112,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => getValueOrNull(params?.row?.maxNpshr),
    valueFormatter: (params) => getValueWithUnit(params?.value, unitMetrics?.npshr?.value?.label)
  },
  {
    field: 'maintenanceCost',
    headerName: Localize.get('maintenanceCostHeader').defaultMessage('maintenanceCostHeader'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => params?.value
  },
  {
    field: 'tco',
    headerName: Localize.get('tcoEfficiencyHeader').defaultMessage('tcoEfficiencyHeader'),
    width: 150,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => params?.value
  },
  {
    field: 'solidsHandling',
    headerName: Localize.get('solidsHandlingCapabilityHeader').defaultMessage(
      'solidsHandlingCapabilityHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="solidsHandling" value={params.value} />
  },
  {
    field: 'aph',
    headerName: Localize.get('abrasiveProductsHandlingHeader').defaultMessage(
      'abrasiveProductsHandlingHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="aph" value={params.value} />
  },
  {
    field: 'shearEffect',
    headerName: Localize.get('shearEffectHeader').defaultMessage('shearEffectHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="shearEffect" value={params.value} />
  },
  {
    field: 'pulsationEffect',
    headerName: Localize.get('pulsationEffectHeader').defaultMessage('pulsationEffectHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="pulsationEffect" value={params.value} />
  },
  {
    field: 'reversibleOperation',
    headerName: Localize.get('reversibleOperationHeader').defaultMessage(
      'reversibleOperationHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="reversibleOperation" value={params.value} />
  },
  {
    field: 'drc',
    headerName: Localize.get('dryRunningCapabilityHeader').defaultMessage(
      'dryRunningCapabilityHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="drc" value={params.value} />
  },
  {
    field: 'valveless',
    headerName: Localize.get('valvelessConstructionHeader').defaultMessage(
      'valvelessConstructionHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="valveless" value={params.value} />
  },
  {
    field: 'ndlec',
    headerName: Localize.get('noDynamicLoadedElastometricHeader').defaultMessage(
      'noDynamicLoadedElastometricHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="ndlec" value={params.value} />
  },
  {
    field: 'ncc',
    headerName: Localize.get('noComponentContactHeader').defaultMessage('noComponentContactHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="ncc" value={params.value} />
  },
  {
    field: 'installationEnvelope',
    headerName: Localize.get('installationEnvelopeHeader').defaultMessage(
      'installationEnvelopeHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="installationEnvelope" value={params.value} />
  },
  {
    field: 'lowMaintenanceTime',
    headerName: Localize.get('maintenanceEaseHeader').defaultMessage('maintenanceEaseHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="lowMaintenanceTime" value={params.value} />
  },
  {
    field: 'lowSlip',
    headerName: Localize.get('accurateDosingHeader').defaultMessage('accurateDosingHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="lowSlip" value={params.value} />
  },
  {
    field: 'sss',
    headerName: Localize.get('shaftSealHeader').defaultMessage('shaftSealHeader'),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="sss" value={params.value} />
  },
  {
    field: 'aclcm',
    headerName: Localize.get('aggressiveChemicalsHeader').defaultMessage(
      'aggressiveChemicalsHeader'
    ),
    width: 180,
    editable: false,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderCell: (params) => <MyRating name="aclcm" value={params.value} />
  }
];

const columnVisibilityInitState = {
  id: true,
  statorMaterial: false,
  statorType: false,
  deliveryTime: true,
  price: true,
  energyEfficiency: false,
  meanTimeBetweenFailures: false,
  maintenanceCost: false,
  tco: false,
  rotationSpeedMax: true,
  powerRequiredMax: true,
  torqueMax: true,
  startingTorque: true,
  solidsHandling: false,
  aph: false,
  shearEffect: false,
  pulsationEffect: false,
  reversibleOperation: false,
  drc: false,
  valveless: false,
  ndlec: false,
  ncc: false,
  installationEnvelope: false,
  lowMaintenanceTime: false,
  lowSlip: false,
  sss: false,
  aclcm: false
};

export { resultListConfig, columnVisibilityInitState };
