import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APP_LANGUAGES } from '@common/constants';
import { getLocalStorage, setLocalStorage } from '@common/helpers/helpers';
import { TRANSLATION } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

const clearLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

const checkRules = (_, isAdmin, expertModeAllowed) => {
  return {
    stepOne: {},
    stepTwo: {},
    stepThree: {
      nextEnabledForExpertMode: Boolean(expertModeAllowed),
      capacitySwitchEnabled: Boolean(expertModeAllowed)
    },
    stepFour: { showAllEnabled: Boolean(expertModeAllowed) },
    stepFive: { showAllEnabled: Boolean(expertModeAllowed) },
    stepSix: { showAllEnabled: Boolean(expertModeAllowed) },
    navBar: { adminEnabled: Boolean(isAdmin) }
  };
};

const USER_LOGOUT_STATE = {
  showSettingsDialog: false,
  session: {
    language: APP_LANGUAGES.EN
  },
  languageUser: APP_LANGUAGES.EN,
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  token: '',
  email: '',
  isAdmin: false,
  userLevel: undefined,
  status: undefined,
  userId: undefined,
  rules: checkRules(),
  plant: '',
  unitSystem: undefined,
  expertModeAllowed: false
};

const auth = getLocalStorage('auth');

export const initialState = {
  showSettingsDialog: false,
  session: {
    language: auth?.languageUser ?? APP_LANGUAGES.EN
  },
  languageUser: auth?.languageUser ?? APP_LANGUAGES.EN,
  isLoggedIn: Boolean(auth?.token),
  firstName: auth?.firstName ?? '',
  lastName: auth?.lastName ?? '',
  token: auth?.token,
  email: auth?.email ?? '',
  isAdmin: auth?.isAdmin,
  userId: auth?.userId,
  userLevel: auth?.userLevel,
  status: auth?.status,
  rules: checkRules(auth?.userLevel, auth?.isAdmin, auth?.expertModeAllowed),
  unitSystem: auth?.unitSystem,
  expertModeAllowed: Boolean(auth?.expertModeAllowed),
  languages: []
};

export const fetchLanguages = createAsyncThunk('user/fetchLanguages', async () => {
  const { data, status } = await getByPathAndParams({
    path: TRANSLATION.LANGUAGES
  });
  if (status !== 200) {
    throw { err: 'Something went wrong' };
  }
  return data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSessionLanguage: (state, { payload }) => {
      state.session.language = payload;
    },
    setUserLanguage: (state, { payload }) => {
      state.session.language = payload;
      state.languageUser = payload;
    },
    setShowSettingsDialog: (state, { payload }) => {
      state.showSettingsDialog = payload;
    },
    setUserAndStorage: (state, { payload }) => {
      const auth = getLocalStorage('auth');
      setLocalStorage('auth', { ...auth, ...payload });
      return {
        ...state,
        ...payload
      };
    },
    setLogOut: () => {
      clearLocalStorageItem('store');
      return USER_LOGOUT_STATE;
    },
    setLoggedIn: (state, { payload }) => {
      setLocalStorage('auth', payload);
      return {
        ...state,
        ...payload,
        session: {
          language: payload.languageUser
        },
        rules: checkRules(payload?.userLevel, payload?.isAdmin, payload?.expertModeAllowed)
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.fulfilled, (state, { payload }) => {
        state.languages = payload.map((language) => {
          return {
            label: language?.LanguageName,
            value: language?.Id
          };
        });
      })
      .addCase(fetchLanguages.pending, () => {})
      .addCase(fetchLanguages.rejected, () => {});
  }
});

export const selectLanguageSession = (state) => state.user.session.language;
export const selectLanguageUser = (state) => state.user.languageUser;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectToken = (state) => state.user.token;
export const selectRules = (state) => state.user.rules;
export const selectUserId = (state) => state.user.userId;
export const selectUnitSystem = (state) => state.user.unitSystem;
export const selectUserLevel = (state) => state.user.userLevel;
export const selectLanguages = (state) => state.user.languages;
export const selectExpertMode = (state) => state.user.expertModeAllowed;
export const selectEmail = (state) => state.user.email;
export const selectShowSettingsDialog = (state) => state.user.showSettingsDialog;

const { reducer, actions } = userSlice;

export const {
  setSessionLanguage,
  setUserLanguage,
  setLogOut,
  setLoggedIn,
  setUserAndStorage,
  setShowSettingsDialog
} = actions;

export default reducer;
