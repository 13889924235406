import * as React from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';

const DataGridUsers = ({
  rows = [],
  columns = [],
  onSelectionUserChange,
  ...rest
}) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={onSelectionUserChange}
      {...rest}
    />
  );
};
export default DataGridUsers;
