import React from 'react';

const ImagePreview = ({ data }) => (
  <img
    style={{ objectFit: 'contain', width: '100%', height: '240px' }}
    src={data}
  />
);

export default ImagePreview;
