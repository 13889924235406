import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectDynamicFluidFieldsValues } from '@common/slices/fluidSlice/fluidSlice';
import { selectDynamicIOAFieldsValues } from '@common/slices/industrySlice/industrySlice';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { selectCurrentStep } from '@common/slices/layoutSlice/layoutSlice';
import {
  setExpertNeeded,
  selectExpertNeeded
} from '@common/slices/operationCondition/operationConditionSlice';

const expertFlagsIOAFieldsEqual = {
  HazardousArea: 1
};

const expertFlagFluidFieldsEqual = {
  Adhesion: 40,
  SolidsSettle: 1,
  TendencyToDewater: 1,
  ShearSensitivity: 43,
  NonNewtonian: 1,
  Toxic: 1,
  Explosive: 1,
  ExpertRequired: 1
};

const expertFlagFluidFieldsGreater = {
  DryOrganicSolidsContent: 28,
  ChlorideContentMax: 0,
  HydrogenSulfideContentMax: 0
};

const checkExpertFlagForIOAFields = (dynamicIOAFieldsValues) => {
  return Object.keys(expertFlagsIOAFieldsEqual).some(
    (fieldName) => dynamicIOAFieldsValues[fieldName] === expertFlagsIOAFieldsEqual[fieldName]
  );
};

const checkExpertFlagForFluidFields = (dynamicFluidFieldsValues) => {
  let flag1 = Object.keys(expertFlagFluidFieldsEqual).some(
    (fieldName) =>
      parseFloat(dynamicFluidFieldsValues[fieldName]) === expertFlagFluidFieldsEqual[fieldName]
  );
  let flag2 = Object.keys(expertFlagFluidFieldsGreater).some(
    (fieldName) =>
      parseFloat(dynamicFluidFieldsValues[fieldName]) > expertFlagFluidFieldsGreater[fieldName]
  );

  return flag1 || flag2;
};

const useExpertState = () => {
  const dispatch = useDispatch();

  const activeStep = useSelector(selectCurrentStep);
  const dynamicIOAFieldsValues = useSelector(selectDynamicIOAFieldsValues);
  const dynamicFluidFieldsValues = useSelector(selectDynamicFluidFieldsValues);

  const expertNeeded = useSelector(selectExpertNeeded);

  useEffect(() => {
    if (
      activeStep.value === STEP_VALUES.generalAttributes.value ||
      activeStep.value === STEP_VALUES.fluidAttributes.value ||
      activeStep.value === STEP_VALUES.operationConditions.value
    ) {
      if (
        checkExpertFlagForIOAFields(dynamicIOAFieldsValues) ||
        (checkExpertFlagForFluidFields(dynamicFluidFieldsValues) &&
          (activeStep.value === STEP_VALUES.fluidAttributes.value ||
            activeStep.value === STEP_VALUES.operationConditions.value))
      ) {
        dispatch(setExpertNeeded(true));
      } else {
        dispatch(setExpertNeeded(false));
      }
    }

    /* eslint-disable-next-line */
  }, [activeStep.value, dynamicIOAFieldsValues, dynamicFluidFieldsValues]);

  return expertNeeded;
};

export default useExpertState;
