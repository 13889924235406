import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import createCustomTheme from './customTheme';

const Theme = ({ children }) => {
  // const colorMode = useSelector(selectColorMode);
  const theme = createCustomTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
