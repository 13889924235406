import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

const UnitAdornment = ({ label }) => (
  <InputAdornment className='unitAdornment' position='end'>
    <Typography className='attributeUnitText'>{label ?? ''}</Typography>
  </InputAdornment>
);

export default UnitAdornment;
