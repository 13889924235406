import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 0,
    paddingTop: 0,
    flexDirection: 'row'
  },
  detailLeftColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    justifySelf: 'flex-start'
  }
});

const MyFooter = ({ releaseCurveDate }) => {
  const revText = `${Localize.get('revTech').defaultMessage('revTech')} ${moment(
    releaseCurveDate
  ).format('MM/YYYY')} ${Localize.get('revTechChanges').defaultMessage('revTechChanges')}`;
  return (
    <View style={styles.footer}>
      <View style={styles.detailLeftColumn}>
        <Text>{Localize.get('vdmaTolerance')}</Text>
        <Text>{revText}</Text>
      </View>
      <Text>{Localize.get('netzschWebAddress').defaultMessage('netzschWebAddress')}</Text>
    </View>
  );
};

export default MyFooter;
