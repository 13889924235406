import React from 'react';

import Button from '@mui/material/Button';

const StyledButton = ({ children, ...rest }) => {
  return (
    <Button variant='contained' color='secondary' component='span' {...rest}>
      {children}
    </Button>
  );
};

export default StyledButton;
