import React from 'react';
import Localize from 'react-intl-universal';

import TabList from '@mui/lab/TabList';

import AdminSubTab from '@components/AdminSubTab/AdminSubTab';

const DataTabHeader = ({ handleTabChange, productPanelTabOptions, disabledOptions }) => {
  return (
    <TabList className="mainTabHeader productTabContainer" onChange={handleTabChange}>
      <AdminSubTab
        disabled={disabledOptions?.optionOne}
        value={productPanelTabOptions.optionOne}
        label={Localize.get('product').defaultMessage('product')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionTwo}
        value={productPanelTabOptions.optionTwo}
        label={Localize.get('productLimitsTab').defaultMessage('productLimitsTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionThree}
        value={productPanelTabOptions.optionThree}
        label={Localize.get('productLimitMappingTab').defaultMessage('productLimitMappingTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionFour}
        value={productPanelTabOptions.optionFour}
        label={Localize.get('productFluidLimitsTab').defaultMessage('productFluidLimitsTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionFive}
        value={productPanelTabOptions.optionFive}
        label={Localize.get('productDetailsTab').defaultMessage('productDetailsTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionSix}
        value={productPanelTabOptions.optionSix}
        label={Localize.get('curveInfoTab').defaultMessage('curveInfoTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionSeven}
        value={productPanelTabOptions.optionSeven}
        label={Localize.get('curvePressureTab').defaultMessage('curvePressureTab')}
      />
      <AdminSubTab
        disabled={disabledOptions?.optionEight}
        value={productPanelTabOptions.optionEight}
        label={Localize.get('statorImageMappingTab').defaultMessage('statorImageMappingTab')}
      />
    </TabList>
  );
};

export default DataTabHeader;
