import { Provider } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import cpqReducer from '@common/slices/cpqSlice/cpqSlice';
import { cpqSliceMiddlewareListener } from '@common/slices/cpqSlice/middleware';
import fluidAttributesReducer from '@common/slices/fluidSlice/fluidSlice';
import { fluidSliceMiddlewareListener } from '@common/slices/fluidSlice/middleware';
import generalAttributesReducer from '@common/slices/industrySlice/industrySlice';
import { industrySliceMiddlewareListener } from '@common/slices/industrySlice/middleware';
import layoutReducer from '@common/slices/layoutSlice/layoutSlice';
import navbarReducer from '@common/slices/navBarSlice';
import operationConditionMiddlewareListener from '@common/slices/operationCondition/middleware';
import operationConditionReducer from '@common/slices/operationCondition/operationConditionSlice';
import resultListSliceMiddlewareListener from '@common/slices/resultListSlice/middleware';
import resultListReducer from '@common/slices/resultListSlice/resultListSlice';
import { searchMethodSliceMiddlewareListener } from '@common/slices/searchMethodSlice/middleware';
import searchMethodReducer from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectPumpSliceMiddlewareListener } from '@common/slices/selectPumpSlice/middleware';
import selectPumpReducer from '@common/slices/selectPumpSlice/selectPumpSlice';
import unitsAndPositionMiddlewareListener from '@common/slices/unitsAndPositionsSlice/middleware';
import unitsAndPositionReducer from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import userReducer from '@common/slices/userSlice/userSlice';

export const store = configureStore({
  reducer: {
    cpq: cpqReducer,
    user: userReducer,
    layout: layoutReducer,
    navbar: navbarReducer,
    unitsAndAttributes: unitsAndPositionReducer,
    generalAttributes: generalAttributesReducer,
    fluidAttributes: fluidAttributesReducer,
    operationCondition: operationConditionReducer,
    searchMethod: searchMethodReducer,
    selectPump: selectPumpReducer,
    resultList: resultListReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      cpqSliceMiddlewareListener.middleware,
      industrySliceMiddlewareListener.middleware,
      fluidSliceMiddlewareListener.middleware,
      operationConditionMiddlewareListener.middleware,
      searchMethodSliceMiddlewareListener.middleware,
      selectPumpSliceMiddlewareListener.middleware,
      resultListSliceMiddlewareListener.middleware,
      unitsAndPositionMiddlewareListener.middleware
    )
});

const ReduxProvider = ({ children }) => <Provider store={store}>{children}</Provider>;

export default ReduxProvider;
