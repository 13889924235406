import { ROUTER_PATHS } from '@common/constants';
import HomePage from '@components/HomePage';
import LogoutRedirection from '@components/LogoutRedirection';
import PendingApprovalPage from '@components/PendingApprovalPage';
import Secret from '@navigation/components/Secret';
import Admin from '@pages/Admin/Admin';
import Analyzer from '@pages/Analyzer/Analyzer';


export default isLoggedIn => [
  {
    path: ROUTER_PATHS.home,
    exact: true,
    element: <HomePage />
  },
  {
    path: ROUTER_PATHS.analyzer,
    exact: true,
    element: <Analyzer />
  },
  {
    path: ROUTER_PATHS.admin,
    exact: true,
    element: <Admin />
  },
  {
    path: ROUTER_PATHS.logoutRedirect,
    exact: true,
    element: <LogoutRedirection />
  },
  {
    path: ROUTER_PATHS.secret,
    exact: true,
    element: <Secret />
  },
  {
    path: ROUTER_PATHS.approval,
    exact: true,
    element: <PendingApprovalPage />
  },
  {
    path: ROUTER_PATHS.other,
    exact: true,
    element: isLoggedIn ? <Analyzer /> : <HomePage />
  }
];
