import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { SEARCH_METHOD } from '@common/constants';
import { selectCpqProductData } from '@common/slices/cpqSlice/cpqSlice';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';

const SearchCard = ({ title, description, searchTime, onClick }) => (
  <Card className="astSelectorCard searchMethodCard">
    <CardContent className="searchCardContent">
      <Typography className="cardValue">{title}</Typography>
    </CardContent>
    <CardContent className="searchCardDescription">{description}</CardContent>
    <CardContent className="searchCardApproximation">{searchTime}</CardContent>

    <CardActions className="cardActions">
      <Button variant="contained" onClick={onClick} className="cardSelectButton">
        {Localize.get('search').defaultMessage('search')}
      </Button>
    </CardActions>
  </Card>
);

const SearchMethodSelector = ({ onClick, title, isUserExpert }) => {
  const cpqProductData = useSelector(selectCpqProductData);
  return (
    <SalesCloudContainer>
      <DetailsTitle
        title={title}
        description={Localize.get('searchMethodDescription').defaultMessage(
          'searchMethodDescription'
        )}
      />
      <Box className="cardList">
        <SearchCard
          key={0}
          onClick={() => onClick(SEARCH_METHOD.MULTI_SELECT)}
          title={Localize.get('productMultiSelect').defaultMessage('productMultiSelect')}
          description={Localize.get('productMultiSelectDescription').defaultMessage(
            'productMultiSelectDescription'
          )}
          searchTime={Localize.get('takes5s').defaultMessage('takes5s')}
        />

        {isUserExpert && (
          <SearchCard
            key={1}
            onClick={() => onClick(SEARCH_METHOD.OTHER_CRITERIA)}
            title={Localize.get('productSingleSelect').defaultMessage('productSingleSelect')}
            description={Localize.get('productSingleSelectDescription').defaultMessage(
              'productSingleSelectDescription'
            )}
            searchTime={Localize.get('fastest').defaultMessage('fastest')}
          />
        )}

        <SearchCard
          key={2}
          onClick={() => onClick(SEARCH_METHOD.NO_RESTRICTION)}
          title={Localize.get('noSearchRestriction').defaultMessage('noSearchRestriction')}
          description={Localize.get('noRestrictionDescription').defaultMessage(
            'noRestrictionDescription'
          )}
          searchTime={Localize.get('takes30s').defaultMessage('takes30s')}
        />
        {cpqProductData?.length > 0 && (
          <SearchCard
            key={3}
            onClick={() => onClick(SEARCH_METHOD.MANUAL_CONFIG)}
            title={Localize.get('manualConfig').defaultMessage('manualConfig')}
            description={Localize.get('manualConfigDescription').defaultMessage(
              'manualConfigDescription'
            )}
            searchTime=""
          />
        )}
      </Box>
    </SalesCloudContainer>
  );
};

export default SearchMethodSelector;
