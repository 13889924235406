import { findById } from '@common/helpers/helpers';
import { CPQ_PATHS, PRODUCT_PATHS } from '@common/network/ApiPaths';
import {
  fetchFluidAttributes,
  fetchFluidTypes,
  fetchFluids,
  fetchIndustries,
  fetchIndustryAttributes,
  fetchIndustryClusters
} from '@common/services';
import {
  mapUnitsForCalcPerformance,
  mapDPForCalcPerformance,
  getAttributesForColumn,
  getCPQAttributeValues,
  getIOAValues,
  getFluidValues,
  sortArrayLexicographic,
  getTypeCodeOptions,
  getMaterialOptions,
  getMaterialElastomerOptions,
  getPumpByParameters
} from '@pages/Analyzer/hooks/utils';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

export const getMappingParams = async () => {
  try {
    const { data } = await getByPathAndParams({
      path: CPQ_PATHS.GET_CPQ_MAPPING_PARAMETERS
    });

    return data;
  } catch (err) {
    return undefined;
  }
};

export const getProductConfiguration = async (Host_Url, CpqTableEntryId) => {
  const cpqResponseWithData = await getByPathAndParams({
    path: CPQ_PATHS.GET_PRODUCT_CONFIGURATION,
    pathVariables: { id: CpqTableEntryId },
    params: { Host_Url }
  });

  return cpqResponseWithData;
};

export const getPumpByCode = async (
  selectedPumpId,
  dynamicIOAFieldsValues,
  fluidParams,
  dutyPointParams,
  thirdStateData,
  dutyPointsStateData,
  unitMetrics
) => {
  let responseProductCalculated = await postByPathAndData({
    path: PRODUCT_PATHS.GET_PUMP_BY_PRODUCT_CODE,
    pathVariables: { ProductCode: selectedPumpId },
    data: {
      productParameters: {
        ...dynamicIOAFieldsValues,
        ...fluidParams,
        ...dutyPointParams
      },
      mode: parseInt(thirdStateData),
      calculation_values: {
        duty_points: mapDPForCalcPerformance(dutyPointsStateData),
        numberOfLinePoints: 6,
        directionAndMaterial: 0,
        abrasionClass: 0,
        fibresClass: 0
      },
      custom_units: mapUnitsForCalcPerformance(unitMetrics)
    }
  });

  return responseProductCalculated;
};

export const getPumpsByRange = async (
  thirdStateData,
  dutyPointsStateData,
  unitMetrics,
  dynamicIOAFieldsValues,
  fluidParams,
  dutyPointParams,
  productRangeId
) => {
  const otherCiteriaPumps = await postByPathAndData({
    path: PRODUCT_PATHS.GET_PUMPS_BY_RANGE,
    data: {
      mode: parseInt(thirdStateData),
      calculationValues: {
        duty_points: mapDPForCalcPerformance(dutyPointsStateData),
        numberOfLinePoints: 6,
        directionAndMaterial: 0,
        abrasionClass: 0,
        fibresClass: 0
      },
      customUnits: mapUnitsForCalcPerformance(unitMetrics),
      productParameters: {
        ...dynamicIOAFieldsValues,
        ...fluidParams,
        ...dutyPointParams
      },
      productCodes: [productRangeId]
    }
  });

  return otherCiteriaPumps;
};

export const getIndustryState = async (firstStateData, language, attributePositions) => {
  const providedFirstState = {};

  if (firstStateData?.industryCluster?.id) {
    const industryClustersData = await fetchIndustryClusters(language);
    if (industryClustersData?.length) {
      const providedIoC = findById(industryClustersData, firstStateData.industryCluster.id);

      if (providedIoC?.id) {
        providedFirstState.industryCluster = providedIoC;
      }
    }
  }

  if (firstStateData?.industry?.id) {
    const industriesData = await fetchIndustries(firstStateData.industryCluster.id, language);

    if (industriesData?.length) {
      const providedIndustry = findById(industriesData, firstStateData.industry.id);

      if (providedIndustry?.id) {
        providedFirstState.industry = providedIndustry;

        const industryAttributesData = await fetchIndustryAttributes(
          providedIndustry?.id,
          language
        );

        providedFirstState.dynamicIOAFields = {
          firstColumn: getAttributesForColumn(
            industryAttributesData,
            attributePositions,
            2, // column number
            1 // id that presents fist step in AST
          ),
          secondColumn: getAttributesForColumn(
            industryAttributesData,
            attributePositions,
            3, // column number
            1 // id that presents fist step in AST
          )
        };

        providedFirstState.cpqIoAAttributeValues = getCPQAttributeValues(industryAttributesData);

        providedFirstState.dynamicIOAFieldsValues = getIOAValues(
          industryAttributesData,
          firstStateData
        );

        providedFirstState.nextAllowed = {
          industryCluster: true,
          industryOfApplication: true,
          attributes: true
        };
      }
    }
  }

  return providedFirstState;
};

export const getFluidState = async (
  firstStateData,
  secondStateData,
  language,
  attributePositions
) => {
  const providedSecondState = {};

  if (secondStateData?.mediumType?.id && firstStateData?.industry?.id) {
    const fluidTypesData = await fetchFluidTypes(firstStateData.industry.id, language);

    if (fluidTypesData?.length) {
      const providedMediumType = findById(fluidTypesData, secondStateData.mediumType.id);

      if (providedMediumType?.id) {
        providedSecondState.mediumType = providedMediumType;
      }
    }
  }
  if (secondStateData?.mediumType?.id && firstStateData?.industry?.id) {
    const fluidsData = await fetchFluids(
      firstStateData.industry.id,
      secondStateData.mediumType.id,
      language
    );

    if (fluidsData?.length) {
      const providedMedium = findById(fluidsData, secondStateData.medium.id);

      if (providedMedium?.id) {
        providedSecondState.medium = providedMedium;
      }
    }

    let fluidAttributesData = await fetchFluidAttributes(providedSecondState?.medium?.id, language);

    if (fluidAttributesData) {
      providedSecondState.dynamicFluidFields = {
        firstColumn: getAttributesForColumn(
          fluidAttributesData,
          attributePositions,
          2, // column number
          2 // id that presents second step in AST
        ),
        secondColumn: getAttributesForColumn(
          fluidAttributesData,
          attributePositions,
          3, // column number
          2 // id that presents second step in AST
        ),
        thirdColumn: getAttributesForColumn(fluidAttributesData, attributePositions, 4)
      };

      providedSecondState.dynamicFluidFieldsValues = getFluidValues(
        fluidAttributesData,
        secondStateData
      );

      providedSecondState.nextAllowed = {
        mediumType: true,
        medium: true,
        fluidAttributes: true
      };
    }
  }

  return providedSecondState;
};

export const getSelectedPumpState = (
  productCalculated,
  otherCiteriaPumps,
  productId,
  productRangeId
) => {
  return {
    nextAllowed: true,
    material: productCalculated[0]?.material,
    materialElastomer: productCalculated[0]?.materialElastomer,
    typeCode: productCalculated[0]?.typeCode,
    cpqSelectedPump: productCalculated[0],
    otherCriteriaSelectedPump: getPumpByParameters(
      productCalculated[0]?.typeCode,
      productCalculated[0]?.material,
      productCalculated[0]?.materialElastomer,
      otherCiteriaPumps.data
    ),
    productCode: productId,
    productRange: productRangeId,
    listOfPumps: otherCiteriaPumps.data,
    listOfTypeCodes: sortArrayLexicographic(getTypeCodeOptions(otherCiteriaPumps.data)),
    listOfMaterial: sortArrayLexicographic(
      getMaterialOptions(otherCiteriaPumps.data, productCalculated[0]?.typeCode)
    ),
    listOfMaterialElastomer: sortArrayLexicographic(
      getMaterialElastomerOptions(
        otherCiteriaPumps.data,
        productCalculated[0]?.material,
        productCalculated[0]?.typeCode
      )
    )
  };
};

export const getOperationConditionState = (dutyPointsStateData, thirdStateData, DEFAULT_VALUES) => {
  return {
    dutyPoints: dutyPointsStateData,
    capacityOrSpeed:
      parseInt(thirdStateData) === DEFAULT_VALUES.ONE_VALUE
        ? DEFAULT_VALUES.DP_INPUT_VARIATION.SPEED
        : DEFAULT_VALUES.DP_INPUT_VARIATION.CAPACITY,
    nextAllowed: true
  };
};

export const getSearchMethodState = async (productId, productRangeId, SEARCH_METHOD) => {
  const { data: products } = await getByPathAndParams({
    path: '/products'
  });

  const { data: productRanges } = await getByPathAndParams({
    path: '/product-ranges/:ProductCode',
    pathVariables: {
      ProductCode: productRangeId
    }
  });

  return {
    selectedSearchCriteria: SEARCH_METHOD.OTHER_CRITERIA,
    nextAllowed: {
      listOfProducts: true,
      listOfProductRanges: true
    },
    singleSelect: {
      selectedProductCode: products
        .filter(({ ProductCode }) => ProductCode === productId)
        .map(({ PublicTitle, ProductCode }) => ({
          productLabel: PublicTitle,
          productCode: ProductCode
        })),
      selectedProductRangeCode: productRanges.map(({ productCode, productLabel }) => ({
        productCode,
        productLabel
      })),
      nextAllowed: {
        listOfProducts: true,
        listOfProductRanges: true
      }
    }
  };
};
