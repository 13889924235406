import Localize from 'react-intl-universal';

import get from 'lodash/get';
import has from 'lodash/has';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';

import { DEFAULT_VALUES, ERROR_MESSAGES } from '@common/constants';
import CPQMappingError from '@common/Exceptions/CPQMappingError';

const formatBooleanForCPQ = (viewModel = {}) => {
  return Object.keys(viewModel).reduce((tempViewModel, currentViewParam) => {
    tempViewModel[currentViewParam] = tempViewModel[currentViewParam]
      ? DEFAULT_VALUES.ONE_VALUE
      : DEFAULT_VALUES.ZERO_VALUE;
    return tempViewModel;
  }, {});
};

const getProductParameters = (productCode = '') => {
  const [
    site,
    baseProduct,
    product,
    productRange,
    size,
    stages,
    geometry,
    pressure,
    joint,
    materials,
    appendix1,
    appendix2,
    appendix3
  ] = splitStringToMultiple(
    productCode,
    1, // 1
    3, // 2
    5, // 3
    7, // 4
    8, // 5
    9, // 6
    10, // 7
    11, // 8
    12, // 9
    13, // 10
    15, // 11
    16 // 12
    // 13
  );

  return {
    site,
    baseProduct,
    product,
    productRange,
    size,
    stages,
    geometry,
    pressure,
    joint,
    materials,
    appendix1,
    appendix2,
    appendix3
  };
};

const splitStringToMultiple = (str, ...indices) => {
  return [0, ...indices].map((startIndex, index, arr) => {
    return str.slice(startIndex, arr[index + 1]);
  });
};

const checkIfHasDecimals = (n) => {
  const result = n - Math.floor(n) !== 0;
  return result;
};

const splitDecimals = (n) => {
  return Math.trunc(n);
};

const checkLengthOfDigits = (n, CpqDigits) => {
  let valueString = `${n}`;
  return valueString.toString().length <= CpqDigits;
};

const reduceValueForCPQ = (
  providedObject = {},
  { InternalDataType, AstCharacteristic, CpqDecimalPlaces, CpqDigits }
) => {
  const value = get(providedObject, AstCharacteristic);
  switch (InternalDataType) {
    case 'NUM': {
      if (isNaN(value)) return null;

      if (value === 'Infinity') return null;
      if (value === '') return null;

      const hasDecimals = checkIfHasDecimals(value);
      if (hasDecimals) {
        const wholeNumber = splitDecimals(value);
        if (checkLengthOfDigits(wholeNumber, CpqDigits)) {
          return parseFloat(toNumber(value).toFixed(CpqDecimalPlaces));
        }
        return value;
      } else {
        if (checkLengthOfDigits(value, CpqDigits)) return parseFloat(value);

        return value;
      }
    }
    case 'CHAR':
      if (toString(value).length <= CpqDigits) return value;
      break;
    default:
      return null;
  }
};

export const convertToCPQBodyParams = (
  cpqMappingData = [],
  calculatedValues = {},
  selectedPump = {},
  resultListModel = {},
  unitMetrics = {},
  firstStepState = {},
  secondStepState = {},
  productCode = '',
  showAllProducts = {},
  user = {},
  thirdPageState = {}
) => {
  const { graphDataOriginal } = calculatedValues;
  const calculationValues =
    graphDataOriginal?.resultValues?.calculation_values?.graphsForDiffDP ?? {};

  const { sortModel = [], viewModel = {} } = resultListModel;
  const viewModelFormatted = formatBooleanForCPQ(viewModel);
  const showAllProductsFormatted = formatBooleanForCPQ(showAllProducts);

  const sortModelObj = sortModel.reduce((sortModelTempObj, currentColumn) => {
    sortModelTempObj[currentColumn.field] = currentColumn.sort;
    return sortModelTempObj;
  }, {});

  const {
    dynamicIOAFieldsValues = {},
    industryCluster,
    industry,
    cpqIoAAttributeValues = {}
  } = firstStepState;
  const allIndustryParams = {
    ...dynamicIOAFieldsValues,
    ...cpqIoAAttributeValues,
    industryCluster,
    industry
  };

  const { dutyPoints = [], capacityOrSpeed } = thirdPageState;

  const {
    dynamicFluidFieldsValues = {},
    mediumType,
    medium,
    cpqFluidAttributeValues = {}
  } = secondStepState;
  const allFluidParams = {
    ...dynamicFluidFieldsValues,
    ...cpqFluidAttributeValues,
    mediumType,
    medium
  };

  const productObj = getProductParameters(productCode);

  const errors = [];

  const getStateForMappingToCPQ = (Section) => {
    switch (Section) {
      case 'Duty_Points':
        return dutyPoints;
      case 'Calculated_Values':
        return calculationValues;
      case 'Product_details':
        return selectedPump;
      case 'Column_Sort_Model':
        return sortModelObj;
      case 'Column_Visibility_Model':
        return viewModelFormatted;
      case 'Operation_Condition':
        return capacityOrSpeed;
      case 'Unit':
        return unitMetrics;
      case 'IoA_Attributes':
        return allIndustryParams;
      case 'IoA_Properties':
        return allIndustryParams;
      case 'Fluid_Attributes':
        return allFluidParams;
      case 'Fluid_Properties':
        return allFluidParams;
      case 'ProductCode':
        return productObj;
      case 'Product_limits':
        return selectedPump;
      case 'ShowAllProducts':
        return showAllProductsFormatted;
      case 'User':
        return user;
      default:
        return {};
    }
  };

  const bodyParams = cpqMappingData.reduce((tempParams, currentCPQParam) => {
    try {
      if (currentCPQParam?.Section === 'Operation_Condition') {
        tempParams[currentCPQParam.CpqCharacteristic] =
          capacityOrSpeed === DEFAULT_VALUES.DP_INPUT_VARIATION.SPEED
            ? DEFAULT_VALUES.ONE_VALUE
            : DEFAULT_VALUES.ZERO_VALUE;
      } else if (currentCPQParam?.Section === 'Unit') {
        if (has(unitMetrics, currentCPQParam.AstCharacteristic)) {
          let selectedUnit = get(unitMetrics, currentCPQParam.AstCharacteristic);
          if (selectedUnit?.value?.key) {
            tempParams[currentCPQParam.CpqCharacteristic] = selectedUnit.value.key;
          }
        }
      } else if (
        currentCPQParam?.Section === 'Product_limits' ||
        currentCPQParam?.Section === 'Fluid_Attributes' ||
        currentCPQParam?.Section === 'Duty_Points' ||
        currentCPQParam?.Section === 'IoA_Attributes' ||
        currentCPQParam?.Section === 'Calculated_Values' ||
        currentCPQParam?.Section === 'Column_Sort_Model' ||
        currentCPQParam?.Section === 'Column_Visibility_Model' ||
        currentCPQParam?.Section === 'ShowAllProducts'
      ) {
        let currentState = getStateForMappingToCPQ(currentCPQParam?.Section);

        if (has(currentState, currentCPQParam.AstCharacteristic)) {
          tempParams[currentCPQParam.CpqCharacteristic] = reduceValueForCPQ(
            currentState,
            currentCPQParam
          );
        }
      } else {
        let currentState = getStateForMappingToCPQ(currentCPQParam?.Section);

        if (has(currentState, currentCPQParam.AstCharacteristic)) {
          tempParams[currentCPQParam.CpqCharacteristic] = reduceValueForCPQ(
            currentState,
            currentCPQParam
          );
        }
      }
    } catch (err) {
      console.log('err', err);
      if (
        err?.status === ERROR_MESSAGES.STATE_MISSING.STATUS ||
        err?.status === ERROR_MESSAGES.REDUCE_VALUE_ISNAN.STATUS ||
        err?.status === ERROR_MESSAGES.CHAR_IS_LONGER.STATUS ||
        err?.status === ERROR_MESSAGES.REDUCE_VALUE_DEFAULT.STATUS ||
        err?.status === ERROR_MESSAGES.DIGIT_IS_LONGER.STATUS
      ) {
        errors.push(err);
      }
    }

    return tempParams;
  }, {});

  if (errors?.length)
    throw new CPQMappingError({
      status: ERROR_MESSAGES.MAPPING_AST_TO_CPQ.STATUS,
      message: Localize.get('mappingAstToCpqErr').defaultMessage('mappingAstToCpqErr'),
      errors: errors
    });

  return bodyParams;
};

export const checkOptionalParams = (params) => {
  if (!params?.CpqTableEntryId) delete params.CpqTableEntryId;
  return params;
};
