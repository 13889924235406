export default [
  {
    field: 'Model',
    headerName: 'Model',
    width: 120,
    align: 'left',
    editable: false
  },
  {
    field: 'Size',
    headerName: 'Size',
    width: 90,
    align: 'left',
    editable: false
  },
  {
    field: 'MaterialType',
    headerName: 'MaterialType',
    width: 90,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorMaterial',
    headerName: 'StatorMaterial',
    width: 130,
    align: 'left',
    editable: false
  },
  {
    field: 'StatorType',
    headerName: 'StatorType',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Stages',
    headerName: 'Stages',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Geometry',
    headerName: 'Geometry',
    width: 70,
    align: 'left',
    editable: false
  },
  {
    field: 'GeometryType',
    headerName: 'GeometryType',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxParticleSize',
    headerName: 'MaxParticleSize',
    width: 120,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Release',
    headerName: 'Release',
    width: 90,
    align: 'left',
    editable: false
  },
  {
    field: 'MachineType',
    headerName: 'MachineType',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'UnitGroup',
    headerName: 'UnitGroup',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Displacement',
    headerName: 'Displacement',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'RotorPitchDiameter',
    headerName: 'RotorPitchDiameter',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Eccentricity',
    headerName: 'Eccentricity',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxSpeedLowPressure',
    headerName: 'MaxSpeedLowPressure',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxSpeedHighPressure',
    headerName: 'MaxSpeedHighPressure',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureLowSpeed',
    headerName: 'MaxPressureLowSpeed',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureHighSpeed',
    headerName: 'MaxPressureHighSpeed',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureLowSpeedRHDCI',
    headerName: 'MaxPressureLowSpeedRHDCI',
    width: 190,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureHighSpeedRHDCI',
    headerName: 'MaxPressureHighSpeedRHDCI',
    width: 195,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureLowSpeedRHDSS',
    headerName: 'MaxPressureLowSpeedRHDSS',
    width: 195,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxPressureHighSpeedRHDSS',
    headerName: 'MaxPressureHighSpeedRHDSS',
    width: 205,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MinSpeedFactor',
    headerName: 'MinSpeedFactor',
    width: 115,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureFactorAbrasion1',
    headerName: 'PressureFactorAbrasion1',
    width: 175,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureFactorAbrasion2',
    headerName: 'PressureFactorAbrasion2',
    width: 175,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureFactorAbrasion3',
    headerName: 'PressureFactorAbrasion3',
    width: 175,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureFactorAbrasion4',
    headerName: 'PressureFactorAbrasion4',
    width: 175,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureFactorAbrasion5',
    headerName: 'PressureFactorAbrasion5',
    width: 175,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpeedFactorAbrasion1',
    headerName: 'SpeedFactorAbrasion1',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpeedFactorAbrasion2',
    headerName: 'SpeedFactorAbrasion2',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpeedFactorAbrasion3',
    headerName: 'SpeedFactorAbrasion3',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpeedFactorAbrasion4',
    headerName: 'SpeedFactorAbrasion4',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SpeedFactorAbrasion5',
    headerName: 'SpeedFactorAbrasion5',
    width: 160,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityLimitFactor1',
    headerName: 'ViscosityLimitFactor1',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityLimitFactor2',
    headerName: 'ViscosityLimitFactor2',
    width: 150,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Temperature1',
    headerName: 'Temperature1',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Temperature2',
    headerName: 'Temperature2',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Temperature3',
    headerName: 'Temperature3',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Temperature2Factor',
    headerName: 'Temperature2Factor',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Temperature3Factor',
    headerName: 'Temperature3Factor',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'RotorTemperature1',
    headerName: 'RotorTemperature1',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'RotorTemperature2',
    headerName: 'RotorTemperature2',
    width: 130,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ClearanceAvailable1',
    headerName: 'ClearanceAvailable1',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ClearanceAvailable2',
    headerName: 'ClearanceAvailable2',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ClearanceLowPressure',
    headerName: 'ClearanceLowPressure',
    width: 145,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ClearanceHighPressure',
    headerName: 'ClearanceHighPressure',
    width: 155,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityPositiveIncClearance',
    headerName: 'ViscosityPositiveIncClearance',
    width: 195,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityPositiveStdClearance',
    headerName: 'ViscosityPositiveStdClearance',
    width: 195,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityFactorIncClearance',
    headerName: 'ViscosityFactorIncClearance',
    width: 190,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityFactorClearance1',
    headerName: 'ViscosityFactorClearance1',
    width: 180,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'ViscosityFactorClearance2',
    headerName: 'ViscosityFactorClearance2',
    width: 180,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Margin1',
    headerName: 'Margin1',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip5LowPressure',
    headerName: 'Slip5LowPressure',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip4LowPressure',
    headerName: 'Slip4LowPressure',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip3LowPressure',
    headerName: 'Slip3LowPressure',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip2LowPressure',
    headerName: 'Slip2LowPressure',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip1LowPressure',
    headerName: 'Slip1LowPressure',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PressureSplit',
    headerName: 'PressureSplit',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Margin2',
    headerName: 'Margin2',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip5HighPressure',
    headerName: 'Slip5HighPressure',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip4HighPressure',
    headerName: 'Slip4HighPressure',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip3HighPressure',
    headerName: 'Slip3HighPressure',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip2HighPressure',
    headerName: 'Slip2HighPressure',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Slip1HighPressure',
    headerName: 'Slip1HighPressure',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SlipSpan',
    headerName: 'SlipSpan',
    width: 70,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SlipEfficiency',
    headerName: 'SlipEfficiency',
    width: 95,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SlipFan',
    headerName: 'SlipFan',
    width: 60,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'CentrePoint',
    headerName: 'CentrePoint',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SafetyFactorFibres1',
    headerName: 'SafetyFactorFibres1',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SafetyFactorFibres2',
    headerName: 'SafetyFactorFibres2',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SafetyFactorFibres3',
    headerName: 'SafetyFactorFibres3',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'SafetyFactorFibres4',
    headerName: 'SafetyFactorFibres4',
    width: 140,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'Torque',
    headerName: 'Torque',
    width: 55,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'TorquePerBar',
    headerName: 'TorquePerBar',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'RiseMaxSpeed',
    headerName: 'RiseMaxSpeed',
    width: 105,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'RiseViscosityFactor',
    headerName: 'RiseViscosityFactor',
    width: 135,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'MaxTorque',
    headerName: 'MaxTorque',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'StartingTorque',
    headerName: 'StartingTorque',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'PortDiameter',
    headerName: 'PortDiameter',
    width: 100,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSH1',
    headerName: 'NPSH1',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSH2',
    headerName: 'NPSH2',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSH3',
    headerName: 'NPSH3',
    width: 80,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHSpan1',
    headerName: 'NPSHSpan1',
    width: 95,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHSpan2',
    headerName: 'NPSHSpan2',
    width: 95,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHSpan3',
    headerName: 'NPSHSpan3',
    width: 95,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHFan1',
    headerName: 'NPSHFan1',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHFan2',
    headerName: 'NPSHFan2',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHFan3',
    headerName: 'NPSHFan3',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  },
  {
    field: 'NPSHFan4',
    headerName: 'NPSHFan4',
    width: 90,
    align: 'right',
    editable: false,
    type: 'number'
  }
];
