import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_VALUES } from '@common/constants';

const initialState = {
  colorMode: DEFAULT_VALUES.THEME.LIGHT
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleColorMode: state => {
      state.colorMode =
        state.colorMode === DEFAULT_VALUES.THEME.LIGHT
          ? DEFAULT_VALUES.THEME.DARK
          : DEFAULT_VALUES.THEME.LIGHT;
    }
  }
});

export const selectColorMode = state => state.navbar.colorMode;

const { actions, reducer } = navbarSlice;

export const { toggleColorMode } = actions;

export default reducer;
