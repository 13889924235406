import React from 'react';
import Localize from 'react-intl-universal';

import { Box } from '@mui/system';

import DataGridUsers from '@components/DataGridUsers';
import Loader from '@components/Loader';
import Toast from '@components/Toast';
import UploadButton from '@components/UploadButton/UploadButton';
import AdminLayout from '@pages/Admin/components/Layout';
import useAdminDisplayState from '@pages/Admin/hooks/useAdminDisplayState';

import { userList } from './config/userList';

const UsersPanel = () => {
  const {
    allUsers,
    fetchUsersLoading,
    uploadIsLoading,
    uploadToastState,
    onSelectionUserChange,
    onUploadCSVOfUsers,
    setUploadToastToInitial
  } = useAdminDisplayState();

  return (
    <Box>
      <Loader isLoading={uploadIsLoading || fetchUsersLoading} />
      <Toast
        open={uploadToastState?.open ?? ''}
        handleClose={setUploadToastToInitial}
        status={uploadToastState?.status ?? ''}
        message={uploadToastState?.message ?? ''}
      />
      <AdminLayout
        listPanel={
          <Box className="adminListContainer">
            <Box className="adminList">
              <DataGridUsers
                columns={[...userList]}
                rows={allUsers}
                onSelectionUserChange={onSelectionUserChange}
                pageSize={50}
                density="compact"
                rowHeight={60}
                scrollable="scrollable"
                fixedSCroll="true"
                columnHeaderHeight={100}
                pagination
                columnBuffer={100}
              />
            </Box>

            <Box className="adminListFooter">
              <UploadButton
                onChange={(e) => onUploadCSVOfUsers(e, 'upsert')}
                id="upload-button-file"
                content={Localize.get('upsertData').defaultMessage('upsertData')}
              />
              <UploadButton
                onChange={(e) => onUploadCSVOfUsers(e, 'replace')}
                id="replace-button-file"
                content={Localize.get('replaceData').defaultMessage('replaceData')}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default UsersPanel;
