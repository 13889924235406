import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const DetailsTitle = ({
  title,
  onChangeUnitsClick = () => {},
  description,
  showChangeUnitsButton,
  showAllSwitch = <div />
}) => {
  return (
    <Box className="detailsTitleContainer">
      <Box className="detailsTitleText">
        <Typography className="astSelectorTitle" variant="h5" component="div">
          {title}
        </Typography>
        {description && <Typography className="stepDescription">{description}</Typography>}
        {showAllSwitch}
      </Box>
      {showChangeUnitsButton && (
        <Button
          onClick={onChangeUnitsClick}
          className="changeUnitsButton"
          variant="contained"
          color="primary"
        >
          {Localize.get('changeUnits').defaultMessage('changeUnits')}
        </Button>
      )}
    </Box>
  );
};

export default DetailsTitle;
