import React, { useState, useRef, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { DEFAULT_VALUES, SEARCH_METHOD } from '@common/constants';
import { selectCpqData } from '@common/slices/cpqSlice/cpqSlice';
import {
  selectGraphData,
  selectGraphDataOriginal,
  selectSelectedDutyPoint,
  selectGroupedDutyPoints,
  selectGraphTitleLabel,
  fetchPumps,
  selectListOfValidPumps,
  selectListOfPumps,
  setSelectedPump,
  selectSelectedPump,
  setSelectedDutyPoint,
  selectResultListIsLoading,
  selectResultListModel,
  setViewModel,
  setSortModel,
  selectShowAllPumps,
  setShowAllPumps,
  selectListErrorAnchor,
  setListErrorAnchor
} from '@common/slices/resultListSlice/resultListSlice';
import {
  selectSearchType,
  selectShowAllProductFamilies,
  selectShowAllProductRanges
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectSessionUnitMetrics } from '@common/slices/unitsAndPositionsSlice/unitsAndPositionsSlice';
import { selectRules } from '@common/slices/userSlice/userSlice';
import DataGridList from '@components/DataGridList';
import InputSwitch from '@components/InputSwitch';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import DataTable from '@components/Table/DataTable';
import PDFWrapper from '@pages/Analyzer/components/ResultList/components/PdfComponent/PDFWrapper';
import useCommunicationWithHost from '@pages/Analyzer/hooks/useCommunicationWithHost';
import { getTorqueTable, getPDFData, mapStatorType } from '@pages/Analyzer/hooks/utils';

import ResultListAccordion from './components/Accordion/ResultListAccordion';
import Graphs from './components/Graphs/Graphs';
import { resultListConfig } from './config/resultList';
import AnalyzerFooter from '../StepperComponent/AnalyzerFooter';

const ResultList = () => {
  const dispatch = useDispatch();
  const userRules = useSelector(selectRules);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

  const cpqData = useSelector(selectCpqData);

  const resultListModel = useSelector(selectResultListModel);
  const unitMetrics = useSelector(selectSessionUnitMetrics);
  const graphData = useSelector(selectGraphData);
  const graphDataOriginal = useSelector(selectGraphDataOriginal);
  const selectedDutyPoints = useSelector(selectSelectedDutyPoint);
  const groupedDutyPoints = useSelector(selectGroupedDutyPoints);
  const graphTitleLabel = useSelector(selectGraphTitleLabel);
  const listOfValidPumps = useSelector(selectListOfValidPumps);
  const listOfAllPumps = useSelector(selectListOfPumps);
  const selectedPump = useSelector(selectSelectedPump);
  const fetchPumpsLoading = useSelector(selectResultListIsLoading);
  const showAllPumps = useSelector(selectShowAllPumps);
  const showAllProductFamilies = useSelector(selectShowAllProductFamilies);
  const showAllProductRanges = useSelector(selectShowAllProductRanges);
  const anchorEl = useSelector(selectListErrorAnchor);

  const [animationDone, setAnimationDone] = useState(false);

  const columnsRef = useRef(resultListConfig(unitMetrics));
  const capacityChartRef = useRef();
  const powerChartRef = useRef();
  const rsvCartRef = useRef();

  const selectedSearchCriteria = useSelector(selectSearchType);

  const [handleSentToCPQNew] = useCommunicationWithHost(
    {
      product: showAllProductFamilies,
      productRange: showAllProductRanges,
      listOfPumps: showAllPumps
    },
    resultListModel,
    dispatch
  );

  const onSelectionModelChange = (model) => {
    dispatch(setSelectedPump(listOfAllPumps.find((pump) => pump.id === model[0])));
  };

  const handleClosePdf = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPdfDialogOpen(false);
  };

  useEffect(() => {
    const openDialog = async () => {
      if (pdfDialogOpen) {
        setTimeout(() => {
          setAnimationDone(true);
        }, 1000);
      } else {
        setAnimationDone(false);
      }
    };

    openDialog();

    return () => clearTimeout();
  }, [pdfDialogOpen]);

  const open = Boolean(anchorEl?.anchorRef);
  const id = open ? DEFAULT_VALUES.POP_OVER_ID : undefined;

  const torqueTableData = getTorqueTable(
    graphDataOriginal?.resultValues?.calculation_values?.graphs,
    selectedDutyPoints
  );

  const pdfData = getPDFData(
    graphDataOriginal?.resultValues?.calculation_values?.graphs,
    selectedDutyPoints
  );

  const statorType = mapStatorType(selectedPump?.statorType);

  const titleForGraphs = graphTitleLabel
    ? `${graphTitleLabel?.viscosity} ${unitMetrics?.dynamicViscosity?.value?.label} ${DEFAULT_VALUES.CHARS.SLASH} ${graphTitleLabel?.temperature} ${unitMetrics?.temperature?.value?.label}`
    : '';

  const handleMouseClick = (index) => {
    if (groupedDutyPoints?.length > 0 && groupedDutyPoints.some((arr) => arr.includes(index - 2))) {
      if (!selectedDutyPoints?.includes(index - 2)) {
        groupedDutyPoints.forEach((arr) => {
          if (arr.includes(index - 2)) {
            dispatch(setSelectedDutyPoint(arr));
          }
        });
      } else if (groupedDutyPoints?.length > 1) {
        dispatch(setSelectedDutyPoint());
      }
    }
  };

  const onColumnOrderChange = (params) => {
    const { oldIndex, targetIndex } = params;
    let myArr = columnsRef.current;
    let newArr = reorderArray(myArr, oldIndex, targetIndex);
    columnsRef.current = newArr;
  };

  const reorderArray = (arr, from, to) => {
    let myArr = [...arr];
    let movedColumn = myArr.splice(from, 1)[0];
    myArr.splice(to, 0, movedColumn);
    return myArr;
  };

  useEffect(() => {
    const promise = dispatch(fetchPumps());

    return () => promise.abort();
    /* eslint-disable-next-line */
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <SalesCloudContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl?.anchorRef}
          onClose={(event) => {
            event.preventDefault();
            event.stopPropagation();
            dispatch(setListErrorAnchor(null));
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box className="productRestrictionContainer">
            {anchorEl?.failedPumpCalculationRestrictions.map((message, index) => (
              <Box key={index}>
                <Typography className="failedRestrictions">{message}</Typography>
              </Box>
            ))}
          </Box>
        </Popover>

        <Dialog maxWidth="xl" open={pdfDialogOpen} onClose={handleClosePdf}>
          <PDFWrapper
            capacityChartRef={capacityChartRef}
            powerChartRef={powerChartRef}
            rsvCartRef={rsvCartRef}
            animationDone={animationDone}
            selectedPump={selectedPump}
            torqueTableData={torqueTableData}
            unitMetrics={unitMetrics}
            pdfData={pdfData}
            statorType={statorType}
            titleForGraphs={titleForGraphs}
            pdfDialogOpen={pdfDialogOpen}
            handleClosePdf={handleClosePdf}
          />
        </Dialog>
        <ResultListAccordion
          title={Localize.get('resultList').defaultMessage('resultList')}
          details={
            <Box className="accordionResultListContainer">
              <Box className="accordionResultListSwitch">
                {Boolean(userRules?.stepSix?.showAllEnabled) &&
                  selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA && (
                    <InputSwitch
                      label={Localize.get('showAll').defaultMessage('showAll')}
                      onClick={(event) => {
                        dispatch(setShowAllPumps(event.target.checked));
                      }}
                      disabled={
                        (showAllProductRanges ||
                          showAllProductFamilies ||
                          !userRules?.stepSix?.showAllEnabled) &&
                        selectedSearchCriteria !== SEARCH_METHOD.NO_RESTRICTION
                      }
                      checked={
                        showAllPumps ||
                        ((showAllProductFamilies || showAllProductRanges) &&
                          selectedSearchCriteria !== SEARCH_METHOD.NO_RESTRICTION)
                      }
                    />
                  )}
              </Box>
              <DataGridList
                loading={fetchPumpsLoading}
                columnHeaderHeight={100}
                onColumnOrderChange={onColumnOrderChange}
                columns={columnsRef?.current || []}
                columnVisibilityModel={resultListModel?.viewModel ?? {}}
                onColumnVisibilityModelChange={(newModel) => dispatch(setViewModel(newModel))}
                sortModel={resultListModel?.sortModel ?? []}
                onSortModelChange={(newSortModel) => dispatch(setSortModel(newSortModel))}
                rows={
                  showAllPumps ||
                  showAllProductFamilies ||
                  showAllProductRanges ||
                  selectedSearchCriteria === SEARCH_METHOD.OTHER_CRITERIA
                    ? listOfAllPumps
                    : listOfValidPumps
                }
                selectionModel={selectedPump?.id}
                onSelectionModelChange={onSelectionModelChange}
                density="compact"
                getRowHeight={() => 'auto'}
                scrollable="scrollable"
                fixedSCroll="true"
                pagination
                columnBuffer={25}
                pageSizeOptions={[5, 10, 25, 100]}
                slots={{
                  toolbar: CustomToolbar
                }}
              />
            </Box>
          }
        />
        {graphDataOriginal?.resultValues?.calculation_values && (
          <>
            <ResultListAccordion
              title={Localize.get('dutyPointsTableTitle').defaultMessage('dutyPointsTableTitle')}
              details={
                <DataTable
                  graphs={graphDataOriginal?.resultValues?.calculation_values?.graphs}
                  unitMetrics={unitMetrics}
                  selectedDutyPoints={selectedDutyPoints}
                  handleMouseClick={handleMouseClick}
                />
              }
            />

            <ResultListAccordion
              title={Localize.get('graphsTitle').defaultMessage('graphsTitle')}
              accordionStyle="graphAccordion"
              details={
                graphData?.speedCapacityList?.length &&
                selectedPump?.model !== null &&
                selectedPump?.model !== undefined &&
                selectedPump?.statorMaterial !== null &&
                selectedPump?.statorMaterial !== undefined &&
                selectedPump?.statorType !== null &&
                selectedPump?.statorType !== undefined ? (
                  <Graphs
                    unitMetrics={unitMetrics}
                    graphData={graphData}
                    capacityChartRef={capacityChartRef}
                    powerChartRef={powerChartRef}
                    rsvCartRef={rsvCartRef}
                    pdfDialogOpen={pdfDialogOpen}
                    titleForGraphs={titleForGraphs}
                    selectedPump={selectedPump}
                  />
                ) : (
                  <Typography>
                    {Localize.get('pumpDataMissing').defaultMessage('pumpDataMissing')}
                  </Typography>
                )
              }
            />
            <Divider />
          </>
        )}
      </SalesCloudContainer>
      <AnalyzerFooter
        nextDisabled={true}
        setPdfDialogOpen={setPdfDialogOpen}
        pdfEnabled={
          graphData?.speedCapacityList?.length &&
          selectedPump?.model !== null &&
          selectedPump?.model !== undefined &&
          selectedPump?.statorMaterial !== null &&
          selectedPump?.statorMaterial !== undefined &&
          selectedPump?.statorType !== null &&
          selectedPump?.statorType !== undefined
        }
        configureButton={
          cpqData?.QuoteNumber && (
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedPump}
              onClick={handleSentToCPQNew}
              className="footerButton"
            >
              {Localize.get('configure').defaultMessage('configure')}
            </Button>
          )
        }
      />
    </>
  );
};

export default ResultList;
