import React, { useState } from 'react';

import { Box } from '@mui/material';

const checkClassName = (providedClassNames) => {
  if (providedClassNames) {
    return `${providedClassNames?.classNameBase ?? ''} ${
      window.self !== window.top
        ? providedClassNames?.classNameCpq ?? ''
        : providedClassNames?.classNameStandalone ?? ''
    }`;
  } else {
    return `attributesContainer ${
      window.self !== window.top
        ? 'attributesContainerWithFooterCPQ'
        : 'attributesContainerWithFooter'
    }`;
  }
};

const SalesCloudContainer = ({ children, providedClassNames }) => {
  const [containerClassName] = useState(checkClassName(providedClassNames));
  return <Box className={containerClassName}>{children}</Box>;
};

export default SalesCloudContainer;
