import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import { DEFAULT_VALUES, SEARCH_METHOD, SETTINGS_DIALOG } from '@common/constants';
import { GENERAL_ATTRIBUTES_PATHS } from '@common/network/ApiPaths';
import { setFluidSliceToInit } from '@common/slices/fluidSlice/fluidSlice';
import {
  selectIndustryCluster,
  selectIndustryOfApplication,
  selectDynamicIOAFields,
  selectDynamicIOAFieldsValues,
  setIndustryCluster,
  setIndustryOfApplication,
  setAttributeValue,
  setSelectAttributeValue,
  selectGeneralAttributesNextAllowed,
  setQuickSelectionOfAttributes
} from '@common/slices/industrySlice/industrySlice';
import { STEP_VALUES } from '@common/slices/layoutSlice/config/layoutConfig';
import { selectCurrentSubStep, setCurrentSubStep } from '@common/slices/layoutSlice/layoutSlice';
import { selectExpertNeeded } from '@common/slices/operationCondition/operationConditionSlice';
import { setResultListToInit } from '@common/slices/resultListSlice/resultListSlice';
import {
  selectSearchType,
  setSearchMethodSliceToInit
} from '@common/slices/searchMethodSlice/searchMethodSlice';
import { selectLanguageSession, setShowSettingsDialog } from '@common/slices/userSlice/userSlice';
import AstSelector from '@components/AstSelector/AstSelector';
import AttributeInput from '@components/AttributeInput';
import DetailsTitle from '@components/DetailsTitle/DetailsTitle';
import SalesCloudContainer from '@components/SalesCouldContainer/SalesCloudContainer';
import StringToElement from '@components/StringToElement';
import { insertPathVariables } from '@services/BaseApi';

import AnalyzerFooter from '../StepperComponent/AnalyzerFooter';

const isDisabled = (nextAllowed, activeSubStep, industryCluster, industry) => {
  return (
    (activeSubStep.value === STEP_VALUES.ioaAttributes.value && !nextAllowed) ||
    (activeSubStep.value === STEP_VALUES.industryCluster.value && !industryCluster?.id) ||
    (activeSubStep.value === STEP_VALUES.industryOfApplication.value && !industry?.id)
  );
};

const GeneralAttributes = () => {
  const dispatch = useDispatch();
  const activeSubStep = useSelector(selectCurrentSubStep);

  const language = useSelector(selectLanguageSession);
  const industryCluster = useSelector(selectIndustryCluster);
  const industry = useSelector(selectIndustryOfApplication);
  const dynamicIOAFields = useSelector(selectDynamicIOAFields);
  const dynamicIOAFieldsValues = useSelector(selectDynamicIOAFieldsValues);
  const nextAllowed = useSelector(selectGeneralAttributesNextAllowed);

  const selectedSearchCriteria = useSelector(selectSearchType);

  const expertNeeded = useSelector(selectExpertNeeded);

  const [anchorEl, setAnchorEl] = useState(null);

  const onChangeUnitsClick = () => {
    dispatch(setShowSettingsDialog(SETTINGS_DIALOG.SESSION));
  };

  const handleSelector = (selectionType, id) => {
    switch (selectionType) {
      case 'industryCluster':
        dispatch(setIndustryCluster(id));
        dispatch(setCurrentSubStep(STEP_VALUES.industryOfApplication));
        break;
      case 'industry':
        dispatch(setIndustryOfApplication(id));
        dispatch(setCurrentSubStep(STEP_VALUES.ioaAttributes));
        break;
      default:
        break;
    }

    dispatch(setFluidSliceToInit());
    if (selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
      dispatch(setSearchMethodSliceToInit());
      dispatch(setResultListToInit());
    }
  };

  const handleOnChangeDynamicValues = (fieldType, fieldName, event, type_id, column) => {
    if (fieldType === 'select') {
      if (type_id === 3) {
        dispatch(
          setSelectAttributeValue({
            fieldName,
            value: event.target.value,
            column
          })
        );
      } else {
        dispatch(
          setAttributeValue({
            fieldName,
            value: event.target.value
          })
        );
        if (fieldName === 'HazardousArea' && event.target.value === 0) {
          dispatch(
            setQuickSelectionOfAttributes({
              HazardousAreaDustRating: 9,
              HazardousAreaGasRating: 5,
              HazardousAreaTemperatureRating: 13,
              ExZoneCertification: 20
            })
          );
        }
        if (fieldName === 'SmoothSurface' && event.target.value === 0) {
          dispatch(
            setQuickSelectionOfAttributes({
              ThreeA: 0,
              EHEDG: 0
            })
          );
        }
        if (fieldName === 'FoodGrade' && event.target.value === 0) {
          dispatch(
            setQuickSelectionOfAttributes({
              FDA: 0,
              EC19352004: 0,
              GB4806: 0,
              Anvisa: 0
            })
          );
        }
      }
    }

    if (fieldType === 'checkbox') {
      dispatch(
        setAttributeValue({
          fieldName,
          value: event.target.checked
        })
      );
    }

    if (selectedSearchCriteria !== SEARCH_METHOD.OTHER_CRITERIA) {
      dispatch(setSearchMethodSliceToInit());
      dispatch(setResultListToInit());
    }
  };

  const onHintClick = (event, hint) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl({ anchorRef: event.currentTarget, hint });
  };

  const onHintClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl?.anchorRef);
  const id = open ? DEFAULT_VALUES.POP_OVER_ID : undefined;

  return (
    <>
      <SalesCloudContainer>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl?.anchorRef}
          onClose={onHintClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box className="hintValue">
            <StringToElement htmlString={anchorEl?.hint ?? ''} />
          </Box>
        </Popover>
        {
          {
            [STEP_VALUES.industryCluster.value]: (
              <AstSelector
                key={STEP_VALUES.industryCluster.value}
                selectionType="industryCluster"
                title={Localize.get('pleaseSelectIndustryCluster').defaultMessage(
                  'pleaseSelectIndustryCluster'
                )}
                description={Localize.get('industryClusterDescription').defaultMessage(
                  'industryClusterDescription'
                )}
                searchTitle={Localize.get('searchIndustryClusters').defaultMessage(
                  'searchIndustryClusters'
                )}
                onClick={handleSelector}
                path={GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_CLUSTERS}
                language={language}
              />
            ),
            [STEP_VALUES.industryOfApplication.value]: (
              <AstSelector
                key={STEP_VALUES.industryOfApplication.value}
                selectionType="industry"
                title={Localize.get('pleaseSelectIndustryOfApplication').defaultMessage(
                  'pleaseSelectIndustryOfApplication'
                )}
                description={Localize.get('industryDescription').defaultMessage(
                  'industryDescription'
                )}
                searchTitle={Localize.get('searchIndustryOfApplication').defaultMessage(
                  'searchIndustryOfApplication'
                )}
                onClick={handleSelector}
                path={insertPathVariables(GENERAL_ATTRIBUTES_PATHS.GET_INDUSTRY_OF_APPLICATIONS, {
                  industryClusterId: industryCluster?.id
                })}
                language={language}
              />
            ),
            [STEP_VALUES.ioaAttributes.value]: (
              <Box>
                <DetailsTitle
                  title={Localize.get('pleaseProvideInfoAboutGeneralAttributes').defaultMessage(
                    'pleaseProvideInfoAboutGeneralAttributes'
                  )}
                  description={Localize.get('industryAttributesDescription').defaultMessage(
                    'industryAttributesDescription'
                  )}
                  onChangeUnitsClick={onChangeUnitsClick}
                />

                <Box className="attributeList">
                  {dynamicIOAFields.firstColumn.map((dynamicField, index) => (
                    <AttributeInput
                      key={index}
                      dynamicField={dynamicField}
                      onHintClick={onHintClick}
                      onChange={handleOnChangeDynamicValues}
                      values={dynamicIOAFieldsValues}
                      column="firstColumn"
                    />
                  ))}

                  {dynamicIOAFields.secondColumn.map((dynamicField, index) => (
                    <AttributeInput
                      key={index}
                      dynamicField={dynamicField}
                      onHintClick={onHintClick}
                      onChange={handleOnChangeDynamicValues}
                      values={dynamicIOAFieldsValues}
                      column="secondColumn"
                    />
                  ))}
                </Box>
              </Box>
            )
          }[activeSubStep.value]
        }
      </SalesCloudContainer>

      <AnalyzerFooter
        nextDisabled={isDisabled(nextAllowed?.attributes, activeSubStep, industryCluster, industry)}
        expertNeeded={expertNeeded}
      />
    </>
  );
};

export default GeneralAttributes;
